export default {
    "scalars": [
        1,
        5,
        7,
        8,
        9,
        11,
        12,
        15,
        21,
        27,
        29,
        47,
        49,
        54,
        87,
        91,
        92,
        95,
        98,
        114,
        117,
        118,
        127,
        130,
        133,
        135,
        136,
        137,
        138,
        140,
        142,
        146,
        149,
        153,
        159,
        162,
        163,
        164,
        170,
        171,
        174,
        175,
        176,
        177,
        180,
        181,
        182,
        184,
        185,
        194,
        196,
        214,
        215,
        216,
        229,
        236,
        243,
        246,
        248,
        264,
        311,
        313,
        316,
        317,
        319,
        331,
        346,
        355,
        363,
        364,
        370,
        371,
        373,
        374,
        394,
        440,
        448,
        449,
        461,
        510,
        520,
        522,
        530,
        532,
        535,
        540,
        542,
        562,
        565,
        577,
        578,
        580,
        586,
        593,
        596,
        624,
        626,
        634,
        712,
        724,
        725,
        732,
        740,
        758,
        759,
        769,
        782,
        816,
        820,
        835,
        841,
        842,
        849,
        859,
        867,
        873,
        874,
        876,
        891,
        892,
        899,
        901,
        905,
        907,
        908,
        912,
        913,
        915,
        917,
        918,
        921,
        926,
        936,
        942,
        949,
        950,
        951,
        974,
        977,
        980,
        988,
        989,
        994,
        1014,
        1017,
        1020,
        1027,
        1030,
        1031,
        1034,
        1035,
        1036,
        1037,
        1038,
        1039,
        1040,
        1041,
        1045,
        1047,
        1048,
        1050,
        1051,
        1054,
        1057,
        1060,
        1061,
        1065,
        1066,
        1067,
        1068,
        1069,
        1075,
        1080,
        1086,
        1089,
        1090,
        1111,
        1124,
        1133,
        1139,
        1142,
        1145,
        1147,
        1149,
        1171,
        1172,
        1173,
        1178,
        1188,
        1190,
        1192,
        1194,
        1195,
        1198,
        1199,
        1206,
        1215,
        1216,
        1225,
        1227,
        1236,
        1238,
        1249,
        1258,
        1266,
        1268,
        1269,
        1275,
        1276,
        1277,
        1279,
        1285,
        1306,
        1307,
        1312,
        1316,
        1320,
        1324,
        1327,
        1338,
        1343,
        1347,
        1371,
        1375,
        1376,
        1377,
        1381,
        1383,
        1396,
        1400,
        1404,
        1423,
        1424,
        1437,
        1440,
        1441,
        1469,
        1470,
        1473,
        1474,
        1476,
        1483,
        1512,
        1513,
        1516,
        1532,
        1554,
        1568,
        1579,
        1604,
        1608,
        1619,
        1620,
        1629,
        1635,
        1636,
        1651,
        1660,
        1665,
        1676,
        1678,
        1680,
        1689,
        1690,
        1716,
        1731,
        1732,
        1846,
        1851,
        1852,
        1863,
        1865,
        1866,
        1875,
        1878,
        1887,
        1892,
        1899,
        1900,
        1901,
        1917,
        1928,
        1929,
        1931,
        1937,
        1939,
        1952,
        1956,
        1963
    ],
    "types": {
        "AcceptedInviteCompanyInfo": {
            "id": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "String": {},
        "AcceptInvitationArgs": {
            "invitationCode": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AcceptInvitationResponse": {
            "userInvitation": [
                1828
            ],
            "__typename": [
                1
            ]
        },
        "AccountBalance": {
            "accountRole": [
                1
            ],
            "balance": [
                5
            ],
            "asOfDate": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "Float": {},
        "AccountingInfo": {
            "isSalesAffecting": [
                7
            ],
            "isCogsAffecting": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "Boolean": {},
        "AccountingMethod": {},
        "AccountingServicesTypes": {},
        "AccountRole": {
            "name": [
                1
            ],
            "groupId": [
                1
            ],
            "reportsHierarchy": [
                1467,
                {
                    "reportTypes": [
                        1470,
                        "[ReportType!]!"
                    ]
                }
            ],
            "__typename": [
                1
            ]
        },
        "AccountState": {},
        "AccountStatus": {},
        "AccountValidation": {
            "openingBalanceValue": [
                5
            ],
            "lastValidAt": [
                461
            ],
            "accountState": [
                11
            ],
            "potentialOpeningValue": [
                5
            ],
            "status": [
                12
            ],
            "madePrivateAt": [
                461
            ],
            "isReconciledFor2024": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "Accrual": {
            "id": [
                15
            ],
            "accrualsData": [
                18
            ],
            "bankTransactionId": [
                15
            ],
            "companyId": [
                15
            ],
            "createdAt": [
                461
            ],
            "updatedAt": [
                461
            ],
            "archivedAt": [
                461
            ],
            "createdBy": [
                29
            ],
            "createdById": [
                1
            ],
            "latestActorType": [
                29
            ],
            "latestActorId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ID": {},
        "AccrualCategorizationInput": {
            "bankTransactionId": [
                15
            ],
            "merchant": [
                1118
            ],
            "classifications": [
                17
            ],
            "__typename": [
                1
            ]
        },
        "AccrualClassificationInput": {
            "amount": [
                5
            ],
            "classificationTagId": [
                15
            ],
            "classificationText": [
                15
            ],
            "businessEvent": [
                1
            ],
            "pairedEntityId": [
                1
            ],
            "pairedEntityType": [
                1268
            ],
            "pairingType": [
                1269
            ],
            "askTheUserResult": [
                989
            ],
            "extraData": [
                989
            ],
            "description": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AccrualData": {
            "classificationTagId": [
                15
            ],
            "businessEventType": [
                1
            ],
            "spreadType": [
                1629
            ],
            "residualAmount": [
                5
            ],
            "specificDate": [
                1621
            ],
            "spreadOverTime": [
                1627
            ],
            "customSpread": [
                451
            ],
            "defaultFixedAssetsData": [
                587
            ],
            "customFixedAssetsData": [
                587
            ],
            "canceledAt": [
                461
            ],
            "canceledBy": [
                1
            ],
            "canceledAccrualsData": [
                270
            ],
            "__typename": [
                1
            ]
        },
        "AccrualDataInput": {
            "classificationTagId": [
                1
            ],
            "businessEventType": [
                1
            ],
            "spreadType": [
                1629
            ],
            "specificDate": [
                1622
            ],
            "spreadOverTime": [
                1628
            ],
            "customSpread": [
                452
            ],
            "defaultFixedAssetsData": [
                588
            ],
            "customFixedAssetsData": [
                588
            ],
            "__typename": [
                1
            ]
        },
        "AccrualsSpreadResponse": {
            "amount": [
                5
            ],
            "postedAt": [
                461
            ],
            "classificationTag": [
                296
            ],
            "bankTransaction": [
                186
            ],
            "businessEventType": [
                1
            ],
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "accrualId": [
                1
            ],
            "bankTransactionId": [
                15
            ],
            "isRemainingExpenses": [
                7
            ],
            "isResidualAmount": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "AccrualsStatus": {},
        "AcknowledgeLoansChanges": {
            "loans": [
                1012
            ],
            "__typename": [
                1
            ]
        },
        "AcknowledgeLoansChangesInput": {
            "loans": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "AcknowledgeUnitsTrackingPhysicalCountProductQuantitiesAmountsInput": {
            "companyId": [
                1
            ],
            "physicalCountId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ActionChange": {
            "accountRole": [
                1
            ],
            "accountRoleV2": [
                10
            ],
            "moneyDirection": [
                27
            ],
            "__typename": [
                1
            ]
        },
        "ActionChangeInput": {
            "accountRole": [
                1
            ],
            "moneyDirection": [
                27
            ],
            "__typename": [
                1
            ]
        },
        "ActionChangeMoneyDirection": {},
        "ActorContextInput": {
            "actorType": [
                29
            ],
            "actorId": [
                1
            ],
            "classifiedAt": [
                461
            ],
            "metadata": [
                989
            ],
            "__typename": [
                1
            ]
        },
        "ActorType": {},
        "AddBankTransactionAttachmentInput": {
            "companyId": [
                15
            ],
            "bankTransactionId": [
                15
            ],
            "attachmentLink": [
                1
            ],
            "attachmentName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AddCompanyToParkerUserArgs": {
            "companyId": [
                1
            ],
            "companyName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AddDepreciationBonus": {
            "date": [
                461
            ],
            "percent": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "AddDepreciationBonusInput": {
            "date": [
                1
            ],
            "percent": [
                5
            ],
            "amount": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "AddMissingBillsTask": {
            "id": [
                243
            ],
            "status": [
                246
            ],
            "stateKey": [
                1
            ],
            "state": [
                35
            ],
            "history": [
                36
            ],
            "__typename": [
                1
            ]
        },
        "AddMissingBillsTaskState": {
            "markedAsCompletedAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "AddMissingBillsTaskStateChangeLog": {
            "id": [
                15
            ],
            "createdAt": [
                461
            ],
            "updaterType": [
                1963
            ],
            "changedByUid": [
                1
            ],
            "reason": [
                1
            ],
            "oldValue": [
                35
            ],
            "newValue": [
                35
            ],
            "__typename": [
                1
            ]
        },
        "AddMissingInvoicesTask": {
            "id": [
                974
            ],
            "status": [
                977
            ],
            "stateKey": [
                1
            ],
            "state": [
                38
            ],
            "history": [
                39
            ],
            "__typename": [
                1
            ]
        },
        "AddMissingInvoicesTaskState": {
            "markedAsCompletedAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "AddMissingInvoicesTaskStateChangeLog": {
            "id": [
                15
            ],
            "createdAt": [
                461
            ],
            "updaterType": [
                1963
            ],
            "changedByUid": [
                1
            ],
            "reason": [
                1
            ],
            "oldValue": [
                38
            ],
            "newValue": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "AddPriorYearAdjustmentInput": {
            "amount": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "Address": {
            "country": [
                1
            ],
            "state": [
                1
            ],
            "city": [
                1
            ],
            "postalCode": [
                1
            ],
            "line1": [
                1
            ],
            "line2": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AddressArgs": {
            "country": [
                1
            ],
            "state": [
                1
            ],
            "city": [
                1
            ],
            "postalCode": [
                1
            ],
            "line1": [
                1
            ],
            "line2": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AddRoleToAffiliatedEntityInput": {
            "companyId": [
                15
            ],
            "sourceEntityOneOf": [
                53
            ],
            "role": [
                331
            ],
            "metadata": [
                44
            ],
            "__typename": [
                1
            ]
        },
        "AddRoleToAffiliatedEntityMetadataInput": {
            "creatorEmail": [
                1
            ],
            "source": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AddRoleToAffiliatedEntityResult": {
            "createdEntityId": [
                15
            ],
            "affiliatedEntity": [
                51
            ],
            "__typename": [
                1
            ]
        },
        "AdjustmentRestockingAction": {
            "quantity": [
                47
            ],
            "id": [
                1
            ],
            "date": [
                461
            ],
            "type": [
                1
            ],
            "salesChannel": [
                1873
            ],
            "sku": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Int": {},
        "AdjustmentsValidation": {
            "adjustments": [
                1425
            ],
            "cogs": [
                1425
            ],
            "source": [
                1425
            ],
            "diff": [
                1425
            ],
            "isValid": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "AdjustmentType": {},
        "AffectedTransactionsIds": {
            "transactionsIds": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "AffiliatedEntity": {
            "id": [
                15
            ],
            "company": [
                322
            ],
            "institutions": [
                837
            ],
            "name": [
                1
            ],
            "companyAffiliates": [
                326
            ],
            "holders": [
                807
            ],
            "relatedParties": [
                1459
            ],
            "unpaidInventoryAmount": [
                5
            ],
            "lenders": [
                1150
            ],
            "employees": [
                534
            ],
            "contractors": [
                386
            ],
            "vendors": [
                1853
            ],
            "__typename": [
                1
            ]
        },
        "AffiliatedEntityByIdInput": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "AffiliateSourceInput": {
            "affiliatedEntityId": [
                15
            ],
            "companyAffiliateId": [
                15
            ],
            "vendorId": [
                15
            ],
            "institutionId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "AllowClassificationForVersion": {},
        "AlterBankTransactionsLifecycleStateInput": {
            "companyId": [
                15
            ],
            "bankTransactionIds": [
                15
            ],
            "actorType": [
                29
            ],
            "actorId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AlterBankTransactionsLifecycleStateResult": {
            "processedIds": [
                15
            ],
            "unprocessed": [
                57
            ],
            "__typename": [
                1
            ]
        },
        "AlterBankTransactionsLifecycleStateUnprocessedResult": {
            "id": [
                15
            ],
            "reason": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AmazonAvailableMarketplace": {
            "displayName": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "marketplaceId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AmazonAvailableValidationDataInput": {
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "AmazonAvailableValidationDataResponse": {
            "integrationAccountIdsData": [
                61
            ],
            "__typename": [
                1
            ]
        },
        "AmazonIntegrationAccountIdAvailableData": {
            "availableMarketplaces": [
                58
            ],
            "shipmentsReportEndTime": [
                461
            ],
            "returnsReportEndTime": [
                461
            ],
            "integrationAccountId": [
                1
            ],
            "impactOnBooksSince": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "AmazonOrdersDiffResponse": {
            "data": [
                988
            ],
            "__typename": [
                1
            ]
        },
        "AmazonProduct": {
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "finaloopId": [
                1
            ],
            "lineOfBusinessId": [
                1
            ],
            "sku": [
                1
            ],
            "price": [
                5
            ],
            "displayName": [
                1
            ],
            "imageUrl": [
                1
            ],
            "listedChannels": [
                1495
            ],
            "appliedCosts": [
                1370
            ],
            "dailySalesData": [
                1496,
                {
                    "timeRange": [
                        1386,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "totalSold": [
                47
            ],
            "avgCostPerUnit": [
                5
            ],
            "asin": [
                1
            ],
            "canEditLatestAppliedCost": [
                7
            ],
            "isFractional": [
                7
            ],
            "isHidden": [
                7
            ],
            "salesData": [
                1510,
                {
                    "timeRange": [
                        1386,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "rawRecipes": [
                1431,
                {
                    "timeRange": [
                        1386,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "componentOfProducts": [
                1368
            ],
            "isAmbiguousSku": [
                7
            ],
            "analyticsOverview": [
                1369,
                {
                    "input": [
                        80
                    ]
                }
            ],
            "warehousesAnalyticsOverview": [
                1408,
                {
                    "input": [
                        80
                    ]
                }
            ],
            "tags": [
                1
            ],
            "isCreatedManually": [
                7
            ],
            "isDataSyncing": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "AmazonProductsReconciliationResponse": {
            "validationId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AmazonProductsValidationReadyResponse": {
            "checkStartTime": [
                461
            ],
            "checkEndTime": [
                461
            ],
            "salesReports": [
                1465
            ],
            "restocksReports": [
                1465
            ],
            "data": [
                74
            ],
            "summary": [
                73
            ],
            "__typename": [
                1
            ]
        },
        "AmazonReconciliationEntityData": {
            "restocked": [
                47
            ],
            "mfn": [
                47
            ],
            "afn": [
                47
            ],
            "totalSold": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "AmazonReconciliationEntityEntityValidationStatus": {
            "restockedDiff": [
                47
            ],
            "afnDiff": [
                47
            ],
            "mfnDiff": [
                47
            ],
            "mcfDiff": [
                47
            ],
            "reportDiff": [
                47
            ],
            "totalDiff": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "AmazonReconciliationProductData": {
            "unitsSold": [
                47
            ],
            "unitsRestocked": [
                47
            ],
            "unitsGiveaway": [
                47
            ],
            "ambiguousSkuQuantity": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "AmazonReconciliationProductValidationStatus": {
            "soldDiff": [
                47
            ],
            "restockedDiff": [
                47
            ],
            "totalDiff": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "AmazonReconciliationRestockedReportData": {
            "mcf": [
                47
            ],
            "afn": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "AmazonReconciliationSalesOrderIds": {
            "recentInvoices": [
                1
            ],
            "oldInvoices": [
                1
            ],
            "notInTimeRange": [
                1
            ],
            "mfn": [
                1
            ],
            "afn": [
                1
            ],
            "all": [
                1
            ],
            "restocked": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AmazonReconciliationSalesReportData": {
            "recentInvoices": [
                47
            ],
            "oldInvoices": [
                47
            ],
            "notInTimeRange": [
                47
            ],
            "mcf": [
                47
            ],
            "afn": [
                47
            ],
            "total": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "AmazonReconciliationSummary": {
            "entityData": [
                66
            ],
            "entityValidationStatus": [
                67
            ],
            "salesReportData": [
                72
            ],
            "restocksReportData": [
                70
            ],
            "productData": [
                68
            ],
            "productValidationStatus": [
                69
            ],
            "__typename": [
                1
            ]
        },
        "AmazonSkuReconciliationData": {
            "comments": [
                1
            ],
            "sku": [
                1
            ],
            "orderIds": [
                71
            ],
            "summary": [
                73
            ],
            "__typename": [
                1
            ]
        },
        "AmazonValidationAdjustSkuQuantitiesInput": {
            "companyId": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "marketplaceId": [
                1
            ],
            "sku": [
                1
            ],
            "reportQuantity": [
                47
            ],
            "finaloopQuantity": [
                47
            ],
            "orderId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AmazonValidationInput": {
            "startTime": [
                461
            ],
            "endTime": [
                461
            ],
            "integrationAccountId": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "companyId": [
                15
            ],
            "marketplaceId": [
                1
            ],
            "ignoreCached": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "AmountRange": {
            "from": [
                5
            ],
            "to": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "AmountRangeInput": {
            "from": [
                5
            ],
            "to": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "Amounts": {
            "amount": [
                5
            ],
            "amountInHomeCurrency": [
                5
            ],
            "currency": [
                440
            ],
            "__typename": [
                1
            ]
        },
        "AnalyticsOverviewInput": {
            "asOfDate": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "AnyActiveProductWithoutQuantities": {
            "anyActiveProductWithoutQuantities": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "APAccountBalance": {
            "accountRole": [
                1
            ],
            "balance": [
                5
            ],
            "asOfDate": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "AppendProcessingLogToBankTransactionsUploadInput": {
            "companyId": [
                15
            ],
            "bankTransactionsUploadId": [
                1
            ],
            "processingLog": [
                213
            ],
            "status": [
                216
            ],
            "__typename": [
                1
            ]
        },
        "AppliedCostItem": {
            "finaloopProductId": [
                1
            ],
            "cost": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ApplyNoUnitsPhysicalCountProductQuantitiesPendingAdjustmentsInput": {
            "physicalCountId": [
                1
            ],
            "companyId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ApplyPendingSalesDataAdjustmentsInput": {
            "companyId": [
                15
            ],
            "managementMethodId": [
                1
            ],
            "mode": [
                87
            ],
            "type": [
                1377
            ],
            "salesChannels": [
                1498
            ],
            "__typename": [
                1
            ]
        },
        "ApplyPendingSalesDataAdjustmentsMode": {},
        "ApplyPendingWarehouseOpeningBalanceInput": {
            "companyId": [
                15
            ],
            "date": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "ArtificialTransactionsByMonth": {
            "type": [
                91
            ],
            "integrationType": [
                1
            ],
            "count": [
                47
            ],
            "sum": [
                5
            ],
            "month": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "ArtificialTransactionsByMonthResponse": {
            "payments": [
                89
            ],
            "refunds": [
                89
            ],
            "__typename": [
                1
            ]
        },
        "ArtificialTransactionsType": {},
        "AskTheUserFlowType": {},
        "AskTheUserNotificationInput": {
            "companyId": [
                15
            ],
            "notificationType": [
                95
            ],
            "notificationData": [
                989
            ],
            "__typename": [
                1
            ]
        },
        "AskTheUserNotificationServiceResult": {
            "success": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "AskTheUserNotificationType": {},
        "AskTheUserResult": {
            "type": [
                1
            ],
            "data": [
                989
            ],
            "__typename": [
                1
            ]
        },
        "AssembledByMap": {
            "id": [
                1
            ],
            "assembled": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "AssemblyDeletedReason": {},
        "AssignCompanyToUser": {
            "email": [
                1
            ],
            "companyId": [
                1
            ],
            "roles": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AssignCompanyToUserResponse": {
            "success": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "AttachmentTargetURL": {
            "fileName": [
                1
            ],
            "url": [
                1
            ],
            "signedUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AttachmentTargetURLS": {
            "signedUrls": [
                101
            ],
            "__typename": [
                1
            ]
        },
        "AttachRelationsToLoanInput": {
            "ids": [
                15
            ],
            "loanId": [
                15
            ],
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "AttachTransactionToCompanyLoanTransactionInput": {
            "transactionId": [
                1
            ],
            "classifications": [
                403
            ],
            "__typename": [
                1
            ]
        },
        "AttemptToClassifyTransactionsInput": {
            "classificationRequests": [
                294
            ],
            "actorContext": [
                28
            ],
            "__typename": [
                1
            ]
        },
        "Auth0UserLookupInput": {
            "email": [
                1
            ],
            "accessKey": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Auth0UserLookupResponse": {
            "exists": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "AwaitedExportResponse": {
            "requestId": [
                1
            ],
            "status": [
                562
            ],
            "signedUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BackOfficeBankStatementRequestsFiltersResponse": {
            "years": [
                1
            ],
            "tiers": [
                363
            ],
            "statuses": [
                180
            ],
            "types": [
                182
            ],
            "__typename": [
                1
            ]
        },
        "BalanceAdjustment": {
            "value": [
                5
            ],
            "forDate": [
                1
            ],
            "actorType": [
                114
            ],
            "actorId": [
                1
            ],
            "createdAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "BalanceLocation": {
            "balance": [
                5
            ],
            "balanceAtIso": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BalanceSheetStatus": {
            "status": [
                1
            ],
            "updatedAt": [
                461
            ],
            "updatedBy": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankAccount": {
            "id": [
                15
            ],
            "companyId": [
                1
            ],
            "integrationAccountId": [
                15
            ],
            "source": [
                876
            ],
            "externalSourceId": [
                1
            ],
            "externalInstitutionId": [
                1
            ],
            "type": [
                142
            ],
            "connectionStatus": [
                118
            ],
            "subtype": [
                140
            ],
            "currency": [
                1
            ],
            "currentBalance": [
                5
            ],
            "institution": [
                837
            ],
            "institutionId": [
                1
            ],
            "institutionName": [
                1
            ],
            "name": [
                1
            ],
            "fullName": [
                1
            ],
            "displayName": [
                1
            ],
            "mask": [
                1
            ],
            "connectedAt": [
                461
            ],
            "isHistoricalUpdateCompleted": [
                7
            ],
            "isMissingHistoricalTransactions": [
                7
            ],
            "userSaidThereAreNoMissingTransactions": [
                7
            ],
            "locks": [
                122
            ],
            "rawData": [
                989
            ],
            "closingBalanceLogs": [
                116
            ],
            "balanceAdjustments": [
                110
            ],
            "latestAccountingEventIds": [
                1
            ],
            "transactionsImportStatus": [
                141
            ],
            "deletedByUserAt": [
                461
            ],
            "deletedByUserId": [
                1
            ],
            "createdAt": [
                461
            ],
            "createdBy": [
                1
            ],
            "shouldUpdateAccountDetailsOnNextUpdate": [
                7
            ],
            "vendor": [
                1853
            ],
            "duplicateOfBankAccountId": [
                1
            ],
            "hasRecurringMission": [
                7
            ],
            "lockStatus": [
                125,
                {
                    "year": [
                        47
                    ]
                }
            ],
            "machineValidationData": [
                1083
            ],
            "accountValidation": [
                13
            ],
            "markedAsClosedByCustomerAt": [
                461
            ],
            "status": [
                138
            ],
            "forwardTransactionsToBankAccountId": [
                1
            ],
            "isManagedByFinaloop": [
                7
            ],
            "hasNotifiedUponNoHistoricalUpdate": [
                7
            ],
            "accountRole": [
                1
            ],
            "transactionsCount": [
                47,
                {
                    "fromDate": [
                        461
                    ],
                    "untilDate": [
                        461
                    ]
                }
            ],
            "minimumTransactionDate": [
                461,
                {
                    "fromBooksStartTime": [
                        7
                    ]
                }
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountBalanceAdjustmentActorType": {},
        "BankAccountBalanceInput": {
            "companyId": [
                15
            ],
            "bankAccountId": [
                15
            ],
            "balanceDateInISO": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountClosingBalance": {
            "value": [
                5
            ],
            "forDate": [
                1
            ],
            "actorType": [
                117
            ],
            "actorId": [
                1
            ],
            "createdAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountClosingBalanceActorType": {},
        "BankAccountConnectionStatus": {},
        "BankAccountEventIdsInput": {
            "bankAccountId": [
                15
            ],
            "eventIds": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountInput": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountInternalInput": {
            "id": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountLock": {
            "type": [
                1
            ],
            "startTime": [
                461
            ],
            "endTime": [
                461
            ],
            "lockedAt": [
                461
            ],
            "actorType": [
                29
            ],
            "actorId": [
                1
            ],
            "reason": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountLockByTypeInput": {
            "startDate": [
                461
            ],
            "endDate": [
                461
            ],
            "lockedAt": [
                461
            ],
            "actorType": [
                29
            ],
            "actorId": [
                1
            ],
            "reason": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountLockInput": {
            "startDate": [
                461
            ],
            "endDate": [
                461
            ],
            "lockedAt": [
                461
            ],
            "actorType": [
                29
            ],
            "actorId": [
                1
            ],
            "type": [
                1
            ],
            "reason": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountLockStatus": {
            "lockedAt": [
                461
            ],
            "minimumAllowedEventTime": [
                461
            ],
            "year": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountNewOwner": {
            "type": [
                127
            ],
            "id": [
                15
            ],
            "name": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountNewOwnerType": {},
        "BankAccountReconciliation": {
            "id": [
                15
            ],
            "bankAccount": [
                113
            ],
            "companyId": [
                1
            ],
            "status": [
                130
            ],
            "transactionsFileLocations": [
                576
            ],
            "statementFileLocations": [
                576
            ],
            "balanceLocation": [
                111
            ],
            "reconciliationDate": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountReconciliationsInput": {
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountReconciliationStatus": {},
        "BankAccountsByIdsInput": {
            "companyId": [
                15
            ],
            "bankAccountIds": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountsInput": {
            "companyId": [
                15
            ],
            "sources": [
                876
            ],
            "statuses": [
                138
            ],
            "types": [
                142
            ],
            "subtypes": [
                140
            ],
            "withDisabled": [
                7
            ],
            "withDeleted": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountStatementFileType": {},
        "BankAccountStatementRequest": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "fromDate": [
                461
            ],
            "toDate": [
                461
            ],
            "bankAccount": [
                160
            ],
            "type": [
                137
            ],
            "subType": [
                136
            ],
            "customerData": [
                161
            ],
            "finaloopData": [
                165
            ],
            "status": [
                135
            ],
            "fileNames": [
                1
            ],
            "displayedBalance": [
                5
            ],
            "csvUploads": [
                439
            ],
            "balanceId": [
                1
            ],
            "markedAsClosedByCustomerAt": [
                461
            ],
            "lockedStatus": [
                1076
            ],
            "originalBankAccountId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountStatementRequestStatus": {},
        "BankAccountStatementRequestSubType": {},
        "BankAccountStatementRequestType": {},
        "BankAccountStatus": {},
        "BankAccountStopImportNewOwner": {
            "id": [
                1
            ],
            "type": [
                127
            ],
            "name": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountSubtype": {},
        "BankAccountTransactionsImportStatus": {
            "changedBy": [
                1
            ],
            "changedAt": [
                461
            ],
            "stopImportFromDate": [
                461
            ],
            "balanceAtStopImportFromDate": [
                5
            ],
            "newOwner": [
                139
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountType": {},
        "BankConnection": {
            "companyId": [
                15
            ],
            "integrationType": [
                876
            ],
            "institution": [
                837
            ],
            "integrationAccountId": [
                15
            ],
            "institutionName": [
                1
            ],
            "institutionLogo": [
                1
            ],
            "isTest": [
                7
            ],
            "accounts": [
                144
            ],
            "isRefreshRequired": [
                7
            ],
            "isDisconnected": [
                7
            ],
            "syncStatus": [
                150
            ],
            "impactOnBooksSince": [
                461
            ],
            "deletedAt": [
                461
            ],
            "deletedBy": [
                1
            ],
            "status": [
                149
            ],
            "__typename": [
                1
            ]
        },
        "BankConnectionAccount": {
            "id": [
                15
            ],
            "minimumTransactionDate": [
                461,
                {
                    "fromBooksStartTime": [
                        7
                    ]
                }
            ],
            "institutionName": [
                1
            ],
            "institutionLogo": [
                1
            ],
            "institution": [
                837
            ],
            "integrationType": [
                876
            ],
            "integrationAccountId": [
                15
            ],
            "externalInstitutionId": [
                1
            ],
            "name": [
                1
            ],
            "fullName": [
                1
            ],
            "mask": [
                1
            ],
            "type": [
                142
            ],
            "subtype": [
                140
            ],
            "currentBalance": [
                5
            ],
            "status": [
                138
            ],
            "connectionStatus": [
                146
            ],
            "transactionsImportStatus": [
                147
            ],
            "isManual": [
                7
            ],
            "isRefreshRequired": [
                7
            ],
            "isDisconnected": [
                7
            ],
            "deletedAt": [
                461
            ],
            "deletedBy": [
                1
            ],
            "createdAt": [
                461
            ],
            "createdBy": [
                1
            ],
            "connectedAt": [
                461
            ],
            "duplicateOfBankAccountId": [
                1
            ],
            "hasRecurringMission": [
                7
            ],
            "isHistoricalUpdateCompleted": [
                7
            ],
            "currency": [
                1
            ],
            "markedAsClosedByCustomerAt": [
                461
            ],
            "locks": [
                145
            ],
            "isManagedByFinaloop": [
                7
            ],
            "accountValidation": [
                13
            ],
            "__typename": [
                1
            ]
        },
        "BankConnectionAccountLock": {
            "startTime": [
                461
            ],
            "endTime": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "BankConnectionAccountStatus": {},
        "BankConnectionAccountTransactionsImportStatus": {
            "changedBy": [
                1
            ],
            "changedAt": [
                461
            ],
            "stopImportFromDate": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "BankConnectionsInput": {
            "companyId": [
                15
            ],
            "withDeleted": [
                7
            ],
            "sources": [
                876
            ],
            "statuses": [
                138
            ],
            "__typename": [
                1
            ]
        },
        "BankConnectionStatus": {},
        "BankConnectionSyncStatus": {
            "lastSuccessfulTransactionsUpdate": [
                461
            ],
            "lastFailedTransactionsUpdate": [
                461
            ],
            "updatedAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "BankDetails": {
            "name": [
                1
            ],
            "lastFourDigits": [
                1
            ],
            "type": [
                1
            ],
            "missingBank": [
                7
            ],
            "accountName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankReconciliationRequest": {
            "id": [
                15
            ],
            "bankAccount": [
                113
            ],
            "companyId": [
                1
            ],
            "company": [
                322
            ],
            "requestType": [
                182
            ],
            "resolutionComment": [
                1
            ],
            "internalMessage": [
                1
            ],
            "finaloopMessage": [
                1
            ],
            "finaloopMessageSender": [
                1
            ],
            "expectedEndDate": [
                461
            ],
            "customerSelectedDate": [
                461
            ],
            "booksBalanceAtDate": [
                5
            ],
            "customerBalanceByStatement": [
                5
            ],
            "customerConfirmedBooksBalanceAtDate": [
                7
            ],
            "resolutionType": [
                176
            ],
            "irrelevantReason": [
                175
            ],
            "transferredEntity": [
                177
            ],
            "defaultEndDate": [
                461
            ],
            "status": [
                180
            ],
            "isBankAccountValid": [
                7
            ],
            "latestActorId": [
                1
            ],
            "latestActorType": [
                29
            ],
            "statementFiles": [
                1447
            ],
            "hasTransactionsBeforeBSD": [
                7
            ],
            "completedStandardOpeningBalance": [
                7
            ],
            "accountCurrentBalance": [
                5
            ],
            "lastAccountTransaction": [
                992
            ],
            "deletedAt": [
                461
            ],
            "createdAt": [
                461
            ],
            "updatedAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "BankReconciliationRequestFilesStatus": {},
        "BankReconciliationRequestInput": {
            "id": [
                15
            ],
            "companyId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankReconciliationRequestsInput": {
            "ids": [
                1
            ],
            "companyId": [
                1
            ],
            "bankAccountIds": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankReconciliationStatementAutoProcessingResultsInput": {
            "companyId": [
                1
            ],
            "requestId": [
                1
            ],
            "status": [
                159
            ],
            "message": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankStatementAccountHolder": {
            "firstName": [
                1
            ],
            "lastName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankStatementAccountHolderInput": {
            "firstName": [
                1
            ],
            "lastName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankStatementAutoProcessingStatus": {},
        "BankStatementBankAccount": {
            "id": [
                15
            ],
            "integrationAccountId": [
                15
            ],
            "name": [
                1
            ],
            "type": [
                142
            ],
            "subtype": [
                140
            ],
            "institutionName": [
                1
            ],
            "institutionId": [
                1
            ],
            "currency": [
                1
            ],
            "mask": [
                1
            ],
            "source": [
                876
            ],
            "transactionsImportStatus": [
                1679
            ],
            "__typename": [
                1
            ]
        },
        "BankStatementCustomerData": {
            "fileNames": [
                1
            ],
            "csvFileNames": [
                1
            ],
            "noCSVFiles": [
                7
            ],
            "noPDFFiles": [
                7
            ],
            "displayedBalance": [
                5
            ],
            "realBalance": [
                5
            ],
            "cycleStart": [
                461
            ],
            "cycleEnd": [
                461
            ],
            "isConfirmed": [
                7
            ],
            "irrelevantReason": [
                1
            ],
            "irrelevantType": [
                980
            ],
            "isMachineReconciled": [
                7
            ],
            "newOwner": [
                1
            ],
            "newOwnerType": [
                127
            ],
            "newOwnerId": [
                1
            ],
            "newOwnerName": [
                1
            ],
            "bankDetails": [
                151
            ],
            "transferDate": [
                461
            ],
            "closedDate": [
                461
            ],
            "relatedMissionId": [
                1
            ],
            "isIntegrationTransfers": [
                7
            ],
            "isClosedThisYear": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "BankStatementFileSubSystem": {},
        "BankStatementFilesValidationProvider": {},
        "BankStatementFileSystem": {},
        "BankStatementFinaloopData": {
            "comment": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankStatementParsedTransaction": {
            "date": [
                1
            ],
            "description": [
                1
            ],
            "amount": [
                5
            ],
            "runningBalance": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "BankStatementParsedTransactionInput": {
            "date": [
                1
            ],
            "description": [
                1
            ],
            "amount": [
                5
            ],
            "runningBalance": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "BankStatementParsingRequest": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "bankTransactionsUploadId": [
                1
            ],
            "bankStatementFileId": [
                1
            ],
            "provider": [
                170
            ],
            "providerModel": [
                1
            ],
            "queryVersion": [
                47
            ],
            "requestPayload": [
                989
            ],
            "runStatus": [
                171
            ],
            "rawResponse": [
                989
            ],
            "startTime": [
                461
            ],
            "endTime": [
                461
            ],
            "responseTimeMs": [
                5
            ],
            "failureReason": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankStatementParsingRequestInput": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "bankTransactionsUploadId": [
                1
            ],
            "bankStatementFileId": [
                1
            ],
            "provider": [
                170
            ],
            "providerModel": [
                1
            ],
            "queryVersion": [
                47
            ],
            "requestPayload": [
                989
            ],
            "runStatus": [
                171
            ],
            "rawResponse": [
                989
            ],
            "startTime": [
                461
            ],
            "endTime": [
                461
            ],
            "responseTimeMs": [
                5
            ],
            "failureReason": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankStatementParsingRequestsProvider": {},
        "BankStatementParsingRequestsRunStatus": {},
        "BankStatementParsingResult": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "bankStatementParsingRequestId": [
                1
            ],
            "bankTransactionsUploadId": [
                1
            ],
            "bankStatementFileId": [
                1
            ],
            "provider": [
                170
            ],
            "providerModel": [
                1
            ],
            "queryVersion": [
                47
            ],
            "status": [
                174
            ],
            "institutionName": [
                1
            ],
            "accountName": [
                1
            ],
            "mask": [
                1
            ],
            "openingBalance": [
                5
            ],
            "closingBalance": [
                5
            ],
            "startDate": [
                461
            ],
            "endDate": [
                461
            ],
            "isReconciled": [
                7
            ],
            "isReconciledByProvider": [
                7
            ],
            "transactions": [
                166
            ],
            "accountHolders": [
                157
            ],
            "transactionsCount": [
                47
            ],
            "totalTransactionsAmount": [
                5
            ],
            "balanceChange": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "BankStatementParsingResultsInput": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "bankStatementParsingRequestId": [
                1
            ],
            "bankTransactionsUploadId": [
                1
            ],
            "bankStatementFileId": [
                1
            ],
            "provider": [
                170
            ],
            "providerModel": [
                1
            ],
            "queryVersion": [
                47
            ],
            "institutionName": [
                1
            ],
            "accountName": [
                1
            ],
            "mask": [
                1
            ],
            "openingBalance": [
                5
            ],
            "closingBalance": [
                5
            ],
            "startDate": [
                461
            ],
            "endDate": [
                461
            ],
            "isReconciled": [
                7
            ],
            "isReconciledByProvider": [
                7
            ],
            "transactions": [
                167
            ],
            "accountHolders": [
                158
            ],
            "transactionsCount": [
                47
            ],
            "totalTransactionsAmount": [
                5
            ],
            "balanceChange": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "BankStatementParsingResultStatus": {},
        "BankStatementReconciliationIrrelevantReason": {},
        "BankStatementReconciliationResolutionType": {},
        "BankStatementReconciliationTransferredEntity": {},
        "BankStatementRequestResolutionInput": {
            "id": [
                15
            ],
            "companyId": [
                1
            ],
            "resolutionType": [
                176
            ],
            "irrelevantReason": [
                175
            ],
            "transferredEntity": [
                177
            ],
            "customerConfirmedBooksBalanceAtDate": [
                7
            ],
            "customerBalanceByStatement": [
                5
            ],
            "customerSelectedDate": [
                461
            ],
            "booksBalanceAtDate": [
                5
            ],
            "statementFiles": [
                1630
            ],
            "forceSubmit": [
                7
            ],
            "fileIds": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankStatementRequestResolutionResponse": {
            "status": [
                180
            ],
            "submissionStatus": [
                181
            ],
            "fileValidationStatus": [
                153
            ],
            "fileIds": [
                1
            ],
            "errors": [
                989
            ],
            "__typename": [
                1
            ]
        },
        "BankStatementRequestStatus": {},
        "BankStatementRequestSubmissionStatus": {},
        "BankStatementRequestType": {},
        "BankStatementValidationResults": {
            "fileId": [
                15
            ],
            "runStatus": [
                184
            ],
            "failureReason": [
                1
            ],
            "matchConfidence": [
                5
            ],
            "matchedComponents": [
                185
            ],
            "institution": [
                1
            ],
            "startDate": [
                461
            ],
            "endDate": [
                461
            ],
            "openingBalance": [
                5
            ],
            "closingBalance": [
                5
            ],
            "accountNumber": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankStatementValidationRunStatus": {},
        "BankStatementVerificationComponents": {},
        "BankTransaction": {
            "id": [
                15
            ],
            "accrual": [
                14
            ],
            "companyId": [
                15
            ],
            "bankAccount": [
                113
            ],
            "postedAt": [
                461
            ],
            "amount": [
                5
            ],
            "currency": [
                1
            ],
            "amountInUsd": [
                5
            ],
            "merchant": [
                1114
            ],
            "businessEvent": [
                188
            ],
            "classificationMetadata": [
                285
            ],
            "description": [
                1
            ],
            "processedByMachineAt": [
                461
            ],
            "lastProcessedByMachineAt": [
                461
            ],
            "applyingClassificationJobId": [
                1
            ],
            "source": [
                1
            ],
            "sourceSpecificData": [
                989
            ],
            "userMemo": [
                199
            ],
            "attachments": [
                187
            ],
            "classificationVersion": [
                47
            ],
            "deletedAt": [
                461
            ],
            "recommendations": [
                202
            ],
            "loan": [
                353
            ],
            "loanInfo": [
                1677
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionAttachment": {
            "signedUrl": [
                1
            ],
            "url": [
                1
            ],
            "name": [
                1
            ],
            "addedBy": [
                1
            ],
            "createdAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionBusinessEvent": {
            "classifiedAt": [
                461
            ],
            "classifierType": [
                1
            ],
            "classifiedBy": [
                1
            ],
            "classifications": [
                189
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionBusinessEventClassification": {
            "businessEvent": [
                1
            ],
            "amount": [
                5
            ],
            "pairedEntityId": [
                1
            ],
            "pairedEntityType": [
                1268
            ],
            "pairingType": [
                1269
            ],
            "classificationTagId": [
                1
            ],
            "classificationTagText": [
                1
            ],
            "classificationText": [
                1
            ],
            "description": [
                1
            ],
            "extraData": [
                989
            ],
            "askTheUserResult": [
                96
            ],
            "pairedEntity": [
                1267
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionByMerchant": {
            "groupId": [
                15
            ],
            "merchant": [
                1114
            ],
            "transactions": [
                186
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionByMerchantResult": {
            "groups": [
                190
            ],
            "total": [
                47
            ],
            "nextToken": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionCategorizationInput": {
            "bankTransactionId": [
                15
            ],
            "merchant": [
                1118
            ],
            "classifications": [
                195
            ],
            "recommendations": [
                292
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionCategorizationResult": {
            "bankTransaction": [
                186
            ],
            "status": [
                194
            ],
            "error": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionCategorizationResultStatus": {},
        "BankTransactionClassificationInput": {
            "amount": [
                5
            ],
            "classificationTagId": [
                15
            ],
            "classificationText": [
                15
            ],
            "businessEvent": [
                1
            ],
            "pairedEntityId": [
                1
            ],
            "pairedEntityType": [
                1268
            ],
            "pairingType": [
                1269
            ],
            "askTheUserResult": [
                989
            ],
            "extraData": [
                989
            ],
            "description": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionClassifierType": {},
        "BankTransactionCursorInput": {
            "offset": [
                47
            ],
            "limit": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionInput": {
            "companyId": [
                15
            ],
            "transactionId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionMemo": {
            "content": [
                1
            ],
            "updatedAt": [
                461
            ],
            "updatedBy": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionPairingInput": {
            "pairedEntityType": [
                1268
            ],
            "pairedEntityId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionPairingResult": {
            "pairedEntityType": [
                1268
            ],
            "pairedEntityId": [
                1
            ],
            "bankTransactions": [
                186
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionRecommendation": {
            "classificationTag": [
                296
            ],
            "score": [
                5
            ],
            "model": [
                1
            ],
            "recommendationsSource": [
                1440
            ],
            "recommendedBy": [
                1441
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionResponse": {
            "bankTransactions": [
                186
            ],
            "nextOffset": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionsByMerchantInput": {
            "companyId": [
                15
            ],
            "bankAccountId": [
                15
            ],
            "dateRange": [
                463
            ],
            "merchantIds": [
                15
            ],
            "merchantIdsOperator": [
                580
            ],
            "nextToken": [
                15
            ],
            "limit": [
                47
            ],
            "searchTerm": [
                1
            ],
            "amountRange": [
                78
            ],
            "absoluteAmounts": [
                7
            ],
            "moneyDirection": [
                1142
            ],
            "searchMerchantsByName": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionsByPairingsInput": {
            "pairings": [
                200
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionsInput": {
            "companyId": [
                15
            ],
            "bankTransactionIds": [
                15
            ],
            "bankAccountIds": [
                15
            ],
            "bankAccountIdsOperator": [
                580
            ],
            "businessEvents": [
                1
            ],
            "businessEventsOperator": [
                580
            ],
            "searchTerm": [
                1
            ],
            "endDate": [
                461
            ],
            "startDate": [
                461
            ],
            "amountLte": [
                5
            ],
            "amountGte": [
                5
            ],
            "checkAgainstOriginalAmount": [
                7
            ],
            "absoluteAmounts": [
                7
            ],
            "source": [
                876
            ],
            "merchantIds": [
                15
            ],
            "searchMerchantsByName": [
                7
            ],
            "merchantIdsOperator": [
                580
            ],
            "withMerchantIdsAsPairedEntities": [
                7
            ],
            "onlyPendingUserInput": [
                7
            ],
            "moneyDirection": [
                1142
            ],
            "cursor": [
                197
            ],
            "excludePairedEntityTypes": [
                1268
            ],
            "showOnlySpreadTransactions": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionsResponse": {
            "bankTransactions": [
                186
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionsSort": {
            "closeToAmount": [
                5
            ],
            "closeToDate": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionsSummary": {
            "amountInUsd": [
                5
            ],
            "count": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionsUpload": {
            "id": [
                15
            ],
            "companyId": [
                1
            ],
            "bankAccount": [
                113
            ],
            "fileLocation": [
                211
            ],
            "uploadOriginator": [
                1
            ],
            "uploadedBy": [
                29
            ],
            "uploadedById": [
                1
            ],
            "uploadedAt": [
                461
            ],
            "processingLogs": [
                212
            ],
            "status": [
                216
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionsUploadFileLocation": {
            "bucket": [
                1
            ],
            "key": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionsUploadProcessingLog": {
            "processorType": [
                215
            ],
            "processorId": [
                1
            ],
            "createdAt": [
                461
            ],
            "state": [
                214
            ],
            "metadata": [
                989
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionsUploadProcessingLogInput": {
            "processorType": [
                215
            ],
            "processorId": [
                1
            ],
            "state": [
                214
            ],
            "message": [
                1
            ],
            "metadata": [
                989
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionsUploadProcessingLogState": {},
        "BankTransactionsUploadProcessorType": {},
        "BankTransactionsUploadStatus": {},
        "BankTransactionToLinkInput": {
            "transactionId": [
                15
            ],
            "amount": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionUploadsInput": {
            "companyId": [
                15
            ],
            "bankAccountIds": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "BankTxSaleTransaction": {
            "id": [
                15
            ],
            "integrationInfo": [
                856
            ],
            "amount": [
                5
            ],
            "postedAt": [
                461
            ],
            "currency": [
                440
            ],
            "amountInHomeCurrency": [
                5
            ],
            "type": [
                1680
            ],
            "finaloopPaymentGateway": [
                1
            ],
            "originalPaymentGateway": [
                1
            ],
            "paymentGatewayVariant": [
                1
            ],
            "isIndication": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "BaseAffiliatedEntity": {
            "id": [
                15
            ],
            "company": [
                322
            ],
            "name": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BigValidationAccountRoleAmount": {
            "amount": [
                5
            ],
            "accountRole": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BigValidationData": {
            "method": [
                1086
            ],
            "startDate": [
                461
            ],
            "endDate": [
                461
            ],
            "entityData": [
                224
            ],
            "booksData": [
                224
            ],
            "status": [
                226
            ],
            "isGreen": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "BigValidationInventoryBalanceData": {
            "inventory": [
                5
            ],
            "inventoryInProcess": [
                5
            ],
            "inventoryInTransit": [
                5
            ],
            "inventoryInTransitInternal": [
                5
            ],
            "legacyInventory": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "BigValidationPeriodData": {
            "cogs": [
                221
            ],
            "purchases": [
                221
            ],
            "endOfYearAdjustments": [
                5
            ],
            "inventoryEndingBalance": [
                223
            ],
            "inventoryOpeningBalance": [
                5
            ],
            "physicalCount": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "BigValidationResponse": {
            "data": [
                222
            ],
            "__typename": [
                1
            ]
        },
        "BigValidationStatus": {
            "openingBalance": [
                7
            ],
            "cogs": [
                7
            ],
            "methodChangeAdjustments": [
                7
            ],
            "endOfYearAdjustments": [
                7
            ],
            "endingBalance": [
                7
            ],
            "purchases": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "Bill": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "billNumber": [
                1
            ],
            "currency": [
                1
            ],
            "amount": [
                5
            ],
            "dueDate": [
                461
            ],
            "createdDate": [
                461
            ],
            "deletedAt": [
                461
            ],
            "vendor": [
                1853
            ],
            "categories": [
                276
            ],
            "balance": [
                5,
                {
                    "input": [
                        230
                    ]
                }
            ],
            "payments": [
                235
            ],
            "indicativePayments": [
                235
            ],
            "credits": [
                231
            ],
            "adjustments": [
                228
            ],
            "refunds": [
                237
            ],
            "__typename": [
                1
            ]
        },
        "BillAdjustment": {
            "id": [
                15
            ],
            "type": [
                229
            ],
            "amount": [
                5
            ],
            "currency": [
                440
            ],
            "actionTime": [
                461
            ],
            "eventTime": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "BillAdjustmentType": {},
        "BillBalanceInput": {
            "asOfDate": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "BillCredit": {
            "creditDate": [
                461
            ],
            "amount": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "Billing": {
            "id": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "BillingPayment": {
            "on_RecurringPayment": [
                1453
            ],
            "on_OneTimePayment": [
                1181
            ],
            "__typename": [
                1
            ]
        },
        "BillItemToReconcile": {
            "companyId": [
                15
            ],
            "billId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "BillPayment": {
            "id": [
                15
            ],
            "paymentDate": [
                461
            ],
            "amount": [
                5
            ],
            "feeAmount": [
                5
            ],
            "amountInUSD": [
                5
            ],
            "currency": [
                440
            ],
            "vendor": [
                1853
            ],
            "settlementType": [
                236
            ],
            "voidedAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "BillPaymentSettlementType": {},
        "BillRefund": {
            "id": [
                15
            ],
            "refundDate": [
                461
            ],
            "amount": [
                5
            ],
            "currency": [
                440
            ],
            "__typename": [
                1
            ]
        },
        "BillsAgingReportInput": {
            "companyId": [
                15
            ],
            "balanceAsOfDate": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "BillsDueDateSummary": {
            "total": [
                529
            ],
            "totalBalanceDue": [
                529
            ],
            "pastDue": [
                529
            ],
            "dueNext7Days": [
                529
            ],
            "dueAfterNext7Days": [
                529
            ],
            "__typename": [
                1
            ]
        },
        "BillsDueDateSummaryInput": {
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "BillsYearEnd": {
            "fiscalYearStartDate": [
                461
            ],
            "fiscalYearEndDate": [
                461
            ],
            "domainState": [
                1939
            ],
            "hubReportedDomainState": [
                1939
            ],
            "tasks": [
                242
            ],
            "__typename": [
                1
            ]
        },
        "BillsYearEndTask": {
            "id": [
                243
            ],
            "stateKey": [
                1
            ],
            "status": [
                246
            ],
            "on_AddMissingBillsTask": [
                34
            ],
            "on_CategorizeUncategorizedBillsTask": [
                275
            ],
            "on_CloseOpenBillsTask": [
                304
            ],
            "on_ConfirmApBalanceTask": [
                379
            ],
            "__typename": [
                1
            ]
        },
        "BillsYearEndTaskId": {},
        "BillsYearEndTasksInput": {
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "BillsYearEndTasksResponse": {
            "yearEnd": [
                241
            ],
            "__typename": [
                1
            ]
        },
        "BillsYearEndTaskStatus": {},
        "BooleanResult": {
            "success": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "BreakdownType": {},
        "BulkEditCsvTemplateInput": {
            "companyId": [
                15
            ],
            "isEmpty": [
                7
            ],
            "managementMethodId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "BulkEditCsvTemplateResponse": {
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BulkRecoveryUnitsTrackingFailureResult": {
            "company": [
                322
            ],
            "reason": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BulkRecoveryUnitsTrackingInput": {
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "BulkRecoveryUnitsTrackingResult": {
            "failureResults": [
                251
            ],
            "successResults": [
                322
            ],
            "__typename": [
                1
            ]
        },
        "BusinessEventActionsConfiguration": {
            "type": [
                1
            ],
            "variant": [
                1
            ],
            "id": [
                15
            ],
            "accrualConfig": [
                986
            ],
            "cashConfig": [
                986
            ],
            "businessEventDefinition": [
                259
            ],
            "__typename": [
                1
            ]
        },
        "BusinessEventActionsConfigurationByType": {
            "type": [
                1
            ],
            "variant": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BusinessEventCategorizationInput": {
            "classifications": [
                284
            ],
            "shouldApplyAlways": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "BusinessEventClassificationInput": {
            "businessEvent": [
                1
            ],
            "amount": [
                5
            ],
            "pairedEntityId": [
                1
            ],
            "pairedEntityType": [
                1268
            ],
            "pairingType": [
                1269
            ],
            "classificationTagId": [
                1
            ],
            "classificationTagText": [
                1
            ],
            "classificationText": [
                1
            ],
            "description": [
                1
            ],
            "extraData": [
                989
            ],
            "askTheUserResult": [
                989
            ],
            "__typename": [
                1
            ]
        },
        "BusinessEventData": {
            "id": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "BusinessEventDefinition": {
            "id": [
                15
            ],
            "name": [
                1
            ],
            "customerDisplayName": [
                1
            ],
            "groups": [
                1
            ],
            "vendorRequired": [
                7
            ],
            "requiredAffiliatedEntityType": [
                1
            ],
            "irrelevantForVendor": [
                7
            ],
            "hideForManualClassification": [
                7
            ],
            "forceShowInEventsListings": [
                7
            ],
            "moneyDirection": [
                1142
            ],
            "customerDescriptionTemplate": [
                1
            ],
            "isRelevantToAccruals": [
                7
            ],
            "isRelevantToAccrualsFixedAsset": [
                7
            ],
            "isRelevantToAccrualsFixedAssetSafeHarbor": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "BusinessEventDefinitionsByGroups": {
            "groups": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BusinessEventDefinitionsByIds": {
            "ids": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BusinessEventInput": {
            "classifications": [
                257
            ],
            "__typename": [
                1
            ]
        },
        "BusinessEventItemToReconcile": {
            "companyId": [
                15
            ],
            "businessEventId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "BusinessEventRuleType": {},
        "BusinessEventsAccrualsConfig": {
            "id": [
                1
            ],
            "isRelevantToAccruals": [
                7
            ],
            "isRelevantToAccrualsFixedAsset": [
                7
            ],
            "isRelevantToAccrualsFixedAssetSafeHarbor": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "CalculateInvoiceInput": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "companyAddressId": [
                15
            ],
            "customerId": [
                15
            ],
            "shippingAddressId": [
                15
            ],
            "billingAddressId": [
                15
            ],
            "displayName": [
                1
            ],
            "postedAt": [
                461
            ],
            "dueDate": [
                461
            ],
            "taxFixedAmount": [
                5
            ],
            "lineItems": [
                1481
            ],
            "discounts": [
                519
            ],
            "paymentMethodIds": [
                15
            ],
            "customerNote": [
                1
            ],
            "issueInvoice": [
                7
            ],
            "currency": [
                440
            ],
            "__typename": [
                1
            ]
        },
        "CalendlyEventInput": {
            "eventId": [
                1
            ],
            "inviteeId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CalendlyEventResponse": {
            "email": [
                1
            ],
            "name": [
                1
            ],
            "phoneNumber": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CancelAccrualInput": {
            "companyId": [
                15
            ],
            "bankTransactionId": [
                15
            ],
            "canceledAtData": [
                271
            ],
            "__typename": [
                1
            ]
        },
        "CanceledAccrualData": {
            "classificationTagId": [
                15
            ],
            "businessEventType": [
                1
            ],
            "spreadType": [
                1629
            ],
            "residualAmount": [
                5
            ],
            "specificDate": [
                1621
            ],
            "spreadOverTime": [
                1627
            ],
            "customSpread": [
                451
            ],
            "defaultFixedAssetsData": [
                587
            ],
            "customFixedAssetsData": [
                587
            ],
            "__typename": [
                1
            ]
        },
        "CanceledAtDataItem": {
            "canceledAt": [
                1
            ],
            "classificationTagId": [
                1
            ],
            "businessEventType": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CancelInvalidInventoryPaymentGroupsInput": {
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "CancelInvalidInventoryPaymentGroupsResponse": {
            "canceledGroupIds": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CategorizeBankTransactionsInput": {
            "companyId": [
                15
            ],
            "categorizations": [
                192
            ],
            "__typename": [
                1
            ]
        },
        "CategorizeUncategorizedBillsTask": {
            "id": [
                243
            ],
            "status": [
                246
            ],
            "stateKey": [
                1
            ],
            "amount": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "Category": {
            "description": [
                1
            ],
            "amount": [
                5
            ],
            "classificationTag": [
                296
            ],
            "__typename": [
                1
            ]
        },
        "CategoryBreakdown": {
            "category": [
                313
            ],
            "amount": [
                5
            ],
            "costType": [
                394
            ],
            "__typename": [
                1
            ]
        },
        "ChangeLog": {
            "id": [
                15
            ],
            "createdAt": [
                461
            ],
            "updaterType": [
                1963
            ],
            "changedByUid": [
                1
            ],
            "reason": [
                1
            ],
            "on_AddMissingBillsTaskStateChangeLog": [
                36
            ],
            "on_AddMissingInvoicesTaskStateChangeLog": [
                39
            ],
            "on_CloseOpenBillsTaskStateChangeLog": [
                306
            ],
            "on_CloseOpenInvoicesTaskStateChangeLog": [
                309
            ],
            "on_ConfirmApBalanceTaskStateChangeLog": [
                381
            ],
            "on_ConfirmArBalanceTaskStateChangeLog": [
                384
            ],
            "on_LinkInvoicePaymentsTaskStateChangeLog": [
                1003
            ],
            "on_YearEndDomainStateChangeLog": [
                1940
            ],
            "on_YearEndDomainStateDataChangeLog": [
                1941
            ],
            "on_YearEndStateChangeLog": [
                1958
            ],
            "on_YearEndUserUnresponsiveChangeLog": [
                1966
            ],
            "__typename": [
                1
            ]
        },
        "ChangeProductsHiddenStatusInput": {
            "companyId": [
                15
            ],
            "productStatusChange": [
                1405
            ],
            "__typename": [
                1
            ]
        },
        "Chargeback": {
            "companyId": [
                15
            ],
            "id": [
                15
            ],
            "source": [
                876
            ],
            "integrationAccountId": [
                15
            ],
            "integrationAccountName": [
                1
            ],
            "amount": [
                5
            ],
            "currency": [
                1
            ],
            "postedAt": [
                461
            ],
            "disputeId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "ChartDataInput": {
            "companyId": [
                15
            ],
            "chartName": [
                1
            ],
            "fromDate": [
                461
            ],
            "toDate": [
                461
            ],
            "breakdown": [
                1
            ],
            "timezone": [
                1
            ],
            "comparisonPeriod": [
                1
            ],
            "view": [
                1
            ],
            "stores": [
                1
            ],
            "saleRank": [
                47
            ],
            "quantityView": [
                47
            ],
            "returnRateRank": [
                47
            ],
            "productIds": [
                1
            ],
            "productMarginRank": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "ChartDataResponse": {
            "title": [
                1
            ],
            "updatedAt": [
                461
            ],
            "category": [
                1
            ],
            "data": [
                460
            ],
            "labels": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CheckAwaitedExportStatusInput": {
            "companyId": [
                15
            ],
            "requestId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ClassificationInput": {
            "businessEvent": [
                1
            ],
            "amount": [
                5
            ],
            "description": [
                1
            ],
            "pairedEntityId": [
                1
            ],
            "pairedEntityType": [
                1268
            ],
            "pairingType": [
                1269
            ],
            "__typename": [
                1
            ]
        },
        "ClassificationMetadata": {
            "enrichmentRuleType": [
                535
            ],
            "enrichmentRuleId": [
                1
            ],
            "businessEventRuleType": [
                264
            ],
            "businessEventRuleId": [
                1
            ],
            "isAugmented": [
                7
            ],
            "actorType": [
                29
            ],
            "actorId": [
                1
            ],
            "classifiedAt": [
                461
            ],
            "recommendations": [
                287
            ],
            "__typename": [
                1
            ]
        },
        "ClassificationMetadataInput": {
            "enrichmentRuleType": [
                535
            ],
            "enrichmentRuleId": [
                1
            ],
            "businessEventRuleType": [
                264
            ],
            "businessEventRuleId": [
                1
            ],
            "isAugmented": [
                7
            ],
            "metadata": [
                989
            ],
            "__typename": [
                1
            ]
        },
        "ClassificationMetadataRecommendation": {
            "chosenRecommendation": [
                291
            ],
            "mechanism": [
                1441
            ],
            "value": [
                290
            ],
            "__typename": [
                1
            ]
        },
        "ClassificationMetadataRecommendationOptionValue": {
            "seenOptions": [
                291
            ],
            "__typename": [
                1
            ]
        },
        "ClassificationMetadataRecommendationPromptValue": {
            "prompt": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ClassificationMetadataRecommendationValue": {
            "on_ClassificationMetadataRecommendationOptionValue": [
                288
            ],
            "on_ClassificationMetadataRecommendationPromptValue": [
                289
            ],
            "__typename": [
                1
            ]
        },
        "ClassificationRecommendation": {
            "tagId": [
                1
            ],
            "score": [
                5
            ],
            "model": [
                1
            ],
            "recommendationsSource": [
                1440
            ],
            "__typename": [
                1
            ]
        },
        "ClassificationRecommendationsInput": {
            "chosenRecommendation": [
                293
            ],
            "mechanism": [
                1441
            ],
            "optionValue": [
                466
            ],
            "promptValue": [
                467
            ],
            "__typename": [
                1
            ]
        },
        "ClassificationRecommendationTag": {
            "tagId": [
                1
            ],
            "score": [
                5
            ],
            "model": [
                1
            ],
            "recommendationsSource": [
                1440
            ],
            "__typename": [
                1
            ]
        },
        "ClassificationRequestInput": {
            "id": [
                15
            ],
            "merchant": [
                1118
            ],
            "businessEvent": [
                262
            ],
            "allowClassificationForVersion": [
                54
            ],
            "classificationMetadata": [
                286
            ],
            "recommendations": [
                292
            ],
            "__typename": [
                1
            ]
        },
        "ClassificationResult": {
            "current": [
                186
            ],
            "previous": [
                186
            ],
            "__typename": [
                1
            ]
        },
        "ClassificationTag": {
            "id": [
                15
            ],
            "name": [
                1
            ],
            "moneyDirection": [
                1142
            ],
            "isHidden": [
                7
            ],
            "isRelevantToBills": [
                7
            ],
            "isRelevantToApplyAlways": [
                7
            ],
            "displayNameForReports": [
                1
            ],
            "keywords": [
                1
            ],
            "classification": [
                298
            ],
            "isApplicableForRefund": [
                7
            ],
            "weight": [
                47
            ],
            "reportsDisplayNameForTagSelection": [
                1
            ],
            "classificationBusinessEventEffects": [
                297
            ],
            "__typename": [
                1
            ]
        },
        "ClassificationTagBusinessEventEffects": {
            "moneyInBusinessEvent": [
                254
            ],
            "moneyOutBusinessEvent": [
                254
            ],
            "__typename": [
                1
            ]
        },
        "ClassificationTagClassification": {
            "in": [
                1
            ],
            "out": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ClassificationTagReportHierarchy": {
            "moneyInHierarchy": [
                1467
            ],
            "moneyOutHierarchy": [
                1467
            ],
            "__typename": [
                1
            ]
        },
        "ClassificationTagReportHierarchyInput": {
            "classificationTagId": [
                15
            ],
            "reportTypes": [
                1470
            ],
            "accountingMethod": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "ClassificationTagsByIdsInput": {
            "ids": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "ClassificationTagsInput": {
            "forBillsOnly": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "ClassifyIntegrationTransferInput": {
            "companyId": [
                15
            ],
            "classifications": [
                863
            ],
            "__typename": [
                1
            ]
        },
        "CloseOpenBillsTask": {
            "id": [
                243
            ],
            "status": [
                246
            ],
            "stateKey": [
                1
            ],
            "currentBillsHash": [
                1
            ],
            "state": [
                305
            ],
            "history": [
                306
            ],
            "__typename": [
                1
            ]
        },
        "CloseOpenBillsTaskState": {
            "confirmedBillsHash": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CloseOpenBillsTaskStateChangeLog": {
            "id": [
                15
            ],
            "createdAt": [
                461
            ],
            "updaterType": [
                1963
            ],
            "changedByUid": [
                1
            ],
            "reason": [
                1
            ],
            "oldValue": [
                305
            ],
            "newValue": [
                305
            ],
            "__typename": [
                1
            ]
        },
        "CloseOpenInvoicesTask": {
            "id": [
                974
            ],
            "status": [
                977
            ],
            "stateKey": [
                1
            ],
            "currentInvoicesHash": [
                1
            ],
            "state": [
                308
            ],
            "history": [
                309
            ],
            "__typename": [
                1
            ]
        },
        "CloseOpenInvoicesTaskState": {
            "confirmedInvoicesHash": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CloseOpenInvoicesTaskStateChangeLog": {
            "id": [
                15
            ],
            "createdAt": [
                461
            ],
            "updaterType": [
                1963
            ],
            "changedByUid": [
                1
            ],
            "reason": [
                1
            ],
            "oldValue": [
                308
            ],
            "newValue": [
                308
            ],
            "__typename": [
                1
            ]
        },
        "ClosingBalance": {
            "companyId": [
                15
            ],
            "id": [
                15
            ],
            "source": [
                876
            ],
            "integrationAccountId": [
                15
            ],
            "integrationAccountName": [
                1
            ],
            "amount": [
                5
            ],
            "currency": [
                1
            ],
            "appliedAt": [
                461
            ],
            "type": [
                311
            ],
            "__typename": [
                1
            ]
        },
        "ClosingBalanceType": {},
        "COGSBreakdownItem": {
            "product": [
                1368
            ],
            "quantity": [
                47
            ],
            "avgCostPerUnit": [
                5
            ],
            "totalCogs": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "CogsCategory": {},
        "COGSEvent": {
            "id": [
                1
            ],
            "groupName": [
                1
            ],
            "period": [
                315
            ],
            "amount": [
                5
            ],
            "salesChannel": [
                1495
            ],
            "lineOfBusinessId": [
                1
            ],
            "memo": [
                1
            ],
            "type": [
                319
            ],
            "source": [
                316
            ],
            "isFullyProcessed": [
                7
            ],
            "costType": [
                394
            ],
            "categoryBreakdown": [
                277
            ],
            "__typename": [
                1
            ]
        },
        "COGSEventPeriod": {
            "fromDate": [
                461
            ],
            "toDate": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "COGSEventSource": {},
        "CogsSource": {},
        "COGSSummaryV2": {
            "groupName": [
                1
            ],
            "type": [
                319
            ],
            "sum": [
                5
            ],
            "salesChannel": [
                1495
            ],
            "realtimeSum": [
                5
            ],
            "manualSum": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "CogsType": {},
        "CommonAction": {
            "quantity": [
                47
            ],
            "id": [
                1
            ],
            "date": [
                461
            ],
            "type": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CommonWarehouseTransfersFilterInput": {
            "companyId": [
                1
            ],
            "productIds": [
                1
            ],
            "warehouseTransferIds": [
                1
            ],
            "shippingDate": [
                1903
            ],
            "arrivalDate": [
                1903
            ],
            "warehouse": [
                1877
            ],
            "textSearch": [
                1
            ],
            "withDeleted": [
                7
            ],
            "vendorId": [
                1
            ],
            "paidStatus": [
                1266
            ],
            "__typename": [
                1
            ]
        },
        "Company": {
            "id": [
                15
            ],
            "billing": [
                339
            ],
            "name": [
                1
            ],
            "userName": [
                1
            ],
            "refferalCode": [
                1
            ],
            "source": [
                989
            ],
            "email": [
                1
            ],
            "isManufacturer": [
                7
            ],
            "phoneNumber": [
                1
            ],
            "address": [
                41
            ],
            "accountingMethod": [
                8
            ],
            "booksStartTime": [
                989
            ],
            "dataRestrictionTime": [
                461
            ],
            "balanceSheetStatus": [
                112
            ],
            "customerSelectedAccountingMethod": [
                448
            ],
            "inventorySettings": [
                920
            ],
            "ordersSettings": [
                1248
            ],
            "generalDetails": [
                989
            ],
            "configuration": [
                341
            ],
            "isTestCompany": [
                7
            ],
            "isArchived": [
                7
            ],
            "createdAtMs": [
                5
            ],
            "createdAtIso": [
                1
            ],
            "canceledAtIso": [
                1
            ],
            "preloadEmail": [
                1
            ],
            "eventsLock": [
                989
            ],
            "eventsLock2": [
                543
            ],
            "legalEntityType": [
                1
            ],
            "taxEntityType": [
                1
            ],
            "trialStartTimeIso": [
                1
            ],
            "trialStartTimeMs": [
                5
            ],
            "trialDurationInDays": [
                47
            ],
            "isPaying": [
                7
            ],
            "fullUiAccess": [
                7
            ],
            "paymentStatus": [
                1
            ],
            "tier": [
                1
            ],
            "tierAtConversion": [
                1
            ],
            "taxPlan": [
                1
            ],
            "stripeCustomerId": [
                15
            ],
            "isOnboardingCompleted": [
                7
            ],
            "onboardingCompletedAtIso": [
                1
            ],
            "onboardingState": [
                1174
            ],
            "isParkerReferral": [
                7
            ],
            "estimatedProjectedRevenue": [
                542
            ],
            "calculatedTier": [
                363
            ],
            "hasActiveTrial": [
                7
            ],
            "lockStatus": [
                360
            ],
            "isLiveTestCompany": [
                7
            ],
            "gtmSource": [
                797
            ],
            "fiscalYearStart": [
                344
            ],
            "lockHistory": [
                359
            ],
            "appsSelectedInPreOnboarding": [
                1
            ],
            "referralCode": [
                1
            ],
            "endOfYearDeadline": [
                989
            ],
            "booksStatus": [
                989
            ],
            "accountingServices": [
                9
            ],
            "demo": [
                1
            ],
            "preOnboardingFunnelType": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CompanyAccountValidation": {
            "account": [
                1
            ],
            "status": [
                373
            ],
            "value": [
                5
            ],
            "isError": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "CompanyAddress": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "source": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "isDefault": [
                7
            ],
            "name": [
                1
            ],
            "email": [
                1
            ],
            "phone": [
                1
            ],
            "line1": [
                1
            ],
            "line2": [
                1
            ],
            "city": [
                1
            ],
            "country": [
                1
            ],
            "province": [
                1
            ],
            "state": [
                1
            ],
            "zip": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CompanyAddressInput": {
            "companyId": [
                15
            ],
            "id": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "CompanyAffiliate": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "type": [
                331
            ],
            "name": [
                1
            ],
            "createdAt": [
                461
            ],
            "updatedAt": [
                461
            ],
            "logoUrl": [
                1
            ],
            "holdingsInPercentage": [
                5
            ],
            "isForeignResident": [
                7
            ],
            "balance": [
                5
            ],
            "transactions": [
                330
            ],
            "status": [
                816
            ],
            "affiliatedEntityId": [
                15
            ],
            "latestAccountingEventIds": [
                1
            ],
            "deletedAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "CompanyAffiliateInput": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "CompanyAffiliatesByIdsInput": {
            "ids": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "CompanyAffiliatesInput": {
            "companyId": [
                15
            ],
            "type": [
                331
            ],
            "searchTerm": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CompanyAffiliateTransaction": {
            "entityId": [
                15
            ],
            "entityType": [
                1
            ],
            "bankAccountId": [
                1
            ],
            "postedAt": [
                461
            ],
            "amount": [
                5
            ],
            "currency": [
                1
            ],
            "amountInUsd": [
                5
            ],
            "type": [
                1
            ],
            "source": [
                876
            ],
            "__typename": [
                1
            ]
        },
        "CompanyAffiliateType": {},
        "CompanyArCashDiffsActionsResponse": {
            "accountRole": [
                1
            ],
            "amount": [
                5
            ],
            "isAccrual": [
                7
            ],
            "isCash": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "CompanyArCashDiffsFixbooksResponse": {
            "eventTime": [
                461
            ],
            "createdAt": [
                461
            ],
            "creatorEmail": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "memo": [
                1
            ],
            "actions": [
                332
            ],
            "__typename": [
                1
            ]
        },
        "CompanyArCashDiffsInput": {
            "companyId": [
                15
            ],
            "unpaidBalanceAsOf": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "CompanyArCashDiffsOrdersResponse": {
            "orderId": [
                15
            ],
            "integrationAccountId": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "displayName": [
                1
            ],
            "ordersVsBooks": [
                5
            ],
            "ordersBalance": [
                5
            ],
            "booksBalance": [
                5
            ],
            "placedAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "CompanyArCashDiffsResponse": {
            "companyId": [
                15
            ],
            "reportDate": [
                461
            ],
            "orders": [
                335
            ],
            "fixbooks": [
                333
            ],
            "totals": [
                337
            ],
            "lockTime": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "CompanyArCashTotalsResponse": {
            "arCashInBooks": [
                5
            ],
            "unpaidOrders": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "CompanyArtificialFulfillmentSettingsInput": {
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "CompanyBilling": {
            "features": [
                565
            ],
            "purchasedOffers": [
                1415
            ],
            "portalUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CompanyByStripeCustomerIdInput": {
            "stripeCustomerId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CompanyConfiguration": {
            "isPnlReportReady": [
                7
            ],
            "isBalanceSheetReportFinal": [
                7
            ],
            "includeCCAccountsInCashFlowBeforePayment": [
                7
            ],
            "includeUDFAccountsInCashFlowBeforeDeposit": [
                7
            ],
            "cashFlowStartTimeISO": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CompanyDomainState": {
            "companyId": [
                15
            ],
            "state": [
                1939
            ],
            "reason": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CompanyEventsLockHistory": {
            "year": [
                47
            ],
            "createdAt": [
                461
            ],
            "userEmail": [
                1
            ],
            "type": [
                1080
            ],
            "__typename": [
                1
            ]
        },
        "CompanyFiscalYearStart": {
            "day": [
                47
            ],
            "month": [
                47
            ],
            "userEmail": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CompanyHealthStatus": {
            "company": [
                322
            ],
            "domains": [
                369
            ],
            "status": [
                373
            ],
            "__typename": [
                1
            ]
        },
        "CompanyHealthStatusSortDirection": {},
        "CompanyIdAndStripeCustomerId": {
            "companyId": [
                1
            ],
            "stripeCustomerId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CompanyIdInput": {
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "CompanyIntegration": {
            "companyId": [
                15
            ],
            "integrationType": [
                876
            ],
            "integrationAccountId": [
                15
            ],
            "integrationTypeAndIntegrationAccountId": [
                1
            ],
            "data": [
                988
            ],
            "enabled": [
                7
            ],
            "impactOnBooksSince": [
                1
            ],
            "createdAtMs": [
                5
            ],
            "deletedByUserAt": [
                1
            ],
            "deletedByUserId": [
                1
            ],
            "disconnectedAt": [
                1
            ],
            "disconnectedBy": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CompanyIntegrationBulkUpdateInput": {
            "companyId": [
                15
            ],
            "updateExpression": [
                1749
            ],
            "__typename": [
                1
            ]
        },
        "CompanyIntegrationInput": {
            "companyId": [
                15
            ],
            "integrationType": [
                876
            ],
            "integrationAccountId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CompanyIntegrationsInput": {
            "companyId": [
                15
            ],
            "integrationType": [
                876
            ],
            "__typename": [
                1
            ]
        },
        "CompanyLoan": {
            "id": [
                15
            ],
            "company": [
                322
            ],
            "source": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "type": [
                1061
            ],
            "lender": [
                1150
            ],
            "currency": [
                440
            ],
            "postedAt": [
                461
            ],
            "direction": [
                1031
            ],
            "isShortTerm": [
                7
            ],
            "amount": [
                5
            ],
            "totalAmount": [
                5
            ],
            "outstandingAmount": [
                5
            ],
            "accruedInterest": [
                5
            ],
            "unrecognizedFees": [
                5
            ],
            "prepaymentLiabilityAmount": [
                5
            ],
            "status": [
                1057
            ],
            "userActionStatus": [
                1065
            ],
            "acknowledgedInternallyAt": [
                461
            ],
            "acknowledgedInternallyBy": [
                1
            ],
            "paidOffAt": [
                461
            ],
            "attachments": [
                1018
            ],
            "workingPaperLink": [
                1
            ],
            "updatedBy": [
                1
            ],
            "lockedAt": [
                461
            ],
            "lastSeenByClientAt": [
                461
            ],
            "deletedAt": [
                461
            ],
            "createdAt": [
                461
            ],
            "updatedAt": [
                461
            ],
            "interestDays": [
                1038
            ],
            "repaymentType": [
                1051
            ],
            "repaymentFrequency": [
                1050
            ],
            "repaymentRate": [
                5
            ],
            "interestRateType": [
                1039
            ],
            "interestRate": [
                5
            ],
            "gracePeriod": [
                47
            ],
            "catchUpPeriod": [
                47
            ],
            "catchUpPercent": [
                5
            ],
            "originationFees": [
                5
            ],
            "prepaymentFees": [
                5
            ],
            "recurringDataFrequency": [
                1048
            ],
            "instantRepaymentMethod": [
                1037
            ],
            "isIndexLinked": [
                7
            ],
            "interestType": [
                1040
            ],
            "variableInterestUpdatePeriod": [
                1068
            ],
            "variableInterestSpread": [
                5
            ],
            "variableInterestBase": [
                1067
            ],
            "compoundFrequency": [
                1027
            ],
            "indexType": [
                1036
            ],
            "isEarlyRepaymentDiscount": [
                7
            ],
            "earlyRepaymentDiscounts": [
                531
            ],
            "transactions": [
                186
            ],
            "lastTransaction": [
                186
            ],
            "userFollowUp2": [
                1825
            ],
            "askIfRepaid": [
                7
            ],
            "hideOutdatedWarning": [
                7
            ],
            "deleteOptionMode": [
                355
            ],
            "pendingDelete": [
                7
            ],
            "pendingRestoreAcknowledgement": [
                7
            ],
            "lastUserMoveToProcessingAt": [
                461
            ],
            "dataRequestType": [
                1030
            ],
            "isHidden": [
                7
            ],
            "lastEventDate": [
                461
            ],
            "isBillPaymentEventAttached": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "CompanyLoanAttachmentUpdateResult": {
            "success": [
                7
            ],
            "errors": [
                1019
            ],
            "loan": [
                353
            ],
            "__typename": [
                1
            ]
        },
        "CompanyLoanDeleteOptionMode": {},
        "CompanyLoanResponse": {
            "loan": [
                353
            ],
            "__typename": [
                1
            ]
        },
        "CompanyLoansResponse": {
            "loans": [
                353
            ],
            "__typename": [
                1
            ]
        },
        "CompanyLockConditions": {
            "accountingMethod": [
                1
            ],
            "moneyIn": [
                1075
            ],
            "moneyOut": [
                1075
            ],
            "reclassificationMoneyIn": [
                1075
            ],
            "reclassificationMoneyOut": [
                1075
            ],
            "assetsEqualToLiabilitiesAndEquities": [
                1075
            ],
            "isPayrollOk": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "CompanyLockHistory": {
            "type": [
                1069
            ],
            "userEmail": [
                1
            ],
            "startDate": [
                461
            ],
            "endDate": [
                461
            ],
            "createdAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "CompanyLockStatus": {
            "minimumAllowedEventTime": [
                461
            ],
            "lockedAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "CompanyMainHolderInput": {
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "CompanySyncBodyInput": {
            "id": [
                15
            ],
            "booksStartTime": [
                461
            ],
            "deletedAt": [
                461
            ],
            "name": [
                1
            ],
            "accountingMethod": [
                1
            ],
            "isTestCompany": [
                7
            ],
            "booksStatus": [
                988
            ],
            "isArchived": [
                7
            ],
            "archivedAtIso": [
                461
            ],
            "createdAt": [
                461
            ],
            "tier": [
                364
            ],
            "trialStartTime": [
                461
            ],
            "trialDurationInDays": [
                47
            ],
            "isPaying": [
                7
            ],
            "eventsLock": [
                988
            ],
            "__typename": [
                1
            ]
        },
        "CompanyTier": {},
        "CompanyTierType": {},
        "CompanyUser": {
            "email": [
                1
            ],
            "roles": [
                1839
            ],
            "name": [
                1
            ],
            "auth0Id": [
                1
            ],
            "invitationStatus": [
                951
            ],
            "__typename": [
                1
            ]
        },
        "CompanyUsersArgs": {
            "companyId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CompanyUsersResponse": {
            "users": [
                365
            ],
            "__typename": [
                1
            ]
        },
        "CompanyValidation": {
            "type": [
                374
            ],
            "metricName": [
                371
            ],
            "name": [
                1
            ],
            "status": [
                373
            ],
            "accounts": [
                323
            ],
            "__typename": [
                1
            ]
        },
        "CompanyValidationDomain": {
            "type": [
                370
            ],
            "name": [
                1
            ],
            "status": [
                373
            ],
            "validations": [
                368
            ],
            "__typename": [
                1
            ]
        },
        "CompanyValidationDomainType": {},
        "CompanyValidationMetricName": {},
        "CompanyValidationSortByValidationInput": {
            "validationType": [
                374
            ],
            "metricName": [
                371
            ],
            "__typename": [
                1
            ]
        },
        "CompanyValidationStatus": {},
        "CompanyValidationType": {},
        "CompanyWorksheet": {
            "worksheetRows": [
                376
            ],
            "company": [
                322
            ],
            "__typename": [
                1
            ]
        },
        "CompanyWorksheetRow": {
            "companyId": [
                1
            ],
            "dateForLocking": [
                461
            ],
            "name": [
                1
            ],
            "amount": [
                5
            ],
            "comment": [
                1
            ],
            "signedAt": [
                461
            ],
            "signedBy": [
                1
            ],
            "signedByName": [
                1
            ],
            "attachmentUrl": [
                1
            ],
            "lockableEntity": [
                377
            ],
            "periodStart": [
                461
            ],
            "periodEnd": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "CompanyWorksheetRowLockableEntity": {
            "on_BankAccount": [
                113
            ],
            "__typename": [
                1
            ]
        },
        "CompleteValidationCycleInput": {
            "validationCycleId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ConfirmApBalanceTask": {
            "id": [
                243
            ],
            "status": [
                246
            ],
            "accountBalances": [
                82
            ],
            "stateKey": [
                1
            ],
            "state": [
                380
            ],
            "history": [
                381
            ],
            "__typename": [
                1
            ]
        },
        "ConfirmApBalanceTaskState": {
            "confirmedBalances": [
                82
            ],
            "__typename": [
                1
            ]
        },
        "ConfirmApBalanceTaskStateChangeLog": {
            "id": [
                15
            ],
            "createdAt": [
                461
            ],
            "updaterType": [
                1963
            ],
            "changedByUid": [
                1
            ],
            "reason": [
                1
            ],
            "oldValue": [
                380
            ],
            "newValue": [
                380
            ],
            "__typename": [
                1
            ]
        },
        "ConfirmArBalanceTask": {
            "id": [
                974
            ],
            "status": [
                977
            ],
            "stateKey": [
                1
            ],
            "accountBalances": [
                4
            ],
            "state": [
                383
            ],
            "history": [
                384
            ],
            "__typename": [
                1
            ]
        },
        "ConfirmArBalanceTaskState": {
            "confirmedBalances": [
                4
            ],
            "__typename": [
                1
            ]
        },
        "ConfirmArBalanceTaskStateChangeLog": {
            "id": [
                15
            ],
            "createdAt": [
                461
            ],
            "updaterType": [
                1963
            ],
            "changedByUid": [
                1
            ],
            "reason": [
                1
            ],
            "oldValue": [
                383
            ],
            "newValue": [
                383
            ],
            "__typename": [
                1
            ]
        },
        "ConfirmRoleForPaymentGatewayInput": {
            "companyId": [
                15
            ],
            "paymentGatewayType": [
                1279
            ],
            "__typename": [
                1
            ]
        },
        "Contractor": {
            "id": [
                15
            ],
            "company": [
                322
            ],
            "source": [
                1
            ],
            "department": [
                509
            ],
            "firstName": [
                1
            ],
            "lastName": [
                1
            ],
            "middleName": [
                1
            ],
            "name": [
                1
            ],
            "businessName": [
                1
            ],
            "isActive": [
                7
            ],
            "deletedAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "ContractorPayment": {
            "id": [
                1
            ],
            "company": [
                322
            ],
            "source": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "reimbursements": [
                1458
            ],
            "externalSourceId": [
                1
            ],
            "department": [
                509
            ],
            "postedAt": [
                461
            ],
            "currency": [
                1
            ],
            "wage": [
                5
            ],
            "wageInHomeCurrency": [
                5
            ],
            "bonus": [
                5
            ],
            "bonusInHomeCurrency": [
                5
            ],
            "categoryGroup": [
                1324
            ],
            "paymentChecksAmount": [
                5
            ],
            "isPaymentChecksAmountImmaterial": [
                7
            ],
            "deletedAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "ContractorPaymentIdsByCompanyInput": {
            "companyIds": [
                15
            ],
            "afterBooksStartTimeOnly": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "ContractorPaymentIdsByCompanyItem": {
            "companyId": [
                1
            ],
            "contractorPaymentIds": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "ContractorPaymentIdsByCompanyResponse": {
            "items": [
                389
            ],
            "__typename": [
                1
            ]
        },
        "ContractorPaymentsInternalInput": {
            "companyId": [
                15
            ],
            "contractorPaymentIds": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "ContractorPaymentsInternalResponse": {
            "contractorPayments": [
                387
            ],
            "__typename": [
                1
            ]
        },
        "CopyBankAccountInput": {
            "sourceBankAccountId": [
                1
            ],
            "newBankAccountId": [
                1
            ],
            "companyId": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "status": [
                138
            ],
            "__typename": [
                1
            ]
        },
        "CostType": {},
        "CreateAccountRoleInput": {
            "name": [
                1
            ],
            "groupId": [
                15
            ],
            "sign": [
                1149
            ],
            "__typename": [
                1
            ]
        },
        "CreateBankAccountInput": {
            "companyId": [
                15
            ],
            "id": [
                15
            ],
            "integrationAccountId": [
                1
            ],
            "source": [
                876
            ],
            "externalSourceId": [
                1
            ],
            "type": [
                142
            ],
            "subtype": [
                140
            ],
            "connectionStatus": [
                118
            ],
            "lastUpdateTime": [
                461
            ],
            "isHistoricalUpdateCompleted": [
                7
            ],
            "currency": [
                1
            ],
            "externalInstitutionId": [
                1
            ],
            "institutionId": [
                1
            ],
            "institutionName": [
                1
            ],
            "name": [
                1
            ],
            "displayName": [
                1
            ],
            "overrideFullName": [
                1
            ],
            "mask": [
                1
            ],
            "currentBalance": [
                5
            ],
            "connectedAt": [
                461
            ],
            "rawData": [
                989
            ],
            "shouldUpdateAccountDetailsOnNextUpdate": [
                7
            ],
            "status": [
                138
            ],
            "createdByActor": [
                29
            ],
            "createdBy": [
                1
            ],
            "createdFromSource": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateBankAccountStatementForStopImportInput": {
            "bankAccountId": [
                15
            ],
            "companyId": [
                15
            ],
            "stopImportFromDate": [
                1
            ],
            "displayedBalance": [
                5
            ],
            "closedDate": [
                1
            ],
            "isConfirmed": [
                7
            ],
            "fileNames": [
                1
            ],
            "csvFileNames": [
                1
            ],
            "realBalance": [
                5
            ],
            "newOwner": [
                126
            ],
            "__typename": [
                1
            ]
        },
        "CreateBankTransactionsUploadInput": {
            "companyId": [
                15
            ],
            "bankAccountId": [
                1
            ],
            "bucket": [
                1
            ],
            "key": [
                1
            ],
            "uploadOriginator": [
                1
            ],
            "uploadedBy": [
                29
            ],
            "uploadedById": [
                1
            ],
            "uploadedAt": [
                461
            ],
            "skipMachineTrigger": [
                7
            ],
            "originFileId": [
                15
            ],
            "fileStatus": [
                216
            ],
            "uploadedOnBankTransactionId": [
                15
            ],
            "fileUrl": [
                1
            ],
            "bankStatementRequestId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateCheckoutSessionInput": {
            "companyId": [
                15
            ],
            "paymentOptionIds": [
                15
            ],
            "successUrl": [
                1
            ],
            "cancelUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateCheckoutSessionResponse": {
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateCompanyAffiliateInput": {
            "companyId": [
                15
            ],
            "name": [
                1
            ],
            "type": [
                331
            ],
            "isForeignResident": [
                7
            ],
            "holdingsInPercentage": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "CreateCompanyInput": {
            "email": [
                1
            ],
            "stripeCustomerId": [
                1
            ],
            "source": [
                988
            ],
            "referralCode": [
                1
            ],
            "isParkerReferral": [
                7
            ],
            "withoutNotification": [
                7
            ],
            "gtmSource": [
                798
            ],
            "appsSelectedInPreOnboarding": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateCompanyLoanFromTransactionClassificationInput": {
            "amount": [
                5
            ],
            "classificationTagId": [
                15
            ],
            "classificationText": [
                15
            ],
            "businessEvent": [
                1
            ],
            "pairedEntityId": [
                1
            ],
            "pairedEntityType": [
                1268
            ],
            "pairingType": [
                1269
            ],
            "askTheUserResult": [
                989
            ],
            "extraData": [
                989
            ],
            "description": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateCompanyLoanFromTransactionMerchantInput": {
            "id": [
                1
            ],
            "type": [
                1124
            ],
            "__typename": [
                1
            ]
        },
        "CreateCompanyLoanInput": {
            "companyId": [
                15
            ],
            "data": [
                1062
            ],
            "__typename": [
                1
            ]
        },
        "CreateCustomerArgs": {
            "name": [
                1
            ],
            "companyName": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "source": [
                449
            ],
            "email": [
                1
            ],
            "phoneNumber": [
                1
            ],
            "billingAddress": [
                42
            ],
            "shippingAddress": [
                42
            ],
            "__typename": [
                1
            ]
        },
        "CreateCustomerCategorizationRuleInput": {
            "companyId": [
                15
            ],
            "applyForAlreadyCategorizedTransactions": [
                7
            ],
            "merchant": [
                1118
            ],
            "moneyDirection": [
                1608
            ],
            "classification": [
                446
            ],
            "__typename": [
                1
            ]
        },
        "CreateCustomerCategorizationRuleResult": {
            "realTimeApplierJobId": [
                1
            ],
            "bankTransactionIdsAffectedByJobId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "CreateFileUploadUrlInput": {
            "companyId": [
                1
            ],
            "source": [
                578
            ],
            "fileName": [
                1
            ],
            "featureContext": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateGlobalRuleResult": {
            "id": [
                15
            ],
            "success": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "CreateGlobalVendorInput": {
            "id": [
                1
            ],
            "name": [
                1
            ],
            "notificationInfo": [
                1862
            ],
            "subTypes": [
                1865
            ],
            "is1099Contractor": [
                7
            ],
            "websiteUrl": [
                1
            ],
            "source": [
                1863
            ],
            "labels": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateGlobalVendorRuleInput": {
            "vendorIds": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateImageUploadUrlInput": {
            "companyId": [
                1
            ],
            "fileName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateInstitutionInput": {
            "name": [
                1
            ],
            "preferredProvider": [
                842
            ],
            "isMajor": [
                1
            ],
            "logoUrl": [
                1
            ],
            "websiteUrl": [
                1
            ],
            "csvInstructionsLink": [
                1
            ],
            "pdfInstructionsLink": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateInstitutionMappingsInput": {
            "source": [
                841
            ],
            "externalSourceId": [
                1
            ],
            "externalSourceName": [
                1
            ],
            "institutionId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateIntegrationTransferRuleInput": {
            "companyId": [
                15
            ],
            "integrationType": [
                876
            ],
            "companyAffiliateId": [
                15
            ],
            "integrationAccountId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "CreateInventoryVendorValidationInput": {
            "companyId": [
                15
            ],
            "fromDate": [
                461
            ],
            "toDate": [
                461
            ],
            "unpaidBillsUntil": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "CreateInventoryVendorValidationResponse": {
            "validationId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateLoanEventDefinitionInput": {
            "name": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateLoanFromBalanceTransactionInput": {
            "companyId": [
                15
            ],
            "balanceTransactionId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "CreateLoanFromBillFinancedInput": {
            "companyId": [
                15
            ],
            "billId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "CreateLoanInput": {
            "companyId": [
                15
            ],
            "data": [
                1063
            ],
            "merchantRole": [
                331
            ],
            "__typename": [
                1
            ]
        },
        "CreateLoansEventsEffectConfigurationInput": {
            "eventDefinitionId": [
                15
            ],
            "loanType": [
                1061
            ],
            "isShortTerm": [
                7
            ],
            "direction": [
                1031
            ],
            "minGracePeriod": [
                47
            ],
            "repaymentType": [
                1051
            ],
            "lenderId": [
                15
            ],
            "memoTemplate": [
                1
            ],
            "dateTemplate": [
                1
            ],
            "accrualConfig": [
                987
            ],
            "cashConfig": [
                987
            ],
            "loanOutstandingAmountAffect": [
                1045
            ],
            "loanAmountAffect": [
                1017
            ],
            "__typename": [
                1
            ]
        },
        "CreateManualLoanEventInput": {
            "companyId": [
                15
            ],
            "loanId": [
                15
            ],
            "events": [
                426
            ],
            "__typename": [
                1
            ]
        },
        "CreateManualLoanEventInput2": {
            "companyId": [
                15
            ],
            "loanId": [
                15
            ],
            "eventDefinitionId": [
                15
            ],
            "amount": [
                5
            ],
            "memo": [
                1
            ],
            "postedAt": [
                461
            ],
            "spreadInput": [
                427
            ],
            "recurrenceSettings": [
                1452
            ],
            "__typename": [
                1
            ]
        },
        "CreateManualLoanEventInputEvents": {
            "eventDefinitionId": [
                15
            ],
            "amount": [
                5
            ],
            "memo": [
                1
            ],
            "postedAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "CreateManualLoanEventSpreadInput": {
            "startDate": [
                461
            ],
            "endDate": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "CreateOrGetBusinessEventDefinitionInput": {
            "type": [
                1
            ],
            "variant": [
                1
            ],
            "accrualConfig": [
                987
            ],
            "cashConfig": [
                987
            ],
            "originalActorId": [
                1
            ],
            "originalActorType": [
                29
            ],
            "integrationType": [
                1
            ],
            "name": [
                1
            ],
            "customerDisplayName": [
                1
            ],
            "groups": [
                1
            ],
            "vendorRequired": [
                7
            ],
            "requiredAffiliatedEntityType": [
                1
            ],
            "irrelevantForVendor": [
                7
            ],
            "hideForManualClassification": [
                7
            ],
            "forceShowInEventsListings": [
                7
            ],
            "isRelevantToAccruals": [
                7
            ],
            "moneyDirection": [
                1142
            ],
            "__typename": [
                1
            ]
        },
        "CreateOrUpdateAccrualInput": {
            "companyId": [
                15
            ],
            "bankTransactionId": [
                15
            ],
            "accrualsData": [
                19
            ],
            "categorizations": [
                16
            ],
            "__typename": [
                1
            ]
        },
        "CreateOrUpdateAccrualResult": {
            "bankTransaction": [
                186
            ],
            "status": [
                21
            ],
            "error": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateProductManuallyError": {
            "message": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateProductManuallyInput": {
            "source": [
                1400
            ],
            "integrationAccountId": [
                1
            ],
            "companyId": [
                1
            ],
            "displayName": [
                1
            ],
            "sku": [
                1
            ],
            "imageUrl": [
                1
            ],
            "isFractional": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "CreateProductManuallyResponse": {
            "on_CreateProductManuallyError": [
                431
            ],
            "on_CreateProductManuallySuccess": [
                434
            ],
            "__typename": [
                1
            ]
        },
        "CreateProductManuallySuccess": {
            "product": [
                1368
            ],
            "__typename": [
                1
            ]
        },
        "CreateTeamCategorizationRuleResult": {
            "realTimeApplierJobId": [
                1
            ],
            "bankTransactionIdsAffectedByJobId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "CreateUserResponse": {
            "user": [
                1818
            ],
            "__typename": [
                1
            ]
        },
        "CreateVendorArgs": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "name": [
                1
            ],
            "notificationInfo": [
                1862
            ],
            "subTypes": [
                1865
            ],
            "is1099Contractor": [
                7
            ],
            "contactEmail": [
                1
            ],
            "source": [
                1863
            ],
            "externalSourceId": [
                1
            ],
            "isPaymentVendor": [
                7
            ],
            "labels": [
                1
            ],
            "logoUrl": [
                1
            ],
            "websiteUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateWarehouseInput": {
            "companyId": [
                1
            ],
            "source": [
                1899
            ],
            "name": [
                1
            ],
            "phone": [
                1
            ],
            "city": [
                1
            ],
            "province": [
                1
            ],
            "country": [
                1
            ],
            "zip": [
                1
            ],
            "address": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CSVUpload": {
            "name": [
                1
            ],
            "uploadId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CurrencyCode": {},
        "CurrentlyRunningInventoryDataSyncInput": {
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "CurrentlyRunningInventoryDataSyncResponse": {
            "totalItems": [
                47
            ],
            "completedItems": [
                47
            ],
            "updatingProducts": [
                1368
            ],
            "__typename": [
                1
            ]
        },
        "Customer": {
            "id": [
                15
            ],
            "name": [
                1
            ],
            "companyId": [
                15
            ],
            "companyName": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "source": [
                449
            ],
            "email": [
                1
            ],
            "phoneNumber": [
                1
            ],
            "billingAddress": [
                444
            ],
            "shippingAddress": [
                444
            ],
            "__typename": [
                1
            ]
        },
        "CustomerAddress": {
            "id": [
                15
            ],
            "country": [
                1
            ],
            "state": [
                1
            ],
            "city": [
                1
            ],
            "postalCode": [
                1
            ],
            "line1": [
                1
            ],
            "line2": [
                1
            ],
            "isShipping": [
                7
            ],
            "isBilling": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "CustomerAddressArgs": {
            "line1": [
                1
            ],
            "line2": [
                1
            ],
            "city": [
                1
            ],
            "country": [
                1
            ],
            "countryCode": [
                1
            ],
            "province": [
                1
            ],
            "provinceCode": [
                1
            ],
            "state": [
                1
            ],
            "stateCode": [
                1
            ],
            "zip": [
                1
            ],
            "source": [
                449
            ],
            "__typename": [
                1
            ]
        },
        "CustomerCategorizationRuleClassificationInput": {
            "classificationTagId": [
                15
            ],
            "businessEvent": [
                1
            ],
            "pairedEntityType": [
                1268
            ],
            "pairedEntityId": [
                1
            ],
            "pairingType": [
                1269
            ],
            "__typename": [
                1
            ]
        },
        "CustomerNotExistError": {
            "message": [
                1
            ],
            "id": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "CustomerSelectedAccountingMethod": {},
        "CustomerSource": {},
        "CustomerV2": {
            "id": [
                15
            ],
            "companyId": [
                1
            ],
            "source": [
                1
            ],
            "name": [
                1
            ],
            "email": [
                1
            ],
            "billingAddress": [
                444
            ],
            "shippingAddress": [
                444
            ],
            "addresses": [
                444
            ],
            "__typename": [
                1
            ]
        },
        "CustomSpread": {
            "specificDate": [
                1621
            ],
            "spreadOverTime": [
                1627
            ],
            "__typename": [
                1
            ]
        },
        "CustomSpreadInput": {
            "specificDate": [
                1622
            ],
            "spreadOverTime": [
                1628
            ],
            "__typename": [
                1
            ]
        },
        "DailyAggregatedOwnersDraw": {
            "employeeId": [
                15
            ],
            "employeeName": [
                1
            ],
            "amount": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "DailyAggregatedPaymentCheck": {
            "id": [
                15
            ],
            "amount": [
                5
            ],
            "postedAt": [
                461
            ],
            "employeeId": [
                15
            ],
            "employmentType": [
                1338
            ],
            "categoryGroup": [
                1324
            ],
            "isImmaterial": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "DailyAggregatedPayrollData": {
            "date": [
                461
            ],
            "salariesAndWages": [
                5
            ],
            "bonus": [
                5
            ],
            "commission": [
                5
            ],
            "correctionPayment": [
                5
            ],
            "tips": [
                5
            ],
            "holidayPay": [
                5
            ],
            "sickLeave": [
                5
            ],
            "severance": [
                5
            ],
            "ownersDraw": [
                5
            ],
            "vacationPay": [
                5
            ],
            "ficaEmployee": [
                5
            ],
            "ficaEmployer": [
                5
            ],
            "federalEmployee": [
                5
            ],
            "federalEmployer": [
                5
            ],
            "stateAndLocalEmployee": [
                5
            ],
            "stateAndLocalEmployer": [
                5
            ],
            "savingsAndRetirement401kEmployee": [
                5
            ],
            "savingsAndRetirement401kEmployer": [
                5
            ],
            "savingsAndRetirementOtherEmployee": [
                5
            ],
            "savingsAndRetirementOtherEmployer": [
                5
            ],
            "healthEmployee": [
                5
            ],
            "healthEmployer": [
                5
            ],
            "transportationEmployee": [
                5
            ],
            "transportationEmployer": [
                5
            ],
            "otherBenefitsEmployee": [
                5
            ],
            "otherBenefitsEmployer": [
                5
            ],
            "childSupport": [
                5
            ],
            "otherGarnishments": [
                5
            ],
            "otherDeductions": [
                5
            ],
            "grossPay": [
                5
            ],
            "netPay": [
                5
            ],
            "employerCost": [
                5
            ],
            "employmentType": [
                1338
            ],
            "categoryGroup": [
                1324
            ],
            "__typename": [
                1
            ]
        },
        "DailyAggregatedReimbursement": {
            "id": [
                15
            ],
            "amount": [
                5
            ],
            "postedAt": [
                461
            ],
            "employeeId": [
                15
            ],
            "isClearedByNetPayPayment": [
                7
            ],
            "paymentMethod": [
                1
            ],
            "employmentType": [
                1338
            ],
            "categoryGroup": [
                1324
            ],
            "__typename": [
                1
            ]
        },
        "DailyPaymentsByContractor": {
            "contractor": [
                386
            ],
            "payments": [
                5
            ],
            "reimbursements": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "DailyPaymentsByEmployee": {
            "employee": [
                534
            ],
            "netPay": [
                5
            ],
            "grossPay": [
                5
            ],
            "employeeTaxes": [
                5
            ],
            "deductionsAndBenefits": [
                5
            ],
            "reimbursements": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "DangerousTouchLoanInput": {
            "loanId": [
                15
            ],
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "DataSerie": {
            "label": [
                1
            ],
            "values": [
                5
            ],
            "unitGroup": [
                1
            ],
            "valueType": [
                1852
            ],
            "previous": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "Date": {},
        "DateRange": {
            "startTime": [
                461
            ],
            "endTime": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "DateRangeInput": {
            "from": [
                461
            ],
            "to": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "DeadInventoryCogs": {
            "amount": [
                5
            ],
            "quantity": [
                5
            ],
            "monthlyBreakdown": [
                465
            ],
            "__typename": [
                1
            ]
        },
        "DeadInventoryMonthlyBreakdown": {
            "date": [
                461
            ],
            "quantity": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "DecisionClassificationMetadataRecommendationOptionValue": {
            "seenOptions": [
                293
            ],
            "__typename": [
                1
            ]
        },
        "DecisionClassificationMetadataRecommendationPromptValue": {
            "prompt": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeleteAccrualInput": {
            "bankTransactionId": [
                15
            ],
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "DeleteBankAccountsInput": {
            "companyId": [
                1
            ],
            "bankAccountIds": [
                15
            ],
            "userEmail": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeleteBankConnectionInput": {
            "companyId": [
                15
            ],
            "integrationType": [
                876
            ],
            "integrationAccountId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeleteBankTransactionAttachmentInput": {
            "companyId": [
                15
            ],
            "bankTransactionId": [
                15
            ],
            "attachmentUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeleteBankTransactionsByBankAccountIdsInput": {
            "companyId": [
                15
            ],
            "bankAccountIds": [
                15
            ],
            "userEmail": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeleteCompanyAffiliateInput": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "DeleteHolderCapitalInvestmentInput": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "DeleteHolderShareInput": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "DeleteInventoryPaymentGroupInput": {
            "companyId": [
                15
            ],
            "paymentGroupId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "DeleteInventoryPaymentGroupResponse": {
            "success": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "DeleteInventoryPurchaseInput": {
            "companyId": [
                15
            ],
            "id": [
                15
            ],
            "lastUpdatedAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "DeleteInvoicesResponse": {
            "deleted": [
                15
            ],
            "errors": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeleteLoanAttachmentInput": {
            "companyId": [
                15
            ],
            "loanId": [
                15
            ],
            "attachmentId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "DeleteLoanInput": {
            "loanId": [
                15
            ],
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "DeleteLoanManualAccountingEventsInput": {
            "companyId": [
                15
            ],
            "loanId": [
                15
            ],
            "accountingEventIds": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "DeleteLoanRelationshipsInput": {
            "relationshipIds": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "DeleteLoansEventsEffectConfigurationInput": {
            "id": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "DeleteLoansEventsEffectConfigurationResult": {
            "success": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "DeleteLoansInput": {
            "loans": [
                481
            ],
            "__typename": [
                1
            ]
        },
        "DeleteLoansResults": {
            "loans": [
                1012
            ],
            "__typename": [
                1
            ]
        },
        "DeleteManualAssemblyInput": {
            "companyId": [
                15
            ],
            "id": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeleteManualEquityEventsInput": {
            "ids": [
                15
            ],
            "configurationIds": [
                15
            ],
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "DeleteManualLoanEventInput": {
            "companyId": [
                15
            ],
            "loanId": [
                15
            ],
            "eventId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "DeleteManualLoanEventsInput": {
            "companyId": [
                15
            ],
            "loanId": [
                15
            ],
            "eventIds": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "DeleteNoUnitsPhysicalCountInput": {
            "id": [
                15
            ],
            "companyId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeleteOrderGroupInput": {
            "companyId": [
                15
            ],
            "orderGroupId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "DeleteOrderGroupResponse": {
            "success": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "DeleteOrderGroupUponTransactionChangeInput": {
            "companyId": [
                1
            ],
            "orderGroupId": [
                1
            ],
            "changedBankTransactionId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeleteOrderGroupUponTransactionChangeResponse": {
            "success": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "DeletePaymentGroupInput": {
            "companyId": [
                15
            ],
            "paymentGroupId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "DeletePaymentGroupResponse": {
            "success": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "DeletePaymentGroupUponTransactionChangeInput": {
            "companyId": [
                1
            ],
            "paymentGroupId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeletePaymentGroupUponTransactionChangeResponse": {
            "success": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "DeletePendingWarehouseOpeningAttachmentInput": {
            "companyId": [
                15
            ],
            "attachmentId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "DeleteProductsInput": {
            "companyId": [
                15
            ],
            "productIds": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeletePurchaseOrderInput": {
            "companyId": [
                1
            ],
            "id": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeleteRecipeInput": {
            "companyId": [
                15
            ],
            "recipeId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeleteUnitsTrackingPhysicalCountInput": {
            "id": [
                15
            ],
            "companyId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeleteWarehouseInput": {
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeleteWarehouseOpeningBalancesInput": {
            "companyId": [
                15
            ],
            "date": [
                461
            ],
            "productId": [
                1
            ],
            "warehouseId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeleteWarehouseTransferInput": {
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Department": {
            "id": [
                15
            ],
            "company": [
                322
            ],
            "source": [
                1
            ],
            "name": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DepreciationMethod": {},
        "DirectLineItem": {
            "id": [
                15
            ],
            "product": [
                1368
            ],
            "quantity": [
                47
            ],
            "vendor": [
                1853
            ],
            "indirectCostsAllocation": [
                831
            ],
            "directCostPerUnit": [
                5
            ],
            "indirectCostPerUnit": [
                5
            ],
            "remainderIndirectCostPerUnit": [
                5
            ],
            "discountCostPerUnit": [
                5
            ],
            "remainderDiscountCostPerUnit": [
                5
            ],
            "landedCostPerUnit": [
                5
            ],
            "remainderLandedCostPerUnit": [
                5
            ],
            "directCostTotal": [
                5
            ],
            "indirectCostTotal": [
                5
            ],
            "landedCostTotal": [
                5
            ],
            "discountCostTotal": [
                5
            ],
            "note": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DirectLineItemInput": {
            "id": [
                15
            ],
            "productId": [
                1
            ],
            "quantity": [
                47
            ],
            "vendorId": [
                15
            ],
            "directCostPerUnit": [
                5
            ],
            "indirectCostsAllocation": [
                832
            ],
            "indirectCostPerUnit": [
                5
            ],
            "remainderIndirectCostPerUnit": [
                5
            ],
            "discountCostPerUnit": [
                5
            ],
            "remainderDiscountCostPerUnit": [
                5
            ],
            "landedCostPerUnit": [
                5
            ],
            "remainderLandedCostPerUnit": [
                5
            ],
            "directCostTotal": [
                5
            ],
            "indirectCostTotal": [
                5
            ],
            "landedCostTotal": [
                5
            ],
            "discountCostTotal": [
                5
            ],
            "note": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DiscardNoUnitsPhysicalCountProductQuantityPendingAdjustmentInput": {
            "physicalCountId": [
                1
            ],
            "companyId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DiscardPendingSalesDataAdjustments": {
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "DiscardPendingWarehouseOpeningBalancesInput": {
            "companyId": [
                15
            ],
            "date": [
                461
            ],
            "warehouseId": [
                1
            ],
            "productId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DiscardUnitsTrackingPhysicalCountProductQuantityPendingAdjustmentInput": {
            "physicalCountId": [
                1
            ],
            "companyId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DisconnectConnectionInput": {
            "companyId": [
                15
            ],
            "integrationType": [
                876
            ],
            "integrationAccountId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Discount": {
            "id": [
                15
            ],
            "type": [
                520
            ],
            "amount": [
                5
            ],
            "description": [
                1
            ],
            "totalAmount": [
                5
            ],
            "discountType": [
                994
            ],
            "__typename": [
                1
            ]
        },
        "DiscountInput": {
            "id": [
                15
            ],
            "amount": [
                5
            ],
            "type": [
                520
            ],
            "description": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DiscountType": {},
        "Dispute": {
            "companyId": [
                15
            ],
            "id": [
                15
            ],
            "source": [
                876
            ],
            "integrationAccountId": [
                15
            ],
            "integrationAccountName": [
                1
            ],
            "disputedAmount": [
                5
            ],
            "disputedCurrency": [
                1
            ],
            "openedAt": [
                461
            ],
            "closedAt": [
                461
            ],
            "reviewStartedAt": [
                461
            ],
            "refundAmount": [
                5
            ],
            "refundCurrency": [
                1
            ],
            "outcome": [
                522
            ],
            "__typename": [
                1
            ]
        },
        "DisputeOutcome": {},
        "DonationCogs": {
            "amount": [
                5
            ],
            "quantity": [
                47
            ],
            "monthlyBreakdown": [
                524
            ],
            "__typename": [
                1
            ]
        },
        "DonationMonthlyBreakdown": {
            "date": [
                461
            ],
            "quantity": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "DownloadAttachmentInput": {
            "companyId": [
                15
            ],
            "loanId": [
                15
            ],
            "attachmentId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "DownloadAttachmentResult": {
            "fileName": [
                1
            ],
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DownloadExplainFifoInput": {
            "companyId": [
                15
            ],
            "fromDate": [
                461
            ],
            "toDate": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "DownloadExplainFifoResult": {
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DueDateSummary": {
            "count": [
                47
            ],
            "sum": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "DuplicateCheckResult": {},
        "EarlyRepaymentDiscount": {
            "period": [
                47
            ],
            "fee": [
                5
            ],
            "periodType": [
                532
            ],
            "__typename": [
                1
            ]
        },
        "EarlyRepaymentDiscountPeriodType": {},
        "EarlyRepaymentDiscountUpdate": {
            "period": [
                47
            ],
            "fee": [
                5
            ],
            "periodType": [
                532
            ],
            "__typename": [
                1
            ]
        },
        "Employee": {
            "id": [
                15
            ],
            "company": [
                322
            ],
            "source": [
                1
            ],
            "department": [
                509
            ],
            "firstName": [
                1
            ],
            "lastName": [
                1
            ],
            "middleName": [
                1
            ],
            "name": [
                1
            ],
            "isOfficer": [
                7
            ],
            "isTerminated": [
                7
            ],
            "jobs": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "EnrichmentRuleType": {},
        "EntitiesByType": {
            "entityType": [
                1
            ],
            "entityIds": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "EntityLockedPeriodBypass": {
            "companyId": [
                15
            ],
            "entityType": [
                1
            ],
            "entityId": [
                15
            ],
            "isBypassed": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "EquityEventsEffectConfiguration": {
            "id": [
                15
            ],
            "legalEntityType": [
                1
            ],
            "taxEntityType": [
                1
            ],
            "shareType": [
                540
            ],
            "memoTemplate": [
                1
            ],
            "dateTemplate": [
                1
            ],
            "businessEventActionsConfiguration": [
                254
            ],
            "latestActorType": [
                29
            ],
            "latestActorId": [
                1
            ],
            "createdAt": [
                461
            ],
            "updatedAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "EquityEventsEffectConfigurationsInput": {
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "EquityEventShareType": {},
        "Error": {
            "message": [
                1
            ],
            "on_CustomerNotExistError": [
                447
            ],
            "on_VendorNotExistError": [
                1859
            ],
            "__typename": [
                1
            ]
        },
        "EstimatedProjectedRevenue": {},
        "EventsLock": {
            "minimumAllowedEventTime": [
                461
            ],
            "lockHistory": [
                343
            ],
            "__typename": [
                1
            ]
        },
        "ExcludedSalesChannelItemReconciliation": {
            "sku": [
                1
            ],
            "isBlacklisted": [
                7
            ],
            "shopifySalesChannel": [
                1
            ],
            "summary": [
                1448
            ],
            "__typename": [
                1
            ]
        },
        "ExplainFifoInput": {
            "companyId": [
                15
            ],
            "productId": [
                1
            ],
            "fromDate": [
                461
            ],
            "toDate": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "ExplainFifoResult": {
            "warehouseMovements": [
                1884
            ],
            "__typename": [
                1
            ]
        },
        "ExportBalanceSheetApiArgs": {
            "companyId": [
                15
            ],
            "isGoogleExport": [
                7
            ],
            "fromTimeIso": [
                461
            ],
            "toTimeIso": [
                461
            ],
            "noDecimals": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "ExportCashFlowApiArgs": {
            "companyId": [
                15
            ],
            "exportArgs": [
                549
            ],
            "__typename": [
                1
            ]
        },
        "ExportCashFlowArgs": {
            "timeRange": [
                1669
            ],
            "fileType": [
                577
            ],
            "groupByTimeWindow": [
                1139
            ],
            "includeUDFAccounts": [
                7
            ],
            "includeCCAccounts": [
                7
            ],
            "breakdowns": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ExportCOGSBreakdownRequest": {
            "companyId": [
                15
            ],
            "from": [
                461
            ],
            "to": [
                461
            ],
            "type": [
                319
            ],
            "salesChannel": [
                1498
            ],
            "category": [
                313
            ],
            "sort": [
                633
            ],
            "searchText": [
                1
            ],
            "productIds": [
                1
            ],
            "warehouseIds": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ExportCOGSBreakdownResponse": {
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ExportCOGSOverviewRequest": {
            "companyId": [
                15
            ],
            "from": [
                461
            ],
            "to": [
                461
            ],
            "filters": [
                635
            ],
            "__typename": [
                1
            ]
        },
        "ExportCOGSOverviewResponse": {
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ExportGeneralLedgerInput": {
            "companyId": [
                15
            ],
            "startDate": [
                461
            ],
            "endDate": [
                461
            ],
            "email": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ExportGeneralLedgerResponse": {
            "jobId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "ExportInvoicesResponse": {
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ExportLedgerAccountTransactionsInput": {
            "companyId": [
                15
            ],
            "exportArgs": [
                566
            ],
            "withBalance": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "ExportLoansCsvInput": {
            "companyIds": [
                1
            ],
            "lenderIds": [
                1
            ],
            "vendorIds": [
                1
            ],
            "affiliatedEntityIds": [
                1
            ],
            "updateBy": [
                1
            ],
            "statuses": [
                1060
            ],
            "companyTiers": [
                1
            ],
            "owners": [
                1
            ],
            "showOnlyModified": [
                7
            ],
            "userActionStatuses": [
                1065
            ],
            "includeDeleted": [
                7
            ],
            "loanType": [
                1061
            ],
            "limit": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "ExportLoansResult": {
            "url": [
                1
            ],
            "fileName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ExportProductsResponse": {
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ExportResponse": {
            "signedUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ExportStatus": {},
        "ExternalBalanceInput": {
            "integrationAccountId": [
                15
            ],
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "FailingValidation": {
            "companyId": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Feature": {},
        "FetchLedgerAccountTransactionsInput": {
            "vendorId": [
                1
            ],
            "categoryId": [
                1
            ],
            "classificationTagId": [
                1
            ],
            "categoryName": [
                1
            ],
            "accountName": [
                1
            ],
            "filterByVendor": [
                7
            ],
            "filterByCategory": [
                7
            ],
            "timeRange": [
                1183
            ],
            "accountingMethod": [
                1
            ],
            "isCashFlow": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "FifoCogsBusinessEventsMetrics": {
            "type": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "integrationType": [
                1
            ],
            "metrics": [
                568
            ],
            "__typename": [
                1
            ]
        },
        "FifoCogsBusinessEventsSingleMetric": {
            "name": [
                1
            ],
            "fifo": [
                5
            ],
            "isValid": [
                7
            ],
            "be": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "FifoValidationCogsSummary": {
            "name": [
                1
            ],
            "fifoCogs": [
                5
            ],
            "isValidCost": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "FifoValidationInput": {
            "date": [
                461
            ],
            "companyId": [
                15
            ],
            "id": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "FifoValidationProductData": {
            "product": [
                1368
            ],
            "warehouse": [
                1871
            ],
            "baseMetrics": [
                574
            ],
            "cogsMetrics": [
                569
            ],
            "__typename": [
                1
            ]
        },
        "FifoValidationProductTotals": {
            "baseMetrics": [
                574
            ],
            "cogsMetrics": [
                569
            ],
            "__typename": [
                1
            ]
        },
        "FifoValidationProductValidationData": {
            "data": [
                571
            ],
            "totals": [
                572
            ],
            "totalBalance": [
                575
            ],
            "fifoCogsBusinessEventsMetrics": [
                567
            ],
            "fifoCogsBusinessEventsMetricsTotal": [
                568
            ],
            "notPropagatedBEIds": [
                1
            ],
            "redundantBEIds": [
                1
            ],
            "isUnitsValid": [
                7
            ],
            "isCogsValid": [
                7
            ],
            "isBusinessEventsValid": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "FifoValidationSummary": {
            "name": [
                1
            ],
            "source": [
                5
            ],
            "sourceCost": [
                5
            ],
            "fifo": [
                5
            ],
            "fifoCost": [
                5
            ],
            "fifoCogs": [
                5
            ],
            "isValidQuantity": [
                7
            ],
            "isValidCost": [
                7
            ],
            "isValidCogs": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "FifoValidationTotalsBalance": {
            "totalIncomingFromSource": [
                5
            ],
            "totalOnHandFromFifo": [
                5
            ],
            "totalCogs": [
                5
            ],
            "totalWaste": [
                5
            ],
            "isValid": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "FileLocation": {
            "name": [
                1
            ],
            "key": [
                1
            ],
            "bankTransactionsUploadId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "FileType": {},
        "FileUploadSource": {},
        "FileUploadUrlResult": {
            "uploadUrl": [
                1
            ],
            "url": [
                1
            ],
            "fileName": [
                1
            ],
            "key": [
                1
            ],
            "bucket": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "FilterOperator": {},
        "FinalizeAskTheUserFlowInput": {
            "companyId": [
                15
            ],
            "bankTransactionId": [
                15
            ],
            "type": [
                92
            ],
            "snapshot": [
                989
            ],
            "__typename": [
                1
            ]
        },
        "FinalizeAskTheUserFlowResult": {
            "success": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "FinaloopProduct": {
            "id": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "FinaloopProductInternalInput": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "FinaloopTagsInput": {
            "id": [
                1
            ],
            "name": [
                1
            ],
            "isNew": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "FinancialReportingStatus": {},
        "FixedAssetsData": {
            "usefulLifeStartDate": [
                461
            ],
            "depreciationMethod": [
                510
            ],
            "residualValue": [
                5
            ],
            "section179Deduction": [
                1529
            ],
            "addDepreciationBonus": [
                32
            ],
            "startDate": [
                461
            ],
            "endDate": [
                461
            ],
            "amount": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "FixedAssetsDataInput": {
            "usefulLifeStartDate": [
                1
            ],
            "depreciationMethod": [
                510
            ],
            "residualValue": [
                5
            ],
            "section179Deduction": [
                1530
            ],
            "addDepreciationBonus": [
                33
            ],
            "startDate": [
                1
            ],
            "endDate": [
                1
            ],
            "amount": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "FloatRange": {
            "min": [
                5
            ],
            "max": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "FulfillLineItemInput": {
            "lineItemId": [
                15
            ],
            "fulfillmentDate": [
                461
            ],
            "quantity": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "FulfillmentAction": {
            "quantity": [
                47
            ],
            "id": [
                1
            ],
            "date": [
                461
            ],
            "type": [
                1
            ],
            "salesChannel": [
                1873
            ],
            "sku": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "FulfillmentCost": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "source": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "sale": [
                1475
            ],
            "order": [
                1260
            ],
            "type": [
                593
            ],
            "externalId": [
                1
            ],
            "externalName": [
                1
            ],
            "amount": [
                5
            ],
            "currency": [
                440
            ],
            "amountInHomeCurrency": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "FulfillmentCostType": {},
        "FulfillmentMethod": {
            "startDate": [
                461
            ],
            "endDate": [
                461
            ],
            "type": [
                596
            ],
            "__typename": [
                1
            ]
        },
        "FulfillmentMethodInput": {
            "startDate": [
                461
            ],
            "endDate": [
                461
            ],
            "type": [
                596
            ],
            "__typename": [
                1
            ]
        },
        "FulfillmentMethodType": {},
        "FulfillmentSetting": {
            "companyId": [
                15
            ],
            "integrationAccountId": [
                15
            ],
            "methods": [
                594
            ],
            "__typename": [
                1
            ]
        },
        "FulfillmentSettingInput": {
            "companyId": [
                15
            ],
            "integrationAccountIds": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "FulfillmentsValidation": {
            "integrationData": [
                1425
            ],
            "adjustments": [
                1425
            ],
            "cogs": [
                1425
            ],
            "source": [
                1425
            ],
            "diff": [
                1425
            ],
            "isValid": [
                7
            ],
            "integrationType": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GenerateBigValidationInput": {
            "companyId": [
                15
            ],
            "to": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "GenerateBigValidationResponse": {
            "validationId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GenerateFifoValidationResponse": {
            "validationId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GenerateNoUnitsValidationError": {
            "message": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GenerateNoUnitsValidationInput": {
            "startDate": [
                461
            ],
            "endDate": [
                461
            ],
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "GenerateNoUnitsValidationResponse": {
            "validationId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GenerateNoUnitsValidationResult": {
            "id": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GeneratePdfError": {
            "message": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GeneratePdfInput": {
            "companyId": [
                1
            ],
            "html": [
                1
            ],
            "id": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GeneratePdfResponse": {
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GeneratePdfResponseV2": {
            "on_GeneratePdfResponse": [
                609
            ],
            "on_GeneratePdfError": [
                607
            ],
            "__typename": [
                1
            ]
        },
        "GetAccountRoleInput": {
            "name": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetAccrualsByTransactionIDsInput": {
            "companyId": [
                15
            ],
            "bankTransactionIds": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "GetAccrualsSpreadInput": {
            "companyId": [
                15
            ],
            "bankTransactionIds": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "GetAllCompaniesForDomainInput": {
            "domain": [
                1952
            ],
            "state": [
                1939
            ],
            "__typename": [
                1
            ]
        },
        "GetAllCompaniesForDomainResponse": {
            "companyId": [
                15
            ],
            "year": [
                47
            ],
            "state": [
                1956
            ],
            "domainState": [
                1949
            ],
            "domainData": [
                1945
            ],
            "__typename": [
                1
            ]
        },
        "GetArtificialTransactionsInput": {
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "GetAttachmentTargetURLSInput": {
            "companyId": [
                15
            ],
            "loanId": [
                15
            ],
            "fileNames": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetBackOfficeBankStatementRequestsInput": {
            "filter": [
                625
            ],
            "sort": [
                623
            ],
            "limit": [
                47
            ],
            "offset": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "GetBackOfficeBankStatementRequestsResponse": {
            "statementRequests": [
                152
            ],
            "totalCount": [
                47
            ],
            "offset": [
                47
            ],
            "limit": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "GetBankAccountStatementFilesInput": {
            "companyId": [
                1
            ],
            "bankAccountId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetBankStatementParsingRequestInput": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "bankTransactionsUploadId": [
                1
            ],
            "bankStatementFileId": [
                1
            ],
            "provider": [
                170
            ],
            "queryVersion": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "GetBankStatementParsingResultsInput": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "bankTransactionsUploadId": [
                1
            ],
            "bankStatementFileId": [
                1
            ],
            "status": [
                174
            ],
            "__typename": [
                1
            ]
        },
        "GetBankStatementReconciliationRequestsSort": {
            "type": [
                626
            ],
            "direction": [
                624
            ],
            "__typename": [
                1
            ]
        },
        "GetBankStatementRequestsDirection": {},
        "GetBankStatementRequestsFiltersInput": {
            "companyIds": [
                15
            ],
            "years": [
                1
            ],
            "tiers": [
                363
            ],
            "statuses": [
                180
            ],
            "types": [
                182
            ],
            "__typename": [
                1
            ]
        },
        "GetBankStatementRequestsSortType": {},
        "GetBillsInput": {
            "companyId": [
                15
            ],
            "dateTo": [
                461
            ],
            "limit": [
                47
            ],
            "offset": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "GetBillsResponse": {
            "bills": [
                227
            ],
            "totalCount": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "GetChargebacksByIdsInput": {
            "ids": [
                15
            ],
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "GetClosingBalancesByIdsInput": {
            "ids": [
                15
            ],
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "GetCOGSBreakdownRequest": {
            "companyId": [
                15
            ],
            "from": [
                461
            ],
            "to": [
                461
            ],
            "type": [
                319
            ],
            "salesChannel": [
                1498
            ],
            "category": [
                313
            ],
            "limit": [
                47
            ],
            "offset": [
                47
            ],
            "sort": [
                633
            ],
            "searchText": [
                1
            ],
            "productIds": [
                1
            ],
            "warehouseIds": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetCOGSBreakdownResponse": {
            "from": [
                461
            ],
            "to": [
                461
            ],
            "type": [
                319
            ],
            "items": [
                312
            ],
            "totalCount": [
                47
            ],
            "hasNext": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "GetCogsBreakdownSort": {
            "field": [
                634
            ],
            "direction": [
                1404
            ],
            "__typename": [
                1
            ]
        },
        "GetCogsBreakdownSortField": {},
        "GetCOGSOverviewFilters": {
            "source": [
                317
            ],
            "type": [
                319
            ],
            "costType": [
                394
            ],
            "category": [
                313
            ],
            "productIds": [
                1
            ],
            "warehouseIds": [
                1
            ],
            "salesChannel": [
                1498
            ],
            "groupNames": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetCOGSOverviewRequest": {
            "companyId": [
                15
            ],
            "from": [
                461
            ],
            "to": [
                461
            ],
            "filters": [
                635
            ],
            "__typename": [
                1
            ]
        },
        "GetCOGSOverviewResponse": {
            "from": [
                461
            ],
            "to": [
                461
            ],
            "summary": [
                318
            ],
            "summaryEvents": [
                314
            ],
            "__typename": [
                1
            ]
        },
        "GetCompaniesByIdsInput": {
            "companyIds": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "GetCompaniesInput": {
            "payingCompaniesOnly": [
                7
            ],
            "tiers": [
                363
            ],
            "__typename": [
                1
            ]
        },
        "GetCompanyInput": {
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "GetCompanyLoanInput": {
            "companyId": [
                15
            ],
            "loanId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "GetCompanyLoansInput": {
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "GetCompanyLockConditionsArgs": {
            "companyId": [
                1
            ],
            "year": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "GetCompanyLockConditionsArgsV2": {
            "companyId": [
                1
            ],
            "periodDates": [
                1357
            ],
            "__typename": [
                1
            ]
        },
        "GetCompanyWorksheetsArgsV2": {
            "companyId": [
                1
            ],
            "periodDates": [
                1357
            ],
            "__typename": [
                1
            ]
        },
        "GetCustomerArgs": {
            "searchTerm": [
                1
            ],
            "limit": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "GetCustomerByIdInput": {
            "companyId": [
                15
            ],
            "id": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "GetCustomerByIdsInput": {
            "companyId": [
                15
            ],
            "ids": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "GetCustomerInternal": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "GetCustomerResponse": {
            "on_Customer": [
                443
            ],
            "on_CustomerNotExistError": [
                447
            ],
            "on_Error": [
                541
            ],
            "__typename": [
                1
            ]
        },
        "GetDisputesByIdsInput": {
            "ids": [
                15
            ],
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "GetEntitiesLockedPeriodBypassesInput": {
            "companyId": [
                15
            ],
            "entitiesByType": [
                536
            ],
            "__typename": [
                1
            ]
        },
        "GetFailingValidationsByValidationCycleInput": {
            "validationCycleId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetFailingValidationsByValidationTemplateInput": {
            "validationType": [
                374
            ],
            "endTime": [
                1851
            ],
            "__typename": [
                1
            ]
        },
        "GetFiscalYerDataInput": {
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "GetFromEmailAddressError": {
            "message": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetFromEmailAddressInput": {
            "companyId": [
                1
            ],
            "entityType": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetFromEmailAddressResponse": {
            "fromAddress": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetFromEmailAddressResult": {
            "on_GetFromEmailAddressResponse": [
                658
            ],
            "on_GetFromEmailAddressError": [
                656
            ],
            "__typename": [
                1
            ]
        },
        "GetFulfillmentCostsByIdsInput": {
            "companyId": [
                15
            ],
            "fulfillmentCostIds": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "GetFulfillmentSettingInput": {
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "GetFulfillmentSettingsResponse": {
            "settings": [
                853
            ],
            "__typename": [
                1
            ]
        },
        "GetGlobalVendorsInput": {
            "ids": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "GetHealthStatusInput": {
            "asOfDate": [
                461
            ],
            "createdAt": [
                461
            ],
            "sort": [
                806
            ],
            "limit": [
                47
            ],
            "offset": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "GetIntegrationTagsInput": {
            "companyId": [
                15
            ],
            "searchText": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetIntegrationTransferInput": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "GetIntegrationTransfersBackofficeInput": {
            "companyId": [
                15
            ],
            "ids": [
                15
            ],
            "timeRange": [
                872
            ],
            "sort": [
                871
            ],
            "type": [
                873
            ],
            "limit": [
                47
            ],
            "integrationType": [
                876
            ],
            "amountRange": [
                77
            ],
            "__typename": [
                1
            ]
        },
        "GetIntegrationTransfersByIdsInput": {
            "ids": [
                15
            ],
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "GetIntegrationTransfersInput": {
            "companyId": [
                15
            ],
            "ids": [
                15
            ],
            "timeRange": [
                872
            ],
            "sort": [
                871
            ],
            "type": [
                873
            ],
            "limit": [
                47
            ],
            "offset": [
                47
            ],
            "integrationType": [
                876
            ],
            "checkAgainstOriginalAmount": [
                7
            ],
            "onlyUnidentified": [
                7
            ],
            "pairedCompanyAffiliateId": [
                15
            ],
            "withDeleted": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryAccountBalanceInput": {
            "companyId": [
                15
            ],
            "pointInTime": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryAccountBalanceResponse": {
            "inventoryAccountBalance": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryPurchasesByIdsInput": {
            "companyId": [
                15
            ],
            "ids": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryPurchasesExportInput": {
            "filters": [
                674
            ],
            "sort": [
                911
            ],
            "managementMethod": [
                1086
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryPurchasesFilters": {
            "companyId": [
                15
            ],
            "startDate": [
                461
            ],
            "endDate": [
                461
            ],
            "text": [
                1
            ],
            "status": [
                915
            ],
            "ids": [
                1
            ],
            "affiliatedEntityId": [
                1
            ],
            "source": [
                908
            ],
            "categoryName": [
                899
            ],
            "purchaseType": [
                918
            ],
            "paidStatus": [
                1266
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryPurchasesForCompanyInput": {
            "companyId": [
                15
            ],
            "historicalPurchasesOnly": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryPurchasesInput": {
            "filters": [
                674
            ],
            "offset": [
                47
            ],
            "limit": [
                47
            ],
            "sort": [
                911
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryPurchaseSummaryInput": {
            "companyId": [
                15
            ],
            "startDate": [
                461
            ],
            "endDate": [
                461
            ],
            "text": [
                1
            ],
            "status": [
                915
            ],
            "purchaseType": [
                918
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryVendorBillsInput": {
            "companyId": [
                15
            ],
            "affiliatedEntityId": [
                15
            ],
            "toDate": [
                461
            ],
            "unpaidBillsUntilDate": [
                461
            ],
            "text": [
                1
            ],
            "fromAmount": [
                5
            ],
            "toAmount": [
                5
            ],
            "itemTypes": [
                926
            ],
            "limit": [
                47
            ],
            "offset": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryVendorBillsOverviewInput": {
            "companyId": [
                15
            ],
            "affiliatedEntityId": [
                15
            ],
            "toDate": [
                461
            ],
            "unpaidBillsUntilDate": [
                461
            ],
            "text": [
                1
            ],
            "fromAmount": [
                5
            ],
            "toAmount": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryVendorFilterValuesInput": {
            "companyId": [
                15
            ],
            "untilDate": [
                461
            ],
            "text": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryVendorParamsInput": {
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryVendorRollingBalanceInput": {
            "companyId": [
                15
            ],
            "affiliatedEntityId": [
                15
            ],
            "fromDate": [
                461
            ],
            "toDate": [
                461
            ],
            "text": [
                1
            ],
            "fromAmount": [
                5
            ],
            "toAmount": [
                5
            ],
            "skipZeroAmount": [
                7
            ],
            "itemTypes": [
                936
            ],
            "limit": [
                47
            ],
            "offset": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryVendorRollingBalanceOverviewInput": {
            "companyId": [
                15
            ],
            "affiliatedEntityId": [
                15
            ],
            "fromDate": [
                461
            ],
            "toDate": [
                461
            ],
            "text": [
                1
            ],
            "fromAmount": [
                5
            ],
            "toAmount": [
                5
            ],
            "skipZeroAmount": [
                7
            ],
            "itemTypes": [
                936
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryVendorsInput": {
            "companyId": [
                15
            ],
            "text": [
                1
            ],
            "untilDate": [
                461
            ],
            "unpaidBillsUntilDate": [
                461
            ],
            "skipZeroRollingBalance": [
                7
            ],
            "negativeRollingBalance": [
                7
            ],
            "fromRollingBalance": [
                5
            ],
            "toRollingBalance": [
                5
            ],
            "sort": [
                941
            ],
            "offset": [
                47
            ],
            "limit": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryVendorsOverviewInput": {
            "companyId": [
                15
            ],
            "text": [
                1
            ],
            "untilDate": [
                461
            ],
            "unpaidBillsUntilDate": [
                461
            ],
            "skipZeroRollingBalance": [
                7
            ],
            "negativeRollingBalance": [
                7
            ],
            "fromRollingBalance": [
                5
            ],
            "toRollingBalance": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "GetInvoicesAffectingPaymentGroupsInput": {
            "companyId": [
                1
            ],
            "invoiceIds": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetInvoicesAffectingPaymentGroupsResponse": {
            "invoicesToPaymentGroups": [
                978
            ],
            "__typename": [
                1
            ]
        },
        "GetInvoiceSettingsInput": {
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "GetInvoicesResult": {
            "invoices": [
                954
            ],
            "__typename": [
                1
            ]
        },
        "GetLatestValidationInput": {
            "companyId": [
                1
            ],
            "type": [
                374
            ],
            "integrationAccountId": [
                1
            ],
            "validationCycleId": [
                1
            ],
            "account": [
                1
            ],
            "startTime": [
                461
            ],
            "endTime": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "GetLatestValidationResult": {
            "validationId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetLoanEventsInput": {
            "companyId": [
                15
            ],
            "loanId": [
                15
            ],
            "filters": [
                1034
            ],
            "page": [
                47
            ],
            "size": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "GetLoanEventsLengthInput": {
            "companyId": [
                15
            ],
            "loanId": [
                15
            ],
            "filters": [
                1034
            ],
            "__typename": [
                1
            ]
        },
        "GetLoanEventsLengthResult": {
            "length": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "GetLoanEventsResult": {
            "events": [
                1032
            ],
            "page": [
                47
            ],
            "size": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "GetLoanRelationshipsResult": {
            "relationships": [
                1049
            ],
            "page": [
                47
            ],
            "size": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "GetLoansByIdInput": {
            "loanIds": [
                15
            ],
            "includeDeleted": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "GetLoansForAllocateInput": {
            "companyId": [
                15
            ],
            "lenderId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "GetLoansInput": {
            "page": [
                47
            ],
            "companyIds": [
                1
            ],
            "lenderIds": [
                1
            ],
            "vendorIds": [
                1
            ],
            "affiliatedEntityIds": [
                1
            ],
            "updateBy": [
                1
            ],
            "statuses": [
                1060
            ],
            "companyTiers": [
                1
            ],
            "owners": [
                1
            ],
            "showOnlyModified": [
                7
            ],
            "includeDeleted": [
                7
            ],
            "userActionStatuses": [
                1065
            ],
            "sort": [
                1056
            ],
            "loanType": [
                1061
            ],
            "limit": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "GetLoansOutstandingAmountOnDateInput": {
            "companyId": [
                15
            ],
            "loansIds": [
                15
            ],
            "date": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "GetLoansOutstandingAmountOnDateResult": {
            "loans": [
                1046
            ],
            "__typename": [
                1
            ]
        },
        "GetMatchingEffectRuleInput": {
            "companyId": [
                15
            ],
            "loanId": [
                15
            ],
            "eventDefinitionId": [
                15
            ],
            "loanInput": [
                703
            ],
            "__typename": [
                1
            ]
        },
        "GetMatchingEffectRuleLoanInput": {
            "amount": [
                5
            ],
            "postedAt": [
                461
            ],
            "spreadInput": [
                427
            ],
            "memo": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetMatchingEffectRuleResult": {
            "effect": [
                1053
            ],
            "memo": [
                1
            ],
            "dailyAccrual": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "GetNonMatchingProductsInput": {
            "companyId": [
                15
            ],
            "fromDate": [
                461
            ],
            "toDate": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "GetNoUnitsPhysicalCountAnyActiveProductsWithoutQuantitiesInput": {
            "companyId": [
                1
            ],
            "physicalCountId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetNoUnitsPhysicalCountClosingBalanceInput": {
            "companyId": [
                1
            ],
            "date": [
                461
            ],
            "excludingDate": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "GetNoUnitsPhysicalCountInput": {
            "id": [
                15
            ],
            "companyId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetNoUnitsPhysicalCountOverviewInput": {
            "physicalCountId": [
                1
            ],
            "companyId": [
                1
            ],
            "filters": [
                737
            ],
            "__typename": [
                1
            ]
        },
        "GetNoUnitsPhysicalCountProductQuantitiesInput": {
            "id": [
                15
            ],
            "companyId": [
                1
            ],
            "productQuantityFilter": [
                1396
            ],
            "page": [
                1265
            ],
            "asOfDate": [
                461
            ],
            "filters": [
                737
            ],
            "sort": [
                711
            ],
            "__typename": [
                1
            ]
        },
        "GetNoUnitsPhysicalCountProductQuantitiesSort": {
            "field": [
                712
            ],
            "direction": [
                1404
            ],
            "__typename": [
                1
            ]
        },
        "GetNoUnitsPhysicalCountProductQuantitiesSortField": {},
        "GetOffersInput": {
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "GetOffersResponse": {
            "status": [
                1171
            ],
            "offers": [
                1170
            ],
            "__typename": [
                1
            ]
        },
        "GetOnboardingStatusesInput": {
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "GetOnboardingStatusInput": {
            "companyId": [
                15
            ],
            "scope": [
                1173
            ],
            "step": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetOrderFilteringBoundsResult": {
            "finaloopPaymentGateways": [
                1
            ],
            "salesChannels": [
                1
            ],
            "orderTotalP999": [
                5
            ],
            "maxItemsCount": [
                47
            ],
            "availableStatuses": [
                1195
            ],
            "__typename": [
                1
            ]
        },
        "GetOrdersAggregationsInput": {
            "filter": [
                720
            ],
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "GetOrdersDateRangeInput": {
            "min": [
                461
            ],
            "max": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "GetOrdersFilter": {
            "productIds": [
                15
            ],
            "tagIds": [
                15
            ],
            "customerIds": [
                15
            ],
            "paymentStatuses": [
                1236
            ],
            "finaloopStatus": [
                1195
            ],
            "finaloopPaymentGateways": [
                1
            ],
            "salesChannels": [
                1
            ],
            "integrationAccountIds": [
                1
            ],
            "itemsCount": [
                589
            ],
            "totalAmountInHomeCurrency": [
                589
            ],
            "placedDate": [
                719
            ],
            "firstPaymentDate": [
                719
            ],
            "firstRefundDate": [
                719
            ],
            "firstFulfillmentDate": [
                719
            ],
            "unpaidAsOfDate": [
                461
            ],
            "hasRestockedItem": [
                7
            ],
            "allTransactionsMatched": [
                7
            ],
            "fulfillmentStatuses": [
                1199
            ],
            "searchText": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetOrdersInput": {
            "companyId": [
                15
            ],
            "filter": [
                720
            ],
            "sort": [
                723
            ],
            "limit": [
                47
            ],
            "offset": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "GetOrdersResultV2": {
            "orders": [
                1260
            ],
            "__typename": [
                1
            ]
        },
        "GetOrdersSort": {
            "type": [
                725
            ],
            "direction": [
                724
            ],
            "__typename": [
                1
            ]
        },
        "GetOrdersSortDirection": {},
        "GetOrdersSortType": {},
        "GetPaymentFeesByIdsInput": {
            "ids": [
                15
            ],
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "GetPaymentsByIdsInput": {
            "ids": [
                15
            ],
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "GetPendingSalesDataAdjustmentsFilter": {
            "attributes": [
                1375
            ],
            "searchText": [
                1
            ],
            "sku": [
                1
            ],
            "source": [
                1
            ],
            "warehouseId": [
                1
            ],
            "dataType": [
                1347
            ],
            "__typename": [
                1
            ]
        },
        "GetPendingSalesDataAdjustmentsInput": {
            "salesChannel": [
                1498
            ],
            "companyId": [
                15
            ],
            "limit": [
                47
            ],
            "offset": [
                47
            ],
            "endDate": [
                461
            ],
            "startDate": [
                461
            ],
            "sort": [
                731
            ],
            "filter": [
                728
            ],
            "__typename": [
                1
            ]
        },
        "GetPendingSalesDataAdjustmentsOverviewInput": {
            "salesChannel": [
                1498
            ],
            "companyId": [
                15
            ],
            "endDate": [
                461
            ],
            "startDate": [
                461
            ],
            "filter": [
                728
            ],
            "__typename": [
                1
            ]
        },
        "GetPendingSalesDataAdjustmentsSort": {
            "field": [
                732
            ],
            "direction": [
                1404
            ],
            "__typename": [
                1
            ]
        },
        "GetPendingSalesDataAdjustmentsSortField": {},
        "GetPendingWarehouseOpeningBalancesAttachmentsInput": {
            "companyId": [
                15
            ],
            "date": [
                461
            ],
            "displayDeleted": [
                7
            ],
            "limit": [
                47
            ],
            "offset": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "GetProductBulkAdjustmentStatus": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "state": [
                1376
            ],
            "totalAmount": [
                47
            ],
            "processedAmount": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "GetProductBulkAdjustmentStatusInput": {
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "GetProductInput": {
            "companyId": [
                15
            ],
            "productId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetProductsFilter": {
            "searchText": [
                1
            ],
            "sku": [
                1
            ],
            "ids": [
                1
            ],
            "source": [
                1
            ],
            "withoutCostOnly": [
                7
            ],
            "withNegativeOnHandOnly": [
                7
            ],
            "attributes": [
                1375
            ],
            "canEditCurrentAppliedCost": [
                7
            ],
            "onlyPrimaryProducts": [
                7
            ],
            "warehouseId": [
                1
            ],
            "asOfDate": [
                461
            ],
            "startDate": [
                461
            ],
            "notMatchingProducts": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "GetProductsInput": {
            "companyId": [
                15
            ],
            "limit": [
                47
            ],
            "offset": [
                47
            ],
            "sort": [
                739
            ],
            "filter": [
                737
            ],
            "extendedList": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "GetProductsSort": {
            "field": [
                740
            ],
            "direction": [
                1404
            ],
            "__typename": [
                1
            ]
        },
        "GetProductsSortField": {},
        "GetPurchaseOrderInput": {
            "companyId": [
                15
            ],
            "purchaseOrderId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetPurchaseOrdersInput": {
            "companyId": [
                15
            ],
            "purchaseOrderIds": [
                1
            ],
            "returnDeleted": [
                7
            ],
            "searchField": [
                1
            ],
            "productIds": [
                1
            ],
            "status": [
                1424
            ],
            "fromInProgressDate": [
                461
            ],
            "toInProgressDate": [
                461
            ],
            "fromLandedCostTotal": [
                5
            ],
            "toLandedCostTotal": [
                5
            ],
            "sortField": [
                1423
            ],
            "sortDirection": [
                1620
            ],
            "showHistorical": [
                7
            ],
            "vendorId": [
                1
            ],
            "paidStatus": [
                1266
            ],
            "limit": [
                47
            ],
            "offset": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "GetPurchaseOrdersOverviewInput": {
            "companyId": [
                15
            ],
            "purchaseOrderIds": [
                1
            ],
            "returnDeleted": [
                7
            ],
            "searchField": [
                1
            ],
            "vendorId": [
                1
            ],
            "productIds": [
                1
            ],
            "fromInProgressDate": [
                461
            ],
            "toInProgressDate": [
                461
            ],
            "fromLandedCostTotal": [
                5
            ],
            "toLandedCostTotal": [
                5
            ],
            "showHistorical": [
                7
            ],
            "paidStatus": [
                1266
            ],
            "__typename": [
                1
            ]
        },
        "GetReadyValidationInput": {
            "companyId": [
                15
            ],
            "validationId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "GetReadyValidationResponse": {
            "url": [
                1
            ],
            "from": [
                461
            ],
            "to": [
                461
            ],
            "generatedAt": [
                461
            ],
            "generatedBy": [
                1
            ],
            "isGreen": [
                7
            ],
            "isError": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "GetRefundsByIdsInput": {
            "ids": [
                15
            ],
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "GetSaleAggregationsInput": {
            "companyId": [
                15
            ],
            "filter": [
                754
            ],
            "__typename": [
                1
            ]
        },
        "GetSaleDateRangeInput": {
            "min": [
                461
            ],
            "max": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "GetSaleFloatRangeInput": {
            "min": [
                5
            ],
            "max": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "GetSaleIntRangeInput": {
            "min": [
                47
            ],
            "max": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "GetSalesChannelsFilter": {
            "ids": [
                15
            ],
            "source": [
                1
            ],
            "name": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetSalesChannelsInput": {
            "companyId": [
                15
            ],
            "filter": [
                751
            ],
            "sort": [
                757
            ],
            "limit": [
                47
            ],
            "offset": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "GetSalesChannelTemplatesInput": {
            "filter": [
                751
            ],
            "sort": [
                757
            ],
            "limit": [
                47
            ],
            "offset": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "GetSalesFilter": {
            "ids": [
                15
            ],
            "customerIds": [
                15
            ],
            "excludeCustomerIds": [
                15
            ],
            "withDeleted": [
                7
            ],
            "withoutVoided": [
                7
            ],
            "withoutDrafts": [
                7
            ],
            "includeAllPreBSD": [
                7
            ],
            "salesChannelName": [
                1
            ],
            "search": [
                1
            ],
            "sources": [
                1512
            ],
            "dueInDays": [
                750
            ],
            "total": [
                749
            ],
            "balance": [
                749
            ],
            "netSales": [
                749
            ],
            "postedAt": [
                748
            ],
            "currencies": [
                440
            ],
            "excludeCurrencies": [
                440
            ],
            "paymentMethodIds": [
                15
            ],
            "productIds": [
                15
            ],
            "unpaidAsOfDate": [
                461
            ],
            "statuses": [
                1513
            ],
            "__typename": [
                1
            ]
        },
        "GetSalesInput": {
            "companyId": [
                15
            ],
            "filter": [
                754
            ],
            "sort": [
                757
            ],
            "limit": [
                47
            ],
            "offset": [
                47
            ],
            "balanceAsOfDate": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "GetSalesResult": {
            "sales": [
                1475
            ],
            "__typename": [
                1
            ]
        },
        "GetSalesSort": {
            "type": [
                759
            ],
            "direction": [
                758
            ],
            "__typename": [
                1
            ]
        },
        "GetSalesSortDirection": {},
        "GetSalesSortType": {},
        "GetSellingFeesByIdsInput": {
            "companyId": [
                15
            ],
            "sellingFeeIds": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "GetShippingCostsByIdsInput": {
            "companyId": [
                15
            ],
            "shippingCostIds": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "GetSignedDatesInput": {
            "companyId": [
                1
            ],
            "periodStart": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "GetSignedDatesResponse": {
            "dates": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "GetTax1099AffiliatedEntityGroupsInput": {
            "companyId": [
                15
            ],
            "calendarYear": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "GetUnitsTrackingPhysicalCountInput": {
            "id": [
                15
            ],
            "companyId": [
                1
            ],
            "asOfDate": [
                461
            ],
            "warehouseId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetUnitsTrackingPhysicalCountOverviewInput": {
            "physicalCountId": [
                1
            ],
            "companyId": [
                1
            ],
            "asOfDate": [
                461
            ],
            "filters": [
                737
            ],
            "__typename": [
                1
            ]
        },
        "GetUnitsTrackingPhysicalCountProductQuantitiesInput": {
            "id": [
                15
            ],
            "companyId": [
                1
            ],
            "productQuantityFilter": [
                1396
            ],
            "asOfDate": [
                461
            ],
            "page": [
                1265
            ],
            "filters": [
                737
            ],
            "sort": [
                768
            ],
            "__typename": [
                1
            ]
        },
        "GetUnitsTrackingPhysicalCountProductQuantitiesSort": {
            "field": [
                769
            ],
            "direction": [
                1404
            ],
            "__typename": [
                1
            ]
        },
        "GetUnitsTrackingPhysicalCountProductQuantitiesSortField": {},
        "GetValidationCycleInput": {
            "executionId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetValidationTemplatesInput": {
            "validationType": [
                374
            ],
            "endTime": [
                1851
            ],
            "__typename": [
                1
            ]
        },
        "GetVendorInput": {
            "companyId": [
                15
            ],
            "id": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "GetVendorResponse": {
            "on_Vendor": [
                1853
            ],
            "on_VendorNotExistError": [
                1859
            ],
            "on_Error": [
                541
            ],
            "__typename": [
                1
            ]
        },
        "GetVendorsInput": {
            "companyId": [
                15
            ],
            "ids": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "GetWarehouseOpeningBalanceOverviewInput": {
            "companyId": [
                15
            ],
            "date": [
                461
            ],
            "text": [
                1
            ],
            "warehouseIds": [
                15
            ],
            "missingData": [
                7
            ],
            "pendingChanges": [
                7
            ],
            "attachmentIds": [
                15
            ],
            "productSource": [
                1400
            ],
            "productAttributes": [
                1375
            ],
            "__typename": [
                1
            ]
        },
        "GetWarehouseOpeningBalancesInput": {
            "companyId": [
                15
            ],
            "date": [
                461
            ],
            "productSource": [
                1400
            ],
            "productAttributes": [
                1375
            ],
            "text": [
                1
            ],
            "warehouseIds": [
                15
            ],
            "missingData": [
                7
            ],
            "pendingChanges": [
                7
            ],
            "attachmentIds": [
                15
            ],
            "sort": [
                1891
            ],
            "limit": [
                47
            ],
            "offset": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "GetWarehousesDefaultsInput": {
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "GetWarehousesInput": {
            "companyId": [
                15
            ],
            "limit": [
                47
            ],
            "offset": [
                47
            ],
            "searchText": [
                1
            ],
            "includeMerged": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "GetWarehouseTransferInput": {
            "companyId": [
                1
            ],
            "id": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetWarehouseTransfersInput": {
            "filters": [
                321
            ],
            "status": [
                1931
            ],
            "sort": [
                1930
            ],
            "offset": [
                47
            ],
            "limit": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "GiftCard": {
            "id": [
                15
            ],
            "company": [
                322
            ],
            "integrationAccountId": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "lastCharacters": [
                1
            ],
            "issueMethod": [
                782
            ],
            "customer": [
                450
            ],
            "issuingOrder": [
                1260
            ],
            "issuedAt": [
                461
            ],
            "expiredAt": [
                461
            ],
            "deactivatedAt": [
                461
            ],
            "currency": [
                1
            ],
            "initialBalance": [
                5
            ],
            "initialBalanceInHomeCurrency": [
                5
            ],
            "remainingBalance": [
                5
            ],
            "remainingBalanceInHomeCurrency": [
                5
            ],
            "note": [
                1
            ],
            "transactions": [
                785
            ],
            "__typename": [
                1
            ]
        },
        "GiftCardIssueMethod": {},
        "GiftCardsByIdsInput": {
            "companyId": [
                15
            ],
            "ids": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "GiftCardsByIdsResponse": {
            "giftCards": [
                781
            ],
            "__typename": [
                1
            ]
        },
        "GiftCardTransaction": {
            "id": [
                15
            ],
            "externalSourceId": [
                15
            ],
            "currency": [
                1
            ],
            "amount": [
                5
            ],
            "amountInHomeCurrency": [
                5
            ],
            "postedAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "GiveawayCogs": {
            "amount": [
                5
            ],
            "quantity": [
                47
            ],
            "monthlyBreakdown": [
                787
            ],
            "__typename": [
                1
            ]
        },
        "GiveawayMonthlyBreakdown": {
            "date": [
                461
            ],
            "quantity": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "GlobalRuleExistByVendorAndMoneyDirectionInput": {
            "vendorId": [
                1
            ],
            "moneyDirection": [
                1142
            ],
            "__typename": [
                1
            ]
        },
        "GlobalRuleInput": {
            "vendorId": [
                1
            ],
            "metadata": [
                989
            ],
            "outcome": [
                989
            ],
            "conditions": [
                989
            ],
            "logicalOperator": [
                1474
            ],
            "updatedAtMS": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "GlobalVendorMappingRule": {
            "id": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "GoogleConnectionLinkInput": {
            "state": [
                989
            ],
            "__typename": [
                1
            ]
        },
        "GoogleConnectLinkResponse": {
            "link": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GoogleConnectStatusResponse": {
            "isConnected": [
                7
            ],
            "email": [
                1
            ],
            "sharedFolderUrl": [
                1
            ],
            "sharedFolderName": [
                1
            ],
            "accessToken": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GoogleTokenCodeArgs": {
            "companyId": [
                15
            ],
            "code": [
                1
            ],
            "scope": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GoogleTokenCodeResponse": {
            "success": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "GrantEntitiesLockedPeriodBypassesInput": {
            "companyId": [
                15
            ],
            "unlockDurationSeconds": [
                47
            ],
            "entitiesByType": [
                536
            ],
            "__typename": [
                1
            ]
        },
        "GTMSource": {
            "utms": [
                799
            ],
            "identifiers": [
                988
            ],
            "__typename": [
                1
            ]
        },
        "GTMSourceInput": {
            "utms": [
                800
            ],
            "identifiers": [
                988
            ],
            "__typename": [
                1
            ]
        },
        "GTMSourceUtms": {
            "firstUtms": [
                801
            ],
            "lastUtms": [
                801
            ],
            "__typename": [
                1
            ]
        },
        "GTMSourceUtmsInput": {
            "firstUtms": [
                802
            ],
            "lastUtms": [
                802
            ],
            "__typename": [
                1
            ]
        },
        "GTMUtms": {
            "utmTimestamp": [
                1
            ],
            "uuid": [
                1
            ],
            "utmSource": [
                1
            ],
            "utmMedium": [
                1
            ],
            "utmCampaign": [
                1
            ],
            "firstPageSeen": [
                1
            ],
            "utmTerm": [
                1
            ],
            "utmContent": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GTMUtmsInput": {
            "utmTimestamp": [
                1
            ],
            "uuid": [
                1
            ],
            "utmSource": [
                1
            ],
            "utmMedium": [
                1
            ],
            "utmCampaign": [
                1
            ],
            "firstPageSeen": [
                1
            ],
            "utmTerm": [
                1
            ],
            "utmContent": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "HandleUserLoginArgs": {
            "source": [
                988
            ],
            "invitationCode": [
                1
            ],
            "gtmSource": [
                798
            ],
            "__typename": [
                1
            ]
        },
        "HandleUserLoginResponse": {
            "acceptedInviteCompanyInfo": [
                0
            ],
            "wasCompanyCreated": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "HasUnacknowledgedChangesInput": {
            "companyId": [
                15
            ],
            "managementMethodId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "HealthStatusSortType": {
            "validation": [
                372
            ],
            "direction": [
                346
            ],
            "__typename": [
                1
            ]
        },
        "Holder": {
            "id": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "HolderCapitalInvestment": {
            "id": [
                15
            ],
            "company": [
                322
            ],
            "holder": [
                326
            ],
            "amount": [
                5
            ],
            "bankTransactionId": [
                1
            ],
            "holderTransactionId": [
                1
            ],
            "businessEventId": [
                1
            ],
            "memo": [
                1
            ],
            "createdAt": [
                461
            ],
            "updatedAt": [
                461
            ],
            "deletedAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "HolderCapitalInvestmentsByIdsInput": {
            "ids": [
                15
            ],
            "includeDeleted": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "HolderCapitalInvestmentsInput": {
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "HolderSafeInvestment": {
            "id": [
                15
            ],
            "company": [
                322
            ],
            "safeHolder": [
                326
            ],
            "amount": [
                5
            ],
            "bankTransactionId": [
                1
            ],
            "holderTransactionId": [
                1
            ],
            "businessEventId": [
                1
            ],
            "memo": [
                1
            ],
            "createdAt": [
                461
            ],
            "updatedAt": [
                461
            ],
            "deletedAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "HolderSafeInvestmentsByIdsInput": {
            "ids": [
                15
            ],
            "includeDeleted": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "HolderShare": {
            "id": [
                15
            ],
            "company": [
                322
            ],
            "holder": [
                326
            ],
            "type": [
                1568
            ],
            "series": [
                1
            ],
            "amount": [
                5
            ],
            "parValue": [
                5
            ],
            "quantity": [
                47
            ],
            "bankTransactionId": [
                1
            ],
            "holderTransactionId": [
                1
            ],
            "businessEventId": [
                1
            ],
            "configuration": [
                538
            ],
            "businessEventData": [
                258
            ],
            "memo": [
                1
            ],
            "postedAt": [
                461
            ],
            "latestActorId": [
                1
            ],
            "createdAt": [
                461
            ],
            "updatedAt": [
                461
            ],
            "deletedAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "HolderSharesByIdsInput": {
            "ids": [
                15
            ],
            "includeDeleted": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "HolderSharesInput": {
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "HolderStatus": {},
        "HolderTransaction": {
            "id": [
                15
            ],
            "company": [
                322
            ],
            "holder": [
                326
            ],
            "bankTransaction": [
                186
            ],
            "type": [
                820
            ],
            "businessEventId": [
                1
            ],
            "configuration": [
                538
            ],
            "businessEventData": [
                258
            ],
            "memo": [
                1
            ],
            "amount": [
                5
            ],
            "postedAt": [
                461
            ],
            "latestActorId": [
                1
            ],
            "createdAt": [
                461
            ],
            "updatedAt": [
                461
            ],
            "deletedAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "HolderTransactionsByIdsInput": {
            "ids": [
                15
            ],
            "includeDeleted": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "HolderTransactionsInput": {
            "companyId": [
                15
            ],
            "onlyManual": [
                7
            ],
            "includeDeleted": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "HolderTransactionType": {},
        "IdResult": {
            "id": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ImageUploadUrlResult": {
            "uploadUrl": [
                1
            ],
            "url": [
                1
            ],
            "iconUrl": [
                1
            ],
            "fileName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ImpersonateArgs": {
            "companyIdToSwitchTo": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ImpersonateResponse": {
            "success": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "IncludedFinaloopSalesChannelItemReconciliation": {
            "salesChannel": [
                985
            ],
            "data": [
                826
            ],
            "summary": [
                1448
            ],
            "productItemSoldData": [
                1602
            ],
            "productStatus": [
                1598
            ],
            "unknownSkuQuantity": [
                47
            ],
            "unknownSkuZeroPriceQuantity": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "IncludedShopifySalesChannelItemReconciliation": {
            "sku": [
                1
            ],
            "isBlacklisted": [
                7
            ],
            "salesChannel": [
                985
            ],
            "shopifySalesChannel": [
                1
            ],
            "summary": [
                1448
            ],
            "__typename": [
                1
            ]
        },
        "IncomingBatchAnalytics": {
            "origin": [
                828
            ],
            "analytics": [
                829
            ],
            "__typename": [
                1
            ]
        },
        "IncomingBatchOrigin": {
            "on_Shipment": [
                1569
            ],
            "on_WarehouseOpeningBalance": [
                1885
            ],
            "on_RecipeWithBreakdown": [
                1439
            ],
            "on_ManualAssemblyWithBreakdown": [
                1095
            ],
            "on_WarehouseTransferWithBreakdown": [
                1934
            ],
            "on_UnitsTrackingPhysicalCount": [
                1698
            ],
            "__typename": [
                1
            ]
        },
        "IncomingInventoryAnalytics": {
            "assembled": [
                5
            ],
            "incoming": [
                5
            ],
            "fulfilled": [
                5
            ],
            "warehouseTransfers": [
                5
            ],
            "adjustments": [
                5
            ],
            "restocked": [
                5
            ],
            "onHand": [
                5
            ],
            "landedCostPerUnit": [
                5
            ],
            "onHandCost": [
                5
            ],
            "unavailable": [
                5
            ],
            "unavailableByAssemblies": [
                5
            ],
            "unavailableByWarehouseTransfersOut": [
                5
            ],
            "netDepleted": [
                5
            ],
            "openingBalanceQuantity": [
                5
            ],
            "openingBalanceCost": [
                5
            ],
            "physicalCountAdjustmentOut": [
                5
            ],
            "unavailableBreakdown": [
                830
            ],
            "__typename": [
                1
            ]
        },
        "IncomingInventoryAnalyticsUnavailableBreakdown": {
            "date": [
                461
            ],
            "total": [
                5
            ],
            "action": [
                1872
            ],
            "__typename": [
                1
            ]
        },
        "IndirectCostAllocation": {
            "id": [
                15
            ],
            "indirectLineItem": [
                833
            ],
            "allocatedCostPerUnit": [
                5
            ],
            "remainderAllocationCostPerUnit": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "IndirectCostAllocationInput": {
            "indirectLineItemId": [
                15
            ],
            "allocatedCostPerUnit": [
                5
            ],
            "remainderAllocationCostPerUnit": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "IndirectLineItem": {
            "id": [
                15
            ],
            "type": [
                835
            ],
            "cost": [
                5
            ],
            "vendor": [
                1853
            ],
            "isDefaultVendor": [
                7
            ],
            "hasAutomaticAllocation": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "IndirectLineItemInput": {
            "id": [
                15
            ],
            "type": [
                835
            ],
            "cost": [
                5
            ],
            "vendorId": [
                15
            ],
            "isDefaultVendor": [
                7
            ],
            "hasAutomaticAllocation": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "IndirectLineItemType": {},
        "InitiateTotalArCashDiffsReportInput": {
            "unpaidBalanceAsOf": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "Institution": {
            "id": [
                15
            ],
            "name": [
                1
            ],
            "preferredProvider": [
                842
            ],
            "logoUrl": [
                1
            ],
            "websiteUrl": [
                1
            ],
            "affiliatedEntityId": [
                15
            ],
            "csvInstructionsLink": [
                1
            ],
            "pdfInstructionsLink": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "InstitutionMapping": {
            "id": [
                15
            ],
            "source": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "externalSourceName": [
                1
            ],
            "institutionId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "InstitutionMappingResponse": {
            "mappings": [
                838
            ],
            "__typename": [
                1
            ]
        },
        "InstitutionMappingsInput": {
            "source": [
                841
            ],
            "externalSourceId": [
                1
            ],
            "externalSourceName": [
                1
            ],
            "institutionId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "InstitutionMappingSourceType": {},
        "InstitutionProviders": {},
        "InstitutionsInput": {
            "name": [
                1
            ],
            "searchTerm": [
                1
            ],
            "ids": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "InstitutionsResponse": {
            "institutions": [
                837
            ],
            "__typename": [
                1
            ]
        },
        "InstitutionVendorID": {
            "vendorId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationCompanyAddressInput": {
            "name": [
                1
            ],
            "email": [
                1
            ],
            "phone": [
                1
            ],
            "line1": [
                1
            ],
            "line2": [
                1
            ],
            "city": [
                1
            ],
            "country": [
                1
            ],
            "province": [
                1
            ],
            "provinceCode": [
                1
            ],
            "state": [
                1
            ],
            "stateCode": [
                1
            ],
            "zip": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationConnectionAccount": {
            "integrationType": [
                876
            ],
            "connectedAt": [
                461
            ],
            "integrationAccountId": [
                15
            ],
            "integrationAccountName": [
                1
            ],
            "impactOnBooksSince": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationConnectionsFilter": {
            "companyId": [
                15
            ],
            "connectionTypes": [
                849
            ],
            "integrationTypes": [
                876
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationConnectionType": {},
        "IntegrationCustomerAddressInput": {
            "line1": [
                1
            ],
            "line2": [
                1
            ],
            "city": [
                1
            ],
            "province": [
                1
            ],
            "provinceCode": [
                1
            ],
            "state": [
                1
            ],
            "stateCode": [
                1
            ],
            "country": [
                1
            ],
            "countryCode": [
                1
            ],
            "zip": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationCustomerInput": {
            "name": [
                1
            ],
            "email": [
                1
            ],
            "externalSourceId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationDiscountInput": {
            "id": [
                15
            ],
            "amount": [
                5
            ],
            "type": [
                520
            ],
            "description": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationFulfillmentSetting": {
            "companyId": [
                15
            ],
            "integrationAccountId": [
                15
            ],
            "source": [
                876
            ],
            "methods": [
                594
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationFulfillmentSettingsInput": {
            "integrationAccountId": [
                1
            ],
            "methods": [
                595
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationIndicativeTransactionInput": {
            "postedAt": [
                461
            ],
            "amount": [
                5
            ],
            "currency": [
                440
            ],
            "amountInHomeCurrency": [
                5
            ],
            "externalSourceId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationInfo": {
            "accountId": [
                15
            ],
            "accountName": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationSaleLineItemInput": {
            "id": [
                15
            ],
            "quantity": [
                5
            ],
            "unitPrice": [
                5
            ],
            "type": [
                1483
            ],
            "currency": [
                440
            ],
            "salesChannelName": [
                1
            ],
            "productId": [
                15
            ],
            "sku": [
                1
            ],
            "description": [
                1
            ],
            "discounts": [
                519
            ],
            "taxRate": [
                5
            ],
            "externalSourceId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationSaleTransaction": {
            "id": [
                15
            ],
            "integrationInfo": [
                856
            ],
            "amount": [
                5
            ],
            "postedAt": [
                461
            ],
            "currency": [
                440
            ],
            "amountInHomeCurrency": [
                5
            ],
            "type": [
                1680
            ],
            "finaloopPaymentGateway": [
                1
            ],
            "originalPaymentGateway": [
                1
            ],
            "paymentGatewayVariant": [
                1
            ],
            "isIndication": [
                7
            ],
            "source": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationSource": {},
        "IntegrationTransactionInput": {
            "postedAt": [
                461
            ],
            "amount": [
                5
            ],
            "currency": [
                440
            ],
            "amountInHomeCurrency": [
                5
            ],
            "externalSourceId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationTransfer": {
            "id": [
                15
            ],
            "companyId": [
                1
            ],
            "amount": [
                5
            ],
            "amountInUsd": [
                5
            ],
            "currency": [
                1
            ],
            "eventTime": [
                461
            ],
            "deletedAt": [
                461
            ],
            "markedAsReconciliationDiscrepancyAt": [
                461
            ],
            "integrationType": [
                876
            ],
            "pairedBankTransaction": [
                186
            ],
            "pairedCompanyAffiliate": [
                326
            ],
            "type": [
                873
            ],
            "classification": [
                862
            ],
            "integrationAccountId": [
                15
            ],
            "integrationAccountName": [
                1
            ],
            "link": [
                1
            ],
            "uploadedFileId": [
                1
            ],
            "failedAt": [
                461
            ],
            "markedAsUnidentifiedAt": [
                461
            ],
            "rawEvent": [
                988
            ],
            "on_Payout": [
                1322
            ],
            "on_Topup": [
                1673
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationTransferClassification": {
            "businessEvent": [
                1
            ],
            "askTheUserResult": [
                989
            ],
            "description": [
                1
            ],
            "pairedEntity": [
                865
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationTransferClassificationInput": {
            "integrationTransferId": [
                15
            ],
            "businessEvent": [
                1
            ],
            "askTheUserResult": [
                989
            ],
            "description": [
                1
            ],
            "pairedEntity": [
                866
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationTransferGroup": {
            "groupId": [
                15
            ],
            "integrationTransfers": [
                861
            ],
            "payer": [
                868
            ],
            "payee": [
                868
            ],
            "isProcessing": [
                7
            ],
            "action": [
                1690
            ],
            "type": [
                873
            ],
            "tooltipText": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationTransferPairedEntity": {
            "id": [
                15
            ],
            "type": [
                867
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationTransferPairedEntityInput": {
            "id": [
                15
            ],
            "type": [
                867
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationTransferPairedEntityType": {},
        "IntegrationTransferParty": {
            "on_UnknownIntegrationTransferBank": [
                1705
            ],
            "on_UnknownIntegrationTransferAccount": [
                1704
            ],
            "on_Vendor": [
                1853
            ],
            "on_BankAccount": [
                113
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationTransferRule": {
            "id": [
                15
            ],
            "integrationType": [
                876
            ],
            "companyAffiliate": [
                326
            ],
            "integrationAccountId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationTransfersByCompanyAffiliateInput": {
            "companyId": [
                15
            ],
            "companyAffiliateId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationTransfersSort": {
            "closeToAmount": [
                5
            ],
            "closeToDate": [
                461
            ],
            "byDate": [
                1620
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationTransferTimeRangeInput": {
            "from": [
                461
            ],
            "to": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationTransferType": {},
        "IntegrationTransferTypeV2": {},
        "IntegrationTransferV2": {
            "companyId": [
                15
            ],
            "id": [
                15
            ],
            "source": [
                876
            ],
            "integrationAccountId": [
                15
            ],
            "integrationAccountName": [
                1
            ],
            "amount": [
                5
            ],
            "currency": [
                1
            ],
            "postedAt": [
                461
            ],
            "failedAt": [
                461
            ],
            "type": [
                874
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationType": {},
        "InternalTransferCandidatesInput": {
            "companyId": [
                15
            ],
            "bankTransactionId": [
                15
            ],
            "allowNotExactMatch": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "internalTransferCandidatesV2Input": {
            "companyId": [
                15
            ],
            "bankTransactionId": [
                15
            ],
            "rangePercentageAmount": [
                5
            ],
            "postedAt": [
                463
            ],
            "allowNotExactDateMatch": [
                7
            ],
            "sort": [
                208
            ],
            "limit": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "InventoryBatch": {
            "action": [
                1872
            ],
            "quantity": [
                47
            ],
            "directCost": [
                5
            ],
            "indirectCosts": [
                1146
            ],
            "__typename": [
                1
            ]
        },
        "InventoryInitStatus": {
            "areProductsLoaded": [
                7
            ],
            "isSalesDataLoaded": [
                7
            ],
            "isDataReconciled": [
                7
            ],
            "hasPurchaseOrders": [
                7
            ],
            "hasWarehouseOpeningBalances": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPaymentGroup": {
            "id": [
                15
            ],
            "companyId": [
                1
            ],
            "postedAt": [
                461
            ],
            "createdAt": [
                461
            ],
            "updatedAt": [
                461
            ],
            "deletedAt": [
                461
            ],
            "affiliatedEntity": [
                51
            ],
            "serialNumber": [
                1
            ],
            "purchaseOrders": [
                1293
            ],
            "warehouseTransfers": [
                1294
            ],
            "inventoryPurchases": [
                1290
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPaymentGroupByIdInput": {
            "inventoryPaymentGroupId": [
                15
            ],
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPaymentGroupByIdResponse": {
            "inventoryPaymentGroup": [
                881
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPaymentGroupLink": {
            "inventoryPaymentGroup": [
                881
            ],
            "serialNumber": [
                1
            ],
            "amount": [
                5
            ],
            "amountInHomeCurrency": [
                5
            ],
            "currency": [
                440
            ],
            "postedAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPaymentGroupsByIdsInput": {
            "companyId": [
                15
            ],
            "inventoryPaymentGroupIds": [
                15
            ],
            "withDeleted": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPaymentGroupsByIdsResponse": {
            "inventoryPaymentGroups": [
                881
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPaymentGroupsFilter": {
            "postedAt": [
                463
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPaymentGroupsInput": {
            "companyId": [
                1
            ],
            "filter": [
                887
            ],
            "affiliatedEntityId": [
                1
            ],
            "sort": [
                890
            ],
            "limit": [
                47
            ],
            "offset": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPaymentGroupsResponse": {
            "inventoryPaymentGroups": [
                881
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPaymentGroupsSort": {
            "type": [
                892
            ],
            "direction": [
                891
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPaymentGroupsSortDirection": {},
        "InventoryPaymentGroupsSortType": {},
        "InventoryPaymentGroupUnclearedBalance": {
            "paymentGroup": [
                881
            ],
            "unclearedBalance": [
                79
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchase": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "source": [
                907
            ],
            "sourceEntity": [
                909
            ],
            "bankTransactionId": [
                15
            ],
            "billId": [
                15
            ],
            "amazonShippingInBusinessEventId": [
                15
            ],
            "payrollId": [
                15
            ],
            "sourceDescription": [
                1
            ],
            "direction": [
                901
            ],
            "dates": [
                462
            ],
            "affiliatedEntity": [
                51
            ],
            "currency": [
                1
            ],
            "amount": [
                5
            ],
            "amountInHomeCurrency": [
                5
            ],
            "historicalPurchaseAffectingMethodId": [
                15
            ],
            "categories": [
                897
            ],
            "items": [
                903
            ],
            "paymentStatus": [
                905
            ],
            "canceledAt": [
                461
            ],
            "lastUpdatedAt": [
                461
            ],
            "attachments": [
                895
            ],
            "paidAt": [
                461
            ],
            "inventoryPaymentGroupLinks": [
                884
            ],
            "unpaidAmount": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchaseAttachment": {
            "filename": [
                1
            ],
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchaseAttachmentInput": {
            "filename": [
                1
            ],
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchaseCategory": {
            "serialNumber": [
                47
            ],
            "categoryName": [
                899
            ],
            "purchaseType": [
                918
            ],
            "amount": [
                5
            ],
            "amountInHomeCurrency": [
                5
            ],
            "classificationTag": [
                296
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchaseCategoryInput": {
            "serialNumber": [
                47
            ],
            "categoryName": [
                899
            ],
            "purchaseType": [
                918
            ],
            "amount": [
                5
            ],
            "classificationTagId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchaseCategoryName": {},
        "InventoryPurchaseCategorySummary": {
            "categoryName": [
                899
            ],
            "purchasedAmountInHomeCurrency": [
                5
            ],
            "incidentalsAmountInHomeCurrency": [
                5
            ],
            "receivedAmountInHomeCurrency": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchaseDirection": {},
        "InventoryPurchaseInput": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "sourceDescription": [
                1
            ],
            "direction": [
                901
            ],
            "dates": [
                463
            ],
            "vendorId": [
                15
            ],
            "affiliatedEntityId": [
                15
            ],
            "currency": [
                1
            ],
            "amount": [
                5
            ],
            "categories": [
                898
            ],
            "items": [
                904
            ],
            "paymentStatus": [
                905
            ],
            "lastUpdatedAt": [
                461
            ],
            "attachments": [
                896
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchaseItem": {
            "amount": [
                5
            ],
            "shipmentDate": [
                461
            ],
            "receivedDate": [
                461
            ],
            "purchaseDate": [
                461
            ],
            "note": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchaseItemInput": {
            "amount": [
                5
            ],
            "shipmentDate": [
                461
            ],
            "receivedDate": [
                461
            ],
            "purchaseDate": [
                461
            ],
            "note": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchasePaymentStatus": {},
        "InventoryPurchasesExportResponse": {
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchaseSource": {},
        "InventoryPurchaseSourceCategory": {},
        "InventoryPurchaseSourceEntity": {
            "on_BankTransaction": [
                186
            ],
            "on_Bill": [
                227
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchasesResponse": {
            "purchases": [
                894
            ],
            "hasNext": [
                7
            ],
            "totalCount": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchasesSort": {
            "field": [
                912
            ],
            "direction": [
                913
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchasesSortByField": {},
        "InventoryPurchasesSortDirection": {},
        "InventoryPurchasesSummaryResponse": {
            "summary": [
                900
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchaseStatus": {},
        "InventoryPurchasesValidationResult": {
            "checkTime": [
                461
            ],
            "expectedInProcessBalance": [
                5
            ],
            "expectedInTransitBalance": [
                5
            ],
            "actualInProcessBalance": [
                5
            ],
            "actualInTransitBalance": [
                5
            ],
            "clearingAccountBalance": [
                5
            ],
            "missingExpectedPurchaseIds": [
                15
            ],
            "unexpectedPurchaseIds": [
                15
            ],
            "missingExpectedBusinessEventsForPurchaseIds": [
                15
            ],
            "unexpectedBusinessEventIds": [
                15
            ],
            "status": [
                917
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchasesValidationStatus": {},
        "InventoryPurchaseType": {},
        "InventoryPurchaseUpdateOutOfDateError": {
            "inventoryPurchase": [
                894
            ],
            "__typename": [
                1
            ]
        },
        "InventorySettings": {
            "companyId": [
                15
            ],
            "managementMethod": [
                1
            ],
            "onboardingCompletionTime": [
                461
            ],
            "rangedManagementMethod": [
                1427
            ],
            "isEligibleForNoClosingBalancePurchaseBased": [
                7
            ],
            "openingBalance": [
                5
            ],
            "latestOpeningBalanceBusinessEventId": [
                1
            ],
            "closingBalances": [
                989
            ],
            "latestClosingBalanceBusinessEventIds": [
                1
            ],
            "realtimeCogsStatus": [
                1430
            ],
            "hasUserReviewedProducts": [
                7
            ],
            "isUsingPaymentGroupsFeature": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "InventoryUsageType": {},
        "InventoryVendor": {
            "affiliatedEntity": [
                51
            ],
            "rollingBalance": [
                5
            ],
            "unpaidBills": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorAdjustment": {
            "date": [
                461
            ],
            "amount": [
                5
            ],
            "bill": [
                227
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorBillItem": {
            "amount": [
                5
            ],
            "balance": [
                5
            ],
            "paidAmount": [
                5
            ],
            "source": [
                925
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorBillItemSource": {
            "on_InventoryPurchase": [
                894
            ],
            "on_InventoryVendorPayment": [
                933
            ],
            "on_InventoryVendorCredit": [
                929
            ],
            "on_InventoryVendorAdjustment": [
                923
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorBillItemType": {},
        "InventoryVendorBillsOverviewResponse": {
            "billsAmount": [
                5
            ],
            "billPayments": [
                5
            ],
            "billCredits": [
                5
            ],
            "billAdjustments": [
                5
            ],
            "billsPurchasesAmount": [
                5
            ],
            "billsIncidentalPurchasesAmount": [
                5
            ],
            "asOfDate": [
                931
            ],
            "beforeUnitsTracking": [
                931
            ],
            "count": [
                47
            ],
            "minAmount": [
                5
            ],
            "maxAmount": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorBillsResponse": {
            "hasNext": [
                7
            ],
            "count": [
                47
            ],
            "items": [
                924
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorCredit": {
            "date": [
                461
            ],
            "amount": [
                5
            ],
            "bill": [
                227
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorFilterValuesResponse": {
            "maxRollingBalance": [
                5
            ],
            "minRollingBalance": [
                5
            ],
            "maxUnpaidBills": [
                5
            ],
            "minUnpaidBills": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorOverviewItem": {
            "amount": [
                5
            ],
            "payments": [
                5
            ],
            "credits": [
                5
            ],
            "adjustments": [
                5
            ],
            "purchasesAmount": [
                5
            ],
            "incidentalPurchasesAmount": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorParamsResponse": {
            "inventoryVendorBillsCount": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorPayment": {
            "date": [
                461
            ],
            "amount": [
                5
            ],
            "bill": [
                227
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorRollingBalanceItem": {
            "amount": [
                5
            ],
            "rollingBalance": [
                5
            ],
            "source": [
                935
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorRollingBalanceItemSource": {
            "on_PurchaseOrder": [
                1416
            ],
            "on_InventoryPurchase": [
                894
            ],
            "on_WarehouseTransfer": [
                1904
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorRollingBalanceItemTypes": {},
        "InventoryVendorRollingBalanceOverviewResponse": {
            "affiliatedEntity": [
                51
            ],
            "purchaseOrdersBalance": [
                5
            ],
            "warehouseTransfersBalance": [
                5
            ],
            "paymentsBalance": [
                5
            ],
            "billsBalance": [
                5
            ],
            "count": [
                47
            ],
            "minAmount": [
                5
            ],
            "maxAmount": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorRollingBalanceResponse": {
            "hasNext": [
                7
            ],
            "items": [
                934
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorsOverviewResponse": {
            "count": [
                47
            ],
            "negativeRollingBalanceCount": [
                47
            ],
            "rollingBalance": [
                5
            ],
            "unpaidBills": [
                5
            ],
            "unpaidBillsOpeningBalance": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorsResponse": {
            "vendors": [
                922
            ],
            "hasNext": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorsSort": {
            "field": [
                942
            ],
            "direction": [
                1620
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorsSortField": {},
        "InventoryVendorValidationResult": {
            "totalsResult": [
                945
            ],
            "vendorsResults": [
                944
            ],
            "nonMatchingUnpaidBills": [
                1153
            ],
            "emptyVendorInventoryPurchases": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorValidationResultItem": {
            "affiliatedEntity": [
                51
            ],
            "rollingBalanceTotalAmount": [
                5
            ],
            "rollingBalanceListAmount": [
                5
            ],
            "rollingBalanceOverviewAmount": [
                5
            ],
            "unpaidBillsTotalAmount": [
                5
            ],
            "unpaidBillsListAmount": [
                5
            ],
            "unpaidBillsOverviewAmount": [
                5
            ],
            "rollingBalancePassed": [
                7
            ],
            "unpaidBillsPassed": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorValidationTotalsResult": {
            "rollingBalanceOverviewAmount": [
                5
            ],
            "rollingBalanceListAmount": [
                5
            ],
            "rollingBalanceBooksAmount": [
                5
            ],
            "rollingBalanceDbAmount": [
                5
            ],
            "unpaidBillsOverviewAmount": [
                5
            ],
            "unpaidBillsIncidentalsAmount": [
                5
            ],
            "unpaidBillsBooksAmount": [
                5
            ],
            "unpaidBillsListAmount": [
                5
            ],
            "unpaidBillsDbAmount": [
                5
            ],
            "rollingBalancePassed": [
                7
            ],
            "unpaidBillsPassed": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "InventoryYearEndHistoryLog": {
            "userEmail": [
                1
            ],
            "updaterType": [
                1
            ],
            "date": [
                461
            ],
            "status": [
                949
            ],
            "__typename": [
                1
            ]
        },
        "InventoryYearEndMissionsInput": {
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "InventoryYearEndMissionsResponse": {
            "missions": [
                1955
            ],
            "__typename": [
                1
            ]
        },
        "InventoryYearEndMissionStatus": {},
        "InventoryYearEndMissionsType": {},
        "InvitationStatus": {},
        "InviteUserArgs": {
            "email": [
                1
            ],
            "roles": [
                1
            ],
            "companyId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "InviteUserResponse": {
            "success": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "Invoice": {
            "id": [
                15
            ],
            "dueDate": [
                461
            ],
            "dueInDays": [
                47
            ],
            "companyAddress": [
                324
            ],
            "currentPdf": [
                963
            ],
            "pdfs": [
                963
            ],
            "paymentMethod": [
                1314
            ],
            "paymentMethods": [
                1314
            ],
            "saleType": [
                1
            ],
            "companyId": [
                15
            ],
            "source": [
                1512
            ],
            "externalSourceUrl": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "integrationAccountId": [
                15
            ],
            "integrationAccountName": [
                1
            ],
            "currency": [
                440
            ],
            "displayName": [
                1
            ],
            "statuses": [
                1513
            ],
            "postedAt": [
                461
            ],
            "createdAt": [
                461
            ],
            "updatedAt": [
                461
            ],
            "deletedAt": [
                461
            ],
            "publishedAt": [
                461
            ],
            "lineItems": [
                1480
            ],
            "shipping": [
                1580
            ],
            "discounts": [
                518
            ],
            "platformDiscounts": [
                518
            ],
            "taxes": [
                1658
            ],
            "taxFixedAmount": [
                5
            ],
            "adjustments": [
                1477
            ],
            "payments": [
                1515
            ],
            "credits": [
                1515
            ],
            "refunds": [
                1515
            ],
            "paymentIndications": [
                1515
            ],
            "refundIndications": [
                1515
            ],
            "customer": [
                450
            ],
            "customerId": [
                15
            ],
            "shippingAddress": [
                444
            ],
            "billingAddress": [
                444
            ],
            "integrationTags": [
                1
            ],
            "integrationNote": [
                1
            ],
            "finaloopNote": [
                1
            ],
            "customerNote": [
                1
            ],
            "isSalesAffecting": [
                7
            ],
            "isCogsAffecting": [
                7
            ],
            "accounting": [
                6
            ],
            "voidedAt": [
                461
            ],
            "voidedBy": [
                1476
            ],
            "summary": [
                1514
            ],
            "__typename": [
                1
            ]
        },
        "InvoiceAdjustmentInput": {
            "invoiceId": [
                15
            ],
            "companyId": [
                15
            ],
            "adjustmentId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "InvoiceInput": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "balanceAsOfDate": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "InvoicePaymentGroup": {
            "id": [
                15
            ],
            "companyId": [
                1
            ],
            "postedAt": [
                461
            ],
            "createdAt": [
                461
            ],
            "updatedAt": [
                461
            ],
            "deletedAt": [
                461
            ],
            "invoices": [
                1291
            ],
            "bankTransactions": [
                1289
            ],
            "expenses": [
                1283
            ],
            "actorType": [
                29
            ],
            "__typename": [
                1
            ]
        },
        "InvoicePaymentGroupByIdResponse": {
            "paymentGroup": [
                957
            ],
            "__typename": [
                1
            ]
        },
        "InvoicePaymentGroupsByIdsResponse": {
            "paymentGroups": [
                957
            ],
            "__typename": [
                1
            ]
        },
        "InvoicePaymentGroupsResponse": {
            "paymentGroups": [
                957
            ],
            "__typename": [
                1
            ]
        },
        "InvoicePaymentGroupsUnclearedBalanceResponse": {
            "asOfDate": [
                461
            ],
            "unclearedBalances": [
                962
            ],
            "__typename": [
                1
            ]
        },
        "InvoicePaymentGroupUnclearedBalance": {
            "paymentGroup": [
                957
            ],
            "unclearedBalance": [
                79
            ],
            "__typename": [
                1
            ]
        },
        "InvoicePdf": {
            "id": [
                15
            ],
            "location": [
                1
            ],
            "createdAt": [
                461
            ],
            "sentAt": [
                461
            ],
            "issuedAt": [
                461
            ],
            "isDraft": [
                7
            ],
            "isVoided": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "InvoicePdfInput": {
            "companyId": [
                15
            ],
            "invoiceId": [
                15
            ],
            "invoicePdfId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "InvoicePdfSentInput": {
            "companyId": [
                15
            ],
            "invoicePdfId": [
                15
            ],
            "invoiceId": [
                15
            ],
            "sentAtStr": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "InvoiceRefundInput": {
            "invoiceId": [
                15
            ],
            "companyId": [
                15
            ],
            "refundId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "InvoiceRefundOrAdjustmentInput": {
            "invoiceId": [
                15
            ],
            "companyId": [
                15
            ],
            "refundId": [
                15
            ],
            "adjustmentId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "InvoicesAggregations": {
            "invoicesCount": [
                47
            ],
            "invoicesAverageValue": [
                5
            ],
            "invoicesNetSales": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "InvoicesBalance": {
            "balance": [
                5
            ],
            "count": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "InvoiceSettings": {
            "logoImageLocation": [
                1
            ],
            "currentAutoInvoiceNumber": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "InvoicesInput": {
            "companyId": [
                15
            ],
            "balanceAsOfDate": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "InvoicesYearEnd": {
            "fiscalYearStartDate": [
                461
            ],
            "fiscalYearEndDate": [
                461
            ],
            "domainState": [
                1939
            ],
            "hubReportedDomainState": [
                1939
            ],
            "tasks": [
                973
            ],
            "__typename": [
                1
            ]
        },
        "InvoicesYearEndTask": {
            "id": [
                974
            ],
            "stateKey": [
                1
            ],
            "status": [
                977
            ],
            "on_AddMissingInvoicesTask": [
                37
            ],
            "on_CloseOpenInvoicesTask": [
                307
            ],
            "on_ConfirmArBalanceTask": [
                382
            ],
            "on_LinkInvoicePaymentsTask": [
                1001
            ],
            "__typename": [
                1
            ]
        },
        "InvoicesYearEndTaskId": {},
        "InvoicesYearEndTasksInput": {
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "InvoicesYearEndTasksResponse": {
            "yearEnd": [
                972
            ],
            "__typename": [
                1
            ]
        },
        "InvoicesYearEndTaskStatus": {},
        "InvoiceToPaymentGroups": {
            "invoiceId": [
                1
            ],
            "paymentGroups": [
                957
            ],
            "__typename": [
                1
            ]
        },
        "IPaymentGroup": {
            "id": [
                15
            ],
            "companyId": [
                1
            ],
            "postedAt": [
                461
            ],
            "createdAt": [
                461
            ],
            "updatedAt": [
                461
            ],
            "deletedAt": [
                461
            ],
            "on_InvoicePaymentGroup": [
                957
            ],
            "on_OrderPaymentGroup": [
                1230
            ],
            "__typename": [
                1
            ]
        },
        "IrrelevantType": {},
        "IsCompanyFulfillmentSettingsAppliedInput": {
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "IsGlobalRuleExistResult": {
            "exists": [
                7
            ],
            "id": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "IsInvitationCodeForRegisteredUserArgs": {
            "invitationCode": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "IsInvitationCodeForRegisteredUserResponse": {
            "isRegisteredUser": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "ItemReconciliationFinaloopSalesChannel": {
            "displayName": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "JournalEntryActions": {
            "firstAccount": [
                25
            ],
            "secondAccount": [
                25
            ],
            "__typename": [
                1
            ]
        },
        "JournalEntryActionsInput": {
            "firstAccount": [
                26
            ],
            "secondAccount": [
                26
            ],
            "__typename": [
                1
            ]
        },
        "JSON": {},
        "JSONObject": {},
        "LastAccountingReportSyncInput": {
            "companyId": [
                15
            ],
            "reportType": [
                1470
            ],
            "__typename": [
                1
            ]
        },
        "LastAccountingReportSyncResponse": {
            "status": [
                1469
            ],
            "exportedAt": [
                461
            ],
            "fileUrl": [
                1
            ],
            "fileName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "LastTransaction": {
            "id": [
                1
            ],
            "postedAt": [
                461
            ],
            "bankAccountId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "lenderFromEntityInput": {
            "companyId": [
                15
            ],
            "entityId": [
                15
            ],
            "entityType": [
                1124
            ],
            "__typename": [
                1
            ]
        },
        "LineItemDiscountType": {},
        "LinkedBankTransaction": {
            "bankTransaction": [
                186
            ],
            "amount": [
                79
            ],
            "__typename": [
                1
            ]
        },
        "LinkedOrder": {
            "order": [
                1260
            ],
            "amountInHomeCurrency": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "LinkInventoryPaymentGroupInput": {
            "companyId": [
                15
            ],
            "postedAt": [
                461
            ],
            "affiliatedEntityId": [
                15
            ],
            "purchaseOrders": [
                1300
            ],
            "warehouseTransfers": [
                1313
            ],
            "inventoryPurchases": [
                1286
            ],
            "__typename": [
                1
            ]
        },
        "LinkInventoryPaymentGroupResponse": {
            "inventoryPaymentGroup": [
                881
            ],
            "__typename": [
                1
            ]
        },
        "LinkInvoicePaymentGroupInput": {
            "companyId": [
                15
            ],
            "postedAt": [
                461
            ],
            "invoices": [
                1287
            ],
            "bankTransactions": [
                1281
            ],
            "expenses": [
                1284
            ],
            "actorType": [
                29
            ],
            "__typename": [
                1
            ]
        },
        "LinkInvoicePaymentGroupResponse": {
            "paymentGroup": [
                957
            ],
            "__typename": [
                1
            ]
        },
        "LinkInvoicePaymentsTask": {
            "id": [
                974
            ],
            "status": [
                977
            ],
            "stateKey": [
                1
            ],
            "currentInvoicesHash": [
                1
            ],
            "state": [
                1002
            ],
            "history": [
                1003
            ],
            "__typename": [
                1
            ]
        },
        "LinkInvoicePaymentsTaskState": {
            "confirmedInvoicesHash": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "LinkInvoicePaymentsTaskStateChangeLog": {
            "id": [
                15
            ],
            "createdAt": [
                461
            ],
            "updaterType": [
                1963
            ],
            "changedByUid": [
                1
            ],
            "reason": [
                1
            ],
            "oldValue": [
                1002
            ],
            "newValue": [
                1002
            ],
            "__typename": [
                1
            ]
        },
        "LinkOrderGroupInput": {
            "companyId": [
                15
            ],
            "postedAt": [
                461
            ],
            "orders": [
                1255
            ],
            "bankTransactions": [
                217
            ],
            "expenses": [
                1205
            ],
            "__typename": [
                1
            ]
        },
        "LinkOrderGroupResponse": {
            "orderGroup": [
                1200
            ],
            "__typename": [
                1
            ]
        },
        "LinkOrderPaymentGroupInput": {
            "companyId": [
                15
            ],
            "postedAt": [
                461
            ],
            "orders": [
                1299
            ],
            "bankTransactions": [
                1281
            ],
            "expenses": [
                1284
            ],
            "__typename": [
                1
            ]
        },
        "LinkOrderPaymentGroupResponse": {
            "paymentGroup": [
                1230
            ],
            "__typename": [
                1
            ]
        },
        "ListCompaniesInput": {
            "withArchivedCompanies": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "ListNoUnitsPhysicalCountsInput": {
            "companyId": [
                1
            ],
            "toDate": [
                461
            ],
            "adjustmentAmountMin": [
                5
            ],
            "adjustmentAmountMax": [
                5
            ],
            "productIds": [
                1
            ],
            "page": [
                1265
            ],
            "includeDrafts": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "ListReadyValidationsInput": {
            "companyId": [
                15
            ],
            "type": [
                374
            ],
            "__typename": [
                1
            ]
        },
        "ListUnitsTrackingPhysicalCountsInput": {
            "companyId": [
                1
            ],
            "toDate": [
                461
            ],
            "warehouseId": [
                1
            ],
            "productIds": [
                1
            ],
            "adjustmentAmountMin": [
                5
            ],
            "adjustmentAmountMax": [
                5
            ],
            "page": [
                1265
            ],
            "includeDrafts": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "Loan": {
            "id": [
                15
            ],
            "company": [
                322
            ],
            "source": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "latestActorType": [
                29
            ],
            "latestActorId": [
                1
            ],
            "type": [
                1061
            ],
            "lender": [
                1150
            ],
            "currency": [
                440
            ],
            "postedAt": [
                461
            ],
            "direction": [
                1031
            ],
            "internalNote": [
                1
            ],
            "isShortTerm": [
                7
            ],
            "amount": [
                5
            ],
            "totalAmount": [
                5
            ],
            "amountInHomeCurrency": [
                5
            ],
            "outstandingAmount": [
                5
            ],
            "accruedInterest": [
                5
            ],
            "unrecognizedFees": [
                5
            ],
            "prepaymentLiabilityAmount": [
                5
            ],
            "status": [
                1057
            ],
            "userActionStatus": [
                1065
            ],
            "treatmentStatus": [
                1060
            ],
            "acknowledgedInternallyAt": [
                461
            ],
            "acknowledgedInternallyBy": [
                1
            ],
            "paidOffAt": [
                461
            ],
            "lastManuallyHandledAt": [
                461
            ],
            "lastManuallyHandledBy": [
                1
            ],
            "attachments": [
                1018
            ],
            "createdBy": [
                1
            ],
            "createdById": [
                1
            ],
            "workingPaperLink": [
                1
            ],
            "internalOwner": [
                1
            ],
            "updatedBy": [
                1
            ],
            "lockedAt": [
                461
            ],
            "lastSeenByClientAt": [
                461
            ],
            "deletedAt": [
                461
            ],
            "createdAt": [
                461
            ],
            "updatedAt": [
                461
            ],
            "restoredAt": [
                461
            ],
            "directionSource": [
                1047
            ],
            "merchantSource": [
                1047
            ],
            "postedAtSource": [
                1047
            ],
            "amountSource": [
                1047
            ],
            "interestDays": [
                1038
            ],
            "repaymentType": [
                1051
            ],
            "repaymentFrequency": [
                1050
            ],
            "repaymentRate": [
                5
            ],
            "interestRateType": [
                1039
            ],
            "interestRate": [
                5
            ],
            "gracePeriod": [
                47
            ],
            "catchUpPeriod": [
                47
            ],
            "catchUpPercent": [
                5
            ],
            "originationFees": [
                5
            ],
            "prepaymentFees": [
                5
            ],
            "dataRequestType": [
                1030
            ],
            "recurringDataFrequency": [
                1048
            ],
            "instantRepaymentMethod": [
                1037
            ],
            "isIndexLinked": [
                7
            ],
            "interestType": [
                1040
            ],
            "variableInterestUpdatePeriod": [
                1068
            ],
            "variableInterestSpread": [
                5
            ],
            "variableInterestBase": [
                1067
            ],
            "compoundFrequency": [
                1027
            ],
            "indexType": [
                1036
            ],
            "modifiedFields": [
                1
            ],
            "manualEventsGroup": [
                1042
            ],
            "billPaymentEvents": [
                1022
            ],
            "isEarlyRepaymentDiscount": [
                7
            ],
            "earlyRepaymentDiscounts": [
                531
            ],
            "userFollowUp2": [
                1825
            ],
            "pendingDelete": [
                7
            ],
            "pendingRestoreAcknowledgement": [
                7
            ],
            "lastUserMoveToProcessingAt": [
                461
            ],
            "isHidden": [
                7
            ],
            "firstEventDate": [
                461
            ],
            "isBillPaymentEventAttached": [
                7
            ],
            "accountingEvents": [
                1013
            ],
            "__typename": [
                1
            ]
        },
        "LoanAccountingEvent": {
            "id": [
                15
            ],
            "amount": [
                5
            ],
            "postedAt": [
                461
            ],
            "memo": [
                1
            ],
            "businessEventActionsConfiguration": [
                254
            ],
            "customTags": [
                989
            ],
            "latestActorType": [
                29
            ],
            "latestActorId": [
                1
            ],
            "updatedAt": [
                461
            ],
            "createdAt": [
                461
            ],
            "businessEventData": [
                1024
            ],
            "__typename": [
                1
            ]
        },
        "LoanAccountRoleEffectDirection": {},
        "LoanAffiliatedTransactionEvent": {
            "id": [
                15
            ],
            "bankTransaction": [
                186
            ],
            "companyAffiliate": [
                326
            ],
            "amount": [
                5
            ],
            "postedAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "LoanAlreadyExistsResult": {
            "loan": [
                1012
            ],
            "__typename": [
                1
            ]
        },
        "LoanAmountEffect": {},
        "LoanAttachment": {
            "id": [
                15
            ],
            "name": [
                1
            ],
            "createdAt": [
                461
            ],
            "type": [
                1020
            ],
            "__typename": [
                1
            ]
        },
        "LoanAttachmentError": {
            "message": [
                1
            ],
            "attachment": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "LoanAttachmentType": {},
        "LoanBillEvent": {
            "id": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "LoanBillPaymentEvent": {
            "id": [
                15
            ],
            "bill": [
                227
            ],
            "paymentId": [
                15
            ],
            "postedAt": [
                461
            ],
            "amount": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "LoanBusinessEvent": {
            "id": [
                15
            ],
            "postedAt": [
                461
            ],
            "eventType": [
                1
            ],
            "memo": [
                1
            ],
            "actions": [
                989
            ],
            "integrationAccountId": [
                1
            ],
            "amount": [
                5
            ],
            "isMigrationAdjustment": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "LoanBusinessEventData": {
            "id": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "LoanComment": {
            "id": [
                15
            ],
            "text": [
                1
            ],
            "createdAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "LoanCommentInput": {
            "id": [
                15
            ],
            "text": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "LoanCompoundFrequency": {},
        "LoanCreationFailureResult": {
            "reason": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "LoanCreationResult": {
            "on_LoanUpdatedResult": [
                1064
            ],
            "on_LoanCreationFailureResult": [
                1028
            ],
            "on_LoanAlreadyExistsResult": [
                1016
            ],
            "on_LoanTransactionProcessedOnlineResult": [
                1059
            ],
            "__typename": [
                1
            ]
        },
        "LoanDataRequestType": {},
        "LoanDirection": {},
        "LoanEvent": {
            "on_LoanManualEvent": [
                1042
            ],
            "on_BankTransaction": [
                186
            ],
            "on_LoanBillEvent": [
                1021
            ],
            "on_LoanBusinessEvent": [
                1023
            ],
            "on_LoanBillPaymentEvent": [
                1022
            ],
            "on_Payout": [
                1322
            ],
            "on_Topup": [
                1673
            ],
            "on_LoanAffiliatedTransactionEvent": [
                1015
            ],
            "on_LoanAccountingEvent": [
                1013
            ],
            "on_IntegrationTransfer": [
                861
            ],
            "__typename": [
                1
            ]
        },
        "LoanEventDefinition": {
            "id": [
                15
            ],
            "name": [
                1
            ],
            "isSpreadable": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "LoanEventFilter": {},
        "LoanEventRecurrence": {},
        "LoanIndexType": {},
        "LoanInstantRepaymentMethod": {},
        "LoanInterestDays": {},
        "LoanInterestRateType": {},
        "LoanInterestType": {},
        "LoanLenderType": {},
        "LoanManualEvent": {
            "id": [
                15
            ],
            "eventDefinition": [
                1033
            ],
            "amount": [
                5
            ],
            "postedAt": [
                461
            ],
            "memo": [
                1
            ],
            "recurrenceSettings": [
                1451
            ],
            "spreadData": [
                1043
            ],
            "latestActorType": [
                29
            ],
            "latestActorId": [
                1
            ],
            "updatedAt": [
                461
            ],
            "createdAt": [
                461
            ],
            "effect": [
                1053
            ],
            "accountingEvents": [
                1013
            ],
            "__typename": [
                1
            ]
        },
        "LoanManualEventSpreadData": {
            "startDate": [
                461
            ],
            "endDate": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "LoanMerchantInput": {
            "id": [
                15
            ],
            "type": [
                1124
            ],
            "__typename": [
                1
            ]
        },
        "LoanOutstandingAmountAffect": {},
        "LoanOutstandingAmountOnDate": {
            "loanId": [
                15
            ],
            "outstandingAmount": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "LoanPropertySource": {},
        "LoanRecurringDataFrequency": {},
        "LoanRelationship": {
            "id": [
                15
            ],
            "isManuallyAttached": [
                7
            ],
            "isLocked": [
                7
            ],
            "event": [
                1032
            ],
            "__typename": [
                1
            ]
        },
        "LoanRepaymentFrequency": {},
        "LoanRepaymentType": {},
        "LoansByIdsResponse": {
            "loans": [
                1012
            ],
            "__typename": [
                1
            ]
        },
        "LoansEventsEffectConfiguration": {
            "id": [
                15
            ],
            "event": [
                1033
            ],
            "loanType": [
                1061
            ],
            "isShortTerm": [
                7
            ],
            "direction": [
                1031
            ],
            "minGracePeriod": [
                47
            ],
            "repaymentType": [
                1051
            ],
            "lender": [
                1150
            ],
            "memoTemplate": [
                1
            ],
            "dateTemplate": [
                1
            ],
            "businessEventActionsConfiguration": [
                254
            ],
            "loanOutstandingAmountAffect": [
                1045
            ],
            "loanAmountAffect": [
                1017
            ],
            "latestActorType": [
                29
            ],
            "latestActorId": [
                1
            ],
            "createdAt": [
                461
            ],
            "updatedAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "LoanSortDirection": {},
        "LoansResponse": {
            "loans": [
                1012
            ],
            "page": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "LoansSort": {
            "field": [
                1
            ],
            "direction": [
                1054
            ],
            "__typename": [
                1
            ]
        },
        "LoanStatus": {},
        "LoanTransactionLockStatus": {
            "transactionId": [
                15
            ],
            "isLocked": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "LoanTransactionProcessedOnlineResult": {
            "reason": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "LoanTreatmentStatus": {},
        "LoanType": {},
        "LoanUpdateBasicData": {
            "lenderId": [
                1
            ],
            "userActionStatus": [
                1065
            ],
            "postedAt": [
                461
            ],
            "amount": [
                5
            ],
            "status": [
                1057
            ],
            "hideOutdatedWarning": [
                7
            ],
            "type": [
                1061
            ],
            "pendingDelete": [
                7
            ],
            "userFollowUp2": [
                1826
            ],
            "direction": [
                1031
            ],
            "dataRequestType": [
                1030
            ],
            "recurringDataFrequency": [
                1048
            ],
            "isShortTerm": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "LoanUpdateData": {
            "status": [
                1057
            ],
            "userActionStatus": [
                1065
            ],
            "lastManuallyHandledAt": [
                461
            ],
            "postedAt": [
                461
            ],
            "amount": [
                5
            ],
            "internalOwner": [
                1
            ],
            "postedAtSource": [
                1047
            ],
            "amountSource": [
                1047
            ],
            "isShortTerm": [
                7
            ],
            "type": [
                1061
            ],
            "direction": [
                1031
            ],
            "directionSource": [
                1047
            ],
            "interestRateType": [
                1039
            ],
            "interestRate": [
                5
            ],
            "dataRequestType": [
                1030
            ],
            "interestType": [
                1040
            ],
            "isIndexLinked": [
                7
            ],
            "gracePeriod": [
                47
            ],
            "interestDays": [
                1038
            ],
            "originationFees": [
                5
            ],
            "prepaymentFees": [
                5
            ],
            "repaymentFrequency": [
                1050
            ],
            "repaymentType": [
                1051
            ],
            "lenderId": [
                1
            ],
            "lenderType": [
                1041
            ],
            "merchantSource": [
                1047
            ],
            "variableInterestUpdatePeriod": [
                1068
            ],
            "variableInterestSpread": [
                5
            ],
            "variableInterestBase": [
                1067
            ],
            "compoundFrequency": [
                1027
            ],
            "recurringDataFrequency": [
                1048
            ],
            "comment": [
                1026
            ],
            "unrecognizedFees": [
                5
            ],
            "repaymentRate": [
                5
            ],
            "catchUpPeriod": [
                47
            ],
            "catchUpPercent": [
                5
            ],
            "catchUpAmount": [
                5
            ],
            "instantRepaymentMethod": [
                1037
            ],
            "indexType": [
                1036
            ],
            "currency": [
                440
            ],
            "isEarlyRepaymentDiscount": [
                7
            ],
            "earlyRepaymentDiscounts": [
                533
            ],
            "userFollowUp2": [
                1826
            ],
            "internalNote": [
                1
            ],
            "lastUserMoveToProcessingAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "LoanUpdatedResult": {
            "loans": [
                1012
            ],
            "__typename": [
                1
            ]
        },
        "LoanUserActionStatus": {},
        "LoanUserFollowUpType": {},
        "LoanVariableInterestBase": {},
        "LoanVariableInterestUpdatePeriod": {},
        "LockAction": {},
        "LockBankAccountInput": {
            "companyId": [
                1
            ],
            "name": [
                1
            ],
            "year": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "LockBankAccountInputV2": {
            "companyId": [
                1
            ],
            "name": [
                1
            ],
            "startDate": [
                461
            ],
            "endDate": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "LockCompanyBankInput": {
            "companyId": [
                1
            ],
            "bankName": [
                1
            ],
            "year": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "LockCompanyInput": {
            "companyId": [
                1
            ],
            "year": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "LockCompanyV2Input": {
            "companyId": [
                1
            ],
            "date": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "LockConditionStatus": {},
        "LockedStatus": {
            "year": [
                47
            ],
            "lockedAt": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "LockLoanInput": {
            "loanId": [
                15
            ],
            "lockDate": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "LockLoanResult": {
            "success": [
                7
            ],
            "message": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "LockStatusV2Input": {
            "from": [
                461
            ],
            "to": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "LockType": {},
        "MachineClassificationInput": {
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "MachineClassificationJob": {
            "finishedAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "MachineValidationData": {
            "madePrivateAt": [
                461
            ],
            "lastValidAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "ManageCompanyYearEndStateInput": {
            "companyId": [
                15
            ],
            "year": [
                47
            ],
            "operation": [
                1089
            ],
            "updatedBy": [
                1
            ],
            "reason": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ManageCompanyYearEndStateResponse": {
            "status": [
                1090
            ],
            "message": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ManagementMethod": {},
        "ManagementMethodYearEndPeriod": {
            "method": [
                1086
            ],
            "startDate": [
                461
            ],
            "endDate": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "ManagementMethodYearEndPeriodInput": {
            "method": [
                1086
            ],
            "startDate": [
                461
            ],
            "endDate": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "ManageYearEndOperations": {},
        "ManageYearEndOperationStatus": {},
        "ManualAssembly": {
            "id": [
                1
            ],
            "product": [
                1368
            ],
            "date": [
                461
            ],
            "warehouse": [
                1871
            ],
            "recipe": [
                1431
            ],
            "unitsToAssemble": [
                47
            ],
            "displayName": [
                1
            ],
            "serialNumber": [
                47
            ],
            "deletedReason": [
                98
            ],
            "__typename": [
                1
            ]
        },
        "ManualAssemblyAction": {
            "quantity": [
                47
            ],
            "id": [
                1
            ],
            "date": [
                461
            ],
            "type": [
                1
            ],
            "manualAssembly": [
                1091
            ],
            "__typename": [
                1
            ]
        },
        "ManualAssemblyBreakdown": {
            "origin": [
                1095
            ],
            "analytics": [
                829
            ],
            "__typename": [
                1
            ]
        },
        "ManualAssemblyDuplicateError": {
            "date": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "ManualAssemblyWithBreakdown": {
            "assembly": [
                1091
            ],
            "warehouse": [
                1871
            ],
            "date": [
                461
            ],
            "breakdown": [
                1432
            ],
            "__typename": [
                1
            ]
        },
        "ManualCompanyLoanCreationResult": {
            "loan": [
                353
            ],
            "__typename": [
                1
            ]
        },
        "ManualEquityEntity": {
            "on_HolderTransaction": [
                817
            ],
            "on_HolderShare": [
                813
            ],
            "__typename": [
                1
            ]
        },
        "ManualEquityEventsInput": {
            "companyId": [
                15
            ],
            "includeDeleted": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "ManualLoanCreationResult": {
            "loan": [
                1012
            ],
            "__typename": [
                1
            ]
        },
        "ManualTransactionsAttachToLoanResult": {
            "loan": [
                353
            ],
            "transactions": [
                186
            ],
            "__typename": [
                1
            ]
        },
        "ManualTxInput": {
            "companyId": [
                15
            ],
            "id": [
                15
            ],
            "amount": [
                5
            ],
            "currency": [
                440
            ],
            "amountInHomeCurrency": [
                5
            ],
            "postedAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "MarkAuth0CompanyUsersAsFinishedOnboardingArgs": {
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "MarkBankAccountReconciliationRelevantInput": {
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "finaloopMessage": [
                1
            ],
            "internalMessage": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "MarkBusinessEventAsRelatedToFixedAssetSafeHarborInput": {
            "id": [
                1
            ],
            "isRelevantToAccrualsFixedAssetSafeHarbor": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "MarkIntegrationTransfersAsDiscrepancyInput": {
            "ids": [
                15
            ],
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "MarkOrdersAsOvercollectedInput": {
            "companyId": [
                15
            ],
            "orderIds": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "MarkOrdersAsOvercollectedResponse": {
            "orders": [
                1260
            ],
            "__typename": [
                1
            ]
        },
        "MarkOrdersAsUncollectibleInput": {
            "companyId": [
                15
            ],
            "orderIds": [
                15
            ],
            "postedAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "MarkOrdersAsUncollectibleResponse": {
            "orders": [
                1260
            ],
            "__typename": [
                1
            ]
        },
        "MarkOrdersInput": {
            "companyId": [
                15
            ],
            "orderMarks": [
                1113
            ],
            "__typename": [
                1
            ]
        },
        "MarkOrderType": {},
        "MarkOrderWithoutFinancialEventsInput": {
            "companyId": [
                15
            ],
            "orderId": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "MarkSingleOrderInput": {
            "orderId": [
                15
            ],
            "markType": [
                1111
            ],
            "__typename": [
                1
            ]
        },
        "Merchant": {
            "on_Vendor": [
                1853
            ],
            "on_Customer": [
                443
            ],
            "on_CompanyAffiliate": [
                326
            ],
            "on_Institution": [
                837
            ],
            "__typename": [
                1
            ]
        },
        "MerchantBankTransactionRecommendationsInput": {
            "companyId": [
                15
            ],
            "affiliatedEntityId": [
                15
            ],
            "moneyDirection": [
                1142
            ],
            "__typename": [
                1
            ]
        },
        "MerchantBankTransactionStatsMerchantKeyInput": {
            "id": [
                15
            ],
            "type": [
                1124
            ],
            "__typename": [
                1
            ]
        },
        "MerchantCategorizationInput": {
            "merchant": [
                1118
            ],
            "shouldApplyAlways": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "MerchantInput": {
            "id": [
                15
            ],
            "type": [
                1124
            ],
            "__typename": [
                1
            ]
        },
        "MerchantKey": {
            "id": [
                15
            ],
            "type": [
                1124
            ],
            "__typename": [
                1
            ]
        },
        "MerchantsBankTransactionStats": {
            "merchantsCount": [
                47
            ],
            "transactionsCount": [
                47
            ],
            "transactionsSum": [
                5
            ],
            "uncategorizedTransactionsCount": [
                47
            ],
            "uncategorizedTransactionsSum": [
                5
            ],
            "transactionBusinessEvents": [
                1
            ],
            "uncategorizedTransactionBusinessEvents": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "MerchantsBankTransactionStatsInput": {
            "companyId": [
                15
            ],
            "merchants": [
                1116
            ],
            "moneyDirection": [
                1608
            ],
            "__typename": [
                1
            ]
        },
        "MerchantsByIdsInput": {
            "companyId": [
                15
            ],
            "merchantIds": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "MerchantsByKeysInput": {
            "keys": [
                1119
            ],
            "__typename": [
                1
            ]
        },
        "MerchantType": {},
        "MeResponse": {
            "user": [
                1818
            ],
            "__typename": [
                1
            ]
        },
        "MergedProduct": {
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "finaloopId": [
                1
            ],
            "displayName": [
                1
            ],
            "imageUrl": [
                1
            ],
            "sku": [
                1
            ],
            "price": [
                5
            ],
            "lineOfBusinessId": [
                1
            ],
            "string": [
                1
            ],
            "listedChannels": [
                1495
            ],
            "appliedCosts": [
                1370
            ],
            "dailySalesData": [
                1496,
                {
                    "timeRange": [
                        1386,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "totalSold": [
                47
            ],
            "avgCostPerUnit": [
                5
            ],
            "representativeFinaloopProductId": [
                1
            ],
            "mergedWithProducts": [
                1368
            ],
            "canEditLatestAppliedCost": [
                7
            ],
            "isFractional": [
                7
            ],
            "isHidden": [
                7
            ],
            "salesData": [
                1510,
                {
                    "timeRange": [
                        1386,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "rawRecipes": [
                1431,
                {
                    "timeRange": [
                        1386,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "componentOfProducts": [
                1368
            ],
            "analyticsOverview": [
                1369,
                {
                    "input": [
                        80
                    ]
                }
            ],
            "warehousesAnalyticsOverview": [
                1408,
                {
                    "input": [
                        80
                    ]
                }
            ],
            "tags": [
                1
            ],
            "isDataSyncing": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "MergeProductsInput": {
            "companyId": [
                1
            ],
            "representativeFinaloopProductId": [
                1
            ],
            "mergedFinaloopProductIds": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "MergeProductsV2": {
            "representativeId": [
                1
            ],
            "productIds": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "MergeProductsV2Input": {
            "companyId": [
                1
            ],
            "mergeInput": [
                1128
            ],
            "splitInput": [
                1624
            ],
            "__typename": [
                1
            ]
        },
        "MergeProductsV2Response": {
            "on_MergeProductsV2Result": [
                1131
            ],
            "on_MergeProductsValidationError": [
                1132
            ],
            "__typename": [
                1
            ]
        },
        "MergeProductsV2Result": {
            "splitResult": [
                1368
            ],
            "mergeResult": [
                1368
            ],
            "__typename": [
                1
            ]
        },
        "MergeProductsValidationError": {
            "message": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "MergeResult": {},
        "MergeTax1099AffiliatedEntitiesInput": {
            "companyId": [
                15
            ],
            "affiliatedEntityIds": [
                15
            ],
            "type": [
                1665
            ],
            "calendarYear": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "MergeWarehousesError": {
            "error": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "MergeWarehousesInput": {
            "companyId": [
                15
            ],
            "representativeId": [
                1
            ],
            "warehouseIds": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "MergeWarehousesResult": {
            "on_MergeWarehousesSuccess": [
                1138
            ],
            "on_MergeWarehousesError": [
                1135
            ],
            "__typename": [
                1
            ]
        },
        "MergeWarehousesSuccess": {
            "isMerged": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "MetricRangeGroupBy": {},
        "MissionSpecificInfoInput": {
            "purchaseBasedOpeningBalance": [
                1412
            ],
            "purchaseBasedClosingBalanceInput": [
                1410
            ],
            "purchaseBasedPurchases": [
                1414
            ],
            "salesBasedMissingCostPerUnit": [
                1490
            ],
            "salesBasedClosingBalance": [
                1488
            ],
            "salesBasedPurchases": [
                1492
            ],
            "unitBasedVendorsNegativeBalance": [
                1697
            ],
            "unitBasedNegativeOnHands": [
                1694
            ],
            "unitBasedClosingBalance": [
                1692
            ],
            "__typename": [
                1
            ]
        },
        "MissionStateInput": {
            "missionType": [
                950
            ],
            "status": [
                949
            ],
            "missionSpecificData": [
                1140
            ],
            "managementMethodPeriod": [
                1088
            ],
            "__typename": [
                1
            ]
        },
        "MoneyDirection": {},
        "MoveBankAccountUploadsToAnotherBankAccountInput": {
            "companyId": [
                15
            ],
            "sourceBankAccountId": [
                15
            ],
            "targetBankAccountId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "Movement": {
            "stockSituation": [
                879
            ],
            "direction": [
                1145
            ],
            "batches": [
                879
            ],
            "unavailableQuantity": [
                47
            ],
            "action": [
                1872
            ],
            "__typename": [
                1
            ]
        },
        "MovementDirection": {},
        "MovementIndirectCost": {
            "type": [
                1147
            ],
            "amount": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "MovementIndirectCostType": {},
        "Mutation": {
            "cancelAccrual": [
                14,
                {
                    "input": [
                        269,
                        "CancelAccrualInput!"
                    ]
                }
            ],
            "createOrUpdateAccrual": [
                430,
                {
                    "input": [
                        429,
                        "CreateOrUpdateAccrualInput!"
                    ]
                }
            ],
            "deleteAccrual": [
                14,
                {
                    "input": [
                        468,
                        "DeleteAccrualInput!"
                    ]
                }
            ],
            "updateBankAccountClosingBalance": [
                113,
                {
                    "input": [
                        1723,
                        "UpdateBankAccountClosingBalanceInput!"
                    ]
                }
            ],
            "createBankAccountBalanceAdjustment": [
                113,
                {
                    "input": [
                        1722,
                        "UpdateBankAccountBalanceAdjustmentInput!"
                    ]
                }
            ],
            "deleteBankAccountBalanceAdjustment": [
                113,
                {
                    "input": [
                        1722,
                        "UpdateBankAccountBalanceAdjustmentInput!"
                    ]
                }
            ],
            "setBankAccountsLatestEventIds": [
                113,
                {
                    "input": [
                        1538,
                        "SetBankAccountsLatestEventIdsInput!"
                    ]
                }
            ],
            "createBankAccount": [
                113,
                {
                    "input": [
                        396,
                        "CreateBankAccountInput!"
                    ]
                }
            ],
            "copyBankAccount": [
                113,
                {
                    "input": [
                        393,
                        "CopyBankAccountInput!"
                    ]
                }
            ],
            "updateBankAccount": [
                113,
                {
                    "input": [
                        1724,
                        "UpdateBankAccountInput!"
                    ]
                }
            ],
            "replaceBankAccountLocks": [
                113,
                {
                    "input": [
                        1464,
                        "ReplaceBankAccountLocksInput!"
                    ]
                }
            ],
            "replaceBankAccountLocksByType": [
                113,
                {
                    "input": [
                        1463,
                        "ReplaceBankAccountLocksByTypeInput!"
                    ]
                }
            ],
            "updateBankAccountsMergeStatus": [
                1648,
                {
                    "input": [
                        1725,
                        "UpdateBankAccountsMergeStatusInput!"
                    ]
                }
            ],
            "lockBankAccount": [
                113,
                {
                    "input": [
                        1070,
                        "LockBankAccountInput!"
                    ]
                }
            ],
            "lockBankAccountV2": [
                113,
                {
                    "input": [
                        1071,
                        "LockBankAccountInputV2!"
                    ]
                }
            ],
            "createInstitution": [
                837,
                {
                    "input": [
                        414,
                        "CreateInstitutionInput!"
                    ]
                }
            ],
            "updateInstitution": [
                837,
                {
                    "input": [
                        1752,
                        "UpdateInstitutionInput!"
                    ]
                }
            ],
            "createInstitutionMapping": [
                838,
                {
                    "input": [
                        415,
                        "CreateInstitutionMappingsInput!"
                    ]
                }
            ],
            "updateInstitutionMapping": [
                838,
                {
                    "input": [
                        1753,
                        "UpdateInstitutionMappingInput!"
                    ]
                }
            ],
            "uploadBankStatement": [
                1804,
                {
                    "input": [
                        1803,
                        "UploadBankStatementInput!"
                    ]
                }
            ],
            "updateBankReconciliationRequestMessage": [
                152,
                {
                    "input": [
                        1726,
                        "UpdateBankReconciliationRequestMessageInput!"
                    ]
                }
            ],
            "updateBankReconciliationRequestStatus": [
                152,
                {
                    "input": [
                        1727,
                        "UpdateBankReconciliationRequestStatusInput!"
                    ]
                }
            ],
            "upsertBankStatementParsingRequest": [
                168,
                {
                    "input": [
                        169,
                        "BankStatementParsingRequestInput!"
                    ]
                }
            ],
            "upsertBankStatementParsingResults": [
                172,
                {
                    "input": [
                        173,
                        "BankStatementParsingResultsInput!"
                    ]
                }
            ],
            "updateBankStatementParsingResultStatus": [
                172,
                {
                    "input": [
                        1728,
                        "UpdateBankStatementParsingResultStatus!"
                    ]
                }
            ],
            "stopImportingDataForBankAccount": [
                113,
                {
                    "input": [
                        1631,
                        "StopImportingDataForBankAccountInput!"
                    ]
                }
            ],
            "stopImportingDataForBankAccountNew": [
                113,
                {
                    "input": [
                        1632,
                        "StopImportingDataForBankAccountNewInput!"
                    ]
                }
            ],
            "createBankAccountStatementForStopImport": [
                134,
                {
                    "input": [
                        397,
                        "CreateBankAccountStatementForStopImportInput!"
                    ]
                }
            ],
            "deleteBankAccounts": [
                7,
                {
                    "input": [
                        469,
                        "DeleteBankAccountsInput!"
                    ]
                }
            ],
            "updateBankAccountReconciliation": [
                128,
                {
                    "input": [
                        1744,
                        "UpdatedBankAccountReconciliation!"
                    ]
                }
            ],
            "submitBankReconciliationRequestResolution": [
                179,
                {
                    "input": [
                        178,
                        "BankStatementRequestResolutionInput!"
                    ]
                }
            ],
            "markBankReconciliationRequestAsRelevant": [
                152,
                {
                    "input": [
                        1103,
                        "MarkBankAccountReconciliationRelevantInput!"
                    ]
                }
            ],
            "triggerBankReconciliationRequests": [
                152,
                {
                    "input": [
                        129,
                        "BankAccountReconciliationsInput!"
                    ]
                }
            ],
            "updateBankReconciliationStatementAutoProcessingResults": [
                7,
                {
                    "input": [
                        156,
                        "BankReconciliationStatementAutoProcessingResultsInput!"
                    ]
                }
            ],
            "categorizeBankTransactions": [
                193,
                {
                    "input": [
                        274,
                        "CategorizeBankTransactionsInput!"
                    ]
                }
            ],
            "createCustomerCategorizationRule": [
                408,
                {
                    "input": [
                        407,
                        "CreateCustomerCategorizationRuleInput!"
                    ]
                }
            ],
            "updateBankTransactionMemo": [
                186,
                {
                    "input": [
                        1729,
                        "UpdateBankTransactionMemoInput!"
                    ]
                }
            ],
            "addBankTransactionAttachment": [
                186,
                {
                    "input": [
                        30,
                        "AddBankTransactionAttachmentInput!"
                    ]
                }
            ],
            "deleteBankTransactionAttachment": [
                186,
                {
                    "input": [
                        471,
                        "DeleteBankTransactionAttachmentInput!"
                    ]
                }
            ],
            "finalizeAskTheUserFlow": [
                582,
                {
                    "input": [
                        581,
                        "FinalizeAskTheUserFlowInput!"
                    ]
                }
            ],
            "askTheUserNotificationService": [
                94,
                {
                    "input": [
                        93,
                        "AskTheUserNotificationInput!"
                    ]
                }
            ],
            "scheduleAtuTransactionsResultProcessing": [
                1522,
                {
                    "input": [
                        1521,
                        "ScheduleAtuTransactionsResultProcessingInput!"
                    ]
                }
            ],
            "createGlobalVendorMappingRule": [
                790,
                {
                    "input": [
                        412,
                        "CreateGlobalVendorRuleInput!"
                    ]
                }
            ],
            "createBankTransactionsUpload": [
                210,
                {
                    "input": [
                        398,
                        "CreateBankTransactionsUploadInput!"
                    ]
                }
            ],
            "upsertBankTransactionLocalRule": [
                435,
                {
                    "input": [
                        1805,
                        "UpsertBankTransactionLocalRuleInput!"
                    ]
                }
            ],
            "createGlobalRule": [
                410,
                {
                    "input": [
                        789,
                        "GlobalRuleInput!"
                    ]
                }
            ],
            "attemptToClassifyTransactions": [
                295,
                {
                    "input": [
                        105,
                        "AttemptToClassifyTransactionsInput!"
                    ]
                }
            ],
            "attemptToClassifyTransactionsWithPairedEntities": [
                295,
                {
                    "input": [
                        105,
                        "AttemptToClassifyTransactionsInput!"
                    ]
                }
            ],
            "deleteBankTransactionsByBankAccountIds": [
                7,
                {
                    "input": [
                        472,
                        "DeleteBankTransactionsByBankAccountIdsInput!"
                    ]
                }
            ],
            "deleteBankTransactionsInternal": [
                56,
                {
                    "input": [
                        55,
                        "AlterBankTransactionsLifecycleStateInput!"
                    ]
                }
            ],
            "restoreBankTransactionsInternal": [
                56,
                {
                    "input": [
                        55,
                        "AlterBankTransactionsLifecycleStateInput!"
                    ]
                }
            ],
            "moveBankAccountUploadsToAnotherBankAccount": [
                210,
                {
                    "input": [
                        1143,
                        "MoveBankAccountUploadsToAnotherBankAccountInput!"
                    ]
                }
            ],
            "appendProcessingLogToBankTransactionsUpload": [
                210,
                {
                    "input": [
                        83,
                        "AppendProcessingLogToBankTransactionsUploadInput!"
                    ]
                }
            ],
            "submitTransactionsFileToBankTransactionsUpload": [
                210,
                {
                    "input": [
                        1643,
                        "SubmitTransactionsFileToBankTransactionsUploadInput!"
                    ]
                }
            ],
            "clearExpiredDoItLaterResults": [
                50
            ],
            "processCheckoutWebhookEvents": [
                7,
                {
                    "args": [
                        1366,
                        "ProcessCheckoutWebhookEventsInput!"
                    ]
                }
            ],
            "syncStripeOffers": [
                7,
                {
                    "args": [
                        1656,
                        "SyncStripeOffersInput!"
                    ]
                }
            ],
            "syncStripeTier1Offer": [
                7,
                {
                    "args": [
                        1657,
                        "SyncStripeTier1Offer!"
                    ]
                }
            ],
            "createCheckoutSession": [
                400,
                {
                    "args": [
                        399,
                        "CreateCheckoutSessionInput!"
                    ]
                }
            ],
            "pollCheckoutSessionStatus": [
                322,
                {
                    "args": [
                        1362,
                        "PollCheckoutSessionStatusInput!"
                    ]
                }
            ],
            "createOrGetBusinessEventDefinition": [
                254,
                {
                    "input": [
                        428,
                        "CreateOrGetBusinessEventDefinitionInput!"
                    ]
                }
            ],
            "createAccountRole": [
                10,
                {
                    "input": [
                        395,
                        "CreateAccountRoleInput!"
                    ]
                }
            ],
            "markBusinessEventAsRelatedToFixedAssetSafeHarbor": [
                265,
                {
                    "input": [
                        1104,
                        "MarkBusinessEventAsRelatedToFixedAssetSafeHarborInput!"
                    ]
                }
            ],
            "updateBooksStartTime": [
                1645,
                {
                    "args": [
                        1730,
                        "UpdateBooksStartTimeArgs!"
                    ]
                }
            ],
            "createFileUploadUrl": [
                579,
                {
                    "input": [
                        409,
                        "CreateFileUploadUrlInput!"
                    ]
                }
            ],
            "updateCompanyAccountingMethod": [
                322,
                {
                    "args": [
                        1735,
                        "UpdateCompanyAccountingMethodArgs!"
                    ]
                }
            ],
            "updateEstimatedProjectedRevenue": [
                322,
                {
                    "args": [
                        1748,
                        "UpdateEstimatedProjectedRevenueInput!"
                    ]
                }
            ],
            "toggleOrdersZeroNetBalance": [
                1645,
                {
                    "args": [
                        1670,
                        "ToggleOrdersZeroNetBalanceInput!"
                    ]
                }
            ],
            "toggleRecognizeZeroNetOrdersAsGiveaways": [
                7,
                {
                    "args": [
                        1671,
                        "ToggleRecognizeZeroNetOrdersAsGiveawaysInput!"
                    ]
                }
            ],
            "createImageUploadUrl": [
                822,
                {
                    "input": [
                        413,
                        "CreateImageUploadUrlInput!"
                    ]
                }
            ],
            "setSettings": [
                1645,
                {
                    "args": [
                        1563,
                        "SetSettingsArgs!"
                    ]
                }
            ],
            "upsertCompanyAddress": [
                324,
                {
                    "input": [
                        1806
                    ]
                }
            ],
            "deleteCompanyAddress": [
                7,
                {
                    "input": [
                        325,
                        "CompanyAddressInput!"
                    ]
                }
            ],
            "updateOnboardingStatus": [
                1176,
                {
                    "input": [
                        1777,
                        "UpdateOnboardingStatusInput!"
                    ]
                }
            ],
            "setFiscalYearData": [
                7,
                {
                    "input": [
                        1539,
                        "SetFiscalYearDataInput!"
                    ]
                }
            ],
            "updateCompanyNamesAndNotifyHandler": [
                322,
                {
                    "input": [
                        1739,
                        "UpdateCompanyNamesAndNotifyHandlerInput!"
                    ]
                }
            ],
            "updateCompany": [
                322,
                {
                    "input": [
                        1738,
                        "UpdateCompanyInput!"
                    ]
                }
            ],
            "setFiscalYearStart": [
                7,
                {
                    "input": [
                        1540,
                        "SetFiscalYearStartInput!"
                    ]
                }
            ],
            "createCompany": [
                322,
                {
                    "args": [
                        402,
                        "CreateCompanyInput!"
                    ]
                }
            ],
            "updateCompanyPayingStatus": [
                322,
                {
                    "args": [
                        1740,
                        "UpdateCompanyPayingStatusInput!"
                    ]
                }
            ],
            "syncCompany": [
                7,
                {
                    "args": [
                        1652,
                        "SyncCompanyInput!"
                    ]
                }
            ],
            "lockCompany": [
                360,
                {
                    "args": [
                        1073,
                        "LockCompanyInput!"
                    ]
                }
            ],
            "unlockCompany": [
                360,
                {
                    "args": [
                        1073,
                        "LockCompanyInput!"
                    ]
                }
            ],
            "lockCompanyV2": [
                322,
                {
                    "args": [
                        1074,
                        "LockCompanyV2Input!"
                    ]
                }
            ],
            "unlockCompanyV2": [
                322,
                {
                    "args": [
                        1708,
                        "UnlockCompanyV2Input!"
                    ]
                }
            ],
            "removeDataRestriction": [
                322,
                {
                    "args": [
                        1460,
                        "RemoveDataRestrictionInput!"
                    ]
                }
            ],
            "validationCycle": [
                1845,
                {
                    "input": [
                        770,
                        "GetValidationCycleInput!"
                    ]
                }
            ],
            "completeValidationCycle": [
                7,
                {
                    "input": [
                        378,
                        "CompleteValidationCycleInput!"
                    ]
                }
            ],
            "sendValidationNotification": [
                7,
                {
                    "input": [
                        1537,
                        "SendValidationNotificationInput!"
                    ]
                }
            ],
            "storeValidation": [
                7,
                {
                    "input": [
                        1638,
                        "StoreValidationInput!"
                    ]
                }
            ],
            "grantEntitiesLockedPeriodBypasses": [
                7,
                {
                    "args": [
                        796,
                        "GrantEntitiesLockedPeriodBypassesInput!"
                    ]
                }
            ],
            "createCompanyAffiliate": [
                326,
                {
                    "input": [
                        401,
                        "CreateCompanyAffiliateInput!"
                    ]
                }
            ],
            "updateCompanyAffiliate": [
                326,
                {
                    "input": [
                        1736,
                        "UpdateCompanyAffiliateInput!"
                    ]
                }
            ],
            "deleteCompanyAffiliate": [
                326,
                {
                    "input": [
                        473,
                        "DeleteCompanyAffiliateInput!"
                    ]
                }
            ],
            "upsertHolderShare": [
                813,
                {
                    "input": [
                        1811,
                        "UpsertHolderShareInput!"
                    ]
                }
            ],
            "deleteHolderShare": [
                813,
                {
                    "input": [
                        475,
                        "DeleteHolderShareInput!"
                    ]
                }
            ],
            "upsertHolderCapitalInvestment": [
                808,
                {
                    "input": [
                        1810,
                        "UpsertHolderCapitalInvestmentInput!"
                    ]
                }
            ],
            "deleteHolderCapitalInvestment": [
                808,
                {
                    "input": [
                        474,
                        "DeleteHolderCapitalInvestmentInput!"
                    ]
                }
            ],
            "addRoleToAffiliatedEntity": [
                45,
                {
                    "input": [
                        43,
                        "AddRoleToAffiliatedEntityInput!"
                    ]
                }
            ],
            "upsertManualEquityEvent": [
                1097,
                {
                    "input": [
                        1816,
                        "UpsertManualEquityEventInput!"
                    ]
                }
            ],
            "deleteManualEquityEvents": [
                1097,
                {
                    "input": [
                        489,
                        "DeleteManualEquityEventsInput!"
                    ]
                }
            ],
            "companyIntegrationBulkUpdate": [
                349,
                {
                    "input": [
                        350,
                        "CompanyIntegrationBulkUpdateInput!"
                    ]
                }
            ],
            "deleteBankConnection": [
                7,
                {
                    "input": [
                        470,
                        "DeleteBankConnectionInput!"
                    ]
                }
            ],
            "disconnectPayrollConnection": [
                7,
                {
                    "input": [
                        517,
                        "DisconnectConnectionInput!"
                    ]
                }
            ],
            "disconnectConnection": [
                7,
                {
                    "input": [
                        517,
                        "DisconnectConnectionInput!"
                    ]
                }
            ],
            "confirmRoleForPaymentGateway": [
                7,
                {
                    "input": [
                        385,
                        "ConfirmRoleForPaymentGatewayInput!"
                    ]
                }
            ],
            "unclassifyIntegrationTransfers": [
                861,
                {
                    "input": [
                        1684,
                        "UnclassifyIntegrationTransferInput!"
                    ]
                }
            ],
            "markIntegrationTransfersAsDiscrepancy": [
                861,
                {
                    "input": [
                        1105,
                        "MarkIntegrationTransfersAsDiscrepancyInput!"
                    ]
                }
            ],
            "classifyIntegrationTransfer": [
                861,
                {
                    "input": [
                        303,
                        "ClassifyIntegrationTransferInput!"
                    ]
                }
            ],
            "createIntegrationTransferRule": [
                869,
                {
                    "input": [
                        416,
                        "CreateIntegrationTransferRuleInput!"
                    ]
                }
            ],
            "scheduleAtuIntegrationTransfersResultProcessing": [
                1520,
                {
                    "input": [
                        1519,
                        "ScheduleAtuIntegrationTransfersResultProcessingInput!"
                    ]
                }
            ],
            "refreshSkuAmbiguity": [
                7,
                {
                    "input": [
                        1456,
                        "RefreshSkuAmbiguityInput!"
                    ]
                }
            ],
            "updateAppliedCostFromIntegration": [
                7,
                {
                    "input": [
                        1720,
                        "UpdateAppliedCostFromIntegrationInput!"
                    ]
                }
            ],
            "triggerCOGSFromScratch": [
                7,
                {
                    "input": [
                        1683,
                        "TriggerCOGSFromScratchInput!"
                    ]
                }
            ],
            "bulkRecoveryUnitsTracking": [
                253,
                {
                    "input": [
                        252
                    ]
                }
            ],
            "syncInventoryIntegrationFulfillmentSetting": [
                7,
                {
                    "input": [
                        1654,
                        "SyncInventoryIntegrationFulfillmentSettingInput!"
                    ]
                }
            ],
            "reconcileInventoryPurchasesFromTransactions": [
                7,
                {
                    "input": [
                        1446,
                        "ReconcileInventoryPurchasesFromTransactionsInput!"
                    ]
                }
            ],
            "reconcileInventoryPurchasesFromBills": [
                7,
                {
                    "input": [
                        1443,
                        "ReconcileInventoryPurchasesFromBillsInput!"
                    ]
                }
            ],
            "reconcileInventoryPurchasesFromBusinessEvents": [
                7,
                {
                    "input": [
                        1444,
                        "ReconcileInventoryPurchasesFromBusinessEventsInput!"
                    ]
                }
            ],
            "reconcileAllPayrollInventoryPurchases": [
                7,
                {
                    "input": [
                        1442,
                        "ReconcileAllInventoryPurchasesInput!"
                    ]
                }
            ],
            "reconcileAllContractorPaymentInventoryPurchases": [
                7,
                {
                    "input": [
                        1442,
                        "ReconcileAllInventoryPurchasesInput!"
                    ]
                }
            ],
            "reconcileAllBillInventoryPurchases": [
                7,
                {
                    "input": [
                        1442,
                        "ReconcileAllInventoryPurchasesInput!"
                    ]
                }
            ],
            "reReconcileInventoryPurchases": [
                7,
                {
                    "input": [
                        1471,
                        "ReReconcileInventoryPurchasesInput!"
                    ]
                }
            ],
            "shopifyItemsValidationBlacklistSku": [
                7,
                {
                    "input": [
                        1590,
                        "ShopifyItemsValidationBlacklistSkuInput!"
                    ]
                }
            ],
            "shopifyItemsValidationBlacklistSalesChannel": [
                7,
                {
                    "input": [
                        1589,
                        "ShopifyItemsValidationBlacklistSalesChannelInput!"
                    ]
                }
            ],
            "markOrderWithoutFinancialEvents": [
                7,
                {
                    "input": [
                        1112,
                        "MarkOrderWithoutFinancialEventsInput!"
                    ]
                }
            ],
            "markOrdersWithoutFinancialEvents": [
                47,
                {
                    "input": [
                        76,
                        "AmazonValidationInput!"
                    ]
                }
            ],
            "generateFifoValidation": [
                602,
                {
                    "input": [
                        570,
                        "FifoValidationInput!"
                    ]
                }
            ],
            "generateNoUnitsValidation": [
                605,
                {
                    "input": [
                        604,
                        "GenerateNoUnitsValidationInput!"
                    ]
                }
            ],
            "generateBigValidation": [
                601,
                {
                    "input": [
                        600,
                        "GenerateBigValidationInput!"
                    ]
                }
            ],
            "amazonValidationAdjustSkuQuantities": [
                7,
                {
                    "input": [
                        75,
                        "AmazonValidationAdjustSkuQuantitiesInput!"
                    ]
                }
            ],
            "createInventoryVendorValidation": [
                418,
                {
                    "input": [
                        417,
                        "CreateInventoryVendorValidationInput!"
                    ]
                }
            ],
            "changeProductsHiddenStatus": [
                7,
                {
                    "input": [
                        279,
                        "ChangeProductsHiddenStatusInput!"
                    ]
                }
            ],
            "createProductManually": [
                433,
                {
                    "input": [
                        432,
                        "CreateProductManuallyInput!"
                    ]
                }
            ],
            "deleteManualAssembly": [
                7,
                {
                    "input": [
                        488,
                        "DeleteManualAssemblyInput!"
                    ]
                }
            ],
            "deletePurchaseOrder": [
                7,
                {
                    "input": [
                        503,
                        "DeletePurchaseOrderInput!"
                    ]
                }
            ],
            "deleteRecipe": [
                7,
                {
                    "input": [
                        504,
                        "DeleteRecipeInput!"
                    ]
                }
            ],
            "mergeProductsV2": [
                1130,
                {
                    "input": [
                        1129,
                        "MergeProductsV2Input!"
                    ]
                }
            ],
            "setProductAdjustments": [
                1368,
                {
                    "input": [
                        1555,
                        "SetProductAdjustmentsInput!"
                    ]
                }
            ],
            "setWarehouseOpeningBalance": [
                1885,
                {
                    "input": [
                        1567,
                        "SetWarehouseOpeningBalanceInput!"
                    ]
                }
            ],
            "updateManualAssembly": [
                1768,
                {
                    "input": [
                        1767,
                        "UpdateManualAssemblyInput!"
                    ]
                }
            ],
            "updateProduct": [
                1368,
                {
                    "input": [
                        1785,
                        "UpdateProductInput!"
                    ]
                }
            ],
            "updateProductFractional": [
                1368,
                {
                    "input": [
                        1784,
                        "UpdateProductFractionalInput!"
                    ]
                }
            ],
            "updatePurchaseOrder": [
                1416,
                {
                    "input": [
                        1419,
                        "PurchaseOrderInput!"
                    ]
                }
            ],
            "updateRecipe": [
                1787,
                {
                    "input": [
                        1786,
                        "UpdateRecipeInput!"
                    ]
                }
            ],
            "generatePdf": [
                609,
                {
                    "input": [
                        608,
                        "GeneratePdfInput!"
                    ]
                }
            ],
            "generatePdfV2": [
                610,
                {
                    "input": [
                        608,
                        "GeneratePdfInput!"
                    ]
                }
            ],
            "sendPoEmail": [
                1536,
                {
                    "input": [
                        1534,
                        "SendPoEmailInput!"
                    ]
                }
            ],
            "setInventoryUsages": [
                7,
                {
                    "input": [
                        1543,
                        "SetInventoryUsagesInput!"
                    ]
                }
            ],
            "updateInventoryPurchase": [
                1757,
                {
                    "input": [
                        902,
                        "InventoryPurchaseInput!"
                    ]
                }
            ],
            "deleteInventoryPurchase": [
                919,
                {
                    "input": [
                        478,
                        "DeleteInventoryPurchaseInput!"
                    ]
                }
            ],
            "schedulePurchasesValidation": [
                1524,
                {
                    "input": [
                        1523,
                        "SchedulePurchasesValidationInput!"
                    ]
                }
            ],
            "setPendingWarehouseOpeningBalance": [
                1355,
                {
                    "input": [
                        1550,
                        "SetPendingWarehouseOpeningBalancesInput!"
                    ]
                }
            ],
            "deleteWarehouseOpeningBalance": [
                7,
                {
                    "input": [
                        507,
                        "DeleteWarehouseOpeningBalancesInput!"
                    ]
                }
            ],
            "discardPendingWarehouseOpeningBalance": [
                7,
                {
                    "input": [
                        515,
                        "DiscardPendingWarehouseOpeningBalancesInput!"
                    ]
                }
            ],
            "applyPendingWarehouseOpeningBalance": [
                7,
                {
                    "input": [
                        88,
                        "ApplyPendingWarehouseOpeningBalanceInput!"
                    ]
                }
            ],
            "setPendingWarehouseOpeningBalanceWithCsv": [
                1356,
                {
                    "input": [
                        1552,
                        "SetPendingWarehouseOpeningBalancesWithCsvInput!"
                    ]
                }
            ],
            "deletePendingWarehouseOpeningBalanceAttachment": [
                7,
                {
                    "input": [
                        501,
                        "DeletePendingWarehouseOpeningAttachmentInput!"
                    ]
                }
            ],
            "updateWarehouseTransfer": [
                1904,
                {
                    "input": [
                        1802,
                        "UpdateWarehouseTransferInput!"
                    ]
                }
            ],
            "deleteWarehouseTransfer": [
                7,
                {
                    "input": [
                        508,
                        "DeleteWarehouseTransferInput!"
                    ]
                }
            ],
            "setPendingSalesDataAdjustments": [
                1548,
                {
                    "input": [
                        1546,
                        "SetPendingSalesDataAdjustmentsInput!"
                    ]
                }
            ],
            "setPendingSalesDataAdjustmentsWithCsv": [
                1385,
                {
                    "input": [
                        1549,
                        "SetPendingSalesDataAdjustmentsWithCsvInput!"
                    ]
                }
            ],
            "discardPendingSalesDataAdjustments": [
                7,
                {
                    "input": [
                        514,
                        "DiscardPendingSalesDataAdjustments!"
                    ]
                }
            ],
            "applyPendingSalesDataAdjustments": [
                734,
                {
                    "input": [
                        86,
                        "ApplyPendingSalesDataAdjustmentsInput!"
                    ]
                }
            ],
            "updateNoUnitsPhysicalCount": [
                821,
                {
                    "input": [
                        1774,
                        "UpdateNoUnitsPhysicalCountInput!"
                    ]
                }
            ],
            "deleteNoUnitsPhysicalCount": [
                7,
                {
                    "input": [
                        492,
                        "DeleteNoUnitsPhysicalCountInput!"
                    ]
                }
            ],
            "updateNoUnitsPhysicalCountProductQuantityPendingAdjustments": [
                7,
                {
                    "input": [
                        1776,
                        "UpdateNoUnitsPhysicalCountProductQuantityPendingAdjustmentsInput!"
                    ]
                }
            ],
            "applyNoUnitsPhysicalCountProductQuantitiesPendingAdjustments": [
                7,
                {
                    "input": [
                        85,
                        "ApplyNoUnitsPhysicalCountProductQuantitiesPendingAdjustmentsInput!"
                    ]
                }
            ],
            "discardNoUnitsPhysicalCountProductQuantityPendingAdjustments": [
                7,
                {
                    "input": [
                        513,
                        "DiscardNoUnitsPhysicalCountProductQuantityPendingAdjustmentInput!"
                    ]
                }
            ],
            "setNoUnitsPhysicalCountPendingAdjustmentsWithCsv": [
                1385,
                {
                    "input": [
                        1553,
                        "SetPhysicalCountPendingAdjustmentsWithCsvInput!"
                    ]
                }
            ],
            "updateUnitsTrackingPhysicalCount": [
                1698,
                {
                    "input": [
                        1791,
                        "UpdateUnitsTrackingPhysicalCountInput!"
                    ]
                }
            ],
            "deleteUnitsTrackingPhysicalCount": [
                7,
                {
                    "input": [
                        505,
                        "DeleteUnitsTrackingPhysicalCountInput!"
                    ]
                }
            ],
            "updateUnitsTrackingPhysicalCountProductQuantityPendingAdjustments": [
                7,
                {
                    "input": [
                        1793,
                        "UpdateUnitsTrackingPhysicalCountProductQuantityPendingAdjustmentsInput!"
                    ]
                }
            ],
            "acknowledgeUnitsTrackingPhysicalCountProductQuantitiesAmounts": [
                7,
                {
                    "input": [
                        24,
                        "AcknowledgeUnitsTrackingPhysicalCountProductQuantitiesAmountsInput!"
                    ]
                }
            ],
            "discardUnitsTrackingPhysicalCountProductQuantityPendingAdjustments": [
                7,
                {
                    "input": [
                        516,
                        "DiscardUnitsTrackingPhysicalCountProductQuantityPendingAdjustmentInput!"
                    ]
                }
            ],
            "setUnitsTrackingPhysicalCountPendingAdjustmentsWithCsv": [
                1385,
                {
                    "input": [
                        1553,
                        "SetPhysicalCountPendingAdjustmentsWithCsvInput!"
                    ]
                }
            ],
            "createWarehouse": [
                1876,
                {
                    "input": [
                        438,
                        "CreateWarehouseInput!"
                    ]
                }
            ],
            "deleteWarehouse": [
                7,
                {
                    "input": [
                        506,
                        "DeleteWarehouseInput!"
                    ]
                }
            ],
            "updateWarehouse": [
                1871,
                {
                    "input": [
                        1800,
                        "UpdateWarehouseInput!"
                    ]
                }
            ],
            "updateWarehousesDefaults": [
                1898,
                {
                    "input": [
                        1801,
                        "UpdateWarehousesDefaultsInput!"
                    ]
                }
            ],
            "mergeWarehouses": [
                1137,
                {
                    "input": [
                        1136,
                        "MergeWarehousesInput!"
                    ]
                }
            ],
            "splitWarehouses": [
                7,
                {
                    "input": [
                        1626,
                        "SplitWarehouseInput!"
                    ]
                }
            ],
            "setUserGivenWarehouseName": [
                1871,
                {
                    "input": [
                        1564,
                        "SetUserGivenWarehouseNameInput!"
                    ]
                }
            ],
            "linkInventoryPaymentGroup": [
                998,
                {
                    "input": [
                        997,
                        "LinkInventoryPaymentGroupInput!"
                    ]
                }
            ],
            "updateInventoryPaymentGroup": [
                1756,
                {
                    "input": [
                        1755,
                        "UpdateInventoryPaymentGroupInput!"
                    ]
                }
            ],
            "deleteInventoryPaymentGroup": [
                477,
                {
                    "input": [
                        476,
                        "DeleteInventoryPaymentGroupInput!"
                    ]
                }
            ],
            "cancelInvalidInventoryPaymentGroups": [
                273,
                {
                    "input": [
                        272,
                        "CancelInvalidInventoryPaymentGroupsInput!"
                    ]
                }
            ],
            "updateInventoryYearEndMission": [
                948,
                {
                    "input": [
                        1758,
                        "UpdateInventoryYearEndMissionInput!"
                    ]
                }
            ],
            "lockLoan": [
                1078,
                {
                    "input": [
                        1077,
                        "LockLoanInput!"
                    ]
                }
            ],
            "unlockLoan": [
                1709,
                {
                    "id": [
                        15,
                        "ID!"
                    ]
                }
            ],
            "updateLoan": [
                1099,
                {
                    "input": [
                        1754,
                        "UpdateInternalLoanInput!"
                    ]
                }
            ],
            "updateLoanAttachments": [
                1099,
                {
                    "input": [
                        1762,
                        "UpdateLoanAttachmentsInput!"
                    ]
                }
            ],
            "createLoan": [
                1099,
                {
                    "input": [
                        422,
                        "CreateLoanInput!"
                    ]
                }
            ],
            "createLoanFromTransaction": [
                1029,
                {
                    "input": [
                        1681,
                        "TransactionUpdatedInput!"
                    ]
                }
            ],
            "createLoanFromBalanceTransaction": [
                1029,
                {
                    "input": [
                        420,
                        "CreateLoanFromBalanceTransactionInput!"
                    ]
                }
            ],
            "createLoanFromBillFinanced": [
                7,
                {
                    "input": [
                        421,
                        "CreateLoanFromBillFinancedInput!"
                    ]
                }
            ],
            "acknowledgeLoansChanges": [
                22,
                {
                    "input": [
                        23,
                        "AcknowledgeLoansChangesInput!"
                    ]
                }
            ],
            "simulateLoanFromTransaction2": [
                1611,
                {
                    "input": [
                        1617,
                        "SimulateTransactionUpdatedInput2!"
                    ]
                }
            ],
            "deleteLoans": [
                487,
                {
                    "input": [
                        486,
                        "DeleteLoansInput!"
                    ]
                }
            ],
            "dangerousForceDeleteLoans": [
                487,
                {
                    "input": [
                        486,
                        "DeleteLoansInput!"
                    ]
                }
            ],
            "dangerousTouchLoan": [
                7,
                {
                    "input": [
                        459,
                        "DangerousTouchLoanInput!"
                    ]
                }
            ],
            "deleteLoanRelationships": [
                7,
                {
                    "input": [
                        483,
                        "DeleteLoanRelationshipsInput!"
                    ]
                }
            ],
            "deleteManualLoanEvent": [
                1771,
                {
                    "input": [
                        490,
                        "DeleteManualLoanEventInput!"
                    ]
                }
            ],
            "deleteManualLoanEvents": [
                1772,
                {
                    "input": [
                        491,
                        "DeleteManualLoanEventsInput!"
                    ]
                }
            ],
            "createManualLoanEvent2": [
                1771,
                {
                    "input": [
                        425,
                        "CreateManualLoanEventInput2!"
                    ]
                }
            ],
            "updateManualLoanEvent2": [
                1771,
                {
                    "input": [
                        1770,
                        "UpdateManualLoanEventInput2!"
                    ]
                }
            ],
            "deleteLoanManualAccountingEvents": [
                1772,
                {
                    "input": [
                        482,
                        "DeleteLoanManualAccountingEventsInput!"
                    ]
                }
            ],
            "updateLoanManualAccountingEvent": [
                1771,
                {
                    "input": [
                        1764,
                        "UpdateLoanManualAccountingEventInput!"
                    ]
                }
            ],
            "deleteLoansEventsEffectConfiguration": [
                485,
                {
                    "input": [
                        484,
                        "DeleteLoansEventsEffectConfigurationInput!"
                    ]
                }
            ],
            "createLoansEventsEffectConfiguration": [
                1053,
                {
                    "input": [
                        423,
                        "CreateLoansEventsEffectConfigurationInput!"
                    ]
                }
            ],
            "createLoanEventDefinition": [
                1033,
                {
                    "input": [
                        419,
                        "CreateLoanEventDefinitionInput!"
                    ]
                }
            ],
            "attachRelationsToLoan": [
                7,
                {
                    "input": [
                        103,
                        "AttachRelationsToLoanInput!"
                    ]
                }
            ],
            "refreshLoanByAttachment": [
                7,
                {
                    "input": [
                        1762,
                        "UpdateLoanAttachmentsInput!"
                    ]
                }
            ],
            "deleteCompanyLoanAttachment": [
                1096,
                {
                    "input": [
                        480,
                        "DeleteLoanAttachmentInput!"
                    ]
                }
            ],
            "updateCompanyLoanAttachments": [
                354,
                {
                    "input": [
                        1762,
                        "UpdateLoanAttachmentsInput!"
                    ]
                }
            ],
            "createCompanyLoan": [
                1096,
                {
                    "input": [
                        405,
                        "CreateCompanyLoanInput!"
                    ]
                }
            ],
            "updateCompanyLoan": [
                1096,
                {
                    "input": [
                        1763,
                        "UpdateLoanInput!"
                    ]
                }
            ],
            "deleteLoan": [
                487,
                {
                    "input": [
                        481,
                        "DeleteLoanInput!"
                    ]
                }
            ],
            "userAttachTransactionsToExistingLoan": [
                1100,
                {
                    "input": [
                        1819,
                        "UserAttachTransactionsToExistingLoanInput!"
                    ]
                }
            ],
            "userAttachTransactionsToNewLoan": [
                1100,
                {
                    "input": [
                        1821,
                        "UserAttachTransactionsToNewLoanInput!"
                    ]
                }
            ],
            "lenderFromEntity": [
                1150,
                {
                    "input": [
                        993,
                        "lenderFromEntityInput!"
                    ]
                }
            ],
            "updateOrderEntities": [
                1644,
                {
                    "input": [
                        1778,
                        "UpdateOrderEntitiesInput!"
                    ]
                }
            ],
            "deleteOrderGroupUponTransactionChange": [
                496,
                {
                    "input": [
                        495,
                        "DeleteOrderGroupUponTransactionChangeInput!"
                    ]
                }
            ],
            "initiateTotalArCashDiffsReport": [
                1644,
                {
                    "input": [
                        836,
                        "InitiateTotalArCashDiffsReportInput!"
                    ]
                }
            ],
            "updateCompanyArCashDiffsInBucket": [
                1644,
                {
                    "input": [
                        334,
                        "CompanyArCashDiffsInput!"
                    ]
                }
            ],
            "toggleZeroNetBalance": [
                1644,
                {
                    "input": [
                        1672,
                        "ToggleZeroNetBalanceInput!"
                    ]
                }
            ],
            "setOrderFinaloopNote": [
                1262,
                {
                    "input": [
                        1544,
                        "SetOrderFinaloopNoteInput!"
                    ]
                }
            ],
            "setOrderFinaloopTags": [
                1262,
                {
                    "input": [
                        1545,
                        "SetOrderFinaloopTagsInput!"
                    ]
                }
            ],
            "setFulfillmentSettings": [
                1542,
                {
                    "input": [
                        1541,
                        "SetFulfillmentSettingsInput!"
                    ]
                }
            ],
            "markOrders": [
                7,
                {
                    "input": [
                        1110
                    ]
                }
            ],
            "linkOrderGroup": [
                1005,
                {
                    "input": [
                        1004,
                        "LinkOrderGroupInput!"
                    ]
                }
            ],
            "updateOrderGroup": [
                1780,
                {
                    "input": [
                        1779,
                        "UpdateOrderGroupInput!"
                    ]
                }
            ],
            "deleteOrderGroup": [
                494,
                {
                    "input": [
                        493,
                        "DeleteOrderGroupInput!"
                    ]
                }
            ],
            "voidOrders": [
                1869,
                {
                    "input": [
                        1868,
                        "VoidOrdersInput!"
                    ]
                }
            ],
            "unvoidOrders": [
                1719,
                {
                    "input": [
                        1718,
                        "UnvoidOrdersInput!"
                    ]
                }
            ],
            "markOrdersAsUncollectible": [
                1109,
                {
                    "input": [
                        1108,
                        "MarkOrdersAsUncollectibleInput!"
                    ]
                }
            ],
            "markOrdersAsOvercollected": [
                1107,
                {
                    "input": [
                        1106,
                        "MarkOrdersAsOvercollectedInput!"
                    ]
                }
            ],
            "unMarkOrdersAsUncollectible": [
                1713,
                {
                    "input": [
                        1712,
                        "UnMarkOrdersAsUncollectibleInput!"
                    ]
                }
            ],
            "unMarkOrdersAsOvercollected": [
                1711,
                {
                    "input": [
                        1710,
                        "UnMarkOrdersAsOvercollectedInput!"
                    ]
                }
            ],
            "deletePaymentGroupUponTransactionChange": [
                500,
                {
                    "input": [
                        499,
                        "DeletePaymentGroupUponTransactionChangeInput!"
                    ]
                }
            ],
            "linkOrderPaymentGroup": [
                1007,
                {
                    "input": [
                        1006,
                        "LinkOrderPaymentGroupInput!"
                    ]
                }
            ],
            "updateOrderPaymentGroup": [
                1782,
                {
                    "input": [
                        1781,
                        "UpdateOrderPaymentGroupInput!"
                    ]
                }
            ],
            "deleteOrderPaymentGroup": [
                498,
                {
                    "input": [
                        497,
                        "DeletePaymentGroupInput!"
                    ]
                }
            ],
            "linkInvoicePaymentGroup": [
                1000,
                {
                    "input": [
                        999,
                        "LinkInvoicePaymentGroupInput!"
                    ]
                }
            ],
            "updateInvoicePaymentGroup": [
                1760,
                {
                    "input": [
                        1759,
                        "UpdateInvoicePaymentGroupInput!"
                    ]
                }
            ],
            "deleteInvoicePaymentGroup": [
                498,
                {
                    "input": [
                        497,
                        "DeletePaymentGroupInput!"
                    ]
                }
            ],
            "updateEmployeesOfficerStatus": [
                7,
                {
                    "input": [
                        1747,
                        "UpdateEmployeesOfficerStatusInput!"
                    ]
                }
            ],
            "updatePayrollSettings": [
                7,
                {
                    "input": [
                        1783,
                        "UpdatePayrollSettingsInput!"
                    ]
                }
            ],
            "createPeriodicPartnerReport": [
                1358,
                {
                    "input": [
                        1359,
                        "PeriodicPartnerReportInput!"
                    ]
                }
            ],
            "scheduleAccountingReportExport": [
                1518,
                {
                    "args": [
                        1517,
                        "ScheduleAccountingReportExportInput!"
                    ]
                }
            ],
            "exportGeneralLedger": [
                555,
                {
                    "input": [
                        554,
                        "ExportGeneralLedgerInput!"
                    ]
                }
            ],
            "upsertIntegrationCustomer": [
                450,
                {
                    "input": [
                        1812,
                        "UpsertIntegrationCustomerArgs!"
                    ]
                }
            ],
            "upsertIntegrationInvoice": [
                954,
                {
                    "input": [
                        1813,
                        "UpsertIntegrationInvoiceInput!"
                    ]
                }
            ],
            "sendSalesToReconcilerByTypeAndDate": [
                7,
                {
                    "input": [
                        1494,
                        "SalesByTypeAndDateInput!"
                    ]
                }
            ],
            "forceDeleteInvoices": [
                479,
                {
                    "input": [
                        1511,
                        "SalesInternalInput!"
                    ]
                }
            ],
            "refreshInvoiceCalculatedFields": [
                954,
                {
                    "input": [
                        1479,
                        "SaleInternalInput!"
                    ]
                }
            ],
            "refreshManyInvoicesCalculatedFields": [
                954,
                {
                    "input": [
                        1511,
                        "SalesInternalInput!"
                    ]
                }
            ],
            "createCustomer": [
                443,
                {
                    "createCustomerArgs": [
                        406,
                        "CreateCustomerArgs!"
                    ]
                }
            ],
            "updateCustomer": [
                443,
                {
                    "updateCustomerArgs": [
                        1743,
                        "UpdateCustomerArgs!"
                    ]
                }
            ],
            "upsertCustomerAddress": [
                444,
                {
                    "input": [
                        1807,
                        "UpsertCustomerAddressArgs!"
                    ]
                }
            ],
            "upsertCustomerV2": [
                450,
                {
                    "input": [
                        1808,
                        "UpsertCustomerV2Args!"
                    ]
                }
            ],
            "upsertInvoice": [
                954,
                {
                    "input": [
                        1814,
                        "UpsertInvoiceInput!"
                    ]
                }
            ],
            "voidInvoice": [
                954,
                {
                    "input": [
                        956,
                        "InvoiceInput!"
                    ]
                }
            ],
            "unvoidInvoice": [
                954,
                {
                    "input": [
                        956,
                        "InvoiceInput!"
                    ]
                }
            ],
            "deleteInvoiceAdjustment": [
                954,
                {
                    "input": [
                        955,
                        "InvoiceAdjustmentInput!"
                    ]
                }
            ],
            "markAsUncollectible": [
                954,
                {
                    "input": [
                        1101,
                        "ManualTxInput!"
                    ]
                }
            ],
            "addRefundToInvoice": [
                954,
                {
                    "input": [
                        1101,
                        "ManualTxInput!"
                    ]
                }
            ],
            "deleteInvoiceRefund": [
                954,
                {
                    "input": [
                        966,
                        "InvoiceRefundInput!"
                    ]
                }
            ],
            "upsertPaymentMethod": [
                1314,
                {
                    "input": [
                        1817
                    ]
                }
            ],
            "deletePaymentMethod": [
                7,
                {
                    "input": [
                        1315,
                        "PaymentMethodInput!"
                    ]
                }
            ],
            "generateInvoicePDF": [
                15,
                {
                    "input": [
                        956,
                        "InvoiceInput!"
                    ]
                }
            ],
            "markInvoicePdfAsSent": [
                1,
                {
                    "input": [
                        965,
                        "InvoicePdfSentInput!"
                    ]
                }
            ],
            "sendInvoiceEmail": [
                954,
                {
                    "input": [
                        956,
                        "InvoiceInput!"
                    ]
                }
            ],
            "issueInvoice": [
                954,
                {
                    "input": [
                        956,
                        "InvoiceInput!"
                    ]
                }
            ],
            "deleteInvoice": [
                7,
                {
                    "input": [
                        956,
                        "InvoiceInput!"
                    ]
                }
            ],
            "updateSalesChannel": [
                1497,
                {
                    "input": [
                        1788,
                        "UpdateSalesChannelInput!"
                    ]
                }
            ],
            "upsertInvoiceSettings": [
                970,
                {
                    "input": [
                        1815,
                        "UpsertInvoiceSettingsInput!"
                    ]
                }
            ],
            "updateUserRolesForCompany": [
                1797,
                {
                    "args": [
                        1796,
                        "UpdateUserRolesForCompanyArgs!"
                    ]
                }
            ],
            "inviteUser": [
                953,
                {
                    "args": [
                        952,
                        "InviteUserArgs!"
                    ]
                }
            ],
            "referUser": [
                1455,
                {
                    "args": [
                        1454,
                        "ReferUserArgs!"
                    ]
                }
            ],
            "removeUser": [
                1462,
                {
                    "args": [
                        1461,
                        "RemoveUserArgs!"
                    ]
                }
            ],
            "acceptInvitation": [
                3,
                {
                    "args": [
                        2,
                        "AcceptInvitationArgs!"
                    ]
                }
            ],
            "handleUserLogin": [
                804,
                {
                    "args": [
                        803,
                        "HandleUserLoginArgs!"
                    ]
                }
            ],
            "updateUserDetails": [
                1795,
                {
                    "args": [
                        1794,
                        "UpdateUserDetailsArgs!"
                    ]
                }
            ],
            "setGoogleToken": [
                795,
                {
                    "args": [
                        794,
                        "GoogleTokenCodeArgs!"
                    ]
                }
            ],
            "disconnectGoogleAccount": [
                795
            ],
            "switchCompany": [
                1650,
                {
                    "args": [
                        1649,
                        "SwitchCompanyArgs!"
                    ]
                }
            ],
            "updateSharedGoogleDriveFolder": [
                1645,
                {
                    "args": [
                        1789,
                        "UpdateSharedGoogleDriveFolder!"
                    ]
                }
            ],
            "assignCompanyToUser": [
                100,
                {
                    "args": [
                        99,
                        "AssignCompanyToUser!"
                    ]
                }
            ],
            "impersonate": [
                824,
                {
                    "args": [
                        823,
                        "ImpersonateArgs!"
                    ]
                }
            ],
            "setUserName": [
                1566,
                {
                    "args": [
                        1565,
                        "SetUserName!"
                    ]
                }
            ],
            "updateAuth0UserDetails": [
                7,
                {
                    "args": [
                        1721,
                        "UpdateAuth0UserDetailsArgs!"
                    ]
                }
            ],
            "addCompanyToParkerUser": [
                7,
                {
                    "args": [
                        31,
                        "AddCompanyToParkerUserArgs!"
                    ]
                }
            ],
            "markAuth0CompanyUsersAsFinishedOnboarding": [
                7,
                {
                    "args": [
                        1102,
                        "MarkAuth0CompanyUsersAsFinishedOnboardingArgs!"
                    ]
                }
            ],
            "createVendor": [
                1853,
                {
                    "createVendorArgs": [
                        437,
                        "CreateVendorArgs!"
                    ]
                }
            ],
            "recordTax1099AffiliatedEntityGroupInput": [
                7,
                {
                    "input": [
                        1450,
                        "RecordTax1099AffiliatedEntityGroupInput!"
                    ]
                }
            ],
            "mergeTax1099AffiliatedEntities": [
                7,
                {
                    "input": [
                        1134,
                        "MergeTax1099AffiliatedEntitiesInput!"
                    ]
                }
            ],
            "splitTax1099AffiliatedEntityGroups": [
                7,
                {
                    "input": [
                        1625,
                        "SplitTax1099AffiliatedEntityGroupsInput!"
                    ]
                }
            ],
            "createGlobalVendor": [
                1853,
                {
                    "input": [
                        411,
                        "CreateGlobalVendorInput!"
                    ]
                }
            ],
            "updateCompanyVendorExternalSourceId": [
                1853,
                {
                    "args": [
                        1742,
                        "UpdateCompanyVendorExternalSourceIdVendorInput!"
                    ]
                }
            ],
            "upsertCompanyVendor": [
                1853,
                {
                    "args": [
                        1798,
                        "UpdateVendorInput!"
                    ]
                }
            ],
            "updateGlobalVendorLogo": [
                1853,
                {
                    "input": [
                        1751,
                        "UpdateGlobalVendorLogoInput!"
                    ]
                }
            ],
            "upsertGlobalVendor": [
                1853,
                {
                    "args": [
                        1809,
                        "UpsertGlobalVendorInput!"
                    ]
                }
            ],
            "unsignWorksheetRowV2": [
                376,
                {
                    "args": [
                        1717,
                        "UnsignWorksheetRowInputV2!"
                    ]
                }
            ],
            "signWorksheetRowV2": [
                376,
                {
                    "args": [
                        1607,
                        "SignWorksheetRowInputV2!"
                    ]
                }
            ],
            "storeDateForLockingV2": [
                376,
                {
                    "args": [
                        1634,
                        "StoreDateForLockingInputV2!"
                    ]
                }
            ],
            "updateCommentsV2": [
                376,
                {
                    "args": [
                        1733,
                        "UpdateCommentsInputV2!"
                    ]
                }
            ],
            "signAndLockBankAccount": [
                376,
                {
                    "args": [
                        1605,
                        "SignAndLockBankAccountInput!"
                    ]
                }
            ],
            "updateCompaniesDomainState": [
                1737,
                {
                    "args": [
                        1734,
                        "UpdateCompaniesDomainStateInput!"
                    ]
                }
            ],
            "manageCompanyYearEndState": [
                1085,
                {
                    "args": [
                        1084,
                        "ManageCompanyYearEndStateInput!"
                    ]
                }
            ],
            "updateYearEndState": [
                1962,
                {
                    "args": [
                        1961,
                        "YearEndStateUpdateInput!"
                    ]
                }
            ],
            "updateYearEndDomainState": [
                1951,
                {
                    "args": [
                        1950,
                        "YearEndDomainStateUpdateInput!"
                    ]
                }
            ],
            "updateYearEndDomainStateData": [
                1947,
                {
                    "args": [
                        1946,
                        "YearEndDomainStateDataUpdateInput!"
                    ]
                }
            ],
            "deleteYearEndDomainStateData": [
                1943,
                {
                    "args": [
                        1942,
                        "YearEndDomainStateDataDeleteInput!"
                    ]
                }
            ],
            "updateYearEndUserUnresponsive": [
                1965,
                {
                    "args": [
                        1964,
                        "YearEndUpdateUserUnresponsiveInput!"
                    ]
                }
            ],
            "__typename": [
                1
            ]
        },
        "NaturalSign": {},
        "NewLender": {
            "id": [
                15
            ],
            "company": [
                322
            ],
            "affiliatedEntity": [
                51
            ],
            "name": [
                1
            ],
            "supportedLoanTypes": [
                1061
            ],
            "defaultLoanType": [
                1061
            ],
            "isShortTerm": [
                7
            ],
            "supportedFollowUpTypes": [
                1066
            ],
            "logoUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "NonMatchedSku": {
            "sku": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "quantity": [
                47
            ],
            "zeroPriceQuantity": [
                47
            ],
            "restocks": [
                47
            ],
            "giveaway": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "NonMatchingProductsResponse": {
            "ambiguousSkuCount": [
                47
            ],
            "notFoundProductSkuCount": [
                47
            ],
            "notFoundProductSkus": [
                1154
            ],
            "__typename": [
                1
            ]
        },
        "NonMatchingUnpaidBill": {
            "billId": [
                1
            ],
            "booksAmount": [
                5
            ],
            "inventoryPurchaseAmount": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "NotFoundProductSkus": {
            "shopName": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "source": [
                1
            ],
            "skus": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "NoUnitsBusinessEventsValidationResult": {
            "isValid": [
                7
            ],
            "isYoung": [
                7
            ],
            "costDiff": [
                5
            ],
            "integrationValidations": [
                1157
            ],
            "__typename": [
                1
            ]
        },
        "NoUnitsDailyProductValidationResult": {
            "productValidations": [
                1166
            ],
            "diff": [
                1425
            ],
            "isValid": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "NoUnitsIntegrationValidation": {
            "isValid": [
                7
            ],
            "integrationType": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "totalCogsAmount": [
                5
            ],
            "totalBusinessEventsAmount": [
                5
            ],
            "dailyCogsType": [
                1
            ],
            "businessEventType": [
                1
            ],
            "isYoung": [
                7
            ],
            "businessEventId": [
                1
            ],
            "dailyCogsId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "NoUnitsPhysicalCount": {
            "id": [
                15
            ],
            "amount": [
                5
            ],
            "date": [
                461
            ],
            "companyId": [
                1
            ],
            "attachments": [
                1159
            ],
            "diff": [
                5
            ],
            "lastAckDiff": [
                5
            ],
            "closingBalance": [
                5
            ],
            "lastAckClosingBalance": [
                5
            ],
            "note": [
                1
            ],
            "isDraft": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "NoUnitsPhysicalCountAttachment": {
            "name": [
                1
            ],
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "NoUnitsPhysicalCountClosingBalanceResult": {
            "closingBalance": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "NoUnitsPhysicalCountOverview": {
            "amount": [
                5
            ],
            "amountWithoutPendingAdjustments": [
                5
            ],
            "missingData": [
                5
            ],
            "count": [
                5
            ],
            "pendingChanges": [
                5
            ],
            "productQuantities": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "NoUnitsPhysicalCountProductQuantities": {
            "products": [
                1163
            ],
            "offset": [
                5
            ],
            "count": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "NoUnitsPhysicalCountProductQuantity": {
            "product": [
                1368
            ],
            "quantity": [
                5
            ],
            "appliedCost": [
                5
            ],
            "isPendingChange": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "NoUnitsPhysicalCountProductQuantityPendingAdjustment": {
            "quantity": [
                5
            ],
            "companyId": [
                1
            ],
            "physicalCountId": [
                1
            ],
            "productId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "NoUnitsPhysicalCountsList": {
            "physicalCounts": [
                1158
            ],
            "offset": [
                47
            ],
            "count": [
                47
            ],
            "totalCount": [
                47
            ],
            "minAmount": [
                5
            ],
            "maxAmount": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "NoUnitsProductValidation": {
            "product": [
                1368
            ],
            "sales": [
                599
            ],
            "restocks": [
                599
            ],
            "deadInventory": [
                48
            ],
            "donations": [
                48
            ],
            "giveaways": [
                48
            ],
            "isValid": [
                7
            ],
            "diff": [
                1425
            ],
            "__typename": [
                1
            ]
        },
        "NoUnitsValidationError": {
            "message": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "NoUnitsValidationResponse": {
            "on_NoUnitsValidationResult": [
                1169
            ],
            "on_NoUnitsValidationError": [
                1167
            ],
            "__typename": [
                1
            ]
        },
        "NoUnitsValidationResult": {
            "isValid": [
                7
            ],
            "dailyProductValidationResult": [
                1156
            ],
            "businessEventsValidationResult": [
                1155
            ],
            "startDate": [
                461
            ],
            "endDate": [
                461
            ],
            "error": [
                1
            ],
            "diff": [
                1425
            ],
            "__typename": [
                1
            ]
        },
        "Offer": {
            "tier": [
                1172
            ],
            "payment": [
                233
            ],
            "features": [
                565
            ],
            "title": [
                1
            ],
            "description": [
                1
            ],
            "couponDiscount": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "OffersStatus": {},
        "OfferTier": {},
        "OnboardingScope": {},
        "OnBoardingState": {
            "signupCompleted": [
                7
            ],
            "currentPage": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "OnboardingStateInput": {
            "signupCompleted": [
                7
            ],
            "currentPage": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "OnboardingStatus": {
            "companyId": [
                15
            ],
            "scope": [
                1173
            ],
            "step": [
                1
            ],
            "status": [
                1178
            ],
            "__typename": [
                1
            ]
        },
        "OnboardingStatuses": {
            "hasReportedCOGSEvents": [
                7
            ],
            "hasClassifiedPurchases": [
                7
            ],
            "hasConfirmedOpeningBalance": [
                7
            ],
            "inventoryInitStatus": [
                880
            ],
            "__typename": [
                1
            ]
        },
        "OnboardingStepStatus": {},
        "OnDemandAssemblyAction": {
            "quantity": [
                47
            ],
            "id": [
                1
            ],
            "date": [
                461
            ],
            "type": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "OnDemandAssemblyBreakdown": {
            "origin": [
                1439
            ],
            "analytics": [
                829
            ],
            "__typename": [
                1
            ]
        },
        "OneTimePayment": {
            "id": [
                15
            ],
            "price": [
                5
            ],
            "quantity": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "OpeningBalanceAction": {
            "quantity": [
                47
            ],
            "id": [
                1
            ],
            "date": [
                461
            ],
            "type": [
                1
            ],
            "averageCost": [
                5
            ],
            "isFromUserInput": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "OptionalTimeRangeInput": {
            "fromUTC": [
                461
            ],
            "toUTC": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "OrderAccountingReconciliationAccount": {
            "entity": [
                1185
            ],
            "account": [
                1185
            ],
            "__typename": [
                1
            ]
        },
        "OrderAccountingReconciliationAmount": {
            "amount": [
                5
            ],
            "accountRole": [
                1
            ],
            "accountName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "OrderAccountingReconciliationRow": {
            "accountName": [
                1
            ],
            "accounts": [
                1184
            ],
            "__typename": [
                1
            ]
        },
        "OrderAccountingReconciliationsResponse": {
            "rows": [
                1186
            ],
            "otherBalances": [
                1229
            ],
            "__typename": [
                1
            ]
        },
        "OrderActionType": {},
        "OrderAdjustment": {
            "id": [
                15
            ],
            "amountInHomeCurrency": [
                5
            ],
            "type": [
                1190
            ],
            "postedAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "OrderAdjustmentType": {},
        "OrderAggregationEntry": {
            "type": [
                1192
            ],
            "value": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "OrderAggregationType": {},
        "OrderEntity": {
            "id": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "OrderEntityType": {},
        "OrderFinaloopStatus": {},
        "OrderFulfillment": {
            "id": [
                15
            ],
            "fulfilledAt": [
                461
            ],
            "fulfillmentLineItems": [
                1197
            ],
            "warehouse": [
                1871
            ],
            "__typename": [
                1
            ]
        },
        "OrderFulfillmentLineItem": {
            "id": [
                15
            ],
            "lineItemId": [
                15
            ],
            "quantity": [
                47
            ],
            "sku": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "OrderFulfillmentSource": {},
        "OrderFulfillmentStatus": {},
        "OrderGroup": {
            "id": [
                15
            ],
            "companyId": [
                1
            ],
            "amountInHomeCurrency": [
                5
            ],
            "orders": [
                996
            ],
            "bankTransactions": [
                995
            ],
            "expenses": [
                1204
            ],
            "orderDistributions": [
                1208
            ],
            "postedAt": [
                461
            ],
            "createdAt": [
                461
            ],
            "updatedAt": [
                461
            ],
            "deletedAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupByIdInput": {
            "orderGroupId": [
                15
            ],
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupByIdResponse": {
            "orderGroup": [
                1200
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupDistributionBasic": {
            "orderGroupId": [
                15
            ],
            "postedAt": [
                461
            ],
            "amountInHomeCurrency": [
                5
            ],
            "orderGroupHasCashPayments": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupExpense": {
            "id": [
                15
            ],
            "expenseType": [
                1206
            ],
            "amount": [
                79
            ],
            "vendor": [
                1853
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupExpenseInput": {
            "expenseType": [
                1206
            ],
            "amount": [
                5
            ],
            "vendorId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupExpenseType": {},
        "OrderGroupLink": {
            "orderGroupId": [
                15
            ],
            "amountInHomeCurrency": [
                5
            ],
            "postedAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupOrderDistribution": {
            "order": [
                1260
            ],
            "bankTransaction": [
                186
            ],
            "expense": [
                1204
            ],
            "postedAt": [
                461
            ],
            "amountInHomeCurrency": [
                5
            ],
            "orderGroupHasCashPayments": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupsByIdsInput": {
            "companyId": [
                15
            ],
            "orderGroupIds": [
                15
            ],
            "withDeleted": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupsByIdsResponse": {
            "orderGroups": [
                1200
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupsFilter": {
            "postedAt": [
                463
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupsInput": {
            "companyId": [
                1
            ],
            "filter": [
                1211
            ],
            "sort": [
                1214
            ],
            "limit": [
                47
            ],
            "offset": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupsResponse": {
            "orderGroups": [
                1200
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupsSort": {
            "type": [
                1216
            ],
            "direction": [
                1215
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupsSortDirection": {},
        "OrderGroupsSortType": {},
        "OrderGroupsTotalUnclearedBalanceInput": {
            "companyId": [
                15
            ],
            "asOfDate": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupsTotalUnclearedBalanceResponse": {
            "asOfDate": [
                461
            ],
            "totalUnclearedBalance": [
                79
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupsUnclearedBalanceInput": {
            "companyId": [
                15
            ],
            "orderGroupIds": [
                15
            ],
            "asOfDate": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupsUnclearedBalanceResponse": {
            "asOfDate": [
                461
            ],
            "unclearedBalances": [
                1221
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupUnclearedBalance": {
            "orderGroup": [
                1200
            ],
            "unclearedBalance": [
                79
            ],
            "__typename": [
                1
            ]
        },
        "OrderIdInput": {
            "companyId": [
                15
            ],
            "orderId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "OrderInternalInput": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "OrderItemsFulfillmentV2": {
            "id": [
                15
            ],
            "trackingInfoRecords": [
                988
            ],
            "externalWarehouseId": [
                1
            ],
            "voidedAt": [
                461
            ],
            "fulfilledAt": [
                461
            ],
            "fulfilledQuantity": [
                47
            ],
            "voidedBy": [
                29
            ],
            "source": [
                1198
            ],
            "createdAt": [
                461
            ],
            "updatedAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "OrderItemStatus": {},
        "OrderItemStatusWithOverride": {
            "status": [
                1225
            ],
            "isOverridden": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "OrderItemType": {},
        "OrderItemV2": {
            "id": [
                15
            ],
            "companyId": [
                1
            ],
            "actionType": [
                1188
            ],
            "type": [
                1227
            ],
            "amount": [
                79
            ],
            "taxAmount": [
                5
            ],
            "taxAmountInHomeCurrency": [
                5
            ],
            "discountAmount": [
                5
            ],
            "discountAmountInHomeCurrency": [
                5
            ],
            "exchangeRateOverride": [
                5
            ],
            "taxExchangeRateOverride": [
                5
            ],
            "discountExchangeRateOverride": [
                5
            ],
            "taxes": [
                988
            ],
            "externalSourceId": [
                1
            ],
            "postedAt": [
                461
            ],
            "externalProductId": [
                1
            ],
            "product": [
                1368
            ],
            "sku": [
                1
            ],
            "quantity": [
                47
            ],
            "unitPrice": [
                5
            ],
            "isRestocked": [
                7
            ],
            "fulfillments": [
                1224
            ],
            "originalFulfillmentId": [
                1
            ],
            "voided": [
                988
            ],
            "isFulfillmentOverridden": [
                7
            ],
            "createdAt": [
                461
            ],
            "updatedAt": [
                461
            ],
            "status": [
                1226
            ],
            "lineItemId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "OrderOtherAccountingReconciliationRow": {
            "accountName": [
                1
            ],
            "accounts": [
                1185
            ],
            "__typename": [
                1
            ]
        },
        "OrderPaymentGroup": {
            "id": [
                15
            ],
            "companyId": [
                1
            ],
            "postedAt": [
                461
            ],
            "createdAt": [
                461
            ],
            "updatedAt": [
                461
            ],
            "deletedAt": [
                461
            ],
            "orders": [
                1292
            ],
            "bankTransactions": [
                1289
            ],
            "expenses": [
                1283
            ],
            "__typename": [
                1
            ]
        },
        "OrderPaymentGroupByIdResponse": {
            "paymentGroup": [
                1230
            ],
            "__typename": [
                1
            ]
        },
        "OrderPaymentGroupsByIdsResponse": {
            "paymentGroups": [
                1230
            ],
            "__typename": [
                1
            ]
        },
        "OrderPaymentGroupsResponse": {
            "paymentGroups": [
                1230
            ],
            "__typename": [
                1
            ]
        },
        "OrderPaymentGroupsUnclearedBalanceResponse": {
            "asOfDate": [
                461
            ],
            "unclearedBalances": [
                1235
            ],
            "__typename": [
                1
            ]
        },
        "OrderPaymentGroupUnclearedBalance": {
            "paymentGroup": [
                1230
            ],
            "unclearedBalance": [
                79
            ],
            "__typename": [
                1
            ]
        },
        "OrderPaymentStatus": {},
        "OrderReconciliationRow": {
            "title": [
                1
            ],
            "saleAmount": [
                5
            ],
            "taxAmount": [
                5
            ],
            "discountAmount": [
                5
            ],
            "shippingAmount": [
                5
            ],
            "refundAmount": [
                5
            ],
            "additionalTaxesAmount": [
                5
            ],
            "totalAmount": [
                5
            ],
            "netAmount": [
                5
            ],
            "subRows": [
                1237
            ],
            "__typename": [
                1
            ]
        },
        "OrderReconciliationRowType": {},
        "OrderReconciliationsInput": {
            "startTime": [
                461
            ],
            "endTime": [
                461
            ],
            "integrationAccountId": [
                15
            ],
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "OrderReconciliationsResponse": {
            "booksRows": [
                1237
            ],
            "excludedRows": [
                1237
            ],
            "adjustedRows": [
                1237
            ],
            "shopifyRow": [
                1237
            ],
            "dateRange": [
                462
            ],
            "currency": [
                1
            ],
            "failureReason": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "OrderRestock": {
            "id": [
                15
            ],
            "postedAt": [
                461
            ],
            "restockLineItems": [
                1242
            ],
            "__typename": [
                1
            ]
        },
        "OrderRestockLineItem": {
            "id": [
                15
            ],
            "quantity": [
                47
            ],
            "lineItemId": [
                15
            ],
            "sku": [
                1
            ],
            "warehouse": [
                1871
            ],
            "__typename": [
                1
            ]
        },
        "OrdersByIdsInput": {
            "companyId": [
                15
            ],
            "orderIds": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "OrdersByIdsResponse": {
            "orders": [
                1260
            ],
            "__typename": [
                1
            ]
        },
        "OrderSettings": {
            "void": [
                1867
            ],
            "__typename": [
                1
            ]
        },
        "OrderSettingsInput": {
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "OrderSettingsResponse": {
            "settings": [
                1245
            ],
            "__typename": [
                1
            ]
        },
        "OrdersSettings": {
            "isZeroNetOrdersSupported": [
                7
            ],
            "shouldRecognizeZeroNetCogsAsGiveaways": [
                7
            ],
            "shopifyStoreV2StartDate": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "OrderStatus": {},
        "OrderStatusWithOverride": {
            "status": [
                1249
            ],
            "isOverridden": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "OrdersYearEndBalances": {
            "unpaidAmount": [
                5
            ],
            "cashDepositAmount": [
                5
            ],
            "netUnpaidBalance": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "OrdersYearEndInput": {
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "OrdersYearEndResponse": {
            "balances": [
                1251
            ],
            "waitingPermissionsIntegrations": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "OrderTag": {
            "id": [
                15
            ],
            "name": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "OrderToLinkInput": {
            "orderId": [
                15
            ],
            "amountInHomeCurrency": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "OrderTotalSummary": {
            "subtotal": [
                79
            ],
            "shippingIncome": [
                79
            ],
            "discounts": [
                79
            ],
            "netSale": [
                79
            ],
            "taxes": [
                79
            ],
            "additionalTaxes": [
                79
            ],
            "refunds": [
                79
            ],
            "fxAdjustment": [
                79
            ],
            "netOrder": [
                79
            ],
            "paid": [
                5
            ],
            "refunded": [
                5
            ],
            "netPaid": [
                5
            ],
            "uncollectible": [
                5
            ],
            "overCollected": [
                5
            ],
            "orderBalance": [
                5
            ],
            "balanceAsOf": [
                461
            ],
            "balanceAsOfAmount": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "OrderTransactionMatchV2": {
            "id": [
                15
            ],
            "amount": [
                79
            ],
            "payment": [
                1637
            ],
            "__typename": [
                1
            ]
        },
        "OrderTransactionType": {},
        "OrderTransactionV2": {
            "id": [
                15
            ],
            "amount": [
                79
            ],
            "exchangeRateOverride": [
                5
            ],
            "type": [
                1258
            ],
            "postedAt": [
                461
            ],
            "matches": [
                1257
            ],
            "finaloopPaymentGateway": [
                1
            ],
            "originalPaymentGateway": [
                1
            ],
            "paymentGatewayVariant": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "createdAt": [
                461
            ],
            "updatedAt": [
                461
            ],
            "isManual": [
                7
            ],
            "voided": [
                988
            ],
            "authorizationCode": [
                1
            ],
            "isArtificiallyMatched": [
                7
            ],
            "matchedAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "OrderV2": {
            "id": [
                15
            ],
            "companyId": [
                1
            ],
            "items": [
                1228
            ],
            "transactions": [
                1259
            ],
            "orderGroupLinks": [
                1207
            ],
            "orderGroupDistributions": [
                1203
            ],
            "orderAdjustments": [
                1189
            ],
            "totalSummary": [
                1256
            ],
            "displayName": [
                1
            ],
            "currency": [
                1
            ],
            "source": [
                1
            ],
            "placedAt": [
                461
            ],
            "firstPaymentDate": [
                461
            ],
            "fullyPaidDate": [
                461
            ],
            "firstRefundDate": [
                461
            ],
            "firstFulfillmentDate": [
                461
            ],
            "latestFinancialDate": [
                461
            ],
            "salesChannel": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "customer": [
                450
            ],
            "isZeroNetOrder": [
                7
            ],
            "integrationTags": [
                1
            ],
            "finaloopTags": [
                1254
            ],
            "finaloopNote": [
                1
            ],
            "integrationNote": [
                1
            ],
            "isChannelProductSalesAffecting": [
                7
            ],
            "isChannelAccountsAffecting": [
                7
            ],
            "isProductSalesAffecting": [
                7
            ],
            "isAccountsAffecting": [
                7
            ],
            "paymentStatus": [
                1236
            ],
            "fulfillmentStatus": [
                1199
            ],
            "balanceInHomeCurrency": [
                5
            ],
            "voidedAt": [
                461
            ],
            "shippingAddress": [
                444
            ],
            "billingAddress": [
                444
            ],
            "store": [
                1633
            ],
            "status": [
                1250
            ],
            "integrationExternalUrl": [
                1
            ],
            "fulfillments": [
                1196
            ],
            "restocks": [
                1241
            ],
            "paymentGroupLinks": [
                1288
            ],
            "__typename": [
                1
            ]
        },
        "OrderV2NotFound": {
            "message": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "OrderV2Result": {
            "on_OrderV2": [
                1260
            ],
            "on_OrderV2NotFound": [
                1261
            ],
            "__typename": [
                1
            ]
        },
        "OverviewCycle": {
            "fromDate": [
                461
            ],
            "toDate": [
                461
            ],
            "openingBalance": [
                5
            ],
            "purchasesSum": [
                5
            ],
            "cogsSum": [
                5
            ],
            "cogsAdjustmentsSum": [
                5
            ],
            "closingBalance": [
                5
            ],
            "warehouseTransfersSum": [
                5
            ],
            "physicalCountAdjustment": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "OverviewCyclesInput": {
            "companyId": [
                15
            ],
            "fromDate": [
                461
            ],
            "toDate": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "PageInput": {
            "offset": [
                47
            ],
            "limit": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "PaidStatus": {},
        "PairedEntity": {
            "on_Bill": [
                227
            ],
            "on_Payout": [
                1322
            ],
            "on_Topup": [
                1673
            ],
            "on_BankTransaction": [
                186
            ],
            "on_CompanyAffiliate": [
                326
            ],
            "on_IntegrationTransfer": [
                861
            ],
            "__typename": [
                1
            ]
        },
        "PairedEntityType": {},
        "PairingType": {},
        "Payment": {
            "companyId": [
                15
            ],
            "id": [
                15
            ],
            "source": [
                876
            ],
            "integrationAccountId": [
                15
            ],
            "integrationAccountName": [
                1
            ],
            "amount": [
                5
            ],
            "currency": [
                1
            ],
            "postedAt": [
                461
            ],
            "externalAuthorizationCode": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "PaymentCheck": {
            "id": [
                15
            ],
            "company": [
                322
            ],
            "source": [
                1
            ],
            "postedAt": [
                461
            ],
            "amount": [
                5
            ],
            "currency": [
                1
            ],
            "amountInHomeCurrency": [
                5
            ],
            "contractor": [
                386
            ],
            "employee": [
                534
            ],
            "matchedBankTransaction": [
                186
            ],
            "isImmaterial": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "PaymentChecksInput": {
            "companyId": [
                15
            ],
            "amount": [
                5
            ],
            "currency": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "PaymentDetails": {
            "id": [
                15
            ],
            "price": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "PaymentFee": {
            "companyId": [
                15
            ],
            "id": [
                15
            ],
            "source": [
                876
            ],
            "integrationAccountId": [
                15
            ],
            "integrationAccountName": [
                1
            ],
            "amount": [
                5
            ],
            "currency": [
                1
            ],
            "type": [
                1276
            ],
            "basis": [
                1275
            ],
            "postedAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "PaymentFeeBasis": {},
        "PaymentFeeType": {},
        "PaymentGateway": {},
        "PaymentGatewaysByCompanyIntegrationAndSalesChannelInput": {
            "companyId": [
                15
            ],
            "integrationAccountId": [
                15
            ],
            "salesChannel": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGatewayType": {},
        "PaymentGroup": {
            "id": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupBankTransactionToLinkInput": {
            "transactionId": [
                15
            ],
            "amount": [
                5
            ],
            "amountInHomeCurrency": [
                5
            ],
            "currency": [
                440
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupByIdInput": {
            "paymentGroupId": [
                15
            ],
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupExpense": {
            "id": [
                15
            ],
            "expenseType": [
                1285
            ],
            "amount": [
                79
            ],
            "vendor": [
                1853
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupExpenseInput": {
            "expenseType": [
                1285
            ],
            "amount": [
                5
            ],
            "vendorId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupExpenseType": {},
        "PaymentGroupInventoryPurchasesToLinkInput": {
            "id": [
                15
            ],
            "inventoryPurchaseId": [
                15
            ],
            "amount": [
                5
            ],
            "amountInHomeCurrency": [
                5
            ],
            "currency": [
                440
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupInvoiceToLinkInput": {
            "invoiceId": [
                15
            ],
            "amount": [
                5
            ],
            "amountInHomeCurrency": [
                5
            ],
            "currency": [
                440
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupLink": {
            "paymentGroupId": [
                15
            ],
            "amount": [
                5
            ],
            "amountInHomeCurrency": [
                5
            ],
            "currency": [
                440
            ],
            "postedAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupLinkedBankTransaction": {
            "bankTransaction": [
                186
            ],
            "bankTransactionPostedAt": [
                461
            ],
            "amount": [
                79
            ],
            "id": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupLinkedInventoryPurchases": {
            "inventoryPurchase": [
                894
            ],
            "inventoryPurchasePostedAt": [
                461
            ],
            "amount": [
                79
            ],
            "id": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupLinkedInvoice": {
            "invoice": [
                954
            ],
            "amountInHomeCurrency": [
                5
            ],
            "currency": [
                440
            ],
            "amount": [
                5
            ],
            "id": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupLinkedOrder": {
            "order": [
                1260
            ],
            "amountInHomeCurrency": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupLinkedPurchaseOrder": {
            "id": [
                15
            ],
            "purchaseOrder": [
                1416
            ],
            "amountInHomeCurrency": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupLinkedWarehouseTransfer": {
            "id": [
                15
            ],
            "warehouseTransfer": [
                1904
            ],
            "amountInHomeCurrency": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupLinksForInvoiceInput": {
            "companyId": [
                1
            ],
            "invoiceId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupLinksForInvoiceResponse": {
            "paymentGroupLinks": [
                1288
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupLinksInput": {
            "companyId": [
                1
            ],
            "saleId": [
                1
            ],
            "paymentGroupType": [
                1312
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupLinksResponse": {
            "paymentGroupLinks": [
                1288
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupOrderToLinkInput": {
            "orderId": [
                15
            ],
            "amountInHomeCurrency": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupPurchaseOrderToLinkInput": {
            "id": [
                15
            ],
            "purchaseOrderId": [
                15
            ],
            "amountInHomeCurrency": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupsByIdsInput": {
            "companyId": [
                15
            ],
            "paymentGroupIds": [
                15
            ],
            "withDeleted": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupsByIdsResponse": {
            "paymentGroups": [
                979
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupsFilter": {
            "postedAt": [
                463
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupsInput": {
            "companyId": [
                1
            ],
            "filter": [
                1303
            ],
            "sort": [
                1305
            ],
            "limit": [
                47
            ],
            "offset": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupsSort": {
            "type": [
                1307
            ],
            "direction": [
                1306
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupsSortDirection": {},
        "PaymentGroupsSortType": {},
        "PaymentGroupsTotalUnclearedBalanceInput": {
            "companyId": [
                15
            ],
            "asOfDate": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupsTotalUnclearedBalanceResponse": {
            "asOfDate": [
                461
            ],
            "totalUnclearedBalance": [
                79
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupsUnclearedBalanceInput": {
            "companyId": [
                15
            ],
            "paymentGroupIds": [
                15
            ],
            "asOfDate": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupTransaction": {
            "id": [
                15
            ],
            "integrationInfo": [
                856
            ],
            "amount": [
                5
            ],
            "postedAt": [
                461
            ],
            "currency": [
                440
            ],
            "amountInHomeCurrency": [
                5
            ],
            "type": [
                1680
            ],
            "finaloopPaymentGateway": [
                1
            ],
            "originalPaymentGateway": [
                1
            ],
            "paymentGatewayVariant": [
                1
            ],
            "isIndication": [
                7
            ],
            "paymentGroup": [
                1280
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupType": {},
        "PaymentGroupWarehouseTransferToLinkInput": {
            "id": [
                15
            ],
            "warehouseTransferId": [
                15
            ],
            "amountInHomeCurrency": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "PaymentMethod": {
            "id": [
                15
            ],
            "type": [
                1316
            ],
            "isDefault": [
                7
            ],
            "instructions": [
                1
            ],
            "bankName": [
                1
            ],
            "accountNumber": [
                1
            ],
            "routingNumber": [
                1
            ],
            "swift": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "PaymentMethodInput": {
            "companyId": [
                15
            ],
            "id": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "PaymentMethodType": {},
        "PaymentOptionInput": {
            "stripeId": [
                1
            ],
            "price": [
                5
            ],
            "recurrence": [
                1320
            ],
            "__typename": [
                1
            ]
        },
        "PaymentProcessorByCompanyAndVendorInput": {
            "companyId": [
                15
            ],
            "vendorId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "PaymentProcessorByCompanyAndVendorResponse": {
            "processor": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "PaymentRecurrence": {},
        "PaymentsByEntityInput": {
            "companyId": [
                15
            ],
            "startDate": [
                461
            ],
            "endDate": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "Payout": {
            "id": [
                15
            ],
            "companyId": [
                1
            ],
            "amount": [
                5
            ],
            "amountInUsd": [
                5
            ],
            "currency": [
                1
            ],
            "eventTime": [
                461
            ],
            "deletedAt": [
                461
            ],
            "markedAsReconciliationDiscrepancyAt": [
                461
            ],
            "integrationType": [
                876
            ],
            "pairedBankTransaction": [
                186
            ],
            "type": [
                873
            ],
            "classification": [
                862
            ],
            "integrationAccountId": [
                15
            ],
            "integrationAccountName": [
                1
            ],
            "pairedCompanyAffiliate": [
                326
            ],
            "link": [
                1
            ],
            "uploadedFileId": [
                1
            ],
            "failedAt": [
                461
            ],
            "markedAsUnidentifiedAt": [
                461
            ],
            "rawEvent": [
                988
            ],
            "__typename": [
                1
            ]
        },
        "Payroll": {
            "id": [
                15
            ],
            "company": [
                322
            ],
            "source": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "currency": [
                1
            ],
            "postedAt": [
                461
            ],
            "deletedAt": [
                461
            ],
            "createdAt": [
                461
            ],
            "updatedAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "PayrollCategory": {},
        "PayrollCategoryMapping": {
            "on_PayrollDepartmentCategoryMapping": [
                1336
            ],
            "on_PayrollEmployeeCategoryMapping": [
                1337
            ],
            "on_PayrollContractorCategoryMapping": [
                1332
            ],
            "__typename": [
                1
            ]
        },
        "PayrollCategoryMappingInput": {
            "id": [
                15
            ],
            "type": [
                1343
            ],
            "category": [
                1324
            ],
            "__typename": [
                1
            ]
        },
        "PayrollCategoryMappingMode": {},
        "PayrollConnection": {
            "integrationAccountId": [
                15
            ],
            "integrationType": [
                876
            ],
            "integrationAccountName": [
                1
            ],
            "importStatus": [
                1329
            ],
            "disconnectedAt": [
                461
            ],
            "disconnectedBy": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "PayrollConnectionImportStatus": {
            "contractorPayments": [
                1330
            ],
            "contractors": [
                1330
            ],
            "departments": [
                1330
            ],
            "employees": [
                1330
            ],
            "payrolls": [
                1330
            ],
            "__typename": [
                1
            ]
        },
        "PayrollConnectionImportStatusData": {
            "lastUpdateTime": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "PayrollConnectionsInput": {
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "PayrollContractorCategoryMapping": {
            "contractor": [
                386
            ],
            "category": [
                1324
            ],
            "__typename": [
                1
            ]
        },
        "PayrollDailyAggregate": {
            "id": [
                15
            ],
            "currency": [
                1
            ],
            "postedAt": [
                461
            ],
            "source": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "startDate": [
                461
            ],
            "endDate": [
                461
            ],
            "unallocatedHealthBenefitsContribution": [
                5
            ],
            "unallocatedCompanyTaxes": [
                5
            ],
            "unallocatedDonationDeductions": [
                5
            ],
            "pension401k": [
                5
            ],
            "deletedAt": [
                461
            ],
            "paymentChecks": [
                454
            ],
            "reimbursements": [
                456
            ],
            "ownersDraw": [
                453
            ],
            "taxDebitAmount": [
                5
            ],
            "netPayDebitAmount": [
                5
            ],
            "dailyAggregates": [
                455
            ],
            "unpaidAmountsViaProvider": [
                1715
            ],
            "__typename": [
                1
            ]
        },
        "PayrollDailyDataInput": {
            "companyId": [
                15
            ],
            "payrollIds": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "PayrollData": {
            "knownVendors": [
                1
            ],
            "unknownVendors": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "PayrollDepartmentCategoryMapping": {
            "department": [
                509
            ],
            "category": [
                1324
            ],
            "__typename": [
                1
            ]
        },
        "PayrollEmployeeCategoryMapping": {
            "employee": [
                534
            ],
            "category": [
                1324
            ],
            "__typename": [
                1
            ]
        },
        "PayrollEmploymentType": {},
        "PayrollIdsByCompanyInput": {
            "companyIds": [
                15
            ],
            "afterBooksStartTimeOnly": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "PayrollIdsByCompanyItem": {
            "companyId": [
                1
            ],
            "payrollIds": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "PayrollIdsByCompanyResponse": {
            "items": [
                1340
            ],
            "__typename": [
                1
            ]
        },
        "PayrollItemToReconcile": {
            "companyId": [
                15
            ],
            "payrollId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "PayrollMappableType": {},
        "PayrollSettings": {
            "withCategoryMapping": [
                7
            ],
            "categoryMappingMode": [
                1327
            ],
            "categoryMappings": [
                1325
            ],
            "__typename": [
                1
            ]
        },
        "PayrollSettingsInput": {
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "PendingAdjustmentsExcludeListItem": {
            "productId": [
                1
            ],
            "date": [
                461
            ],
            "warehouseId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "PendingProductAdjustmentsDataType": {},
        "PendingSalesDataAdjustments": {
            "id": [
                1
            ],
            "salesChannel": [
                1495
            ],
            "date": [
                461
            ],
            "unitsSold": [
                47
            ],
            "unitsRestocked": [
                47
            ],
            "warehouseId": [
                1
            ],
            "warehouse": [
                1871
            ],
            "productId": [
                1
            ],
            "companyId": [
                1
            ],
            "currentState": [
                1485
            ],
            "__typename": [
                1
            ]
        },
        "PendingSalesDataAdjustmentsItem": {
            "productId": [
                1
            ],
            "product": [
                1368
            ],
            "pendingSalesDataAdjustments": [
                1348
            ],
            "__typename": [
                1
            ]
        },
        "PendingSalesDataAdjustmentsOverviewResponse": {
            "count": [
                47
            ],
            "pendingCount": [
                47
            ],
            "missingDataCount": [
                47
            ],
            "lastPendingChange": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "PendingSalesDataAdjustmentsResponse": {
            "items": [
                1349
            ],
            "excludeList": [
                1346
            ],
            "__typename": [
                1
            ]
        },
        "PendingWarehouseOpeningBalance": {
            "id": [
                15
            ],
            "warehouse": [
                1871
            ],
            "quantity": [
                47
            ],
            "costPerUnit": [
                5
            ],
            "date": [
                461
            ],
            "attachmentId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "PendingWarehouseOpeningBalanceAttachment": {
            "id": [
                15
            ],
            "companyId": [
                1
            ],
            "date": [
                461
            ],
            "filename": [
                1
            ],
            "url": [
                1
            ],
            "createdAt": [
                461
            ],
            "deletedAt": [
                461
            ],
            "pendingChangesCount": [
                47
            ],
            "summaryData": [
                1354
            ],
            "__typename": [
                1
            ]
        },
        "PendingWarehouseOpeningBalancesAttachmentSummary": {
            "totalCount": [
                47
            ],
            "validCount": [
                47
            ],
            "invalidCount": [
                47
            ],
            "errorCount": [
                47
            ],
            "errorItems": [
                1886
            ],
            "__typename": [
                1
            ]
        },
        "PendingWarehouseOpeningBalancesResponse": {
            "items": [
                1888
            ],
            "__typename": [
                1
            ]
        },
        "PendingWarehouseOpeningBalancesWithCsvResponse": {
            "attachmentId": [
                15
            ],
            "summaryData": [
                1354
            ],
            "__typename": [
                1
            ]
        },
        "PeriodDatesInput": {
            "periodStart": [
                461
            ],
            "periodEnd": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "PeriodicPartnerReport": {
            "company": [
                322
            ],
            "partner": [
                1468
            ],
            "createdAt": [
                461
            ],
            "latestActorId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "PeriodicPartnerReportInput": {
            "companyId": [
                15
            ],
            "partnerId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "Permission": {
            "id": [
                15
            ],
            "name": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "PerVendorBreakdownInput": {
            "affiliatedEntityId": [
                1
            ],
            "newTotal": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "PollCheckoutSessionStatusInput": {
            "checkoutSessionId": [
                1
            ],
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "PrevalidateCompanyLoanInput": {
            "companyId": [
                15
            ],
            "data": [
                1062
            ],
            "attachments": [
                1761
            ],
            "__typename": [
                1
            ]
        },
        "ProcessBillContentInput": {
            "companyId": [
                15
            ],
            "filePathInBucket": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ProcessBillContentResult": {
            "isSuccessful": [
                7
            ],
            "id": [
                15
            ],
            "vendor": [
                1853
            ],
            "categories": [
                276
            ],
            "date": [
                461
            ],
            "dueDate": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "ProcessCheckoutWebhookEventsInput": {
            "webhookEvent": [
                988
            ],
            "__typename": [
                1
            ]
        },
        "ProcessLoanAttachmentResult": {
            "success": [
                7
            ],
            "errors": [
                1019
            ],
            "__typename": [
                1
            ]
        },
        "Product": {
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "finaloopId": [
                1
            ],
            "displayName": [
                1
            ],
            "imageUrl": [
                1
            ],
            "sku": [
                1
            ],
            "lineOfBusinessId": [
                1
            ],
            "listedChannels": [
                1495
            ],
            "appliedCosts": [
                1370
            ],
            "dailySalesData": [
                1496,
                {
                    "timeRange": [
                        1386,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "totalSold": [
                47
            ],
            "avgCostPerUnit": [
                5
            ],
            "price": [
                5
            ],
            "canEditLatestAppliedCost": [
                7
            ],
            "isFractional": [
                7
            ],
            "isHidden": [
                7
            ],
            "salesData": [
                1510,
                {
                    "timeRange": [
                        1386,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "rawRecipes": [
                1431,
                {
                    "timeRange": [
                        1386,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "componentOfProducts": [
                1368
            ],
            "analyticsOverview": [
                1369,
                {
                    "input": [
                        80
                    ]
                }
            ],
            "warehousesAnalyticsOverview": [
                1408,
                {
                    "input": [
                        80
                    ]
                }
            ],
            "tags": [
                1
            ],
            "isDataSyncing": [
                7
            ],
            "on_AmazonProduct": [
                63
            ],
            "on_MergedProduct": [
                1126
            ],
            "on_ShopifyProduct": [
                1595
            ],
            "on_TikTokProduct": [
                1667
            ],
            "on_UserManualProduct": [
                1834
            ],
            "on_WalmartProduct": [
                1870
            ],
            "__typename": [
                1
            ]
        },
        "ProductAnalyticsOverview": {
            "inTransit": [
                5
            ],
            "incoming": [
                5
            ],
            "netDepleted": [
                5
            ],
            "onHand": [
                5
            ],
            "costPerUnit": [
                5
            ],
            "onHandCost": [
                5
            ],
            "unavailable": [
                5
            ],
            "openingBalanceCost": [
                5
            ],
            "openingBalanceQuantity": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ProductAppliedCost": {
            "asOfDate": [
                461
            ],
            "appliedCost": [
                5
            ],
            "source": [
                1371
            ],
            "isLocked": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "ProductAppliedCostSource": {},
        "ProductAppliedCostWithDepletedCount": {
            "asOfDate": [
                461
            ],
            "appliedCost": [
                5
            ],
            "source": [
                1371
            ],
            "depletedCount": [
                47
            ],
            "isLocked": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "ProductAssembliesBreakdownInput": {
            "companyId": [
                15
            ],
            "productId": [
                1
            ],
            "date": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "ProductAssembliesBreakdownResult": {
            "manualAssemblies": [
                1093
            ],
            "onDemandAssemblies": [
                1180
            ],
            "__typename": [
                1
            ]
        },
        "ProductAttributesTypes": {},
        "ProductBulkAdjustmentStatusState": {},
        "ProductBulkAdjustmentStatusType": {},
        "ProductBulkEditCsvAttachment": {
            "id": [
                15
            ],
            "companyId": [
                1
            ],
            "filename": [
                1
            ],
            "url": [
                1
            ],
            "createdAt": [
                461
            ],
            "deletedAt": [
                461
            ],
            "summaryData": [
                1384
            ],
            "__typename": [
                1
            ]
        },
        "ProductBulkEditCsvAttachmentsInput": {
            "companyId": [
                15
            ],
            "type": [
                1381
            ],
            "displayDeleted": [
                7
            ],
            "managementMethodId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "ProductBulkEditCsvAttachmentsResponse": {
            "attachments": [
                1378
            ],
            "__typename": [
                1
            ]
        },
        "ProductBulkEditCsvAttachmentType": {},
        "ProductBulkEditCsvErrorItem": {
            "rowNumber": [
                47
            ],
            "cellNumber": [
                47
            ],
            "errorType": [
                1383
            ],
            "__typename": [
                1
            ]
        },
        "ProductBulkEditCsvErrorItemType": {},
        "ProductBulkEditCsvSummary": {
            "totalCount": [
                47
            ],
            "validCount": [
                47
            ],
            "invalidCount": [
                47
            ],
            "errorCount": [
                47
            ],
            "errorItems": [
                1382
            ],
            "__typename": [
                1
            ]
        },
        "ProductBulkEditCsvUploadResponse": {
            "attachmentId": [
                15
            ],
            "summaryData": [
                1384
            ],
            "__typename": [
                1
            ]
        },
        "ProductDataTimeRangeInput": {
            "fromDate": [
                461
            ],
            "toDate": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "ProductDeadInventoryAdjustment": {
            "id": [
                1
            ],
            "finaloopProductId": [
                1
            ],
            "date": [
                461
            ],
            "unitsDeadInventory": [
                5
            ],
            "warehouse": [
                1871
            ],
            "__typename": [
                1
            ]
        },
        "ProductDeadInventoryAdjustments": {
            "adjustments": [
                1387
            ],
            "__typename": [
                1
            ]
        },
        "ProductDonationAdjustment": {
            "finaloopProductId": [
                1
            ],
            "date": [
                461
            ],
            "unitsDonated": [
                47
            ],
            "warehouse": [
                1871
            ],
            "__typename": [
                1
            ]
        },
        "ProductDonationAdjustments": {
            "adjustments": [
                1389
            ],
            "__typename": [
                1
            ]
        },
        "ProductGiveawayAdjustment": {
            "finaloopProductId": [
                1
            ],
            "date": [
                461
            ],
            "unitsGiveaway": [
                47
            ],
            "warehouse": [
                1871
            ],
            "__typename": [
                1
            ]
        },
        "ProductGiveawayAdjustments": {
            "adjustments": [
                1391
            ],
            "__typename": [
                1
            ]
        },
        "ProductPendingAdjustmentsCountBreakdownInput": {
            "companyId": [
                15
            ],
            "managementMethodId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ProductPendingAdjustmentsCountBreakdownResponse": {
            "salesAdjustmentsCount": [
                47
            ],
            "deadInventoryAdjustmentsCount": [
                47
            ],
            "giveawayAdjustmentsCount": [
                47
            ],
            "donationAdjustmentsCount": [
                47
            ],
            "totalCount": [
                47
            ],
            "salesAdjustmentsBreakdown": [
                1486
            ],
            "__typename": [
                1
            ]
        },
        "ProductPurchases": {
            "purchaseOrder": [
                1416
            ],
            "__typename": [
                1
            ]
        },
        "ProductQuantityFilter": {},
        "Products2Result": {
            "products": [
                1368
            ],
            "hasNext": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "ProductSalesDataAdjustment": {
            "salesChannel": [
                1495
            ],
            "date": [
                461
            ],
            "unitsSold": [
                47
            ],
            "unitsRestocked": [
                47
            ],
            "warehouse": [
                1871
            ],
            "__typename": [
                1
            ]
        },
        "ProductSalesDataAdjustments": {
            "adjustments": [
                1398
            ],
            "__typename": [
                1
            ]
        },
        "ProductSource": {},
        "ProductsOverviewInput": {
            "companyId": [
                15
            ],
            "filter": [
                737
            ],
            "__typename": [
                1
            ]
        },
        "ProductsOverviewResponse": {
            "totalOnHandInventory": [
                5
            ],
            "totalNegativeOnHand": [
                5
            ],
            "productsWithoutCostCount": [
                47
            ],
            "totalProducts": [
                47
            ],
            "totalHidden": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "ProductsResult": {
            "totalProducts": [
                47
            ],
            "productsWithoutCostCount": [
                47
            ],
            "totalOnHandInventory": [
                5
            ],
            "totalNegativeOnHand": [
                47
            ],
            "totalHidden": [
                47
            ],
            "products": [
                1368
            ],
            "hasNext": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "ProductsSortDirection": {},
        "ProductStatusChangeInput": {
            "productId": [
                1
            ],
            "isHidden": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "ProductsValidationData": {
            "skuData": [
                1618
            ],
            "ambiguousSkus": [
                1151
            ],
            "unknownSkus": [
                1151
            ],
            "__typename": [
                1
            ]
        },
        "ProductsValidationDataInput": {
            "companyId": [
                15
            ],
            "integrationType": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "isUIOperation": [
                7
            ],
            "timeRange": [
                1386
            ],
            "__typename": [
                1
            ]
        },
        "ProductWarehousesAnalyticsOverview": {
            "warehouse": [
                1871
            ],
            "analytics": [
                1369
            ],
            "__typename": [
                1
            ]
        },
        "PurchaseBasedClosingBalance": {
            "missionType": [
                950
            ],
            "status": [
                949
            ],
            "historyLog": [
                946
            ],
            "managementMethodPeriod": [
                1087
            ],
            "openingBalance": [
                5
            ],
            "purchases": [
                5
            ],
            "cogs": [
                5
            ],
            "closingBalance": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "PurchaseBasedClosingBalanceInput": {
            "openingBalance": [
                5
            ],
            "purchases": [
                5
            ],
            "cogs": [
                5
            ],
            "closingBalance": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "PurchaseBasedOpeningBalance": {
            "missionType": [
                950
            ],
            "status": [
                949
            ],
            "historyLog": [
                946
            ],
            "managementMethodPeriod": [
                1087
            ],
            "openingBalance": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "PurchaseBasedOpeningBalanceInput": {
            "openingBalance": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "PurchaseBasedPurchases": {
            "missionType": [
                950
            ],
            "status": [
                949
            ],
            "historyLog": [
                946
            ],
            "managementMethodPeriod": [
                1087
            ],
            "purchases": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "PurchaseBasedPurchasesInput": {
            "purchases": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "PurchasedOffer": {
            "purchasedAt": [
                461
            ],
            "tier": [
                1172
            ],
            "features": [
                565
            ],
            "price": [
                5
            ],
            "title": [
                1
            ],
            "description": [
                1
            ],
            "recurrence": [
                1320
            ],
            "__typename": [
                1
            ]
        },
        "PurchaseOrder": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "displayName": [
                1
            ],
            "placementDate": [
                461
            ],
            "inProgressDate": [
                461
            ],
            "vendor": [
                1853
            ],
            "directLineItems": [
                511
            ],
            "indirectLineItems": [
                833
            ],
            "shipments": [
                1569
            ],
            "directCostTotal": [
                5
            ],
            "indirectCostTotal": [
                5
            ],
            "landedCostTotal": [
                5
            ],
            "landedCostBreakdown": [
                1420
            ],
            "discountCostTotal": [
                5
            ],
            "status": [
                1424
            ],
            "publishedEventIds": [
                1
            ],
            "deletedAt": [
                461
            ],
            "isDeleted": [
                7
            ],
            "serialNumber": [
                47
            ],
            "notes": [
                1
            ],
            "attachments": [
                1417
            ],
            "inventoryPaymentGroupLinks": [
                884
            ],
            "unpaidAmounts": [
                1714
            ],
            "__typename": [
                1
            ]
        },
        "PurchaseOrderAttachment": {
            "id": [
                15
            ],
            "filename": [
                1
            ],
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "PurchaseOrderAttachmentInput": {
            "id": [
                1
            ],
            "filename": [
                1
            ],
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "PurchaseOrderInput": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "displayName": [
                1
            ],
            "placementDate": [
                461
            ],
            "vendorId": [
                15
            ],
            "directLineItems": [
                512
            ],
            "indirectLineItems": [
                834
            ],
            "shipments": [
                1577
            ],
            "directCostTotal": [
                5
            ],
            "indirectCostTotal": [
                5
            ],
            "landedCostTotal": [
                5
            ],
            "discountCostTotal": [
                5
            ],
            "inProgressDate": [
                461
            ],
            "notes": [
                1
            ],
            "attachments": [
                1418
            ],
            "__typename": [
                1
            ]
        },
        "PurchaseOrderLandedCostBreakdown": {
            "inTransit": [
                5
            ],
            "inProcess": [
                5
            ],
            "received": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "PurchaseOrderOverview": {
            "status": [
                1424
            ],
            "landedCostTotal": [
                5
            ],
            "totalPurchaseOrders": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "PurchaseOrderOverviewV2": {
            "maxLandedCostTotal": [
                5
            ],
            "purchaseOrderOverview": [
                1421
            ],
            "__typename": [
                1
            ]
        },
        "PurchaseOrdersSortField": {},
        "PurchaseOrderStatus": {},
        "QuantityAndCost": {
            "quantity": [
                47
            ],
            "cost": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "Query": {
            "accrualsSpreadByTransactionIDs": [
                20,
                {
                    "input": [
                        613,
                        "GetAccrualsSpreadInput!"
                    ]
                }
            ],
            "getAccrualsByTransactionIDs": [
                14,
                {
                    "input": [
                        612,
                        "GetAccrualsByTransactionIDsInput!"
                    ]
                }
            ],
            "reportedBusinessBankTransactionsAccount": [
                113,
                {
                    "input": [
                        1466,
                        "ReportedBusinessBankTransactionsAccountInput!"
                    ]
                }
            ],
            "bankAccountInternal": [
                113,
                {
                    "input": [
                        121,
                        "BankAccountInternalInput!"
                    ]
                }
            ],
            "institutionVendorsIds": [
                845,
                {
                    "vendorId": [
                        15
                    ]
                }
            ],
            "institutionMappings": [
                839,
                {
                    "input": [
                        840
                    ]
                }
            ],
            "institutionById": [
                837,
                {
                    "id": [
                        15,
                        "ID!"
                    ]
                }
            ],
            "bankStatementParsingRequest": [
                168,
                {
                    "input": [
                        621,
                        "GetBankStatementParsingRequestInput!"
                    ]
                }
            ],
            "bankStatementParsingResults": [
                172,
                {
                    "input": [
                        622,
                        "GetBankStatementParsingResultsInput!"
                    ]
                }
            ],
            "backOfficeBankStatementRequests": [
                619,
                {
                    "input": [
                        618,
                        "GetBackOfficeBankStatementRequestsInput!"
                    ]
                }
            ],
            "backOfficeBankStatementRequestsFilters": [
                109
            ],
            "bankReconciliationStatementFiles": [
                1447,
                {
                    "input": [
                        620,
                        "GetBankAccountStatementFilesInput!"
                    ]
                }
            ],
            "bankAccount": [
                113,
                {
                    "input": [
                        120,
                        "BankAccountInput!"
                    ]
                }
            ],
            "bankAccounts": [
                113,
                {
                    "input": [
                        132,
                        "BankAccountsInput!"
                    ]
                }
            ],
            "bankAccountsByIds": [
                113,
                {
                    "input": [
                        131,
                        "BankAccountsByIdsInput!"
                    ]
                }
            ],
            "bankAccountReconciliations": [
                128,
                {
                    "input": [
                        129,
                        "BankAccountReconciliationsInput!"
                    ]
                }
            ],
            "bankAccountBalance": [
                5,
                {
                    "input": [
                        115,
                        "BankAccountBalanceInput!"
                    ]
                }
            ],
            "institutions": [
                844,
                {
                    "input": [
                        843
                    ]
                }
            ],
            "bankReconciliationRequest": [
                152,
                {
                    "input": [
                        154,
                        "BankReconciliationRequestInput!"
                    ]
                }
            ],
            "bankReconciliationRequests": [
                152,
                {
                    "input": [
                        155,
                        "BankReconciliationRequestsInput!"
                    ]
                }
            ],
            "bankTransaction": [
                186,
                {
                    "getBankTransactionInput": [
                        198,
                        "BankTransactionInput!"
                    ]
                }
            ],
            "bankTransactions": [
                203,
                {
                    "input": [
                        206,
                        "BankTransactionsInput!"
                    ]
                }
            ],
            "bankTransactionsByMerchant": [
                191,
                {
                    "bankTransactionsByMerchantInput": [
                        204,
                        "BankTransactionsByMerchantInput!"
                    ]
                }
            ],
            "searchMerchants": [
                1114,
                {
                    "input": [
                        1526,
                        "SearchMerchantsInput!"
                    ]
                }
            ],
            "merchantBankTransactionRecommendations": [
                202,
                {
                    "input": [
                        1115,
                        "MerchantBankTransactionRecommendationsInput!"
                    ]
                }
            ],
            "searchVendorsTransactionsPriority": [
                1528,
                {
                    "input": [
                        1527,
                        "SearchVendorsTransactionPriorityInput!"
                    ]
                }
            ],
            "classificationTags": [
                296,
                {
                    "input": [
                        302
                    ]
                }
            ],
            "classificationTagsByIds": [
                296,
                {
                    "input": [
                        301,
                        "ClassificationTagsByIdsInput!"
                    ]
                }
            ],
            "internalTransferCandidates": [
                203,
                {
                    "input": [
                        877,
                        "InternalTransferCandidatesInput!"
                    ]
                }
            ],
            "internalTransferCandidatesV2": [
                207,
                {
                    "input": [
                        878,
                        "internalTransferCandidatesV2Input!"
                    ]
                }
            ],
            "unidentifiedBankTransactionsGroups": [
                1687,
                {
                    "input": [
                        1688,
                        "UnidentifiedBankTransactionsGroupsInput!"
                    ]
                }
            ],
            "merchantsBankTransactionStats": [
                1120,
                {
                    "input": [
                        1121,
                        "MerchantsBankTransactionStatsInput!"
                    ]
                }
            ],
            "bankTransactionsSummary": [
                209,
                {
                    "input": [
                        206,
                        "BankTransactionsInput!"
                    ]
                }
            ],
            "merchantsByIds": [
                1114,
                {
                    "input": [
                        1122,
                        "MerchantsByIdsInput!"
                    ]
                }
            ],
            "suggestBankTransactionClassification": [
                1647,
                {
                    "input": [
                        1646,
                        "SuggestBankTransactionClassificationInput!"
                    ]
                }
            ],
            "lastMachineClassificationJob": [
                1082,
                {
                    "input": [
                        1081,
                        "MachineClassificationInput!"
                    ]
                }
            ],
            "merchantsByKeys": [
                1114,
                {
                    "input": [
                        1123,
                        "MerchantsByKeysInput!"
                    ]
                }
            ],
            "bankTransactionUploads": [
                210,
                {
                    "input": [
                        218,
                        "BankTransactionUploadsInput!"
                    ]
                }
            ],
            "bankTransactionsByPairings": [
                201,
                {
                    "input": [
                        205,
                        "BankTransactionsByPairingsInput!"
                    ]
                }
            ],
            "isGlobalRuleExistByVendorAndMoneyDirection": [
                982,
                {
                    "input": [
                        788,
                        "GlobalRuleExistByVendorAndMoneyDirectionInput!"
                    ]
                }
            ],
            "companyByStripeCustomerId": [
                322,
                {
                    "args": [
                        340,
                        "CompanyByStripeCustomerIdInput!"
                    ]
                }
            ],
            "stripeCustomerIdsByCompanyIds": [
                1642,
                {
                    "args": [
                        1641,
                        "StripeCustomerIdsByCompanyIdsInput!"
                    ]
                }
            ],
            "offers": [
                714,
                {
                    "args": [
                        713,
                        "GetOffersInput!"
                    ]
                }
            ],
            "billsByIds": [
                227,
                {
                    "input": [
                        15,
                        "[ID!]!"
                    ]
                }
            ],
            "billsDueDateSummary": [
                239,
                {
                    "input": [
                        240,
                        "BillsDueDateSummaryInput!"
                    ]
                }
            ],
            "processBillContent": [
                1365,
                {
                    "input": [
                        1364,
                        "ProcessBillContentInput!"
                    ]
                }
            ],
            "bills": [
                628,
                {
                    "input": [
                        627,
                        "GetBillsInput!"
                    ]
                }
            ],
            "billsAgingReportCSV": [
                108,
                {
                    "args": [
                        238,
                        "BillsAgingReportInput!"
                    ]
                }
            ],
            "billsYearEndTasks": [
                245,
                {
                    "input": [
                        244,
                        "BillsYearEndTasksInput!"
                    ]
                }
            ],
            "businessEventDefinitions": [
                259
            ],
            "businessEventDefinitionsByIds": [
                259,
                {
                    "input": [
                        261,
                        "BusinessEventDefinitionsByIds!"
                    ]
                }
            ],
            "businessEventDefinitionsByGroups": [
                259,
                {
                    "input": [
                        260,
                        "BusinessEventDefinitionsByGroups!"
                    ]
                }
            ],
            "businessEventActionsConfigurations": [
                254
            ],
            "businessEventActionsConfigurationByType": [
                254,
                {
                    "input": [
                        255,
                        "BusinessEventActionsConfigurationByType!"
                    ]
                }
            ],
            "accountRoles": [
                10
            ],
            "classificationBusinessEventDefinitions": [
                259
            ],
            "businessEventsAccrualsConfig": [
                265
            ],
            "accountRole": [
                10,
                {
                    "input": [
                        611,
                        "GetAccountRoleInput!"
                    ]
                }
            ],
            "company": [
                322,
                {
                    "args": [
                        640,
                        "GetCompanyInput!"
                    ]
                }
            ],
            "getCompanyAddresses": [
                324,
                {
                    "input": [
                        348,
                        "CompanyIdInput!"
                    ]
                }
            ],
            "getCompanyDefaultAddress": [
                324,
                {
                    "input": [
                        348,
                        "CompanyIdInput!"
                    ]
                }
            ],
            "signedDownloadUrl": [
                1,
                {
                    "args": [
                        1606,
                        "SignedDownloadUrlInput!"
                    ]
                }
            ],
            "onboardingStatus": [
                1176,
                {
                    "input": [
                        716,
                        "GetOnboardingStatusInput!"
                    ]
                }
            ],
            "listCompanies": [
                322,
                {
                    "args": [
                        1008
                    ]
                }
            ],
            "getCompanies": [
                322,
                {
                    "args": [
                        639
                    ]
                }
            ],
            "getCompaniesByIds": [
                322,
                {
                    "args": [
                        638,
                        "GetCompaniesByIdsInput!"
                    ]
                }
            ],
            "healthStatus": [
                345,
                {
                    "input": [
                        664,
                        "GetHealthStatusInput!"
                    ]
                }
            ],
            "getLatestValidation": [
                691,
                {
                    "input": [
                        690,
                        "GetLatestValidationInput!"
                    ]
                }
            ],
            "validationTemplates": [
                1850,
                {
                    "input": [
                        771,
                        "GetValidationTemplatesInput!"
                    ]
                }
            ],
            "failingValidationsByValidationCycle": [
                564,
                {
                    "input": [
                        653,
                        "GetFailingValidationsByValidationCycleInput!"
                    ]
                }
            ],
            "failingValidationsByValidationTemplate": [
                564,
                {
                    "input": [
                        654,
                        "GetFailingValidationsByValidationTemplateInput!"
                    ]
                }
            ],
            "getEntitiesLockedPeriodBypasses": [
                537,
                {
                    "args": [
                        652,
                        "GetEntitiesLockedPeriodBypassesInput!"
                    ]
                }
            ],
            "listReadyValidations": [
                1429,
                {
                    "input": [
                        1010,
                        "ListReadyValidationsInput!"
                    ]
                }
            ],
            "getReadyValidation": [
                745,
                {
                    "input": [
                        744,
                        "GetReadyValidationInput!"
                    ]
                }
            ],
            "companyMainHolder": [
                326,
                {
                    "input": [
                        361,
                        "CompanyMainHolderInput!"
                    ]
                }
            ],
            "companyAffiliatesByIds": [
                326,
                {
                    "input": [
                        328,
                        "CompanyAffiliatesByIdsInput!"
                    ]
                }
            ],
            "holderTransactionsByIds": [
                817,
                {
                    "input": [
                        818,
                        "HolderTransactionsByIdsInput!"
                    ]
                }
            ],
            "holderSharesByIds": [
                813,
                {
                    "input": [
                        814,
                        "HolderSharesByIdsInput!"
                    ]
                }
            ],
            "holderCapitalInvestmentsByIds": [
                808,
                {
                    "input": [
                        809,
                        "HolderCapitalInvestmentsByIdsInput!"
                    ]
                }
            ],
            "holderSafeInvestmentsByIds": [
                811,
                {
                    "input": [
                        812,
                        "HolderSafeInvestmentsByIdsInput!"
                    ]
                }
            ],
            "equityEventsEffectConfigurations": [
                538,
                {
                    "input": [
                        539,
                        "EquityEventsEffectConfigurationsInput!"
                    ]
                }
            ],
            "manualEquityEvents": [
                1097,
                {
                    "input": [
                        1098,
                        "ManualEquityEventsInput!"
                    ]
                }
            ],
            "companyAffiliates": [
                326,
                {
                    "input": [
                        329,
                        "CompanyAffiliatesInput!"
                    ]
                }
            ],
            "companyAffiliate": [
                326,
                {
                    "input": [
                        327,
                        "CompanyAffiliateInput!"
                    ]
                }
            ],
            "holderShares": [
                813,
                {
                    "input": [
                        815,
                        "HolderSharesInput!"
                    ]
                }
            ],
            "holderCapitalInvestments": [
                808,
                {
                    "input": [
                        810,
                        "HolderCapitalInvestmentsInput!"
                    ]
                }
            ],
            "holderTransactions": [
                817,
                {
                    "input": [
                        819,
                        "HolderTransactionsInput!"
                    ]
                }
            ],
            "affiliatedEntityById": [
                51,
                {
                    "input": [
                        52,
                        "AffiliatedEntityByIdInput!"
                    ]
                }
            ],
            "companyIntegration": [
                349,
                {
                    "input": [
                        351,
                        "CompanyIntegrationInput!"
                    ]
                }
            ],
            "companyIntegrations": [
                349,
                {
                    "input": [
                        352,
                        "CompanyIntegrationsInput!"
                    ]
                }
            ],
            "closingBalancesByIds": [
                310,
                {
                    "input": [
                        630,
                        "GetClosingBalancesByIdsInput!"
                    ]
                }
            ],
            "bankConnections": [
                143,
                {
                    "input": [
                        148,
                        "BankConnectionsInput!"
                    ]
                }
            ],
            "payrollConnections": [
                1328,
                {
                    "input": [
                        1331,
                        "PayrollConnectionsInput!"
                    ]
                }
            ],
            "integrationConnectionAccounts": [
                847,
                {
                    "input": [
                        848,
                        "IntegrationConnectionsFilter!"
                    ]
                }
            ],
            "integrationTransfersByCompanyAffiliate": [
                861,
                {
                    "input": [
                        870,
                        "IntegrationTransfersByCompanyAffiliateInput!"
                    ]
                }
            ],
            "integrationTransfersBackoffice": [
                861,
                {
                    "input": [
                        667,
                        "GetIntegrationTransfersBackofficeInput!"
                    ]
                }
            ],
            "integrationTransfer": [
                861,
                {
                    "input": [
                        666,
                        "GetIntegrationTransferInput!"
                    ]
                }
            ],
            "integrationTransfers": [
                861,
                {
                    "input": [
                        669,
                        "GetIntegrationTransfersInput!"
                    ]
                }
            ],
            "groupedIntegrationTransfers": [
                864,
                {
                    "input": [
                        669,
                        "GetIntegrationTransfersInput!"
                    ]
                }
            ],
            "finaloopProductInternal": [
                583,
                {
                    "input": [
                        584,
                        "FinaloopProductInternalInput!"
                    ]
                }
            ],
            "inventoryPurchasesForCompany": [
                894,
                {
                    "input": [
                        675,
                        "GetInventoryPurchasesForCompanyInput!"
                    ]
                }
            ],
            "inventoryPurchasesByIds": [
                894,
                {
                    "input": [
                        672,
                        "GetInventoryPurchasesByIdsInput!"
                    ]
                }
            ],
            "explainFifo": [
                546,
                {
                    "input": [
                        545,
                        "ExplainFifoInput!"
                    ]
                }
            ],
            "getCOGSBreakdown2": [
                632,
                {
                    "input": [
                        631,
                        "GetCOGSBreakdownRequest!"
                    ]
                }
            ],
            "exportCOGSBreakdown": [
                551,
                {
                    "input": [
                        550,
                        "ExportCOGSBreakdownRequest!"
                    ]
                }
            ],
            "getCOGSOverview2": [
                637,
                {
                    "input": [
                        636,
                        "GetCOGSOverviewRequest!"
                    ]
                }
            ],
            "exportCOGSOverview": [
                553,
                {
                    "input": [
                        552,
                        "ExportCOGSOverviewRequest!"
                    ]
                }
            ],
            "product": [
                1368,
                {
                    "input": [
                        736,
                        "GetProductInput!"
                    ]
                }
            ],
            "productAssembliesInventoryBreakdown": [
                1374,
                {
                    "input": [
                        1373,
                        "ProductAssembliesBreakdownInput!"
                    ]
                }
            ],
            "productsCsv": [
                560,
                {
                    "input": [
                        738,
                        "GetProductsInput!"
                    ]
                }
            ],
            "products2": [
                1397,
                {
                    "input": [
                        738,
                        "GetProductsInput!"
                    ]
                }
            ],
            "productsOverview": [
                1402,
                {
                    "input": [
                        1401,
                        "ProductsOverviewInput!"
                    ]
                }
            ],
            "purchaseOrder": [
                1416,
                {
                    "input": [
                        741,
                        "GetPurchaseOrderInput!"
                    ]
                }
            ],
            "purchaseOrders": [
                1416,
                {
                    "input": [
                        742,
                        "GetPurchaseOrdersInput!"
                    ]
                }
            ],
            "purchaseOrdersV2": [
                1416,
                {
                    "input": [
                        742,
                        "GetPurchaseOrdersInput!"
                    ]
                }
            ],
            "purchaseOrdersV2Total": [
                47,
                {
                    "input": [
                        742,
                        "GetPurchaseOrdersInput!"
                    ]
                }
            ],
            "purchaseOrdersOverview": [
                1421,
                {
                    "input": [
                        743,
                        "GetPurchaseOrdersOverviewInput!"
                    ]
                }
            ],
            "purchaseOrdersOverviewV2": [
                1422,
                {
                    "input": [
                        743,
                        "GetPurchaseOrdersOverviewInput!"
                    ]
                }
            ],
            "salesChannels": [
                1495,
                {
                    "input": [
                        1506,
                        "SalesChannelsInput!"
                    ]
                }
            ],
            "currentlyRunningInventoryDataSync": [
                442,
                {
                    "input": [
                        441,
                        "CurrentlyRunningInventoryDataSyncInput!"
                    ]
                }
            ],
            "inventoryAccountBalance": [
                671,
                {
                    "input": [
                        670,
                        "GetInventoryAccountBalanceInput!"
                    ]
                }
            ],
            "getOnboardingStatuses": [
                1177,
                {
                    "input": [
                        715,
                        "GetOnboardingStatusesInput!"
                    ]
                }
            ],
            "nonMatchedProducts": [
                1152,
                {
                    "input": [
                        705,
                        "GetNonMatchingProductsInput!"
                    ]
                }
            ],
            "getFromEmailAddress": [
                659,
                {
                    "input": [
                        657,
                        "GetFromEmailAddressInput!"
                    ]
                }
            ],
            "warehouseIncomingInventoryOverview": [
                1883,
                {
                    "input": [
                        1882,
                        "WarehouseIncomingInventoryOverviewInput!"
                    ]
                }
            ],
            "warehouseIncomingInventoryBreakdown": [
                1881,
                {
                    "input": [
                        1880,
                        "WarehouseIncomingInventoryBreakdownInput!"
                    ]
                }
            ],
            "productBulkEditCsvAttachments": [
                1380,
                {
                    "input": [
                        1379,
                        "ProductBulkEditCsvAttachmentsInput!"
                    ]
                }
            ],
            "inventoryPurchases": [
                910,
                {
                    "input": [
                        676,
                        "GetInventoryPurchasesInput!"
                    ]
                }
            ],
            "inventoryPurchasesExport": [
                906,
                {
                    "input": [
                        673,
                        "GetInventoryPurchasesExportInput!"
                    ]
                }
            ],
            "inventoryPurchasesSummary": [
                914,
                {
                    "input": [
                        677,
                        "GetInventoryPurchaseSummaryInput!"
                    ]
                }
            ],
            "inventoryOverviewCycles": [
                1263,
                {
                    "input": [
                        1264,
                        "OverviewCyclesInput!"
                    ]
                }
            ],
            "shopifyItemsReconciliation": [
                1597,
                {
                    "input": [
                        1582,
                        "ShopifyItemsReconciliationInput!"
                    ]
                }
            ],
            "shopifyItemsReconciliationOrders": [
                1588,
                {
                    "input": [
                        1587,
                        "ShopifyItemsReconciliationOrdersInput!"
                    ]
                }
            ],
            "shopifyItemsReconciliationOrdersDiff": [
                1585,
                {
                    "input": [
                        1584,
                        "ShopifyItemsReconciliationOrdersDiffInput!"
                    ]
                }
            ],
            "amazonProductsReconciliation": [
                64,
                {
                    "input": [
                        76,
                        "AmazonValidationInput!"
                    ]
                }
            ],
            "amazonAvailableValidationData": [
                60,
                {
                    "input": [
                        59,
                        "AmazonAvailableValidationDataInput!"
                    ]
                }
            ],
            "amazonOrdersDiff": [
                62,
                {
                    "input": [
                        76,
                        "AmazonValidationInput!"
                    ]
                }
            ],
            "inventoryVendorsOverview": [
                939,
                {
                    "input": [
                        685,
                        "GetInventoryVendorsOverviewInput!"
                    ]
                }
            ],
            "inventoryVendors": [
                940,
                {
                    "input": [
                        684,
                        "GetInventoryVendorsInput!"
                    ]
                }
            ],
            "inventoryVendorRollingBalance": [
                938,
                {
                    "input": [
                        682,
                        "GetInventoryVendorRollingBalanceInput!"
                    ]
                }
            ],
            "inventoryVendorRollingBalanceOverview": [
                937,
                {
                    "input": [
                        683,
                        "GetInventoryVendorRollingBalanceOverviewInput!"
                    ]
                }
            ],
            "inventoryVendorBills": [
                928,
                {
                    "input": [
                        678,
                        "GetInventoryVendorBillsInput!"
                    ]
                }
            ],
            "inventoryVendorBillsOverview": [
                927,
                {
                    "input": [
                        679,
                        "GetInventoryVendorBillsOverviewInput!"
                    ]
                }
            ],
            "inventoryVendorFilterValues": [
                930,
                {
                    "input": [
                        680,
                        "GetInventoryVendorFilterValuesInput!"
                    ]
                }
            ],
            "inventoryVendorParams": [
                932,
                {
                    "input": [
                        681,
                        "GetInventoryVendorParamsInput!"
                    ]
                }
            ],
            "warehouseOpeningBalanceOverview": [
                1889,
                {
                    "input": [
                        775,
                        "GetWarehouseOpeningBalanceOverviewInput!"
                    ]
                }
            ],
            "warehouseOpeningBalances": [
                1893,
                {
                    "input": [
                        776,
                        "GetWarehouseOpeningBalancesInput!"
                    ]
                }
            ],
            "pendingWarehouseOpeningBalancesAttachments": [
                1353,
                {
                    "input": [
                        733,
                        "GetPendingWarehouseOpeningBalancesAttachmentsInput!"
                    ]
                }
            ],
            "getWarehouseOpeningBalancesCsvTemplate": [
                1890,
                {
                    "input": [
                        775,
                        "GetWarehouseOpeningBalanceOverviewInput!"
                    ]
                }
            ],
            "warehouseTransfersOverview": [
                1926,
                {
                    "input": [
                        321,
                        "CommonWarehouseTransfersFilterInput!"
                    ]
                }
            ],
            "warehouseTransfers": [
                1927,
                {
                    "input": [
                        780,
                        "GetWarehouseTransfersInput!"
                    ]
                }
            ],
            "warehouseTransfer": [
                1904,
                {
                    "input": [
                        779,
                        "GetWarehouseTransferInput!"
                    ]
                }
            ],
            "pendingSalesDataAdjustments": [
                1351,
                {
                    "input": [
                        729,
                        "GetPendingSalesDataAdjustmentsInput!"
                    ]
                }
            ],
            "pendingSalesDataAdjustmentsOverview": [
                1350,
                {
                    "input": [
                        730,
                        "GetPendingSalesDataAdjustmentsOverviewInput!"
                    ]
                }
            ],
            "productBulkAdjustmentStatus": [
                734,
                {
                    "input": [
                        735,
                        "GetProductBulkAdjustmentStatusInput!"
                    ]
                }
            ],
            "salesAdjustmentsCsvTemplate": [
                250,
                {
                    "input": [
                        249,
                        "BulkEditCsvTemplateInput!"
                    ]
                }
            ],
            "productPendingAdjustmentsCountBreakdown": [
                1394,
                {
                    "input": [
                        1393,
                        "ProductPendingAdjustmentsCountBreakdownInput!"
                    ]
                }
            ],
            "listNoUnitsPhysicalCounts": [
                1165,
                {
                    "input": [
                        1009,
                        "ListNoUnitsPhysicalCountsInput!"
                    ]
                }
            ],
            "noUnitsPhysicalCount": [
                1158,
                {
                    "input": [
                        708,
                        "GetNoUnitsPhysicalCountInput!"
                    ]
                }
            ],
            "noUnitsPhysicalCountProductQuantities": [
                1162,
                {
                    "input": [
                        710,
                        "GetNoUnitsPhysicalCountProductQuantitiesInput!"
                    ]
                }
            ],
            "noUnitsPhysicalCountOverview": [
                1161,
                {
                    "input": [
                        709,
                        "GetNoUnitsPhysicalCountOverviewInput!"
                    ]
                }
            ],
            "getNoUnitsPhysicalCountClosingBalance": [
                1160,
                {
                    "input": [
                        707,
                        "GetNoUnitsPhysicalCountClosingBalanceInput!"
                    ]
                }
            ],
            "getNoUnitsPhysicalCountAnyActiveProductsWithoutQuantities": [
                81,
                {
                    "input": [
                        706,
                        "GetNoUnitsPhysicalCountAnyActiveProductsWithoutQuantitiesInput!"
                    ]
                }
            ],
            "listUnitsTrackingPhysicalCounts": [
                1703,
                {
                    "input": [
                        1011,
                        "ListUnitsTrackingPhysicalCountsInput!"
                    ]
                }
            ],
            "unitsTrackingPhysicalCount": [
                1698,
                {
                    "input": [
                        765,
                        "GetUnitsTrackingPhysicalCountInput!"
                    ]
                }
            ],
            "unitsTrackingPhysicalCountProductQuantities": [
                1701,
                {
                    "input": [
                        767,
                        "GetUnitsTrackingPhysicalCountProductQuantitiesInput!"
                    ]
                }
            ],
            "unitsTrackingPhysicalCountOverview": [
                1700,
                {
                    "input": [
                        766,
                        "GetUnitsTrackingPhysicalCountOverviewInput!"
                    ]
                }
            ],
            "physicalCountCsvTemplate": [
                250,
                {
                    "input": [
                        249,
                        "BulkEditCsvTemplateInput!"
                    ]
                }
            ],
            "hasUnacknowledgedChanges": [
                7,
                {
                    "input": [
                        805,
                        "HasUnacknowledgedChangesInput!"
                    ]
                }
            ],
            "warehouses": [
                1894,
                {
                    "input": [
                        778,
                        "GetWarehousesInput!"
                    ]
                }
            ],
            "warehouseDefaults": [
                1898,
                {
                    "input": [
                        777,
                        "GetWarehousesDefaultsInput!"
                    ]
                }
            ],
            "inventoryPaymentGroupById": [
                883,
                {
                    "input": [
                        882,
                        "InventoryPaymentGroupByIdInput!"
                    ]
                }
            ],
            "inventoryPaymentGroupsByIds": [
                886,
                {
                    "input": [
                        885,
                        "InventoryPaymentGroupsByIdsInput!"
                    ]
                }
            ],
            "validateInventoryPaymentGroupByEntity": [
                1844,
                {
                    "input": [
                        1843,
                        "ValidateInventoryPaymentGroupByEntityInput!"
                    ]
                }
            ],
            "inventoryYearEndMissions": [
                948,
                {
                    "input": [
                        947,
                        "InventoryYearEndMissionsInput!"
                    ]
                }
            ],
            "loans": [
                1055,
                {
                    "input": [
                        699,
                        "GetLoansInput!"
                    ]
                }
            ],
            "getMatchingEffectRule": [
                704,
                {
                    "input": [
                        702,
                        "GetMatchingEffectRuleInput!"
                    ]
                }
            ],
            "loansEventsEffectConfiguration": [
                1053
            ],
            "getLoansById": [
                1052,
                {
                    "input": [
                        697,
                        "GetLoansByIdInput!"
                    ]
                }
            ],
            "exportLoans": [
                559,
                {
                    "input": [
                        558,
                        "ExportLoansCsvInput!"
                    ]
                }
            ],
            "getLoanEvents": [
                695,
                {
                    "input": [
                        692,
                        "GetLoanEventsInput!"
                    ]
                }
            ],
            "getLoanRelationships": [
                696,
                {
                    "input": [
                        692,
                        "GetLoanEventsInput!"
                    ]
                }
            ],
            "getLoanEventsLength": [
                694,
                {
                    "input": [
                        693,
                        "GetLoanEventsLengthInput!"
                    ]
                }
            ],
            "loanEventDefinitions": [
                1033
            ],
            "getLoansUpdatedBy": [
                1
            ],
            "knownLenders": [
                1150
            ],
            "companyLoans": [
                357,
                {
                    "input": [
                        642,
                        "GetCompanyLoansInput!"
                    ]
                }
            ],
            "companyLoan": [
                356,
                {
                    "input": [
                        641,
                        "GetCompanyLoanInput!"
                    ]
                }
            ],
            "getAttachmentTargetURLS": [
                102,
                {
                    "input": [
                        617,
                        "GetAttachmentTargetURLSInput!"
                    ]
                }
            ],
            "downloadAttachment": [
                526,
                {
                    "input": [
                        525,
                        "DownloadAttachmentInput!"
                    ]
                }
            ],
            "getLoansOutstandingAmountOnDate": [
                701,
                {
                    "input": [
                        700,
                        "GetLoansOutstandingAmountOnDateInput!"
                    ]
                }
            ],
            "getLoanTransactionStatuses": [
                1058,
                {
                    "transactionIds": [
                        15,
                        "[ID!]!"
                    ]
                }
            ],
            "prevalidateCompanyLoan": [
                1367,
                {
                    "input": [
                        1363,
                        "PrevalidateCompanyLoanInput!"
                    ]
                }
            ],
            "companyArCashDiffs": [
                336,
                {
                    "input": [
                        334,
                        "CompanyArCashDiffsInput!"
                    ]
                }
            ],
            "giftCardsByIds": [
                784,
                {
                    "input": [
                        783,
                        "GiftCardsByIdsInput!"
                    ]
                }
            ],
            "shopifyReportDateLimits": [
                1600,
                {
                    "input": [
                        1599,
                        "ShopifyReportDateLimitsInput!"
                    ]
                }
            ],
            "shopifyReconciliationData": [
                1240,
                {
                    "input": [
                        1239,
                        "OrderReconciliationsInput!"
                    ]
                }
            ],
            "paymentProcessorByCompanyAndVendor": [
                1319,
                {
                    "input": [
                        1318,
                        "PaymentProcessorByCompanyAndVendorInput!"
                    ]
                }
            ],
            "vendorByPaymentGateway": [
                1858,
                {
                    "input": [
                        1857,
                        "VendorByPaymentGatewayInput!"
                    ]
                }
            ],
            "salesChannelsByCompanyAndIntegration": [
                1505,
                {
                    "input": [
                        1504,
                        "SalesChannelsByCompanyAndIntegrationInput!"
                    ]
                }
            ],
            "paymentGatewaysByCompanyIntegrationAndSalesChannel": [
                1,
                {
                    "input": [
                        1278,
                        "PaymentGatewaysByCompanyIntegrationAndSalesChannelInput!"
                    ]
                }
            ],
            "getOrdersV2": [
                722,
                {
                    "input": [
                        721,
                        "GetOrdersInput!"
                    ]
                }
            ],
            "getOrdersAggregations": [
                1191,
                {
                    "input": [
                        718,
                        "GetOrdersAggregationsInput!"
                    ]
                }
            ],
            "getOrderById": [
                1262,
                {
                    "input": [
                        1222,
                        "OrderIdInput!"
                    ]
                }
            ],
            "getFinaloopTags": [
                1254,
                {
                    "input": [
                        348,
                        "CompanyIdInput!"
                    ]
                }
            ],
            "getIntegrationTags": [
                1254,
                {
                    "input": [
                        665,
                        "GetIntegrationTagsInput!"
                    ]
                }
            ],
            "getFinaloopPaymentGateways": [
                1,
                {
                    "input": [
                        348,
                        "CompanyIdInput!"
                    ]
                }
            ],
            "getOrderSalesChannels": [
                1,
                {
                    "input": [
                        348,
                        "CompanyIdInput!"
                    ]
                }
            ],
            "getOrderFilteringBounds": [
                717,
                {
                    "input": [
                        348,
                        "CompanyIdInput!"
                    ]
                }
            ],
            "getFulfillmentSettings": [
                662,
                {
                    "input": [
                        661,
                        "GetFulfillmentSettingInput!"
                    ]
                }
            ],
            "isCompanyFulfillmentSettingsApplied": [
                7,
                {
                    "input": [
                        981,
                        "IsCompanyFulfillmentSettingsAppliedInput!"
                    ]
                }
            ],
            "ordersByIds": [
                1244,
                {
                    "input": [
                        1243,
                        "OrdersByIdsInput!"
                    ]
                }
            ],
            "orderGroupById": [
                1202,
                {
                    "input": [
                        1201,
                        "OrderGroupByIdInput!"
                    ]
                }
            ],
            "orderGroupsByIds": [
                1210,
                {
                    "input": [
                        1209,
                        "OrderGroupsByIdsInput!"
                    ]
                }
            ],
            "orderGroups": [
                1213,
                {
                    "input": [
                        1212,
                        "OrderGroupsInput!"
                    ]
                }
            ],
            "orderGroupsUnclearedBalance": [
                1220,
                {
                    "input": [
                        1219,
                        "OrderGroupsUnclearedBalanceInput!"
                    ]
                }
            ],
            "orderGroupsTotalUnclearedBalance": [
                1218,
                {
                    "input": [
                        1217,
                        "OrderGroupsTotalUnclearedBalanceInput!"
                    ]
                }
            ],
            "ordersYearEnd": [
                1253,
                {
                    "input": [
                        1252,
                        "OrdersYearEndInput!"
                    ]
                }
            ],
            "paymentGroupLinks": [
                1298,
                {
                    "input": [
                        1297,
                        "PaymentGroupLinksInput!"
                    ]
                }
            ],
            "getInvoicesAffectingPaymentGroups": [
                687,
                {
                    "input": [
                        686,
                        "GetInvoicesAffectingPaymentGroupsInput!"
                    ]
                }
            ],
            "getPaymentGroupsByIds": [
                1302,
                {
                    "input": [
                        1301,
                        "PaymentGroupsByIdsInput!"
                    ]
                }
            ],
            "orderPaymentGroupById": [
                1231,
                {
                    "input": [
                        1282,
                        "PaymentGroupByIdInput!"
                    ]
                }
            ],
            "orderPaymentGroupsByIds": [
                1232,
                {
                    "input": [
                        1301,
                        "PaymentGroupsByIdsInput!"
                    ]
                }
            ],
            "orderPaymentGroups": [
                1233,
                {
                    "input": [
                        1304,
                        "PaymentGroupsInput!"
                    ]
                }
            ],
            "orderPaymentGroupsUnclearedBalance": [
                1234,
                {
                    "input": [
                        1310,
                        "PaymentGroupsUnclearedBalanceInput!"
                    ]
                }
            ],
            "orderPaymentGroupsTotalUnclearedBalance": [
                1309,
                {
                    "input": [
                        1308,
                        "PaymentGroupsTotalUnclearedBalanceInput!"
                    ]
                }
            ],
            "invoicePaymentGroupById": [
                958,
                {
                    "input": [
                        1282,
                        "PaymentGroupByIdInput!"
                    ]
                }
            ],
            "invoicePaymentGroupsByIds": [
                959,
                {
                    "input": [
                        1301,
                        "PaymentGroupsByIdsInput!"
                    ]
                }
            ],
            "invoicePaymentGroups": [
                960,
                {
                    "input": [
                        1304,
                        "PaymentGroupsInput!"
                    ]
                }
            ],
            "invoicePaymentGroupsUnclearedBalance": [
                961,
                {
                    "input": [
                        1310,
                        "PaymentGroupsUnclearedBalanceInput!"
                    ]
                }
            ],
            "invoicePaymentGroupsTotalUnclearedBalance": [
                1309,
                {
                    "input": [
                        1308,
                        "PaymentGroupsTotalUnclearedBalanceInput!"
                    ]
                }
            ],
            "artificialTransactionsByMonth": [
                90,
                {
                    "input": [
                        616,
                        "GetArtificialTransactionsInput!"
                    ]
                }
            ],
            "paymentsByIds": [
                1270,
                {
                    "input": [
                        727,
                        "GetPaymentsByIdsInput!"
                    ]
                }
            ],
            "refundsByIds": [
                1457,
                {
                    "input": [
                        746,
                        "GetRefundsByIdsInput!"
                    ]
                }
            ],
            "integrationTransfersByIds": [
                875,
                {
                    "input": [
                        668,
                        "GetIntegrationTransfersByIdsInput!"
                    ]
                }
            ],
            "disputesByIds": [
                521,
                {
                    "input": [
                        651,
                        "GetDisputesByIdsInput!"
                    ]
                }
            ],
            "paymentFeesByIds": [
                1274,
                {
                    "input": [
                        727,
                        "GetPaymentsByIdsInput!"
                    ]
                }
            ],
            "chargebacksByIds": [
                280,
                {
                    "input": [
                        629,
                        "GetChargebacksByIdsInput!"
                    ]
                }
            ],
            "paymentChecks": [
                1271,
                {
                    "input": [
                        1272,
                        "PaymentChecksInput!"
                    ]
                }
            ],
            "payrollDailyAggregatesByEmploymentTypeAndCategory": [
                1333,
                {
                    "input": [
                        1334,
                        "PayrollDailyDataInput!"
                    ]
                }
            ],
            "payrollIdsByCompany": [
                1341,
                {
                    "input": [
                        1339,
                        "PayrollIdsByCompanyInput!"
                    ]
                }
            ],
            "contractorPaymentsInternal": [
                392,
                {
                    "input": [
                        391,
                        "ContractorPaymentsInternalInput!"
                    ]
                }
            ],
            "contractorPaymentIdsByCompany": [
                390,
                {
                    "input": [
                        388,
                        "ContractorPaymentIdsByCompanyInput!"
                    ]
                }
            ],
            "paymentsByEmployee": [
                458,
                {
                    "input": [
                        1321,
                        "PaymentsByEntityInput!"
                    ]
                }
            ],
            "paymentsByContractor": [
                457,
                {
                    "input": [
                        1321,
                        "PaymentsByEntityInput!"
                    ]
                }
            ],
            "payrollSettings": [
                1344,
                {
                    "input": [
                        1345,
                        "PayrollSettingsInput!"
                    ]
                }
            ],
            "validations": [
                1847,
                {
                    "args": [
                        1849,
                        "ValidationsArgs!"
                    ]
                }
            ],
            "periodicPartnerReports": [
                1358
            ],
            "periodicReportsPartners": [
                1468
            ],
            "exportCashFlow": [
                561,
                {
                    "args": [
                        548,
                        "ExportCashFlowApiArgs!"
                    ]
                }
            ],
            "exportBalanceSheet": [
                108,
                {
                    "args": [
                        547,
                        "ExportBalanceSheetApiArgs!"
                    ]
                }
            ],
            "ledgerAccountTransactionsCSV": [
                108,
                {
                    "args": [
                        557,
                        "ExportLedgerAccountTransactionsInput!"
                    ]
                }
            ],
            "checkExportStatus": [
                108,
                {
                    "args": [
                        283,
                        "CheckAwaitedExportStatusInput!"
                    ]
                }
            ],
            "syncReportDefinition": [
                1518,
                {
                    "args": [
                        1655,
                        "SyncReportInput!"
                    ]
                }
            ],
            "lastAccountingReportSync": [
                991,
                {
                    "args": [
                        990,
                        "LastAccountingReportSyncInput!"
                    ]
                }
            ],
            "chartData": [
                282,
                {
                    "args": [
                        281,
                        "ChartDataInput!"
                    ]
                }
            ],
            "classificationTagReportHierarchy": [
                299,
                {
                    "args": [
                        300,
                        "ClassificationTagReportHierarchyInput!"
                    ]
                }
            ],
            "customerInternal": [
                443,
                {
                    "args": [
                        649,
                        "GetCustomerInternal!"
                    ]
                }
            ],
            "sellingFeesByIds": [
                1531,
                {
                    "input": [
                        760,
                        "GetSellingFeesByIdsInput!"
                    ]
                }
            ],
            "fulfillmentCostsByIds": [
                592,
                {
                    "input": [
                        660,
                        "GetFulfillmentCostsByIdsInput!"
                    ]
                }
            ],
            "shippingCostsByIds": [
                1578,
                {
                    "input": [
                        761,
                        "GetShippingCostsByIdsInput!"
                    ]
                }
            ],
            "getCustomers": [
                443,
                {
                    "getCustomerArgs": [
                        646,
                        "GetCustomerArgs!"
                    ]
                }
            ],
            "getCustomerById": [
                650,
                {
                    "id": [
                        15,
                        "ID!"
                    ]
                }
            ],
            "getCustomerByIds": [
                443,
                {
                    "ids": [
                        15,
                        "[ID!]!"
                    ]
                }
            ],
            "searchCustomers": [
                450,
                {
                    "input": [
                        1525,
                        "SearchCustomersInput!"
                    ]
                }
            ],
            "getCustomerV2ById": [
                450,
                {
                    "input": [
                        647,
                        "GetCustomerByIdInput!"
                    ]
                }
            ],
            "getCustomerV2ByIds": [
                450,
                {
                    "input": [
                        648,
                        "GetCustomerByIdsInput!"
                    ]
                }
            ],
            "sale": [
                1475,
                {
                    "input": [
                        1478,
                        "SaleInput!"
                    ]
                }
            ],
            "getSales": [
                756,
                {
                    "input": [
                        755,
                        "GetSalesInput!"
                    ]
                }
            ],
            "invoice": [
                954,
                {
                    "input": [
                        956,
                        "InvoiceInput!"
                    ]
                }
            ],
            "getInvoices": [
                689,
                {
                    "input": [
                        755,
                        "GetSalesInput!"
                    ]
                }
            ],
            "getInvoicePdfUrl": [
                1,
                {
                    "input": [
                        964,
                        "InvoicePdfInput!"
                    ]
                }
            ],
            "getPaymentMethods": [
                1314,
                {
                    "input": [
                        348,
                        "CompanyIdInput!"
                    ]
                }
            ],
            "calculateInvoice": [
                954,
                {
                    "input": [
                        266,
                        "CalculateInvoiceInput!"
                    ]
                }
            ],
            "getInvoicesCsv": [
                556,
                {
                    "input": [
                        755,
                        "GetSalesInput!"
                    ]
                }
            ],
            "getInvoiceAggregations": [
                968,
                {
                    "input": [
                        747,
                        "GetSaleAggregationsInput!"
                    ]
                }
            ],
            "getInvoicesBalance": [
                969,
                {
                    "input": [
                        747,
                        "GetSaleAggregationsInput!"
                    ]
                }
            ],
            "invoicesAgingReportCSV": [
                108,
                {
                    "args": [
                        755,
                        "GetSalesInput!"
                    ]
                }
            ],
            "salesChannel": [
                1497,
                {
                    "input": [
                        1478,
                        "SaleInput!"
                    ]
                }
            ],
            "getSalesChannels": [
                1497,
                {
                    "input": [
                        752,
                        "GetSalesChannelsInput!"
                    ]
                }
            ],
            "getSalesChannelTemplates": [
                1509,
                {
                    "input": [
                        753,
                        "GetSalesChannelTemplatesInput!"
                    ]
                }
            ],
            "invoiceSettings": [
                970,
                {
                    "input": [
                        688,
                        "GetInvoiceSettingsInput!"
                    ]
                }
            ],
            "getInvoiceExamplePdfUrl": [
                1,
                {
                    "input": [
                        971,
                        "InvoicesInput!"
                    ]
                }
            ],
            "invoicesYearEndTasks": [
                976,
                {
                    "input": [
                        975,
                        "InvoicesYearEndTasksInput!"
                    ]
                }
            ],
            "me": [
                1125
            ],
            "isInvitationCodeForRegisteredUser": [
                984,
                {
                    "args": [
                        983,
                        "IsInvitationCodeForRegisteredUserArgs!"
                    ]
                }
            ],
            "companyUsers": [
                367,
                {
                    "args": [
                        366
                    ]
                }
            ],
            "userInvitationByCode": [
                1830,
                {
                    "args": [
                        1829,
                        "UserInvitationArgs!"
                    ]
                }
            ],
            "userReferralLink": [
                1838,
                {
                    "args": [
                        1837
                    ]
                }
            ],
            "userInvitationsByEmail": [
                1832,
                {
                    "args": [
                        1831,
                        "UserInvitationsByEmailArgs!"
                    ]
                }
            ],
            "userRoles": [
                1840
            ],
            "auth0UserLookup": [
                107,
                {
                    "args": [
                        106,
                        "Auth0UserLookupInput!"
                    ]
                }
            ],
            "googleConnectStatus": [
                793
            ],
            "googleConnectionLink": [
                792,
                {
                    "input": [
                        791
                    ]
                }
            ],
            "calendlyEventDetails": [
                268,
                {
                    "args": [
                        267,
                        "CalendlyEventInput!"
                    ]
                }
            ],
            "userNameFromReferralCode": [
                1836,
                {
                    "args": [
                        1835,
                        "UserNameFromReferralCodeInput!"
                    ]
                }
            ],
            "userByEmail": [
                1823,
                {
                    "args": [
                        1822,
                        "UserByEmailArgs!"
                    ]
                }
            ],
            "usersByEmails": [
                1842,
                {
                    "args": [
                        1841,
                        "UsersByEmailsArgs!"
                    ]
                }
            ],
            "vendors": [
                1853,
                {
                    "input": [
                        1861,
                        "VendorsInput!"
                    ]
                }
            ],
            "vendor": [
                773,
                {
                    "input": [
                        772,
                        "GetVendorInput!"
                    ]
                }
            ],
            "vendorsByIds": [
                1853,
                {
                    "input": [
                        774,
                        "GetVendorsInput!"
                    ]
                }
            ],
            "vendorsBySubtype": [
                1853,
                {
                    "companyId": [
                        15,
                        "ID!"
                    ],
                    "subTypes": [
                        1865,
                        "[VendorSubType!]!"
                    ]
                }
            ],
            "tax1099AffiliatedEntityGroups": [
                1664,
                {
                    "input": [
                        764,
                        "GetTax1099AffiliatedEntityGroupsInput!"
                    ]
                }
            ],
            "institutionVendors": [
                1853
            ],
            "vendorById": [
                1853,
                {
                    "input": [
                        1855,
                        "VendorByIdInput!"
                    ]
                }
            ],
            "vendorByAffiliatedEntityId": [
                1853,
                {
                    "input": [
                        1854,
                        "VendorByAffiliatedEntityIdInput!"
                    ]
                }
            ],
            "vendorByName": [
                1853,
                {
                    "input": [
                        1856,
                        "VendorByNameInput!"
                    ]
                }
            ],
            "globalVendorsByIds": [
                1853,
                {
                    "input": [
                        663,
                        "GetGlobalVendorsInput!"
                    ]
                }
            ],
            "vendorsByIntegrationType": [
                1853,
                {
                    "input": [
                        1860,
                        "VendorsByIntegrationTypeInput!"
                    ]
                }
            ],
            "getCompanyWorksheetsV2": [
                375,
                {
                    "args": [
                        645,
                        "GetCompanyWorksheetsArgsV2!"
                    ]
                }
            ],
            "getCompanyLockConditionsV2": [
                358,
                {
                    "args": [
                        644,
                        "GetCompanyLockConditionsArgsV2!"
                    ]
                }
            ],
            "getSignedDates": [
                763,
                {
                    "args": [
                        762,
                        "GetSignedDatesInput!"
                    ]
                }
            ],
            "getAllCompaniesForDomain": [
                615,
                {
                    "args": [
                        614,
                        "GetAllCompaniesForDomainInput!"
                    ]
                }
            ],
            "yearEnd": [
                1960,
                {
                    "args": [
                        1959,
                        "YearEndStateInput!"
                    ]
                }
            ],
            "yearEndDomainStateDatum": [
                1945,
                {
                    "args": [
                        1948,
                        "YearEndDomainStateDatumInput!"
                    ]
                }
            ],
            "yearEndDomainStateData": [
                1945,
                {
                    "args": [
                        1944,
                        "YearEndDomainStateDataInput!"
                    ]
                }
            ],
            "__typename": [
                1
            ]
        },
        "RangedManagementMethod": {
            "managementMethod": [
                1086
            ],
            "startDate": [
                461
            ],
            "endDate": [
                461
            ],
            "id": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "RangedManagementMethodInput": {
            "managementMethod": [
                1086
            ],
            "startDate": [
                461
            ],
            "endDate": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "ReadyValidation": {
            "id": [
                15
            ],
            "from": [
                461
            ],
            "to": [
                461
            ],
            "generatedAt": [
                461
            ],
            "generatedBy": [
                1
            ],
            "isGreen": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "RealtimeCogsStatus": {
            "isAmazonEnabled": [
                7
            ],
            "isShopifyEnabled": [
                7
            ],
            "isWalmartEnabled": [
                7
            ],
            "isTikTokEnabled": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "Recipe": {
            "id": [
                1
            ],
            "from": [
                461
            ],
            "to": [
                461
            ],
            "isAssembledOnDemand": [
                7
            ],
            "product": [
                1368
            ],
            "warehouse": [
                1871
            ],
            "assemblyCost": [
                5
            ],
            "components": [
                1435
            ],
            "managementMethod": [
                1437
            ],
            "displayName": [
                1
            ],
            "deletedReason": [
                98
            ],
            "manualAssemblyId": [
                1
            ],
            "totalAssembled": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "RecipeBreakdown": {
            "quantity": [
                5
            ],
            "wasteQuantity": [
                5
            ],
            "costPerUnit": [
                5
            ],
            "product": [
                1368
            ],
            "warehouse": [
                1871
            ],
            "__typename": [
                1
            ]
        },
        "RecipeCirclesFoundError": {
            "data": [
                1434
            ],
            "__typename": [
                1
            ]
        },
        "RecipeCirclesFoundErrorData": {
            "recipe": [
                1431
            ],
            "product": [
                1368
            ],
            "__typename": [
                1
            ]
        },
        "RecipeComponent": {
            "product": [
                1368
            ],
            "warehouse": [
                1871
            ],
            "quantity": [
                5
            ],
            "wasteQuantity": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "RecipeComponentInput": {
            "productId": [
                1
            ],
            "warehouseId": [
                1
            ],
            "quantity": [
                5
            ],
            "wasteQuantity": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "RecipeManagementMethod": {},
        "RecipeOverlappingError": {
            "recipes": [
                1431
            ],
            "__typename": [
                1
            ]
        },
        "RecipeWithBreakdown": {
            "recipe": [
                1431
            ],
            "startDate": [
                461
            ],
            "endDate": [
                461
            ],
            "warehouse": [
                1871
            ],
            "breakdown": [
                1432
            ],
            "__typename": [
                1
            ]
        },
        "RecommendationSource": {},
        "RecommendedBy": {},
        "ReconcileAllInventoryPurchasesInput": {
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "ReconcileInventoryPurchasesFromBillsInput": {
            "billItemsToReconcile": [
                234
            ],
            "__typename": [
                1
            ]
        },
        "ReconcileInventoryPurchasesFromBusinessEventsInput": {
            "businessEventItemsToReconcile": [
                263
            ],
            "__typename": [
                1
            ]
        },
        "ReconcileInventoryPurchasesFromPayrollsInput": {
            "payrollItemsToReconcile": [
                1342
            ],
            "__typename": [
                1
            ]
        },
        "ReconcileInventoryPurchasesFromTransactionsInput": {
            "transactionItemsToReconcile": [
                1675
            ],
            "__typename": [
                1
            ]
        },
        "ReconciliationRequestStatementFile": {
            "s3Bucket": [
                1
            ],
            "s3Key": [
                1
            ],
            "fileName": [
                1
            ],
            "fileType": [
                133
            ],
            "status": [
                153
            ],
            "providerValidationResults": [
                183
            ],
            "createdAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "ReconciliationSummary": {
            "totalInReport": [
                47
            ],
            "isGreen": [
                7
            ],
            "inBooks": [
                47
            ],
            "unfulfilledInBooks": [
                47
            ],
            "voided": [
                47
            ],
            "restocked": [
                47
            ],
            "excluded": [
                47
            ],
            "totalInDb": [
                47
            ],
            "fulfilled": [
                47
            ],
            "fulfilledNotSold": [
                47
            ],
            "soldNotFulfilled": [
                47
            ],
            "zeroNet": [
                47
            ],
            "zeroNetFulfilled": [
                47
            ],
            "zeroNetNotSold": [
                47
            ],
            "zeroNetNotFulfilled": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "RecordTax1099AffiliatedEntityGroupIdentifierInput": {
            "id": [
                15
            ],
            "type": [
                1665
            ],
            "isGroup": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "RecordTax1099AffiliatedEntityGroupInput": {
            "companyId": [
                15
            ],
            "identifiers": [
                1449
            ],
            "calendarYear": [
                47
            ],
            "isDone": [
                7
            ],
            "disqualifyReason": [
                1660
            ],
            "resetDisqualifyReason": [
                7
            ],
            "file": [
                1663
            ],
            "resetFile": [
                7
            ],
            "finaloopComment": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "RecurrenceSettings": {
            "recurrence": [
                1035
            ],
            "totalRecurrences": [
                47
            ],
            "cutOffDate": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "RecurrenceSettingsInput": {
            "recurrence": [
                1035
            ],
            "totalRecurrences": [
                47
            ],
            "cutOffDate": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "RecurringPayment": {
            "yearly": [
                1273
            ],
            "monthly": [
                1273
            ],
            "yearlyDiscount": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ReferUserArgs": {
            "email": [
                1
            ],
            "companyId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "referUserResponse": {
            "success": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "RefreshSkuAmbiguityInput": {
            "companyId": [
                15
            ],
            "productId": [
                1
            ],
            "skus": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Refund": {
            "companyId": [
                15
            ],
            "id": [
                15
            ],
            "source": [
                876
            ],
            "integrationAccountId": [
                15
            ],
            "integrationAccountName": [
                1
            ],
            "amount": [
                5
            ],
            "currency": [
                1
            ],
            "postedAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "Reimbursement": {
            "id": [
                1
            ],
            "source": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "postedAt": [
                461
            ],
            "amount": [
                5
            ],
            "isClearedByNetPayPayment": [
                7
            ],
            "employee": [
                534
            ],
            "contractor": [
                386
            ],
            "contractorPayment": [
                387
            ],
            "payroll": [
                1323
            ],
            "deletedAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "RelatedParty": {
            "id": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "RemoveDataRestrictionInput": {
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "RemoveUserArgs": {
            "email": [
                1
            ],
            "companyId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "RemoveUserResponse": {
            "success": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "ReplaceBankAccountLocksByTypeInput": {
            "companyId": [
                15
            ],
            "bankAccountId": [
                15
            ],
            "locks": [
                123
            ],
            "lockType": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ReplaceBankAccountLocksInput": {
            "companyId": [
                15
            ],
            "bankAccountId": [
                15
            ],
            "locks": [
                124
            ],
            "__typename": [
                1
            ]
        },
        "Report": {
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ReportedBusinessBankTransactionsAccountInput": {
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "ReportHierarchyNode": {
            "name": [
                1
            ],
            "reportType": [
                1470
            ],
            "breakdownType": [
                248
            ],
            "accountRole": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "ReportsPartner": {
            "id": [
                15
            ],
            "name": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ReportSyncStatus": {},
        "ReportType": {},
        "ReReconcileInventoryPurchasesInput": {
            "purchasesIds": [
                1
            ],
            "businessEventIds": [
                1
            ],
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "RestockingAction": {
            "quantity": [
                47
            ],
            "id": [
                1
            ],
            "date": [
                461
            ],
            "type": [
                1
            ],
            "salesChannel": [
                1873
            ],
            "fulfillmentDate": [
                461
            ],
            "sku": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Role": {},
        "RootConditionOperator": {},
        "Sale": {
            "id": [
                15
            ],
            "saleType": [
                1
            ],
            "companyId": [
                15
            ],
            "source": [
                1512
            ],
            "externalSourceUrl": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "integrationAccountId": [
                15
            ],
            "integrationAccountName": [
                1
            ],
            "currency": [
                440
            ],
            "displayName": [
                1
            ],
            "statuses": [
                1513
            ],
            "postedAt": [
                461
            ],
            "createdAt": [
                461
            ],
            "updatedAt": [
                461
            ],
            "deletedAt": [
                461
            ],
            "publishedAt": [
                461
            ],
            "lineItems": [
                1480
            ],
            "shipping": [
                1580
            ],
            "discounts": [
                518
            ],
            "platformDiscounts": [
                518
            ],
            "taxes": [
                1658
            ],
            "taxFixedAmount": [
                5
            ],
            "adjustments": [
                1477
            ],
            "payments": [
                1515
            ],
            "credits": [
                1515
            ],
            "refunds": [
                1515
            ],
            "paymentIndications": [
                1515
            ],
            "refundIndications": [
                1515
            ],
            "customer": [
                450
            ],
            "customerId": [
                15
            ],
            "shippingAddress": [
                444
            ],
            "billingAddress": [
                444
            ],
            "integrationTags": [
                1
            ],
            "integrationNote": [
                1
            ],
            "finaloopNote": [
                1
            ],
            "customerNote": [
                1
            ],
            "isSalesAffecting": [
                7
            ],
            "isCogsAffecting": [
                7
            ],
            "voidedAt": [
                461
            ],
            "voidedBy": [
                1476
            ],
            "summary": [
                1514
            ],
            "__typename": [
                1
            ]
        },
        "SaleActor": {},
        "SaleAdjustment": {
            "id": [
                15
            ],
            "amount": [
                5
            ],
            "currency": [
                440
            ],
            "amountInHomeCurrency": [
                5
            ],
            "type": [
                49
            ],
            "postedAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "SaleInput": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "balanceAsOfDate": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "SaleInternalInput": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "SaleLineItem": {
            "id": [
                15
            ],
            "quantity": [
                5
            ],
            "unitPrice": [
                5
            ],
            "currency": [
                440
            ],
            "type": [
                1483
            ],
            "integrationInfo": [
                856
            ],
            "product": [
                1368
            ],
            "sku": [
                1
            ],
            "description": [
                1
            ],
            "discounts": [
                518
            ],
            "shipping": [
                1580
            ],
            "taxes": [
                1658
            ],
            "subtotal": [
                5
            ],
            "totalAmount": [
                5
            ],
            "salesChannel": [
                1497
            ],
            "salesChannelId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "SaleLineItemInput": {
            "id": [
                15
            ],
            "quantity": [
                5
            ],
            "unitPrice": [
                5
            ],
            "type": [
                1483
            ],
            "currency": [
                440
            ],
            "salesChannelName": [
                1
            ],
            "productId": [
                15
            ],
            "sku": [
                1
            ],
            "description": [
                1
            ],
            "discounts": [
                519
            ],
            "taxRate": [
                5
            ],
            "externalSourceId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SaleLineItemTaxInfo": {
            "taxableAmount": [
                5
            ],
            "taxRate": [
                5
            ],
            "totalTaxAmount": [
                5
            ],
            "isTaxInclusive": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "SaleLineItemType": {},
        "SaleOrder": {
            "id": [
                15
            ],
            "saleType": [
                1
            ],
            "companyId": [
                15
            ],
            "source": [
                1512
            ],
            "externalSourceUrl": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "integrationAccountId": [
                15
            ],
            "integrationAccountName": [
                1
            ],
            "currency": [
                440
            ],
            "displayName": [
                1
            ],
            "statuses": [
                1513
            ],
            "postedAt": [
                461
            ],
            "createdAt": [
                461
            ],
            "updatedAt": [
                461
            ],
            "deletedAt": [
                461
            ],
            "publishedAt": [
                461
            ],
            "lineItems": [
                1480
            ],
            "shipping": [
                1580
            ],
            "discounts": [
                518
            ],
            "platformDiscounts": [
                518
            ],
            "taxes": [
                1658
            ],
            "taxFixedAmount": [
                5
            ],
            "adjustments": [
                1477
            ],
            "payments": [
                1515
            ],
            "credits": [
                1515
            ],
            "refunds": [
                1515
            ],
            "paymentIndications": [
                1515
            ],
            "refundIndications": [
                1515
            ],
            "customer": [
                450
            ],
            "customerId": [
                15
            ],
            "shippingAddress": [
                444
            ],
            "billingAddress": [
                444
            ],
            "integrationTags": [
                1
            ],
            "integrationNote": [
                1
            ],
            "finaloopNote": [
                1
            ],
            "customerNote": [
                1
            ],
            "accounting": [
                6
            ],
            "isSalesAffecting": [
                7
            ],
            "isCogsAffecting": [
                7
            ],
            "voidedAt": [
                461
            ],
            "voidedBy": [
                1476
            ],
            "summary": [
                1514
            ],
            "__typename": [
                1
            ]
        },
        "SalesAdjustmentOriginalValue": {
            "unitsSold": [
                47
            ],
            "unitsRestocked": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "SalesAdjustmentsBreakdownItem": {
            "salesChannel": [
                1495
            ],
            "count": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "SalesBasedClosingBalance": {
            "missionType": [
                950
            ],
            "status": [
                949
            ],
            "historyLog": [
                946
            ],
            "managementMethodPeriod": [
                1087
            ],
            "closingBalance": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "SalesBasedClosingBalanceInput": {
            "closingBalance": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "SalesBasedMissingCostPerUnit": {
            "missionType": [
                950
            ],
            "status": [
                949
            ],
            "historyLog": [
                946
            ],
            "managementMethodPeriod": [
                1087
            ],
            "missingCostPerUnitProducts": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "SalesBasedMissingCostPerUnitInput": {
            "missingCostPerUnitProducts": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "SalesBasedPurchases": {
            "missionType": [
                950
            ],
            "status": [
                949
            ],
            "historyLog": [
                946
            ],
            "managementMethodPeriod": [
                1087
            ],
            "purchases": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "SalesBasedPurchasesInput": {
            "purchases": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "SalesBasedReportCogs": {
            "missionType": [
                950
            ],
            "status": [
                949
            ],
            "historyLog": [
                946
            ],
            "managementMethodPeriod": [
                1087
            ],
            "__typename": [
                1
            ]
        },
        "SalesByTypeAndDateInput": {
            "saleType": [
                1516
            ],
            "companyId": [
                15
            ],
            "from": [
                461
            ],
            "to": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannel": {
            "accountRole": [
                1
            ],
            "integrationType": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "currency": [
                1
            ],
            "displayName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannelDailySalesData": {
            "salesChannel": [
                1495
            ],
            "salesData": [
                1503
            ],
            "soldSkusSummary": [
                1507
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannelInfo": {
            "id": [
                15
            ],
            "name": [
                1
            ],
            "source": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "isCogsAffecting": [
                7
            ],
            "isSalesAffecting": [
                7
            ],
            "financialReportingStatus": [
                586
            ],
            "periods": [
                1500
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannelInput": {
            "accountRole": [
                1
            ],
            "integrationType": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "currency": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannelMonthlyBreakdown": {
            "date": [
                461
            ],
            "summary": [
                1508
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannelPeriod": {
            "startDate": [
                461
            ],
            "endDate": [
                461
            ],
            "financialReportingStatus": [
                586
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannelPeriodInput": {
            "startDate": [
                461
            ],
            "endDate": [
                461
            ],
            "financialReportingStatus": [
                586
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannelSalesCogs": {
            "hasAdjustments": [
                7
            ],
            "salesChannel": [
                1495
            ],
            "summary": [
                1508
            ],
            "monthlyBreakdown": [
                1499
            ],
            "amount": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannelSalesData": {
            "date": [
                461
            ],
            "unitsSold": [
                47
            ],
            "unitsRestocked": [
                47
            ],
            "unitsGiveaway": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannelsByCompanyAndIntegrationInput": {
            "companyId": [
                15
            ],
            "integrationAccountId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannelsByCompanyAndIntegrationResponse": {
            "id": [
                15
            ],
            "name": [
                1
            ],
            "isSalesAffecting": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannelsInput": {
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannelSkuSalesSummary": {
            "sku": [
                1
            ],
            "unitsSold": [
                47
            ],
            "unitsRestocked": [
                47
            ],
            "unitsGiveaway": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannelSkuSummary": {
            "sku": [
                1
            ],
            "fulfilled": [
                47
            ],
            "restocked": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannelTemplate": {
            "id": [
                15
            ],
            "name": [
                1
            ],
            "source": [
                1
            ],
            "isCogsAffecting": [
                7
            ],
            "isSalesAffecting": [
                7
            ],
            "financialReportingStatus": [
                586
            ],
            "__typename": [
                1
            ]
        },
        "SalesData": {
            "recipes": [
                1431
            ],
            "assembledByRecipeMap": [
                97
            ],
            "salesChannels": [
                1502
            ],
            "deadInventory": [
                464
            ],
            "giveaway": [
                786
            ],
            "donation": [
                523
            ],
            "waste": [
                1935
            ],
            "warehouseSalesData": [
                1895
            ],
            "salesAdjustments": [
                1399
            ],
            "deadInventoryAdjustments": [
                1388
            ],
            "giveawayAdjustments": [
                1392
            ],
            "donationAdjustments": [
                1390
            ],
            "appliedCosts": [
                1372
            ],
            "__typename": [
                1
            ]
        },
        "SalesInternalInput": {
            "ids": [
                15
            ],
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "SaleSource": {},
        "SaleStatus": {},
        "SaleTotals": {
            "subtotal": [
                79
            ],
            "subtotalAfterItemDiscounts": [
                79
            ],
            "subtotalAfterDiscounts": [
                79
            ],
            "totalBeforeTaxes": [
                79
            ],
            "total": [
                79
            ],
            "tipsSubtotal": [
                79
            ],
            "shippingSubtotal": [
                79
            ],
            "shippingDiscounts": [
                79
            ],
            "shipping": [
                79
            ],
            "payments": [
                79
            ],
            "refunds": [
                79
            ],
            "credits": [
                79
            ],
            "adjustments": [
                79
            ],
            "taxes": [
                79
            ],
            "globalDiscounts": [
                79
            ],
            "globalPlatformDiscounts": [
                79
            ],
            "itemDiscounts": [
                79
            ],
            "totalDiscounts": [
                79
            ],
            "unpaidBalance": [
                79
            ],
            "balanceAsOf": [
                461
            ],
            "balanceAsOfAmount": [
                79
            ],
            "latestFinancialTimestamp": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "SaleTransaction": {
            "id": [
                15
            ],
            "integrationInfo": [
                856
            ],
            "amount": [
                5
            ],
            "postedAt": [
                461
            ],
            "currency": [
                440
            ],
            "amountInHomeCurrency": [
                5
            ],
            "type": [
                1680
            ],
            "finaloopPaymentGateway": [
                1
            ],
            "originalPaymentGateway": [
                1
            ],
            "paymentGatewayVariant": [
                1
            ],
            "isIndication": [
                7
            ],
            "on_BankTxSaleTransaction": [
                219
            ],
            "on_IntegrationSaleTransaction": [
                858
            ],
            "on_PaymentGroupTransaction": [
                1311
            ],
            "__typename": [
                1
            ]
        },
        "SaleType": {},
        "ScheduleAccountingReportExportInput": {
            "companyId": [
                15
            ],
            "reportType": [
                1470
            ],
            "isEnabled": [
                7
            ],
            "byMonth": [
                7
            ],
            "fractions": [
                7
            ],
            "withMerchantsBreakdown": [
                7
            ],
            "fromDate": [
                461
            ],
            "toDate": [
                461
            ],
            "includeCCAccounts": [
                7
            ],
            "includeUDFAccounts": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "ScheduleAccountingReportExportResponse": {
            "reportType": [
                1470
            ],
            "isEnabled": [
                7
            ],
            "byMonth": [
                7
            ],
            "fractions": [
                7
            ],
            "withMerchantsBreakdown": [
                7
            ],
            "fromDate": [
                461
            ],
            "toDate": [
                461
            ],
            "syncMostUpdatedReport": [
                7
            ],
            "includeCCAccounts": [
                7
            ],
            "includeUDFAccounts": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "ScheduleAtuIntegrationTransfersResultProcessingInput": {
            "companyId": [
                15
            ],
            "integrationTransferIds": [
                15
            ],
            "askTheUserResult": [
                989
            ],
            "__typename": [
                1
            ]
        },
        "ScheduleAtuIntegrationTransfersResultProcessingResult": {
            "success": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "ScheduleAtuTransactionsResultProcessingInput": {
            "companyId": [
                15
            ],
            "transactionIds": [
                15
            ],
            "askTheUserResult": [
                989
            ],
            "__typename": [
                1
            ]
        },
        "ScheduleAtuTransactionsResultProcessingResult": {
            "success": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "SchedulePurchasesValidationInput": {
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "SchedulePurchasesValidationResponse": {
            "validationId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SearchCustomersInput": {
            "companyId": [
                15
            ],
            "searchTerm": [
                1
            ],
            "source": [
                449
            ],
            "limit": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "SearchMerchantsInput": {
            "companyId": [
                15
            ],
            "searchTerm": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SearchVendorsTransactionPriorityInput": {
            "companyId": [
                15
            ],
            "searchTerm": [
                1
            ],
            "merchantType": [
                1124
            ],
            "subType": [
                1865
            ],
            "__typename": [
                1
            ]
        },
        "SearchVendorsTransactionsPriorityResult": {
            "vendors": [
                1853
            ],
            "counts": [
                1864
            ],
            "__typename": [
                1
            ]
        },
        "Section179Deduction": {
            "date": [
                461
            ],
            "amount": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "Section179DeductionInput": {
            "date": [
                1
            ],
            "amount": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "SellingFee": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "source": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "sale": [
                1475
            ],
            "order": [
                1260
            ],
            "type": [
                1532
            ],
            "externalId": [
                1
            ],
            "externalName": [
                1
            ],
            "amount": [
                5
            ],
            "currency": [
                440
            ],
            "amountInHomeCurrency": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "SellingFeeType": {},
        "SendPoEmailError": {
            "message": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SendPoEmailInput": {
            "companyId": [
                1
            ],
            "id": [
                1
            ],
            "to": [
                1
            ],
            "from": [
                1
            ],
            "cc": [
                1
            ],
            "bcc": [
                1
            ],
            "subject": [
                1
            ],
            "message": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SendPoEmailResponse": {
            "success": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "SendPoEmailResult": {
            "on_SendPoEmailResponse": [
                1535
            ],
            "on_SendPoEmailError": [
                1533
            ],
            "__typename": [
                1
            ]
        },
        "SendValidationNotificationInput": {
            "caption": [
                1
            ],
            "validationCycleId": [
                1
            ],
            "validationTemplateId": [
                1
            ],
            "slackChannel": [
                1
            ],
            "type": [
                370
            ],
            "__typename": [
                1
            ]
        },
        "SetBankAccountsLatestEventIdsInput": {
            "bankAccountEventIds": [
                119
            ],
            "__typename": [
                1
            ]
        },
        "SetFiscalYearDataInput": {
            "companyId": [
                15
            ],
            "month": [
                47
            ],
            "day": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "SetFiscalYearStartInput": {
            "companyId": [
                15
            ],
            "month": [
                47
            ],
            "day": [
                47
            ],
            "userEmail": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SetFulfillmentSettingsInput": {
            "companyId": [
                15
            ],
            "settings": [
                854
            ],
            "__typename": [
                1
            ]
        },
        "SetFulfillmentSettingsResponse": {
            "settings": [
                853
            ],
            "__typename": [
                1
            ]
        },
        "SetInventoryUsagesInput": {
            "companyId": [
                15
            ],
            "usageTypes": [
                921
            ],
            "__typename": [
                1
            ]
        },
        "SetOrderFinaloopNoteInput": {
            "companyId": [
                15
            ],
            "orderId": [
                15
            ],
            "finaloopNote": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SetOrderFinaloopTagsInput": {
            "companyId": [
                15
            ],
            "orderId": [
                15
            ],
            "finaloopTags": [
                585
            ],
            "__typename": [
                1
            ]
        },
        "SetPendingSalesDataAdjustmentsInput": {
            "companyId": [
                15
            ],
            "salesChannel": [
                1498
            ],
            "managementMethodId": [
                15
            ],
            "items": [
                1547
            ],
            "__typename": [
                1
            ]
        },
        "SetPendingSalesDataAdjustmentsItemInput": {
            "productId": [
                1
            ],
            "pendingId": [
                1
            ],
            "date": [
                461
            ],
            "unitsSold": [
                47
            ],
            "unitsRestocked": [
                47
            ],
            "warehouseId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SetPendingSalesDataAdjustmentsResponse": {
            "items": [
                1349
            ],
            "__typename": [
                1
            ]
        },
        "SetPendingSalesDataAdjustmentsWithCsvInput": {
            "companyId": [
                15
            ],
            "url": [
                1
            ],
            "filename": [
                1
            ],
            "managementMethodId": [
                15
            ],
            "salesChannel": [
                1498
            ],
            "__typename": [
                1
            ]
        },
        "SetPendingWarehouseOpeningBalancesInput": {
            "companyId": [
                15
            ],
            "date": [
                461
            ],
            "items": [
                1551
            ],
            "__typename": [
                1
            ]
        },
        "SetPendingWarehouseOpeningBalancesItemInput": {
            "productId": [
                1
            ],
            "warehouseId": [
                1
            ],
            "sourceId": [
                1
            ],
            "pendingId": [
                1
            ],
            "quantity": [
                47
            ],
            "costPerUnit": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "SetPendingWarehouseOpeningBalancesWithCsvInput": {
            "companyId": [
                15
            ],
            "date": [
                461
            ],
            "url": [
                1
            ],
            "filename": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SetPhysicalCountPendingAdjustmentsWithCsvInput": {
            "companyId": [
                15
            ],
            "physicalCountId": [
                15
            ],
            "url": [
                1
            ],
            "filename": [
                1
            ],
            "managementMethodId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "SetProductAdjustmentOperation": {},
        "SetProductAdjustmentsInput": {
            "companyId": [
                15
            ],
            "finaloopProductId": [
                1
            ],
            "salesDataAdjustments": [
                1561
            ],
            "deadInventoryAdjustments": [
                1558
            ],
            "giveawayAdjustments": [
                1560
            ],
            "donationAdjustments": [
                1559
            ],
            "appliedCosts": [
                1557
            ],
            "__typename": [
                1
            ]
        },
        "SetProductAdjustmentsResponse": {
            "appliedCosts": [
                1370
            ],
            "newDeadInventoryAdjustments": [
                1387
            ],
            "__typename": [
                1
            ]
        },
        "SetProductAppliedCostInput": {
            "operation": [
                1554
            ],
            "asOfDate": [
                461
            ],
            "appliedCost": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "SetProductDeadInventoryAdjustmentInput": {
            "id": [
                1
            ],
            "operation": [
                1554
            ],
            "finaloopProductId": [
                1
            ],
            "date": [
                461
            ],
            "unitsDeadInventory": [
                5
            ],
            "warehouseId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SetProductDonationAdjustmentInput": {
            "operation": [
                1554
            ],
            "finaloopProductId": [
                1
            ],
            "date": [
                461
            ],
            "unitsDonated": [
                47
            ],
            "warehouseId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SetProductGiveawayAdjustmentInput": {
            "operation": [
                1554
            ],
            "finaloopProductId": [
                1
            ],
            "date": [
                461
            ],
            "unitsGiveaway": [
                47
            ],
            "warehouseId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SetProductSalesDataAdjustmentInput": {
            "operation": [
                1554
            ],
            "salesChannel": [
                1498
            ],
            "date": [
                461
            ],
            "unitsSold": [
                47
            ],
            "unitsRestocked": [
                47
            ],
            "warehouseId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SetPurchaseOrderBusinessEventInput": {
            "companyId": [
                15
            ],
            "purchaseOrderId": [
                1
            ],
            "publishedEventIds": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SetSettingsArgs": {
            "companyId": [
                1
            ],
            "rangedManagementMethod": [
                1428
            ],
            "__typename": [
                1
            ]
        },
        "SetUserGivenWarehouseNameInput": {
            "companyId": [
                15
            ],
            "warehouseId": [
                15
            ],
            "name": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SetUserName": {
            "email": [
                1
            ],
            "name": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SetUserNameResponse": {
            "success": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "SetWarehouseOpeningBalanceInput": {
            "warehouseId": [
                1
            ],
            "companyId": [
                15
            ],
            "productId": [
                1
            ],
            "quantity": [
                47
            ],
            "costPerUnit": [
                5
            ],
            "date": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "ShareType": {},
        "Shipment": {
            "id": [
                15
            ],
            "shipmentDate": [
                461
            ],
            "arrival": [
                1571
            ],
            "contents": [
                1575
            ],
            "displayName": [
                1
            ],
            "vendor": [
                1853
            ],
            "notes": [
                1
            ],
            "attachments": [
                1573
            ],
            "purchaseOrder": [
                1416
            ],
            "cost": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ShipmentAction": {
            "quantity": [
                47
            ],
            "id": [
                1
            ],
            "date": [
                461
            ],
            "type": [
                1
            ],
            "purchaseOrder": [
                1416
            ],
            "directCost": [
                5
            ],
            "indirectCosts": [
                1146
            ],
            "__typename": [
                1
            ]
        },
        "ShipmentArrival": {
            "arrivalDate": [
                461
            ],
            "warehouse": [
                1871
            ],
            "__typename": [
                1
            ]
        },
        "ShipmentArrivalInput": {
            "arrivalDate": [
                461
            ],
            "warehouseId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "ShipmentAttachment": {
            "id": [
                15
            ],
            "filename": [
                1
            ],
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ShipmentAttachmentInput": {
            "filename": [
                1
            ],
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ShipmentContents": {
            "id": [
                15
            ],
            "lineItem": [
                511
            ],
            "quantity": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "ShipmentContentsInput": {
            "lineItemId": [
                15
            ],
            "quantity": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "ShipmentInput": {
            "id": [
                15
            ],
            "shipmentDate": [
                461
            ],
            "arrival": [
                1572
            ],
            "contents": [
                1576
            ],
            "displayName": [
                1
            ],
            "vendorId": [
                15
            ],
            "notes": [
                1
            ],
            "attachments": [
                1574
            ],
            "__typename": [
                1
            ]
        },
        "ShippingCost": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "source": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "sale": [
                1475
            ],
            "order": [
                1260
            ],
            "type": [
                1579
            ],
            "externalId": [
                1
            ],
            "externalName": [
                1
            ],
            "amount": [
                5
            ],
            "currency": [
                440
            ],
            "amountInHomeCurrency": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ShippingCostType": {},
        "ShippingLineItem": {
            "id": [
                15
            ],
            "quantity": [
                5
            ],
            "unitPrice": [
                5
            ],
            "currency": [
                440
            ],
            "type": [
                1483
            ],
            "description": [
                1
            ],
            "discounts": [
                518
            ],
            "taxes": [
                1658
            ],
            "subtotal": [
                5
            ],
            "totalAmount": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyCsvBalance": {
            "companyId": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "fromDate": [
                461
            ],
            "toDate": [
                461
            ],
            "balances": [
                1603
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyItemsReconciliationInput": {
            "startTime": [
                461
            ],
            "endTime": [
                461
            ],
            "integrationAccountId": [
                1
            ],
            "companyId": [
                15
            ],
            "isRegenerateFromScratch": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyItemsReconciliationOrderData": {
            "orderId": [
                1
            ],
            "url": [
                1
            ],
            "shopifySalesChannel": [
                1
            ],
            "salesChannel": [
                1
            ],
            "quantity": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyItemsReconciliationOrdersDiffInput": {
            "companyId": [
                15
            ],
            "startTime": [
                461
            ],
            "endTime": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyItemsReconciliationOrdersDiffResult": {
            "ordersData": [
                1586
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyItemsReconciliationOrdersDiffResultData": {
            "orderId": [
                15
            ],
            "reportQuantity": [
                47
            ],
            "entitiesQuantity": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyItemsReconciliationOrdersInput": {
            "startTime": [
                461
            ],
            "endTime": [
                461
            ],
            "integrationAccountId": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "companyId": [
                15
            ],
            "sku": [
                1
            ],
            "itemType": [
                1604
            ],
            "salesChannel": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyItemsReconciliationOrdersResponse": {
            "ordersData": [
                1583
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyItemsValidationBlacklistSalesChannelInput": {
            "companyId": [
                15
            ],
            "integrationAccountId": [
                1
            ],
            "salesChannel": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyItemsValidationBlacklistSkuInput": {
            "companyId": [
                15
            ],
            "integrationAccountId": [
                1
            ],
            "sku": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyOrderSourceData": {
            "financialStatus": [
                1
            ],
            "fulfillmentStatus": [
                1
            ],
            "gqlId": [
                1
            ],
            "orderValidationData": [
                1592
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyOrderValidationData": {
            "sales": [
                1594
            ],
            "refunds": [
                1593
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyOrderValidationRefund": {
            "id": [
                1
            ],
            "refundAmount": [
                5
            ],
            "giftCardAmount": [
                5
            ],
            "giftCardDiscountAmount": [
                5
            ],
            "shippingAmount": [
                5
            ],
            "taxAmount": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyOrderValidationSale": {
            "id": [
                1
            ],
            "saleAmount": [
                5
            ],
            "giftCardAmount": [
                5
            ],
            "giftCardDiscountAmount": [
                5
            ],
            "taxAmount": [
                5
            ],
            "dutyAmount": [
                5
            ],
            "discountAmount": [
                5
            ],
            "shippingAmount": [
                5
            ],
            "tipAmount": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyProduct": {
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "finaloopId": [
                1
            ],
            "lineOfBusinessId": [
                1
            ],
            "sku": [
                1
            ],
            "price": [
                5
            ],
            "displayName": [
                1
            ],
            "imageUrl": [
                1
            ],
            "listedChannels": [
                1495
            ],
            "appliedCosts": [
                1370
            ],
            "dailySalesData": [
                1496,
                {
                    "timeRange": [
                        1386,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "totalSold": [
                47
            ],
            "avgCostPerUnit": [
                5
            ],
            "productUrl": [
                1
            ],
            "productVariantId": [
                1
            ],
            "canEditLatestAppliedCost": [
                7
            ],
            "isFractional": [
                7
            ],
            "isHidden": [
                7
            ],
            "salesData": [
                1510,
                {
                    "timeRange": [
                        1386,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "rawRecipes": [
                1431,
                {
                    "timeRange": [
                        1386,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "componentOfProducts": [
                1368
            ],
            "isAmbiguousSku": [
                7
            ],
            "analyticsOverview": [
                1369,
                {
                    "input": [
                        80
                    ]
                }
            ],
            "warehousesAnalyticsOverview": [
                1408,
                {
                    "input": [
                        80
                    ]
                }
            ],
            "tags": [
                1
            ],
            "isCreatedManually": [
                7
            ],
            "isDataSyncing": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyProductsValidationData": {
            "integrationAccountId": [
                1
            ],
            "data": [
                1601
            ],
            "reportEndTime": [
                461
            ],
            "reportPath": [
                1
            ],
            "summary": [
                1448
            ],
            "productItemSoldData": [
                1602
            ],
            "productStatus": [
                1598
            ],
            "unknownSkuQuantity": [
                47
            ],
            "unknownSkuZeroPriceQuantity": [
                47
            ],
            "isNotArtificiallyFulfilled": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyProductsValidationResponse": {
            "validationId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyReconciliationProductsStatus": {
            "unitsSoldDiff": [
                47
            ],
            "unitsRestockedDiff": [
                47
            ],
            "unitsGiveawayDiff": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyReportDateLimitsInput": {
            "integrationAccountId": [
                15
            ],
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyReportDateLimitsResponse": {
            "dateRange": [
                462
            ],
            "failureType": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ShopifySkuReconciliationData": {
            "sku": [
                1
            ],
            "isBlacklisted": [
                7
            ],
            "excludedSalesChannelsData": [
                544
            ],
            "includedSalesChannelsData": [
                825
            ],
            "summary": [
                1448
            ],
            "productItemSoldData": [
                1602
            ],
            "productStatus": [
                1598
            ],
            "unknownSkuQuantity": [
                47
            ],
            "unknownSkuZeroPriceQuantity": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "ShopifySkuReconciliationProductItemSoldData": {
            "unitsSold": [
                47
            ],
            "unitsSoldWithZeroPrice": [
                47
            ],
            "unitsRestocked": [
                47
            ],
            "unitsGiveaway": [
                47
            ],
            "ambiguousSkuQuantity": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyTimezoneBalance": {
            "timezone": [
                1
            ],
            "grossAmount": [
                5
            ],
            "netAmount": [
                5
            ],
            "taxAmount": [
                5
            ],
            "discountAmount": [
                5
            ],
            "shippingAmount": [
                5
            ],
            "refundAmount": [
                5
            ],
            "totalAmount": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyValidationItemType": {},
        "SignAndLockBankAccountInput": {
            "companyId": [
                1
            ],
            "bankAccountId": [
                1
            ],
            "signedBy": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SignedDownloadUrlInput": {
            "companyId": [
                1
            ],
            "s3Url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SignWorksheetRowInputV2": {
            "companyId": [
                1
            ],
            "name": [
                1
            ],
            "amount": [
                5
            ],
            "comment": [
                1
            ],
            "periodDates": [
                1357
            ],
            "__typename": [
                1
            ]
        },
        "SimpleMoneyDirection": {},
        "SimulatedLoanFailure": {
            "reason": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SimulatedLoanIrrelevantEvent": {
            "event": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SimulatedLoanSummary": {
            "on_SimulatedLoanIrrelevantEvent": [
                1610
            ],
            "on_SimulatedLoanUpdate": [
                1612
            ],
            "on_SimulatedLoanFailure": [
                1609
            ],
            "__typename": [
                1
            ]
        },
        "SimulatedLoanUpdate": {
            "createdLoan": [
                1613
            ],
            "deletedLoan": [
                1614
            ],
            "__typename": [
                1
            ]
        },
        "SimulatedLoanUpdateCreate": {
            "type": [
                1061
            ],
            "isShortTerm": [
                7
            ],
            "date": [
                461
            ],
            "amount": [
                5
            ],
            "lender": [
                1150
            ],
            "__typename": [
                1
            ]
        },
        "SimulatedLoanUpdateDelete": {
            "loanId": [
                1
            ],
            "type": [
                1061
            ],
            "isShortTerm": [
                7
            ],
            "date": [
                461
            ],
            "amount": [
                5
            ],
            "lender": [
                1150
            ],
            "__typename": [
                1
            ]
        },
        "SimulateTransactionUpdatedClassification": {
            "businessEvent": [
                1
            ],
            "amount": [
                5
            ],
            "pairedEntityId": [
                1
            ],
            "pairedEntityType": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SimulateTransactionUpdatedInput": {
            "companyId": [
                15
            ],
            "transactionId": [
                1
            ],
            "businessEvent": [
                1
            ],
            "merchantId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SimulateTransactionUpdatedInput2": {
            "companyId": [
                15
            ],
            "transactionId": [
                1
            ],
            "classifications": [
                1615
            ],
            "merchantId": [
                1
            ],
            "merchantType": [
                1041
            ],
            "__typename": [
                1
            ]
        },
        "SkuValidationData": {
            "sku": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "unitsSold": [
                47
            ],
            "unitsRestocked": [
                47
            ],
            "unitsGiveaway": [
                47
            ],
            "unitsSoldWithZeroPrice": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "SortByMerchants": {},
        "SortDirection": {},
        "SpecificDate": {
            "date": [
                461
            ],
            "amount": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "SpecificDateInput": {
            "date": [
                1
            ],
            "amount": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "SplitProductsInput": {
            "companyId": [
                1
            ],
            "finaloopProductIds": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SplitProductsV2": {
            "productIds": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SplitTax1099AffiliatedEntityGroupsInput": {
            "companyId": [
                15
            ],
            "groupIds": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "SplitWarehouseInput": {
            "companyId": [
                15
            ],
            "representativeId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SpreadOverTime": {
            "fromDate": [
                461
            ],
            "toDate": [
                461
            ],
            "amount": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "SpreadOverTimeInput": {
            "fromDate": [
                1
            ],
            "toDate": [
                1
            ],
            "amount": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "SpreadType": {},
        "StatementFile": {
            "s3Bucket": [
                1
            ],
            "s3Key": [
                1
            ],
            "fileName": [
                1
            ],
            "fileType": [
                133
            ],
            "__typename": [
                1
            ]
        },
        "StopImportingDataForBankAccountInput": {
            "bankAccountId": [
                15
            ],
            "companyId": [
                15
            ],
            "stopImportFromDate": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "StopImportingDataForBankAccountNewInput": {
            "bankAccountId": [
                15
            ],
            "companyId": [
                15
            ],
            "stopImportFromDate": [
                1
            ],
            "newOwner": [
                126
            ],
            "__typename": [
                1
            ]
        },
        "Store": {
            "id": [
                15
            ],
            "name": [
                1
            ],
            "homeCurrency": [
                440
            ],
            "__typename": [
                1
            ]
        },
        "StoreDateForLockingInputV2": {
            "companyId": [
                1
            ],
            "name": [
                1
            ],
            "dateForLocking": [
                461
            ],
            "periodDates": [
                1357
            ],
            "__typename": [
                1
            ]
        },
        "StoreOrderLineItemType": {},
        "StoreOrderTransactionMatchType": {},
        "StorePaymentV2": {
            "id": [
                15
            ],
            "source": [
                876
            ],
            "externalSourceId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "StoreValidationInput": {
            "validationTemplateId": [
                1
            ],
            "validationId": [
                1
            ],
            "validationCycleId": [
                1
            ],
            "result": [
                1639
            ],
            "__typename": [
                1
            ]
        },
        "StoreValidationResultDataInput": {
            "companyId": [
                1
            ],
            "domain": [
                370
            ],
            "type": [
                374
            ],
            "integrationAccountId": [
                1
            ],
            "account": [
                1
            ],
            "accountDisplayName": [
                1
            ],
            "isError": [
                7
            ],
            "metrics": [
                1640
            ],
            "dataUrl": [
                1
            ],
            "from": [
                461
            ],
            "to": [
                461
            ],
            "generatedBy": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "StoreValidationResultDataMeticItemInput": {
            "metricName": [
                371
            ],
            "value": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "StripeCustomerIdsByCompanyIdsInput": {
            "companyIds": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "StripeCustomerIdsResponse": {
            "companyAndStripeIds": [
                347
            ],
            "__typename": [
                1
            ]
        },
        "SubmitTransactionsFileToBankTransactionsUploadInput": {
            "companyId": [
                15
            ],
            "bankTransactionsUploadId": [
                1
            ],
            "bucket": [
                1
            ],
            "key": [
                1
            ],
            "processorId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SuccessResponse": {
            "success": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "SuccessResult": {
            "success": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "SuggestBankTransactionClassificationInput": {
            "companyId": [
                15
            ],
            "bankTransactionId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "SuggestBankTransactionClassificationResult": {
            "isSuccessful": [
                7
            ],
            "tag": [
                296
            ],
            "reason": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SuspectedBankAccountDuplication": {
            "id": [
                15
            ],
            "companyId": [
                1
            ],
            "bankAccountId1": [
                1
            ],
            "bankAccountId2": [
                1
            ],
            "liveConnectionBankAccountId": [
                1
            ],
            "readyForMergingAt": [
                461
            ],
            "hubspotNotificationSentAt": [
                461
            ],
            "duplicateCheckStartedAt": [
                461
            ],
            "duplicateCheckCompletedAt": [
                461
            ],
            "duplicateCheckResult": [
                530
            ],
            "mergeStartedAt": [
                461
            ],
            "mergeCompletedAt": [
                461
            ],
            "mergeResult": [
                1133
            ],
            "fileLocationBucket": [
                1
            ],
            "fileLocationKey": [
                1
            ],
            "mergedBy": [
                29
            ],
            "mergedById": [
                1
            ],
            "totalMergedTransactionsChunks": [
                47
            ],
            "currentlyMergedTransactionsChunks": [
                47
            ],
            "createdAt": [
                461
            ],
            "updatedAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "SwitchCompanyArgs": {
            "companyId": [
                1
            ],
            "companyIdToSwitchTo": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SwitchCompanyResponse": {
            "success": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "SyncCompanyEventName": {},
        "SyncCompanyInput": {
            "eventName": [
                1651
            ],
            "body": [
                362
            ],
            "__typename": [
                1
            ]
        },
        "SyncGtmSourceInput": {
            "companyId": [
                15
            ],
            "gtmSource": [
                798
            ],
            "__typename": [
                1
            ]
        },
        "SyncInventoryIntegrationFulfillmentSettingInput": {
            "companyId": [
                15
            ],
            "source": [
                1400
            ],
            "isEnabled": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "SyncReportInput": {
            "companyId": [
                15
            ],
            "reportType": [
                1470
            ],
            "__typename": [
                1
            ]
        },
        "SyncStripeOffersInput": {
            "subscriptionProductId": [
                1
            ],
            "transitionFeeProductId": [
                1
            ],
            "catchupProductId": [
                1
            ],
            "couponCode": [
                1
            ],
            "isStripeTest": [
                7
            ],
            "description": [
                1
            ],
            "title": [
                1
            ],
            "tier": [
                1172
            ],
            "__typename": [
                1
            ]
        },
        "SyncStripeTier1Offer": {
            "yearlySubscriptionPriceId": [
                1
            ],
            "monthlySubscriptionPriceId": [
                1
            ],
            "transitionFeePriceId": [
                1
            ],
            "catchupPriceId": [
                1
            ],
            "isStripeTest": [
                7
            ],
            "description": [
                1
            ],
            "title": [
                1
            ],
            "couponCode": [
                1
            ],
            "companyId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Tax": {
            "id": [
                15
            ],
            "amount": [
                5
            ],
            "description": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Tax1099AffiliatedEntity": {
            "affiliatedEntity": [
                51
            ],
            "type": [
                1665
            ],
            "calendarYear": [
                47
            ],
            "totalPayments": [
                5
            ],
            "businessEvents": [
                1
            ],
            "sources": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Tax1099AffiliatedEntityDisqualifyReason": {},
        "Tax1099AffiliatedEntityGroup": {
            "id": [
                15
            ],
            "groupName": [
                1
            ],
            "isGroup": [
                7
            ],
            "type": [
                1665
            ],
            "calendarYear": [
                47
            ],
            "affiliatedEntities": [
                1659
            ],
            "totalPayments": [
                5
            ],
            "businessEvents": [
                1
            ],
            "sources": [
                1
            ],
            "disqualifyReason": [
                1660
            ],
            "file": [
                1662
            ],
            "markedAsDoneAt": [
                461
            ],
            "finaloopComment": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Tax1099AffiliatedEntityGroupFile": {
            "url": [
                1
            ],
            "name": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Tax1099AffiliatedEntityGroupFileInput": {
            "url": [
                1
            ],
            "name": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Tax1099AffiliatedEntityGroupsByType": {
            "type": [
                1665
            ],
            "calendarYear": [
                47
            ],
            "affiliatedEntityGroups": [
                1661
            ],
            "__typename": [
                1
            ]
        },
        "Tax1099AffiliatedEntityType": {},
        "TaxInfo": {
            "amount": [
                5
            ],
            "description": [
                1
            ],
            "isTaxInclusive": [
                7
            ],
            "createdAt": [
                461
            ],
            "updatedAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "TikTokProduct": {
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "finaloopId": [
                1
            ],
            "lineOfBusinessId": [
                1
            ],
            "sku": [
                1
            ],
            "price": [
                5
            ],
            "displayName": [
                1
            ],
            "imageUrl": [
                1
            ],
            "listedChannels": [
                1495
            ],
            "appliedCosts": [
                1370
            ],
            "dailySalesData": [
                1496,
                {
                    "timeRange": [
                        1386,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "totalSold": [
                47
            ],
            "avgCostPerUnit": [
                5
            ],
            "asin": [
                1
            ],
            "canEditLatestAppliedCost": [
                7
            ],
            "isFractional": [
                7
            ],
            "isHidden": [
                7
            ],
            "salesData": [
                1510,
                {
                    "timeRange": [
                        1386,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "rawRecipes": [
                1431,
                {
                    "timeRange": [
                        1386,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "componentOfProducts": [
                1368
            ],
            "isAmbiguousSku": [
                7
            ],
            "analyticsOverview": [
                1369,
                {
                    "input": [
                        80
                    ]
                }
            ],
            "warehousesAnalyticsOverview": [
                1408,
                {
                    "input": [
                        80
                    ]
                }
            ],
            "tags": [
                1
            ],
            "isCreatedManually": [
                7
            ],
            "isDataSyncing": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "TimeRange": {
            "fromUTC": [
                461
            ],
            "toUTC": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "TimeRangeInput": {
            "fromUTC": [
                461
            ],
            "toUTC": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "ToggleOrdersZeroNetBalanceInput": {
            "companyId": [
                1
            ],
            "isZeroNetOrdersSupported": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "ToggleRecognizeZeroNetOrdersAsGiveawaysInput": {
            "companyId": [
                15
            ],
            "shouldRecognizeZeroNetCogsAsGiveaways": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "ToggleZeroNetBalanceInput": {
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "Topup": {
            "id": [
                15
            ],
            "companyId": [
                1
            ],
            "amount": [
                5
            ],
            "amountInUsd": [
                5
            ],
            "currency": [
                1
            ],
            "eventTime": [
                461
            ],
            "deletedAt": [
                461
            ],
            "markedAsReconciliationDiscrepancyAt": [
                461
            ],
            "integrationType": [
                876
            ],
            "pairedBankTransaction": [
                186
            ],
            "type": [
                873
            ],
            "classification": [
                862
            ],
            "integrationAccountId": [
                15
            ],
            "integrationAccountName": [
                1
            ],
            "pairedCompanyAffiliate": [
                326
            ],
            "link": [
                1
            ],
            "uploadedFileId": [
                1
            ],
            "failedAt": [
                461
            ],
            "markedAsUnidentifiedAt": [
                461
            ],
            "rawEvent": [
                988
            ],
            "__typename": [
                1
            ]
        },
        "TransactionDescription": {
            "description": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "TransactionItemToReconcile": {
            "companyId": [
                15
            ],
            "transactionId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "TransactionKind": {},
        "TransactionLoan": {
            "transaction": [
                186
            ],
            "loan": [
                353
            ],
            "isLocked": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "TransactionPaymentType": {},
        "TransactionsImportStatus": {
            "stopImportFromDate": [
                1
            ],
            "changedBy": [
                1
            ],
            "changedAt": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "TransactionType": {},
        "TransactionUpdatedInput": {
            "companyId": [
                15
            ],
            "transactionId": [
                1
            ],
            "actorType": [
                29
            ],
            "__typename": [
                1
            ]
        },
        "TransitionFeeInput": {
            "stripeId": [
                1
            ],
            "price": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "TriggerCOGSFromScratchInput": {
            "companyId": [
                15
            ],
            "managementMethod": [
                1086
            ],
            "productIds": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UnclassifyIntegrationTransferInput": {
            "ids": [
                15
            ],
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "UnconnectedAccount": {
            "vendor": [
                1853
            ],
            "__typename": [
                1
            ]
        },
        "UnidentifiedBankTransactionParty": {
            "on_BankAccount": [
                113
            ],
            "on_UnknownTransactionBank": [
                1707
            ],
            "on_UnknownTransactionAccount": [
                1706
            ],
            "on_UnconnectedAccount": [
                1685
            ],
            "on_Vendor": [
                1853
            ],
            "on_TransactionDescription": [
                1674
            ],
            "__typename": [
                1
            ]
        },
        "UnidentifiedBankTransactionsGroup": {
            "transactions": [
                186
            ],
            "payer": [
                1686
            ],
            "payee": [
                1686
            ],
            "groupId": [
                15
            ],
            "isProcessing": [
                7
            ],
            "action": [
                1690
            ],
            "tooltipText": [
                1
            ],
            "type": [
                1689
            ],
            "__typename": [
                1
            ]
        },
        "UnidentifiedBankTransactionsGroupsInput": {
            "companyId": [
                15
            ],
            "fromDate": [
                461
            ],
            "toDate": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "UnidentifiedBankTransactionsGroupType": {},
        "UnidentifiedTransfersGroupAction": {},
        "UnitBasedClosingBalance": {
            "missionType": [
                950
            ],
            "status": [
                949
            ],
            "historyLog": [
                946
            ],
            "managementMethodPeriod": [
                1087
            ],
            "closingBalance": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "UnitBasedClosingBalanceInput": {
            "closingBalance": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "UnitBasedNegativeOnHands": {
            "missionType": [
                950
            ],
            "status": [
                949
            ],
            "historyLog": [
                946
            ],
            "managementMethodPeriod": [
                1087
            ],
            "productsWithNegativeOnHands": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "UnitBasedNegativeOnHandsInput": {
            "productsWithNegativeOnHands": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "UnitBasedReportCogs": {
            "missionType": [
                950
            ],
            "status": [
                949
            ],
            "historyLog": [
                946
            ],
            "managementMethodPeriod": [
                1087
            ],
            "__typename": [
                1
            ]
        },
        "UnitBasedVendorsNegativeBalance": {
            "missionType": [
                950
            ],
            "status": [
                949
            ],
            "historyLog": [
                946
            ],
            "managementMethodPeriod": [
                1087
            ],
            "vendorsWithNegativeBalance": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "UnitBasedVendorsNegativeBalanceInput": {
            "vendorsWithNegativeBalance": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "UnitsTrackingPhysicalCount": {
            "id": [
                15
            ],
            "companyId": [
                1
            ],
            "amountAdjustment": [
                5
            ],
            "lastAckAmountAdjustment": [
                5
            ],
            "amountAdjustmentWithPendingAdjustments": [
                5
            ],
            "date": [
                461
            ],
            "warehouse": [
                1871
            ],
            "attachments": [
                1699
            ],
            "closingBalance": [
                5
            ],
            "amount": [
                5
            ],
            "note": [
                1
            ],
            "isDraft": [
                7
            ],
            "deletedAt": [
                461
            ],
            "hasUnacknowledgedChanges": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "UnitsTrackingPhysicalCountAttachment": {
            "name": [
                1
            ],
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UnitsTrackingPhysicalCountOverview": {
            "missingData": [
                5
            ],
            "count": [
                5
            ],
            "pendingChanges": [
                5
            ],
            "productQuantities": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "UnitsTrackingPhysicalCountProductQuantities": {
            "products": [
                1702
            ],
            "offset": [
                5
            ],
            "count": [
                5
            ],
            "hasUnacknowledgedChanges": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "UnitsTrackingPhysicalCountProductQuantity": {
            "product": [
                1368
            ],
            "quantity": [
                5
            ],
            "avgCost": [
                5
            ],
            "lastAckAvgCost": [
                5
            ],
            "quantityAdjustment": [
                5
            ],
            "lastAckQuantityAdjustment": [
                5
            ],
            "amountAdjustment": [
                5
            ],
            "lastAckAmountAdjustment": [
                5
            ],
            "unitsOnHand": [
                5
            ],
            "lastAckUnitsOnHand": [
                5
            ],
            "hasUnacknowledgedChanges": [
                7
            ],
            "isPendingChange": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "UnitsTrackingPhysicalCountsList": {
            "physicalCounts": [
                1698
            ],
            "offset": [
                47
            ],
            "count": [
                47
            ],
            "totalCount": [
                47
            ],
            "minAdjustment": [
                5
            ],
            "maxAdjustment": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "UnknownIntegrationTransferAccount": {
            "dummy": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UnknownIntegrationTransferBank": {
            "dummy": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UnknownTransactionAccount": {
            "vendor": [
                1853
            ],
            "__typename": [
                1
            ]
        },
        "UnknownTransactionBank": {
            "vendor": [
                1853
            ],
            "__typename": [
                1
            ]
        },
        "UnlockCompanyV2Input": {
            "companyId": [
                1
            ],
            "startDate": [
                461
            ],
            "endDate": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "UnlockLoanResult": {
            "success": [
                7
            ],
            "message": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UnMarkOrdersAsOvercollectedInput": {
            "companyId": [
                15
            ],
            "orderIds": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "UnMarkOrdersAsOvercollectedResponse": {
            "orders": [
                1260
            ],
            "__typename": [
                1
            ]
        },
        "UnMarkOrdersAsUncollectibleInput": {
            "companyId": [
                15
            ],
            "orderIds": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "UnMarkOrdersAsUncollectibleResponse": {
            "orders": [
                1260
            ],
            "__typename": [
                1
            ]
        },
        "UnpaidAmountPerAffiliatedEntity": {
            "affiliatedEntity": [
                51
            ],
            "totalSum": [
                5
            ],
            "unpaidAmount": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "UnpaidAmountsViaProvider": {
            "type": [
                1716
            ],
            "categoryGroup": [
                1324
            ],
            "amount": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "UnpaidAmountsViaProviderType": {},
        "UnsignWorksheetRowInputV2": {
            "companyId": [
                1
            ],
            "name": [
                1
            ],
            "periodDates": [
                1357
            ],
            "__typename": [
                1
            ]
        },
        "UnvoidOrdersInput": {
            "companyId": [
                15
            ],
            "orderIds": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "UnvoidOrdersResponse": {
            "orders": [
                1260
            ],
            "failedIds": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "UpdateAppliedCostFromIntegrationInput": {
            "appliedCosts": [
                84
            ],
            "companyId": [
                15
            ],
            "source": [
                1
            ],
            "impactOnBooksSince": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "UpdateAuth0UserDetailsArgs": {
            "userEmail": [
                1
            ],
            "companyName": [
                1
            ],
            "userName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateBankAccountBalanceAdjustmentInput": {
            "companyId": [
                15
            ],
            "bankAccountId": [
                15
            ],
            "balanceAdjustment": [
                5
            ],
            "balanceAdjustmentDate": [
                1
            ],
            "actorType": [
                114
            ],
            "actorId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateBankAccountClosingBalanceInput": {
            "companyId": [
                15
            ],
            "bankAccountId": [
                15
            ],
            "closingBalance": [
                5
            ],
            "closingBalanceDate": [
                1
            ],
            "actorType": [
                117
            ],
            "actorId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateBankAccountInput": {
            "companyId": [
                15
            ],
            "id": [
                15
            ],
            "integrationAccountId": [
                1
            ],
            "currentBalance": [
                5
            ],
            "lastUpdateTime": [
                461
            ],
            "isHistoricalUpdateCompleted": [
                7
            ],
            "rawData": [
                989
            ],
            "shouldUpdateAccountDetailsOnNextUpdate": [
                7
            ],
            "status": [
                138
            ],
            "forwardTransactionsToBankAccountId": [
                1
            ],
            "hasNotifiedUponNoHistoricalUpdate": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "UpdateBankAccountsMergeStatusInput": {
            "companyId": [
                15
            ],
            "suspectedDuplicationId": [
                15
            ],
            "fileLocationBucket": [
                1
            ],
            "fileLocationKey": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateBankReconciliationRequestMessageInput": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "message": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateBankReconciliationRequestStatusInput": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "status": [
                180
            ],
            "__typename": [
                1
            ]
        },
        "UpdateBankStatementParsingResultStatus": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "bankTransactionsUploadId": [
                1
            ],
            "bankStatementFileId": [
                1
            ],
            "status": [
                174
            ],
            "__typename": [
                1
            ]
        },
        "UpdateBankTransactionMemoInput": {
            "companyId": [
                15
            ],
            "bankTransactionId": [
                15
            ],
            "text": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateBooksStartTimeArgs": {
            "companyId": [
                1
            ],
            "booksStartTime": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "UpdateCheckDetails": {},
        "UpdateCheckTypes": {},
        "UpdateCommentsInputV2": {
            "companyId": [
                1
            ],
            "name": [
                1
            ],
            "comment": [
                1
            ],
            "attachmentUrl": [
                1
            ],
            "periodDates": [
                1357
            ],
            "__typename": [
                1
            ]
        },
        "UpdateCompaniesDomainStateInput": {
            "domain": [
                1952
            ],
            "year": [
                47
            ],
            "companiesState": [
                342
            ],
            "__typename": [
                1
            ]
        },
        "UpdateCompanyAccountingMethodArgs": {
            "companyId": [
                1
            ],
            "accountingMethod": [
                448
            ],
            "flow": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateCompanyAffiliateInput": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "name": [
                1
            ],
            "isForeignResident": [
                7
            ],
            "holdingsInPercentage": [
                5
            ],
            "status": [
                816
            ],
            "__typename": [
                1
            ]
        },
        "UpdateCompanyDomainStateResponse": {
            "companyId": [
                15
            ],
            "yearEndDomainStateId": [
                1
            ],
            "updateCheck": [
                1732
            ],
            "state": [
                1939
            ],
            "details": [
                1731
            ],
            "__typename": [
                1
            ]
        },
        "UpdateCompanyInput": {
            "companyId": [
                15
            ],
            "onboardingState": [
                1175
            ],
            "estimatedProjectedRevenue": [
                542
            ],
            "phoneNumber": [
                1
            ],
            "isOnboardingCompleted": [
                7
            ],
            "appsSelectedInPreOnboarding": [
                1
            ],
            "referralCode": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateCompanyNamesAndNotifyHandlerInput": {
            "companyId": [
                15
            ],
            "userName": [
                1
            ],
            "companyName": [
                1
            ],
            "estimatedProjectedRevenue": [
                542
            ],
            "phoneNumber": [
                1
            ],
            "appsSelectedInPreOnboarding": [
                1
            ],
            "referralCode": [
                1
            ],
            "accountingServices": [
                9
            ],
            "demo": [
                1
            ],
            "preOnboardingFunnelType": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateCompanyPayingStatusInput": {
            "companyId": [
                1
            ],
            "isPaying": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "UpdateCompanyTierInput": {
            "companyId": [
                1
            ],
            "tier": [
                363
            ],
            "__typename": [
                1
            ]
        },
        "UpdateCompanyVendorExternalSourceIdVendorInput": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "externalSourceId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateCustomerArgs": {
            "id": [
                1
            ],
            "name": [
                1
            ],
            "companyName": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "source": [
                449
            ],
            "email": [
                1
            ],
            "phoneNumber": [
                1
            ],
            "billingAddress": [
                42
            ],
            "shippingAddress": [
                42
            ],
            "__typename": [
                1
            ]
        },
        "UpdatedBankAccountReconciliation": {
            "id": [
                15
            ],
            "companyId": [
                1
            ],
            "transactionsFileNames": [
                1
            ],
            "statementFileNames": [
                1
            ],
            "balance": [
                5
            ],
            "balanceAtIso": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdatedLoans": {
            "loans": [
                1012
            ],
            "__typename": [
                1
            ]
        },
        "UpdateEmployeesOfficerStatusChange": {
            "employeeId": [
                15
            ],
            "isOfficer": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "UpdateEmployeesOfficerStatusInput": {
            "companyId": [
                15
            ],
            "changes": [
                1746
            ],
            "__typename": [
                1
            ]
        },
        "UpdateEstimatedProjectedRevenueInput": {
            "companyId": [
                1
            ],
            "estimatedProjectedRevenue": [
                542
            ],
            "__typename": [
                1
            ]
        },
        "UpdateExpression": {
            "impactOnBooksSince": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateGlobalVendorLogo": {
            "id": [
                15
            ],
            "logoUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateGlobalVendorLogoInput": {
            "id": [
                15
            ],
            "logoUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateInstitutionInput": {
            "id": [
                15
            ],
            "name": [
                1
            ],
            "preferredProvider": [
                842
            ],
            "isMajor": [
                1
            ],
            "logoUrl": [
                1
            ],
            "websiteUrl": [
                1
            ],
            "csvInstructionsLink": [
                1
            ],
            "pdfInstructionsLink": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateInstitutionMappingInput": {
            "id": [
                15
            ],
            "source": [
                841
            ],
            "externalSourceId": [
                1
            ],
            "externalSourceName": [
                1
            ],
            "institutionId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateInternalLoanInput": {
            "companyId": [
                15
            ],
            "loanId": [
                15
            ],
            "data": [
                1063
            ],
            "merchantRole": [
                331
            ],
            "__typename": [
                1
            ]
        },
        "UpdateInventoryPaymentGroupInput": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "postedAt": [
                461
            ],
            "affiliatedEntityId": [
                15
            ],
            "purchaseOrders": [
                1300
            ],
            "warehouseTransfers": [
                1313
            ],
            "inventoryPurchases": [
                1286
            ],
            "__typename": [
                1
            ]
        },
        "UpdateInventoryPaymentGroupResponse": {
            "inventoryPaymentGroup": [
                881
            ],
            "__typename": [
                1
            ]
        },
        "UpdateInventoryPurchaseResponse": {
            "on_InventoryPurchase": [
                894
            ],
            "on_InventoryPurchaseUpdateOutOfDateError": [
                919
            ],
            "__typename": [
                1
            ]
        },
        "UpdateInventoryYearEndMissionInput": {
            "companyId": [
                15
            ],
            "missions": [
                1141
            ],
            "__typename": [
                1
            ]
        },
        "UpdateInvoicePaymentGroupInput": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "postedAt": [
                461
            ],
            "invoices": [
                1287
            ],
            "bankTransactions": [
                1281
            ],
            "expenses": [
                1284
            ],
            "actorType": [
                29
            ],
            "__typename": [
                1
            ]
        },
        "UpdateInvoicePaymentGroupResponse": {
            "paymentGroup": [
                957
            ],
            "__typename": [
                1
            ]
        },
        "UpdateLoanAttachmentInput": {
            "id": [
                15
            ],
            "fileName": [
                1
            ],
            "url": [
                1
            ],
            "type": [
                1020
            ],
            "__typename": [
                1
            ]
        },
        "UpdateLoanAttachmentsInput": {
            "companyId": [
                15
            ],
            "loanId": [
                15
            ],
            "attachments": [
                1761
            ],
            "__typename": [
                1
            ]
        },
        "UpdateLoanInput": {
            "companyId": [
                15
            ],
            "loanId": [
                15
            ],
            "data": [
                1062
            ],
            "__typename": [
                1
            ]
        },
        "UpdateLoanManualAccountingEventInput": {
            "companyId": [
                15
            ],
            "loanId": [
                15
            ],
            "accountingEventId": [
                15
            ],
            "amount": [
                5
            ],
            "memo": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateLoansInput": {
            "loans": [
                1766
            ],
            "data": [
                1063
            ],
            "__typename": [
                1
            ]
        },
        "UpdateLoansInputLoan": {
            "companyId": [
                15
            ],
            "loanId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "UpdateManualAssemblyInput": {
            "companyId": [
                15
            ],
            "id": [
                1
            ],
            "productId": [
                1
            ],
            "displayName": [
                1
            ],
            "date": [
                461
            ],
            "warehouseId": [
                1
            ],
            "unitsToAssemble": [
                47
            ],
            "recipe": [
                1786
            ],
            "__typename": [
                1
            ]
        },
        "UpdateManualAssemblyResult": {
            "on_ManualAssembly": [
                1091
            ],
            "on_RecipeCirclesFoundError": [
                1433
            ],
            "__typename": [
                1
            ]
        },
        "UpdateManualLoanEventInput": {
            "companyId": [
                15
            ],
            "loanId": [
                15
            ],
            "loanEventId": [
                15
            ],
            "eventDefinitionId": [
                15
            ],
            "amount": [
                5
            ],
            "memo": [
                1
            ],
            "postedAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "UpdateManualLoanEventInput2": {
            "companyId": [
                15
            ],
            "loanId": [
                15
            ],
            "loanEventId": [
                15
            ],
            "eventDefinitionId": [
                15
            ],
            "amount": [
                5
            ],
            "memo": [
                1
            ],
            "postedAt": [
                461
            ],
            "recurrenceSettings": [
                1452
            ],
            "spreadInput": [
                427
            ],
            "__typename": [
                1
            ]
        },
        "UpdateManualLoanEventResult": {
            "loan": [
                1012
            ],
            "__typename": [
                1
            ]
        },
        "UpdateManualLoanEventsResult": {
            "loan": [
                1012
            ],
            "__typename": [
                1
            ]
        },
        "UpdateNoUnitsPhysicalCountAttachmentInput": {
            "name": [
                1
            ],
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateNoUnitsPhysicalCountInput": {
            "id": [
                1
            ],
            "amount": [
                5
            ],
            "date": [
                461
            ],
            "companyId": [
                1
            ],
            "lastAckDiff": [
                5
            ],
            "lastAckClosingBalance": [
                5
            ],
            "attachments": [
                1773
            ],
            "note": [
                1
            ],
            "isDraft": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "UpdateNoUnitsPhysicalCountProductQuantityPendingAdjustmentInput": {
            "productId": [
                1
            ],
            "quantity": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "UpdateNoUnitsPhysicalCountProductQuantityPendingAdjustmentsInput": {
            "physicalCountId": [
                1
            ],
            "companyId": [
                1
            ],
            "adjustments": [
                1775
            ],
            "__typename": [
                1
            ]
        },
        "UpdateOnboardingStatusInput": {
            "companyId": [
                15
            ],
            "scope": [
                1173
            ],
            "step": [
                1
            ],
            "status": [
                1178
            ],
            "__typename": [
                1
            ]
        },
        "UpdateOrderEntitiesInput": {
            "entityType": [
                1194
            ],
            "companyId": [
                1
            ],
            "entityIds": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "UpdateOrderGroupInput": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "postedAt": [
                461
            ],
            "orders": [
                1255
            ],
            "bankTransactions": [
                217
            ],
            "expenses": [
                1205
            ],
            "__typename": [
                1
            ]
        },
        "UpdateOrderGroupResponse": {
            "orderGroup": [
                1200
            ],
            "__typename": [
                1
            ]
        },
        "UpdateOrderPaymentGroupInput": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "postedAt": [
                461
            ],
            "orders": [
                1299
            ],
            "bankTransactions": [
                1281
            ],
            "expenses": [
                1284
            ],
            "__typename": [
                1
            ]
        },
        "UpdateOrderPaymentGroupResponse": {
            "paymentGroup": [
                1230
            ],
            "__typename": [
                1
            ]
        },
        "UpdatePayrollSettingsInput": {
            "companyId": [
                15
            ],
            "withCategoryMapping": [
                7
            ],
            "categoryMappingMode": [
                1327
            ],
            "categoryMappings": [
                1326
            ],
            "__typename": [
                1
            ]
        },
        "UpdateProductFractionalInput": {
            "companyId": [
                1
            ],
            "productId": [
                1
            ],
            "isFractional": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "UpdateProductInput": {
            "companyId": [
                1
            ],
            "productId": [
                1
            ],
            "displayName": [
                1
            ],
            "imageUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateRecipeInput": {
            "companyId": [
                15
            ],
            "id": [
                1
            ],
            "from": [
                461
            ],
            "to": [
                461
            ],
            "isAssembledOnDemand": [
                7
            ],
            "productId": [
                1
            ],
            "warehouseId": [
                1
            ],
            "assemblyCost": [
                5
            ],
            "components": [
                1436
            ],
            "managementMethod": [
                1437
            ],
            "displayName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateRecipeResult": {
            "on_Recipe": [
                1431
            ],
            "on_RecipeCirclesFoundError": [
                1433
            ],
            "on_RecipeOverlappingError": [
                1438
            ],
            "__typename": [
                1
            ]
        },
        "UpdateSalesChannelInput": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "financialReportingStatus": [
                586
            ],
            "periods": [
                1501
            ],
            "__typename": [
                1
            ]
        },
        "UpdateSharedGoogleDriveFolder": {
            "companyId": [
                15
            ],
            "folderId": [
                15
            ],
            "folderName": [
                1
            ],
            "folderUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateUnitsTrackingPhysicalCountAttachmentInput": {
            "name": [
                1
            ],
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateUnitsTrackingPhysicalCountInput": {
            "id": [
                15
            ],
            "date": [
                461
            ],
            "warehouseId": [
                1
            ],
            "companyId": [
                1
            ],
            "attachments": [
                1790
            ],
            "note": [
                1
            ],
            "isDraft": [
                7
            ],
            "lastAckClosingBalance": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "UpdateUnitsTrackingPhysicalCountProductQuantityPendingAdjustmentInput": {
            "productId": [
                1
            ],
            "quantity": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "UpdateUnitsTrackingPhysicalCountProductQuantityPendingAdjustmentsInput": {
            "companyId": [
                1
            ],
            "physicalCountId": [
                1
            ],
            "adjustments": [
                1792
            ],
            "__typename": [
                1
            ]
        },
        "UpdateUserDetailsArgs": {
            "id": [
                15
            ],
            "email": [
                1
            ],
            "name": [
                1
            ],
            "auth0Id": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateUserDetailsResponse": {
            "user": [
                1818
            ],
            "__typename": [
                1
            ]
        },
        "UpdateUserRolesForCompanyArgs": {
            "email": [
                1
            ],
            "roles": [
                1
            ],
            "companyId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateUserRolesForCompanyResponse": {
            "success": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "UpdateVendorInput": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "source": [
                1863
            ],
            "name": [
                1
            ],
            "logoUrl": [
                1
            ],
            "labels": [
                1
            ],
            "subTypes": [
                1865
            ],
            "is1099Contractor": [
                7
            ],
            "externalSourceId": [
                1
            ],
            "contactEmail": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateWarehouseFieldsInput": {
            "name": [
                1
            ],
            "phone": [
                1
            ],
            "city": [
                1
            ],
            "province": [
                1
            ],
            "country": [
                1
            ],
            "zip": [
                1
            ],
            "address": [
                1
            ],
            "isDefault": [
                7
            ],
            "isActive": [
                7
            ],
            "userGivenName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateWarehouseInput": {
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "updateFields": [
                1799
            ],
            "__typename": [
                1
            ]
        },
        "UpdateWarehousesDefaultsInput": {
            "companyId": [
                15
            ],
            "defaults": [
                1897
            ],
            "__typename": [
                1
            ]
        },
        "UpdateWarehouseTransferInput": {
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "serialNumber": [
                47
            ],
            "displayName": [
                1
            ],
            "transit": [
                1933
            ],
            "lineItems": [
                1921
            ],
            "costLineItems": [
                1916
            ],
            "attachments": [
                1909
            ],
            "notes": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UploadBankStatementInput": {
            "companyId": [
                15
            ],
            "bankAccountId": [
                15
            ],
            "s3Bucket": [
                1
            ],
            "s3Key": [
                1
            ],
            "fileName": [
                1
            ],
            "fileType": [
                133
            ],
            "system": [
                164
            ],
            "subSystem": [
                162
            ],
            "validationProvider": [
                163
            ],
            "__typename": [
                1
            ]
        },
        "UploadBankStatementResponse": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "bankAccount": [
                113
            ],
            "providerValidationResults": [
                183
            ],
            "__typename": [
                1
            ]
        },
        "UpsertBankTransactionLocalRuleInput": {
            "companyId": [
                15
            ],
            "bankTransactionId": [
                15
            ],
            "classification": [
                195
            ],
            "moneyDirection": [
                1608
            ],
            "merchant": [
                1118
            ],
            "shouldAlwaysApplyMerchant": [
                7
            ],
            "shouldAlwaysApplyClassification": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "UpsertCompanyAddressInput": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "isDefault": [
                7
            ],
            "deactivatedAt": [
                461
            ],
            "name": [
                1
            ],
            "source": [
                1
            ],
            "email": [
                1
            ],
            "phone": [
                1
            ],
            "line1": [
                1
            ],
            "line2": [
                1
            ],
            "city": [
                1
            ],
            "province": [
                1
            ],
            "provinceCode": [
                1
            ],
            "state": [
                1
            ],
            "stateCode": [
                1
            ],
            "country": [
                1
            ],
            "zip": [
                1
            ],
            "finaloopSubjectId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpsertCustomerAddressArgs": {
            "id": [
                1
            ],
            "customerId": [
                1
            ],
            "companyId": [
                1
            ],
            "line1": [
                1
            ],
            "line2": [
                1
            ],
            "city": [
                1
            ],
            "country": [
                1
            ],
            "countryCode": [
                1
            ],
            "province": [
                1
            ],
            "provinceCode": [
                1
            ],
            "zip": [
                1
            ],
            "state": [
                1
            ],
            "stateCode": [
                1
            ],
            "source": [
                449
            ],
            "integrationAccountId": [
                1
            ],
            "isBilling": [
                7
            ],
            "isShipping": [
                7
            ],
            "deletedAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "UpsertCustomerV2Args": {
            "id": [
                1
            ],
            "affiliatedEntityId": [
                1
            ],
            "companyId": [
                1
            ],
            "name": [
                1
            ],
            "email": [
                1
            ],
            "source": [
                449
            ],
            "integrationAccountId": [
                1
            ],
            "billingAddress": [
                445
            ],
            "shippingAddress": [
                445
            ],
            "billingIsSameAsShipping": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "UpsertGlobalVendorInput": {
            "id": [
                15
            ],
            "name": [
                1
            ],
            "source": [
                1863
            ],
            "websiteUrl": [
                1
            ],
            "labels": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpsertHolderCapitalInvestmentInput": {
            "id": [
                15
            ],
            "holderId": [
                15
            ],
            "companyId": [
                15
            ],
            "amount": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "UpsertHolderShareInput": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "holderId": [
                15
            ],
            "type": [
                1568
            ],
            "series": [
                1
            ],
            "amount": [
                5
            ],
            "parValue": [
                5
            ],
            "quantity": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "UpsertIntegrationCustomerArgs": {
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "name": [
                1
            ],
            "email": [
                1
            ],
            "source": [
                449
            ],
            "integrationAccountId": [
                1
            ],
            "finaloopSubjectId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpsertIntegrationInvoiceInput": {
            "companyId": [
                15
            ],
            "companyAddress": [
                846
            ],
            "shippingAddress": [
                850
            ],
            "billingAddress": [
                850
            ],
            "customer": [
                851
            ],
            "payments": [
                860
            ],
            "refunds": [
                860
            ],
            "paymentIndications": [
                855
            ],
            "refundIndications": [
                855
            ],
            "credits": [
                860
            ],
            "externalSourceId": [
                15
            ],
            "externalSourceUrl": [
                1
            ],
            "source": [
                859
            ],
            "integrationAccountId": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "displayName": [
                1
            ],
            "postedAt": [
                461
            ],
            "dueDate": [
                461
            ],
            "taxFixedAmount": [
                5
            ],
            "lineItems": [
                857
            ],
            "discounts": [
                852
            ],
            "customerNote": [
                1
            ],
            "issueInvoice": [
                7
            ],
            "voidedAt": [
                461
            ],
            "deletedAt": [
                461
            ],
            "currency": [
                440
            ],
            "__typename": [
                1
            ]
        },
        "UpsertInvoiceInput": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "companyAddressId": [
                15
            ],
            "customerId": [
                15
            ],
            "shippingAddressId": [
                15
            ],
            "billingAddressId": [
                15
            ],
            "displayName": [
                1
            ],
            "postedAt": [
                461
            ],
            "dueDate": [
                461
            ],
            "taxFixedAmount": [
                5
            ],
            "lineItems": [
                1481
            ],
            "discounts": [
                519
            ],
            "paymentMethodIds": [
                15
            ],
            "customerNote": [
                1
            ],
            "issueInvoice": [
                7
            ],
            "sendInvoice": [
                7
            ],
            "source": [
                1512
            ],
            "currency": [
                440
            ],
            "__typename": [
                1
            ]
        },
        "UpsertInvoiceSettingsInput": {
            "companyId": [
                15
            ],
            "logoImageLocation": [
                1
            ],
            "currentAutoInvoiceNumber": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "UpsertManualEquityEventInput": {
            "id": [
                15
            ],
            "holderId": [
                15
            ],
            "companyId": [
                15
            ],
            "amount": [
                5
            ],
            "businessEvent": [
                1
            ],
            "configurationId": [
                15
            ],
            "memo": [
                1
            ],
            "postedAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "UpsertPaymentMethodInput": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "type": [
                1316
            ],
            "isDefault": [
                7
            ],
            "deletedAt": [
                461
            ],
            "instructions": [
                1
            ],
            "bankName": [
                1
            ],
            "accountNumber": [
                1
            ],
            "routingNumber": [
                1
            ],
            "swift": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "User": {
            "id": [
                15
            ],
            "email": [
                1
            ],
            "auth0Id": [
                1
            ],
            "name": [
                1
            ],
            "companies": [
                1824
            ],
            "permissions": [
                1360
            ],
            "globalRole": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UserAttachTransactionsToExistingLoanInput": {
            "companyId": [
                15
            ],
            "loanId": [
                15
            ],
            "transactions": [
                104
            ],
            "__typename": [
                1
            ]
        },
        "UserAttachTransactionsToNewLoanData": {
            "lenderId": [
                1
            ],
            "amount": [
                5
            ],
            "postedAt": [
                461
            ],
            "direction": [
                1031
            ],
            "isShortTerm": [
                7
            ],
            "type": [
                1061
            ],
            "userActionStatus": [
                1065
            ],
            "__typename": [
                1
            ]
        },
        "UserAttachTransactionsToNewLoanInput": {
            "companyId": [
                15
            ],
            "transactions": [
                104
            ],
            "merchant": [
                1044
            ],
            "newLoanData": [
                1820
            ],
            "__typename": [
                1
            ]
        },
        "UserByEmailArgs": {
            "email": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UserByEmailResponse": {
            "user": [
                1818
            ],
            "__typename": [
                1
            ]
        },
        "UserCompanies": {
            "company": [
                322
            ],
            "roles": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "UserFollowUp": {
            "type": [
                1066
            ],
            "data": [
                989
            ],
            "__typename": [
                1
            ]
        },
        "UserFollowUpInput": {
            "type": [
                1066
            ],
            "data": [
                989
            ],
            "__typename": [
                1
            ]
        },
        "userIdInput": {
            "email": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UserInvitation": {
            "id": [
                15
            ],
            "email": [
                1
            ],
            "companyId": [
                15
            ],
            "roles": [
                1
            ],
            "invitedByUserId": [
                1
            ],
            "invitationCode": [
                1
            ],
            "acceptedAt": [
                461
            ],
            "cancelledBy": [
                1
            ],
            "cancelledAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "UserInvitationArgs": {
            "invitationCode": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UserInvitationResponse": {
            "userInvitation": [
                1833
            ],
            "__typename": [
                1
            ]
        },
        "UserInvitationsByEmailArgs": {
            "email": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UserInvitationsByEmailResponse": {
            "userInvitations": [
                1833
            ],
            "__typename": [
                1
            ]
        },
        "UserInvitationWithCompany": {
            "id": [
                15
            ],
            "email": [
                1
            ],
            "company": [
                322
            ],
            "roles": [
                1
            ],
            "invitedByUserId": [
                1
            ],
            "invitationCode": [
                1
            ],
            "acceptedAt": [
                461
            ],
            "cancelledBy": [
                1
            ],
            "cancelledAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "UserManualProduct": {
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "finaloopId": [
                1
            ],
            "lineOfBusinessId": [
                1
            ],
            "sku": [
                1
            ],
            "price": [
                5
            ],
            "displayName": [
                1
            ],
            "imageUrl": [
                1
            ],
            "listedChannels": [
                1495
            ],
            "appliedCosts": [
                1370
            ],
            "dailySalesData": [
                1496,
                {
                    "timeRange": [
                        1386,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "totalSold": [
                47
            ],
            "avgCostPerUnit": [
                5
            ],
            "canEditLatestAppliedCost": [
                7
            ],
            "isFractional": [
                7
            ],
            "isHidden": [
                7
            ],
            "salesData": [
                1510,
                {
                    "timeRange": [
                        1386,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "rawRecipes": [
                1431,
                {
                    "timeRange": [
                        1386,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "componentOfProducts": [
                1368
            ],
            "isAmbiguousSku": [
                7
            ],
            "analyticsOverview": [
                1369,
                {
                    "input": [
                        80
                    ]
                }
            ],
            "warehousesAnalyticsOverview": [
                1408,
                {
                    "input": [
                        80
                    ]
                }
            ],
            "tags": [
                1
            ],
            "isDataSyncing": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "UserNameFromReferralCodeInput": {
            "referralCode": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UserNameFromReferralCodeResponse": {
            "userName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UserReferralLinkArgs": {
            "companyId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UserReferralLinkResponse": {
            "referralLink": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UserRole": {
            "id": [
                1
            ],
            "name": [
                1
            ],
            "description": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UserRolesResponse": {
            "userRoles": [
                1839
            ],
            "__typename": [
                1
            ]
        },
        "UsersByEmailsArgs": {
            "emails": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UsersByEmailsResponse": {
            "users": [
                1818
            ],
            "__typename": [
                1
            ]
        },
        "ValidateInventoryPaymentGroupByEntityInput": {
            "companyId": [
                15
            ],
            "purchaseOrderId": [
                1
            ],
            "warehouseTransferId": [
                1
            ],
            "inventoryPurchaseId": [
                1
            ],
            "bankTransactionId": [
                1
            ],
            "billId": [
                1
            ],
            "payrollId": [
                1
            ],
            "perVendorBreakdown": [
                1361
            ],
            "__typename": [
                1
            ]
        },
        "ValidateInventoryPaymentGroupByEntityResponse": {
            "canceledInventoryPaymentGroups": [
                881
            ],
            "__typename": [
                1
            ]
        },
        "ValidationCycle": {
            "id": [
                1
            ],
            "status": [
                1846
            ],
            "executionId": [
                1
            ],
            "finishedCount": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "ValidationCycleStatus": {},
        "ValidationResult": {
            "companyId": [
                1
            ],
            "integrationType": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "currency": [
                1
            ],
            "accountRoles": [
                1
            ],
            "accountingMethod": [
                1
            ],
            "resultSet": [
                1848
            ],
            "__typename": [
                1
            ]
        },
        "ValidationResultSet": {
            "createdAt": [
                461
            ],
            "sampleDate": [
                461
            ],
            "sampleValue": [
                5
            ],
            "currentValue": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ValidationsArgs": {
            "integrationType": [
                1
            ],
            "accountRole": [
                1
            ],
            "resultsLimit": [
                47
            ],
            "payingCompaniesOnly": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "ValidationTemplate": {
            "id": [
                1
            ],
            "domain": [
                370
            ],
            "type": [
                374
            ],
            "endTime": [
                1851
            ],
            "isEnabled": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "ValidationTemplateEndTime": {},
        "ValueType": {},
        "Vendor": {
            "id": [
                15
            ],
            "integrationType": [
                876
            ],
            "name": [
                1
            ],
            "companyId": [
                15
            ],
            "externalSourceId": [
                1
            ],
            "websiteUrl": [
                1
            ],
            "logoUrl": [
                1
            ],
            "source": [
                1863
            ],
            "labels": [
                1
            ],
            "contactEmail": [
                1
            ],
            "is1099Contractor": [
                7
            ],
            "type": [
                1866
            ],
            "subType": [
                1865
            ],
            "affiliatedEntityId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "VendorByAffiliatedEntityIdInput": {
            "affiliatedEntityId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "VendorByIdInput": {
            "id": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "VendorByNameInput": {
            "name": [
                1
            ],
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "VendorByPaymentGatewayInput": {
            "paymentGateway": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "VendorByPaymentGatewayResponse": {
            "vendor": [
                1853
            ],
            "__typename": [
                1
            ]
        },
        "VendorNotExistError": {
            "message": [
                1
            ],
            "id": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "VendorsByIntegrationTypeInput": {
            "integrationType": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "VendorsInput": {
            "searchTerm": [
                1
            ],
            "limit": [
                47
            ],
            "companyId": [
                15
            ],
            "subTypes": [
                1865
            ],
            "__typename": [
                1
            ]
        },
        "VendorsNotificationInfoInput": {
            "subTypes": [
                1865
            ],
            "purchaseOrderId": [
                1
            ],
            "purchaseOrderGroup": [
                1
            ],
            "logrocketSessionUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "VendorSource": {},
        "VendorsTransactionsCount": {
            "id": [
                1
            ],
            "amount": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "VendorSubType": {},
        "VendorType": {},
        "VoidOrderSettings": {
            "restrictedPaymentGateways": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "VoidOrdersInput": {
            "companyId": [
                15
            ],
            "orderIds": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "VoidOrdersResponse": {
            "orders": [
                1260
            ],
            "failedIds": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "WalmartProduct": {
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "finaloopId": [
                1
            ],
            "lineOfBusinessId": [
                1
            ],
            "sku": [
                1
            ],
            "price": [
                5
            ],
            "displayName": [
                1
            ],
            "imageUrl": [
                1
            ],
            "listedChannels": [
                1495
            ],
            "appliedCosts": [
                1370
            ],
            "dailySalesData": [
                1496,
                {
                    "timeRange": [
                        1386,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "totalSold": [
                47
            ],
            "avgCostPerUnit": [
                5
            ],
            "canEditLatestAppliedCost": [
                7
            ],
            "walmartId": [
                1
            ],
            "productUrl": [
                1
            ],
            "isFractional": [
                7
            ],
            "isHidden": [
                7
            ],
            "salesData": [
                1510,
                {
                    "timeRange": [
                        1386,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "rawRecipes": [
                1431,
                {
                    "timeRange": [
                        1386,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "componentOfProducts": [
                1368
            ],
            "isAmbiguousSku": [
                7
            ],
            "analyticsOverview": [
                1369,
                {
                    "input": [
                        80
                    ]
                }
            ],
            "warehousesAnalyticsOverview": [
                1408,
                {
                    "input": [
                        80
                    ]
                }
            ],
            "tags": [
                1
            ],
            "isCreatedManually": [
                7
            ],
            "isDataSyncing": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "Warehouse": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "name": [
                1
            ],
            "source": [
                1899
            ],
            "phone": [
                1
            ],
            "city": [
                1
            ],
            "province": [
                1
            ],
            "country": [
                1
            ],
            "zip": [
                1
            ],
            "address": [
                1
            ],
            "isDeletable": [
                7
            ],
            "isActive": [
                7
            ],
            "primaryWarehouse": [
                1871
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseAction": {
            "quantity": [
                47
            ],
            "id": [
                1
            ],
            "date": [
                461
            ],
            "type": [
                1
            ],
            "on_AdjustmentRestockingAction": [
                46
            ],
            "on_CommonAction": [
                320
            ],
            "on_FulfillmentAction": [
                591
            ],
            "on_ManualAssemblyAction": [
                1092
            ],
            "on_OnDemandAssemblyAction": [
                1179
            ],
            "on_OpeningBalanceAction": [
                1182
            ],
            "on_RestockingAction": [
                1472
            ],
            "on_ShipmentAction": [
                1570
            ],
            "on_WarehouseTransferArrivalAction": [
                1906
            ],
            "on_WarehouseTransferShippingAction": [
                1924
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseActionSalesChannel": {
            "integrationType": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseCreateError": {
            "message": [
                1
            ],
            "type": [
                1875
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseCreateErrorTypeEnum": {},
        "WarehouseCreateResult": {
            "on_Warehouse": [
                1871
            ],
            "on_WarehouseCreateError": [
                1874
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseFilter": {
            "type": [
                1878
            ],
            "warehouseId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseFilterType": {},
        "WarehouseIncomingInventoryBreakdownFiltersInput": {
            "hasUnavailable": [
                7
            ],
            "searchText": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseIncomingInventoryBreakdownInput": {
            "companyId": [
                15
            ],
            "productId": [
                1
            ],
            "warehouseId": [
                1
            ],
            "sort": [
                1902
            ],
            "limit": [
                47
            ],
            "offset": [
                47
            ],
            "filters": [
                1879
            ],
            "date": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseIncomingInventoryBreakdownResult": {
            "incomingBatches": [
                827
            ],
            "totalBatches": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseIncomingInventoryOverviewInput": {
            "companyId": [
                15
            ],
            "productId": [
                1
            ],
            "date": [
                461
            ],
            "fromDate": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseIncomingInventoryOverviewResult": {
            "warehouse": [
                1871
            ],
            "analytics": [
                829
            ],
            "inTransit": [
                47
            ],
            "openingBalance": [
                1885
            ],
            "totalBatches": [
                47
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseMovements": {
            "warehouse": [
                1871
            ],
            "movements": [
                1144
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseOpeningBalance": {
            "id": [
                15
            ],
            "warehouse": [
                1871
            ],
            "product": [
                1368
            ],
            "quantity": [
                47
            ],
            "costPerUnit": [
                5
            ],
            "date": [
                461
            ],
            "isMissingUserInput": [
                7
            ],
            "attachmentId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseOpeningBalanceErrorItem": {
            "rowNumber": [
                47
            ],
            "cellNumber": [
                47
            ],
            "errorType": [
                1887
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseOpeningBalanceErrorItemType": {},
        "WarehouseOpeningBalanceItem": {
            "product": [
                1368
            ],
            "warehouseOpeningBalance": [
                1885
            ],
            "pendingWarehouseOpeningBalance": [
                1352
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseOpeningBalanceOverviewResponse": {
            "count": [
                47
            ],
            "pendingCount": [
                47
            ],
            "missingDataCount": [
                47
            ],
            "totalCost": [
                5
            ],
            "minQuantity": [
                5
            ],
            "maxQuantity": [
                5
            ],
            "minCostPerUnit": [
                5
            ],
            "maxCostPerUnit": [
                5
            ],
            "lastPendingChange": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseOpeningBalancesCsvTemplateResponse": {
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseOpeningBalanceSort": {
            "field": [
                1892
            ],
            "direction": [
                1620
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseOpeningBalanceSortField": {},
        "WarehouseOpeningBalancesResponse": {
            "items": [
                1888
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseResult": {
            "data": [
                1871
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseSalesData": {
            "warehouse": [
                1871
            ],
            "salesChannels": [
                1502
            ],
            "deadInventory": [
                464
            ],
            "giveaway": [
                786
            ],
            "donation": [
                523
            ],
            "waste": [
                1935
            ],
            "salesAdjustments": [
                1399
            ],
            "deadInventoryAdjustments": [
                1388
            ],
            "giveawayAdjustments": [
                1392
            ],
            "donationAdjustments": [
                1390
            ],
            "__typename": [
                1
            ]
        },
        "WarehousesDefaults": {
            "integrationType": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "warehouse": [
                1871
            ],
            "__typename": [
                1
            ]
        },
        "WarehousesDefaultsInput": {
            "integrationType": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "warehouseId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "WarehousesDefaultsResult": {
            "companyId": [
                15
            ],
            "defaults": [
                1896
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseSource": {},
        "WarehousesProductPurchasesBreakdownSortDirection": {},
        "WarehousesProductPurchasesBreakdownSortField": {},
        "WarehousesProductPurchasesBreakdownSortInput": {
            "field": [
                1901
            ],
            "direction": [
                1900
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTimeRangeInput": {
            "startDate": [
                461
            ],
            "endDate": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransfer": {
            "id": [
                15
            ],
            "companyId": [
                15
            ],
            "serialNumber": [
                47
            ],
            "displayName": [
                1
            ],
            "transit": [
                1932
            ],
            "lineItems": [
                1918
            ],
            "costLineItems": [
                1915
            ],
            "attachments": [
                1908
            ],
            "status": [
                1931
            ],
            "notes": [
                1
            ],
            "deletedAt": [
                461
            ],
            "productsCost": [
                5
            ],
            "transferCost": [
                5
            ],
            "totalCost": [
                5
            ],
            "isHistorical": [
                7
            ],
            "inventoryPaymentGroupLinks": [
                884
            ],
            "unpaidAmounts": [
                1714
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferArrival": {
            "date": [
                461
            ],
            "warehouse": [
                1871
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferArrivalAction": {
            "quantity": [
                47
            ],
            "id": [
                1
            ],
            "date": [
                461
            ],
            "type": [
                1
            ],
            "warehouseTransfer": [
                1904
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferArrivalInput": {
            "date": [
                461
            ],
            "warehouseId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferAttachment": {
            "filename": [
                1
            ],
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferAttachmentInput": {
            "filename": [
                1
            ],
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferBatchHistoryItem": {
            "action": [
                1872
            ],
            "landedCost": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferBreakdownBatch": {
            "quantity": [
                5
            ],
            "history": [
                1910
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferBreakdownItem": {
            "action": [
                1872
            ],
            "quantity": [
                5
            ],
            "landedCost": [
                5
            ],
            "batches": [
                1911
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferCostAllocation": {
            "costLineItemId": [
                1
            ],
            "costPerUnit": [
                5
            ],
            "lastUnitCost": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferCostAllocationInput": {
            "costLineItemId": [
                1
            ],
            "costPerUnit": [
                5
            ],
            "lastUnitCost": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferCostLineItem": {
            "id": [
                1
            ],
            "type": [
                1917
            ],
            "amount": [
                5
            ],
            "vendor": [
                1853
            ],
            "isManuallyAllocated": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferCostLineItemInput": {
            "id": [
                1
            ],
            "type": [
                1917
            ],
            "amount": [
                5
            ],
            "vendorId": [
                1
            ],
            "isManuallyAllocated": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferCostLineItemType": {},
        "WarehouseTransferLineItem": {
            "id": [
                1
            ],
            "product": [
                1368
            ],
            "quantity": [
                47
            ],
            "lineItemIdx": [
                47
            ],
            "costAllocations": [
                1913
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferLineItemFifoCost": {
            "directCost": [
                5
            ],
            "indirectCosts": [
                1920
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferLineItemFifoIndirectCost": {
            "type": [
                835
            ],
            "amount": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferLineItemInput": {
            "id": [
                1
            ],
            "productId": [
                1
            ],
            "quantity": [
                47
            ],
            "lineItemIdx": [
                47
            ],
            "costAllocations": [
                1914
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferOverviewItem": {
            "status": [
                1931
            ],
            "count": [
                47
            ],
            "totalCost": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferShipping": {
            "date": [
                461
            ],
            "warehouse": [
                1871
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferShippingAction": {
            "quantity": [
                47
            ],
            "id": [
                1
            ],
            "date": [
                461
            ],
            "type": [
                1
            ],
            "warehouseTransfer": [
                1904
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferShippingInput": {
            "date": [
                461
            ],
            "warehouseId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransfersOverview": {
            "items": [
                1922
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransfersResponse": {
            "warehouseTransfers": [
                1904
            ],
            "hasNext": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransfersSortDirection": {},
        "WarehouseTransfersSortField": {},
        "WarehouseTransfersSortInput": {
            "field": [
                1929
            ],
            "direction": [
                1928
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferStatus": {},
        "WarehouseTransferTransit": {
            "shipping": [
                1923
            ],
            "arrival": [
                1905
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferTransitInput": {
            "shipping": [
                1925
            ],
            "arrival": [
                1907
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferWithBreakdown": {
            "warehouseTransfer": [
                1904
            ],
            "date": [
                461
            ],
            "items": [
                1912
            ],
            "__typename": [
                1
            ]
        },
        "WasteCogs": {
            "amount": [
                5
            ],
            "quantity": [
                5
            ],
            "monthlyBreakdown": [
                1936
            ],
            "__typename": [
                1
            ]
        },
        "WasteMonthlyBreakdown": {
            "date": [
                461
            ],
            "quantity": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "YearEndDomainMode": {},
        "YearEndDomainsStates": {
            "banks": [
                1949
            ],
            "transactions": [
                1949
            ],
            "unclassified_transactions": [
                1949
            ],
            "unmatched_payouts_and_transfers": [
                1949
            ],
            "payments": [
                1949
            ],
            "inventory": [
                1949
            ],
            "loans": [
                1949
            ],
            "equity": [
                1949
            ],
            "payroll": [
                1949
            ],
            "sales_tax": [
                1949
            ],
            "investments": [
                1949
            ],
            "security_deposits": [
                1949
            ],
            "accounting_and_tax_information": [
                1949
            ],
            "orders": [
                1949
            ],
            "invoices": [
                1949
            ],
            "bills": [
                1949
            ],
            "__typename": [
                1
            ]
        },
        "YearEndDomainState": {},
        "YearEndDomainStateChangeLog": {
            "id": [
                15
            ],
            "createdAt": [
                461
            ],
            "updaterType": [
                1963
            ],
            "changedByUid": [
                1
            ],
            "reason": [
                1
            ],
            "oldValue": [
                1939
            ],
            "newValue": [
                1939
            ],
            "__typename": [
                1
            ]
        },
        "YearEndDomainStateDataChangeLog": {
            "id": [
                15
            ],
            "createdAt": [
                461
            ],
            "updaterType": [
                1963
            ],
            "changedByUid": [
                1
            ],
            "reason": [
                1
            ],
            "oldValue": [
                989
            ],
            "newValue": [
                989
            ],
            "__typename": [
                1
            ]
        },
        "YearEndDomainStateDataDeleteInput": {
            "companyId": [
                15
            ],
            "domain": [
                1952
            ],
            "dataKey": [
                1
            ],
            "updaterType": [
                1963
            ],
            "updatedBy": [
                1
            ],
            "reason": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "YearEndDomainStateDataDeleteResponse": {
            "deletedAt": [
                461
            ],
            "__typename": [
                1
            ]
        },
        "YearEndDomainStateDataInput": {
            "companyId": [
                15
            ],
            "domain": [
                1952
            ],
            "__typename": [
                1
            ]
        },
        "YearEndDomainStateDataResponse": {
            "id": [
                15
            ],
            "dataKey": [
                1
            ],
            "data": [
                989
            ],
            "history": [
                1941
            ],
            "__typename": [
                1
            ]
        },
        "YearEndDomainStateDataUpdateInput": {
            "companyId": [
                15
            ],
            "domain": [
                1952
            ],
            "dataKey": [
                1
            ],
            "data": [
                989
            ],
            "updaterType": [
                1963
            ],
            "updatedBy": [
                1
            ],
            "reason": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "YearEndDomainStateDataUpdateResponse": {
            "id": [
                15
            ],
            "data": [
                989
            ],
            "dataKey": [
                1
            ],
            "historyLog": [
                1941
            ],
            "__typename": [
                1
            ]
        },
        "YearEndDomainStateDatumInput": {
            "companyId": [
                15
            ],
            "domain": [
                1952
            ],
            "dataKey": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "YearEndDomainStateResponse": {
            "state": [
                1939
            ],
            "canSubmit": [
                7
            ],
            "history": [
                1940
            ],
            "__typename": [
                1
            ]
        },
        "YearEndDomainStateUpdateInput": {
            "companyId": [
                15
            ],
            "domain": [
                1952
            ],
            "state": [
                1939
            ],
            "updatedBy": [
                1
            ],
            "updaterType": [
                1963
            ],
            "reason": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "YearEndDomainStateUpdateResponse": {
            "state": [
                1939
            ],
            "canSubmit": [
                7
            ],
            "historyLog": [
                1940
            ],
            "__typename": [
                1
            ]
        },
        "YearEndDomainTypes": {},
        "YearEndDomainUpToDateState": {
            "companyId": [
                15
            ],
            "state": [
                1939
            ],
            "isEnabled": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "YearEndDomainUpToDateStates": {
            "companyId": [
                15
            ],
            "banks": [
                1953
            ],
            "unclassifiedTransactions": [
                1953
            ],
            "unmatchedPayoutsAndTransfers": [
                1953
            ],
            "transactions": [
                1953
            ],
            "unclassified_transactions": [
                1953
            ],
            "unmatched_payouts_and_transfers": [
                1953
            ],
            "bills": [
                1953
            ],
            "inventory": [
                1953
            ],
            "loans": [
                1953
            ],
            "orders": [
                1953
            ],
            "paymentsMissions": [
                1953
            ],
            "invoices": [
                1953
            ],
            "accountingAndTaxInformation": [
                1953
            ],
            "payroll": [
                1953
            ],
            "salesTax": [
                1953
            ],
            "securityDeposits": [
                1953
            ],
            "investments": [
                1953
            ],
            "equity": [
                1953
            ],
            "__typename": [
                1
            ]
        },
        "YearEndInventoryMission": {
            "missionType": [
                950
            ],
            "status": [
                949
            ],
            "historyLog": [
                946
            ],
            "managementMethodPeriod": [
                1087
            ],
            "on_PurchaseBasedClosingBalance": [
                1409
            ],
            "on_PurchaseBasedOpeningBalance": [
                1411
            ],
            "on_PurchaseBasedPurchases": [
                1413
            ],
            "on_SalesBasedClosingBalance": [
                1487
            ],
            "on_SalesBasedMissingCostPerUnit": [
                1489
            ],
            "on_SalesBasedPurchases": [
                1491
            ],
            "on_SalesBasedReportCogs": [
                1493
            ],
            "on_UnitBasedClosingBalance": [
                1691
            ],
            "on_UnitBasedNegativeOnHands": [
                1693
            ],
            "on_UnitBasedReportCogs": [
                1695
            ],
            "on_UnitBasedVendorsNegativeBalance": [
                1696
            ],
            "__typename": [
                1
            ]
        },
        "YearEndState": {},
        "YearEndStateAdditionalData": {
            "companyId": [
                15
            ],
            "accountingMethod": [
                1
            ],
            "legalEntityType": [
                1
            ],
            "taxEntityType": [
                1
            ],
            "entityTypeDescription": [
                1
            ],
            "fiscalYearEndDate": [
                461
            ],
            "fiscalYearStartDate": [
                461
            ],
            "finaloopDeadline": [
                461
            ],
            "finaloopLastCall": [
                461
            ],
            "filingDeadline": [
                461
            ],
            "extensionFiled": [
                7
            ],
            "daysFromFiscalEndDateToFilingDeadline": [
                47
            ],
            "salesTaxLiabilityOpeningBalance": [
                5
            ],
            "salesTaxLiabilityClosingBalance": [
                5
            ],
            "netSalesTaxCollected": [
                5
            ],
            "salesTaxPaymentToAuthorities": [
                5
            ],
            "securityDepositsClosingBalance": [
                5
            ],
            "investmentsGeneralClosingBalance": [
                5
            ],
            "payroll": [
                1335
            ],
            "__typename": [
                1
            ]
        },
        "YearEndStateChangeLog": {
            "id": [
                15
            ],
            "createdAt": [
                461
            ],
            "updaterType": [
                1963
            ],
            "changedByUid": [
                1
            ],
            "reason": [
                1
            ],
            "oldValue": [
                1956
            ],
            "newValue": [
                1956
            ],
            "__typename": [
                1
            ]
        },
        "YearEndStateInput": {
            "companyId": [
                15
            ],
            "__typename": [
                1
            ]
        },
        "YearEndStateResponse": {
            "id": [
                15
            ],
            "companyId": [
                1
            ],
            "state": [
                1956
            ],
            "year": [
                47
            ],
            "domainsStates": [
                1938
            ],
            "domainsUpToDateStates": [
                1954
            ],
            "additionalData": [
                1957
            ],
            "history": [
                1958
            ],
            "isQualified": [
                7
            ],
            "isUserUnresponsive": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "YearEndStateUpdateInput": {
            "companyId": [
                15
            ],
            "state": [
                1956
            ],
            "updatedBy": [
                1
            ],
            "updaterType": [
                1963
            ],
            "reason": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "YearEndStateUpdateResponse": {
            "state": [
                1956
            ],
            "__typename": [
                1
            ]
        },
        "YearEndStateUpdaterType": {},
        "YearEndUpdateUserUnresponsiveInput": {
            "companyId": [
                15
            ],
            "isUserUnresponsive": [
                7
            ],
            "updatedBy": [
                1
            ],
            "updaterType": [
                1963
            ],
            "reason": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "YearEndUpdateUserUnresponsiveResponse": {
            "isUserUnresponsive": [
                7
            ],
            "historyLog": [
                1966
            ],
            "__typename": [
                1
            ]
        },
        "YearEndUserUnresponsiveChangeLog": {
            "id": [
                15
            ],
            "createdAt": [
                461
            ],
            "updaterType": [
                1963
            ],
            "changedByUid": [
                1
            ],
            "reason": [
                1
            ],
            "oldValue": [
                7
            ],
            "newValue": [
                7
            ],
            "__typename": [
                1
            ]
        }
    }
}